import React from "react";
import "./ViewMode.css";
import "react-datepicker/dist/react-datepicker.css"
import Cookies from "js-cookie";
import { faPlane, faPlaneDeparture, faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { layer } from "@fortawesome/fontawesome-svg-core";
import { toast } from "react-toastify";
import { toastOptions } from "../../common/Utils";
import axiosInstance from "../../common/interceptor";
import { HOST } from "../../common/constant";

export default class ViewMode extends React.Component<{
    map: any
}, {
    trafficLayer: number,
    satMode: any,
    useTraffic: any,
    useSatellite: any
}> {
    constructor(props: any) {
        super(props)
        this.state = {
            trafficLayer: Number(Cookies.get('trafficLayer')) || 0,
            satMode: Number(Cookies.get('satMode')) || 2,
            useTraffic: false,
            useSatellite: false
        }
    };
    trafficLayer: any = null;
    TRAFFIC = 1;
    SATELLITE = 2;
    DIM_2D = 2;
    DIM_3D = 3;
    ZOOM_ERROR_MSG = "現在のズームで3Dモデルが表示できません。ズームインしてください。";

    refreshInterval = 0;

    async componentWillReceiveProps(nextProps: any) {
        if (nextProps.map && !this.trafficLayer) {
			this.trafficLayer = new google.maps.TrafficLayer();
            let user: any = JSON.parse(Cookies.get('user') || "{}");
            var api = "/Form/V1/Dat/GPS/GetSetting?siteID=" + user.siteId + "&sysID=" + user.siteId;
            let siteSetting: any = await
                axiosInstance
                    .get(HOST() + api)
                        .then(res => res.data.data)
            this.refreshInterval = siteSetting.trafficRefresh * 60000;
            if (this.isTraffic()) {
                this.updateTrafficOnMap(nextProps.map, 1);

            } else if (this.isSatelite()) {
                nextProps.map.setMapTypeId('satellite');
                nextProps.map.setTilt(this.is2D() ? 0 : 45);
                if (nextProps.map.zoom < 18 && this.is3D()) {
                    toast.warn(this.ZOOM_ERROR_MSG, toastOptions);
                }
            }
        }
    }

    private updateTrafficOnMap(map: any, on: any) {
        const _this = this;
        if (on == 0) {
            this.trafficLayer.setMap(null);
            setTimeout(function () { _this.updateTrafficOnMap(map, 1) }, 1);
        } else {
            this.trafficLayer = new google.maps.TrafficLayer();
			if (_this.isTraffic()) {
				this.trafficLayer.setMap(map);
			}
            if (this.refreshInterval > 0) {
                setTimeout(function () { _this.updateTrafficOnMap(map, 0) }, this.refreshInterval);
            }
        }
    }

    componentWillMount() {
        let user: any = JSON.parse(Cookies.get('user') || "{}");
        axiosInstance
            .get(HOST() + `/Form/V1/Authenticate/getsite?siteId=${user.siteId}`)
            .then(response => {
                let useTraffic: any = response.data.data.useTraffic;
                this.setState({
                    useTraffic: useTraffic == 1 || useTraffic == 3,
                    useSatellite: useTraffic == 2 || useTraffic == 3
                }, () => {
                    if ((this.isTraffic() && !this.state.useTraffic)
                        || (this.isSatelite() && !this.state.useSatellite)) {
                        this.setState({ trafficLayer: 0 });
                    }
                });
            })
    }

    isTraffic = () => this.TRAFFIC == this.state.trafficLayer;
    isSatelite = () => this.SATELLITE == this.state.trafficLayer;
    is2D = () => this.DIM_2D == this.state.satMode;
    is3D = () => this.DIM_3D == this.state.satMode;

    public toggleTraffic() {
        if (!this.state.useTraffic) {
            return;
        }
        this.props.map.setMapTypeId('roadmap');
        this.trafficLayer.setMap(!this.isTraffic() ? this.props.map : null);
        Cookies.set('trafficLayer', !this.isTraffic() ? "1" : "0", { expires: 365 });
        this.setState({ trafficLayer: !this.isTraffic() ? 1 : 0 });
    }

    public toggleSat() {
        if (!this.state.useSatellite) {
            return;
        }
        const satMode = Number(Cookies.get('satMode') || this.DIM_2D );
        this.props.map.setMapTypeId(!this.isSatelite() ? 'satellite' : 'roadmap');
        Cookies.set('trafficLayer', !this.isSatelite() ? "2" : "0", { expires: 365 });
        this.setState({ trafficLayer: !this.isSatelite() ? 2 : 0, satMode: satMode }, () => {
            this.trafficLayer.setMap(this.isTraffic() ? this.props.map : null);
        });
    }

    public setDim(mode: any) {
        if (mode == this.state.satMode) {
            return;
        }
        Cookies.set('satMode', mode, { expires: 365 });
        this.setState({ satMode: mode }, () => {
            this.props.map.setTilt(this.is2D() ? 0 : 45);
            if (this.props.map.zoom < 18 && this.is3D()) {
                toast.warn(this.ZOOM_ERROR_MSG, toastOptions);
            }
        });
    }

    public render() {
        return (
            <div className="ViewMode">
                <div className={"button " + (this.isTraffic() ? 'currentSelected ' : ' ') + (this.state.useTraffic ? '' : 'disabled')} onClick={() => this.toggleTraffic()}>
                    <FontAwesomeIcon icon={faTruck} color="#000000" size="4x"></FontAwesomeIcon>
                    <div className="label">交通情報</div>
                </div>

                <div className={"button " + (this.isSatelite() ? 'currentSelected' : '') + (this.state.useSatellite ? '' : 'disabled')} onClick={() => this.toggleSat()}>
                    <FontAwesomeIcon icon={faPlaneDeparture} color="#000000" size="4x"></FontAwesomeIcon>
                    <div className="label">航空写真</div>
                </div>
                <div className="dim" style={{ display: this.isSatelite() && this.state.useSatellite ? "" : "none" }}>
                    <div>
                        <div className={"dim-btn " + (this.is2D() ? 'currentSelected' : '')} onClick={() => this.setDim(this.DIM_2D)}>2D</div>
                        <div className={"dim-btn " + (this.is3D() ? 'currentSelected' : '')} onClick={() => this.setDim(this.DIM_3D)}>3D</div>
                    </div>
                </div>
            </div>
        )
    };
}