import * as React from 'react';
import './OperationHistory.css';
import Moment from 'react-moment';
import axiosInstance from '../../common/interceptor';
import { HOST } from '../../common/constant';
import moment from 'moment';
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import eventBus from "../../common/EventBus"

export default class OperationHistoryList extends React.Component<{
    OperationHistoryList: any[],
    map: any,
    updatePath: Function,
    isEco: any,
    doSort: Function,
    recAreas: any[]
}, {
    OperationHistoryList: any[],
    selectedIndex: number,
    sortCol: string,
    sortDirectionAsc: boolean,
    pitaVersion: string,
    co2EmissionDisplay: string,
    recAreas: any[]
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            OperationHistoryList: this.props.OperationHistoryList,
            selectedIndex: -1,
            sortCol: '',
            sortDirectionAsc: true,
            pitaVersion: '0',
            co2EmissionDisplay: '',
            recAreas: []
        };
    }

    drawedPath: any = {};
    pathDrawedPosition: any = {};
    direction: any = {
        1: "往路",
        2: "復路"
    }

    componentDidMount() {
        var user: any = JSON.parse(Cookies.get('user') || "{}");
        if (user) {
            var api = "/Form/V1/Dat/GPS/GetSetting?siteID=" + user.siteId + "&sysID=" + user.siteId;
            axiosInstance
                .get(HOST() + api)
                .then(res => {
                    const data = res.data.data;
                    this.setState({
                        pitaVersion: user.pitaVersion,
                        co2EmissionDisplay: data.co2EmissionDisplay
                    });

                }).catch(e => {
                    toast.error('Loading settings failed!');
                });

            //kiểm tra recAreas
            var api = "/Form/V1/Dat/Area/GetRecAreas?&sysID=" + user.siteId;
            axiosInstance
                .get(HOST() + api)
                .then(res => {
                    const data = res.data.data;
                    this.setState({
                        recAreas: data

                    });

                }).catch(e => {
                    toast.error('Loading recAreas failed!');
                });


        }

        eventBus.on("changeDetailSetting", (data: any) => {
            this.checkCo2EmissionDisplay(data.co2EmissionDisplay);
        });

        eventBus.on("changeRecArea", (data: any) => {
            //kiểm tra recAreas
            var api = "/Form/V1/Dat/Area/GetRecAreas?&sysID=" + user.siteId;
            axiosInstance
                .get(HOST() + api)
                .then(res => {
                    const data = res.data.data;
                    this.setState({
                        recAreas: data

                    });

                }).catch(e => {
                    toast.error('Loading recAreas failed!');
                });
        });
    }

    private checkCo2EmissionDisplay(co2EmissionDisplay: string) {
        this.setState({ co2EmissionDisplay: co2EmissionDisplay });
    }

    private formatShortDateToMMDDhhmm(datestring: any) {
        if (!datestring) return '';
        // "updDT": "2020-03-19T12:50:32",
        let momentObj = moment(datestring, "YYYY-MM-DDTHH:mm:ss");
        let showDate = moment(momentObj).format('HH:mm');
        return showDate;
    }

    clearPath(deep: any = false) {
        if (this.drawedPath[this.state.selectedIndex]) {
            const { lineContainer, fromMarker, toMarker } = this.drawedPath[this.state.selectedIndex];
            lineContainer.forEach((e: any) => e.setMap(null));
            fromMarker.setMap(null);
            toMarker.setMap(null);
        }
        this.setState({ selectedIndex: -1 });
        this.props.updatePath(null);

        deep && (this.drawedPath = []);
    }

    async drawRoute(history: any, index: number) {
        this.clearPath();
        this.setState({ selectedIndex: index });

        const _this = this;
        const path = await axiosInstance
            .get(HOST() + `/Form/V1/Dat/Report/OperationHist/GPS?fromDate=${history.depaDT}&toDate=${history.arriDT}&CliID=${history.cliID}`)
            .then(response => {
                if (!response || !response.data || !response.data.data) {
                    return [];
                }
                return response.data.data;
            })
        const dataAccel = await axiosInstance
            .get(HOST() + `/Form/V1/Dat/Report/GetAccel?fromDate=${history.depaDT}&toDate=${history.arriDT}&CliID=${history.cliID}`)
            .then(response => {
                if (!response || !response.data || !response.data.data) {
                    return [];
                }
                return response.data.data;
            })

        const bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < path.length; i++) {
            path[i].lat = path[i].posLat;
            path[i].lng = path[i].posLon;
            bounds.extend(path[i]);
        }

        const fromMarker = new window.google.maps.Marker({
            map: this.props.map,
            position: path[0],
            draggable: false,
            animation: window.google.maps.Animation.DROP,
            title: 'ここから',
            label: { color: 'white', text: "出" }
        });
        const toMarker = new window.google.maps.Marker({
            map: this.props.map,
            position: path[path.length - 1],
            draggable: false,
            animation: window.google.maps.Animation.DROP,
            title: 'ここまで',
            label: { color: 'white', text: "到" }
        });

        const lineContainer: any = [];
        const currentDate: any = new Date();
        const lineSymbolDashed = {
            path: "M 0,-1 0,1",
            strokeOpacity: 1,
            strokeColor: 'darkgreen',
            scale: 2,
        };

        let gpsError: any = null;
        for (let i = 0; i < path.length - 1; i++) {
            const pointS: any = path[i];
            const pointE: any = path[i + 1];
            if (pointS.lat == pointE.lat
                && pointS.lng == pointE.lng) {
                if (gpsError == null) gpsError = new Date(pointS.posDT);
                continue;
            }
            const isAcc = dataAccel.filter((e: any) => pointS.posDT <= e.overDT && e.overDT <= pointE.posDT).length > 0;
            const lastUpdate: any = new Date(pointS.posDT);

            let color: any = '#03a9f4';
            if (pointS.spdOver > 0) {
                color = 'red';
            } else if (isAcc) {
                color = 'purple';
            } else if ((currentDate - lastUpdate) > 60000) {
                color = '#03a9f4';
            }
            const tempDate = moment(new Date(pointE.posDT)).add(-1, 'minutes');
            var polyline = gpsError && gpsError < tempDate ? // more than 1 minutes old
                new window.google.maps.Polyline({
                    path: [pointS, pointE],
                    strokeOpacity: 0,
                    icons: [
                        {
                            icon: lineSymbolDashed,
                            offset: "0",
                            repeat: "10px",
                        },
                    ],
                    map: this.props.map
                })
                :
                new window.google.maps.Polyline({
                    path: [pointS, pointE],
                    strokeColor: color,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: color,
                    fillOpacity: 0.35,
                    map: this.props.map
                });
            lineContainer.push(polyline);
            gpsError = null;
        }

        _this.drawedPath[index] = { lineContainer, fromMarker, toMarker, pathData: path, bounds };
        _this.pathDrawedPosition[index] = bounds.getCenter();
        this.props.updatePath(path);
        bounds && this.props.map.fitBounds(bounds);
    }

    componentWillReceiveProps(nextProps: any) {
        this.setState({
            OperationHistoryList: nextProps.OperationHistoryList,
        });
    }

    sort(colName: string) {
        if (this.state.OperationHistoryList.length == 0) return;

        if (colName == this.state.sortCol) {
            this.setState({ sortDirectionAsc: !this.state.sortDirectionAsc }, () => this.props.doSort(this.state))
        } else {
            this.setState({ sortDirectionAsc: true, sortCol: colName }, () => this.props.doSort(this.state))
        }
    }

    clearSort() {
        this.setState({ sortDirectionAsc: true, sortCol: '' })
    }

    public render() {
        const sortArrow = (colName: string) => {
            if (colName == this.state.sortCol) {
                return this.state.sortDirectionAsc ? 'up-arrow' : 'down-arrow';
            }

            return '';
        }
        return (
            <div className="table-responsive OperationHistoryList" id="OperationHistoryList">
                {this.props.isEco ? (<table className="table table-bordered table-fixed">
                    <thead>
                        <tr className="v-middle">
                            <th className={sortArrow('operDT')} onClick={() => this.sort('operDT')} >作業日</th>
                            <th className={sortArrow('cliNm')} onClick={() => this.sort('cliNm')} >端末</th>
                            <th className={sortArrow('driverNm')} onClick={() => this.sort('driverNm')} >所有者名</th>
                            <th className={sortArrow('operCount')} onClick={() => this.sort('operCount')} >回</th>
                            <th className={sortArrow('direction')} onClick={() => this.sort('direction')} >方向</th>
                            <th className={sortArrow('cargoNm')} onClick={() => this.sort('cargoNm')} >種別</th>
                            <th className={sortArrow('routeName')} onClick={() => this.sort('routeName')}>ルート</th>
                            <th className={sortArrow('departure')} onClick={() => this.sort('departure')} >出発</th>
                            <th className={sortArrow('arrival')} onClick={() => this.sort('arrival')} >目的</th>
                            <th className={sortArrow('depaDT')} onClick={() => this.sort('depaDT')} >発時</th>

                            {
                                Cookies.get('pv') === '2' && (
                                    <th className={sortArrow('pausDT')} onClick={() => this.sort('pausDT')} >現場到着</th>
                                )
                            }

                            <th className={sortArrow('arriDT')} onClick={() => this.sort('arriDT')} >
                                {Cookies.get('pv') !== '2' ? '着時' : '荷降し終了'}
                            </th>


                            <th className={sortArrow('ecoCount')} onClick={() => this.sort('ecoCount')} >加速度超過</th>
                            <th className={sortArrow('mileage')} onClick={() => this.sort('mileage')} >走行距離(km)</th>
                            <th className={sortArrow('eco1')} onClick={() => this.sort('eco1')} >ルート逸脱回数</th>
                            <th className={sortArrow('eco2')} onClick={() => this.sort('eco2')} >速度超過回数</th>
                            {
                                this.state.co2EmissionDisplay && this.state.co2EmissionDisplay === 'True' && <th className={sortArrow('co2EmissionDisplay')} onClick={() => this.sort('co2EmissionDisplay')} >CO2排出量(kg-CO2)</th>
                            }
                            {
                                this.state.recAreas && this.state.recAreas.length > 0 && this.state.recAreas.map((a: any) => (
                                    <>
                                        <th>エリアID：{a.areaID}<br />入場時刻</th>
                                        <th>エリアID：{a.areaID}<br />退場時刻</th>
                                    </>
                                ))
                            }
                            <th className={sortArrow('eco3')} onClick={() => this.sort('eco3')} >急加速回数</th>
                            <th className={sortArrow('eco4')} onClick={() => this.sort('eco4')} >急ブレーキ回数</th>
                            <th className={sortArrow('eco5')} onClick={() => this.sort('eco5')} >急ハンドル回数</th>
                            {
                                Cookies.get('pv') === '1' && (
                                    <>
                                        <th className={sortArrow('weightCarNo')} onClick={() => this.sort('weightCarNo')} >車両番号</th>
                                        <th className={sortArrow('posDT')} onClick={() => this.sort('posDT')} >計量時刻</th>
                                        <th className={sortArrow('planeWeight')} onClick={() => this.sort('planeWeight')} >空車重量(kg)</th>
                                        <th className={sortArrow('maxWeight')} onClick={() => this.sort('maxWeight')} >最大積載重量(kg)</th>
                                        <th className={sortArrow('netWeight')} onClick={() => this.sort('netWeight')} >積載重量(kg)</th>
                                        <th className={sortArrow('grossWeight')} onClick={() => this.sort('grossWeight')} >総重量(kg)</th>
                                    </>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.OperationHistoryList.map((item: any, index: any) => (
                                <tr key={"OperationHistoryList" + index}
                                    className={this.state.selectedIndex == index ? 'selectedRow' : ''}
                                    onClick={() => this.drawRoute(item, index)}>
                                    <td>
                                        <Moment format="MM/DD" date={item.operDT} />
                                    </td>
                                    <td>{item.cliNm}</td>
                                    <td>{item.driverNm}</td>
                                    <td>{item.operCount}</td>
                                    <td>{this.direction[item.direction]}</td>
                                    <td>{item.cargoNm}</td>
                                    <td>{item.routeName}</td>
                                    <td>{item.departure}</td>
                                    <td>{item.arrival}</td>
                                    <td>{(item.depaDT == '1970-01-01T09:00:00') ? '' : this.formatShortDateToMMDDhhmm(item.depaDT)}</td>
                                    {
                                        Cookies.get('pv') === '2' && (
                                            <td>{(item.pausDT == '1970-01-01T09:00:00') ? '' : this.formatShortDateToMMDDhhmm(item.pausDT)}</td>
                                        )
                                    }
                                    <td>{(item.arriDT == '1970-01-01T09:00:00') ? '' : this.formatShortDateToMMDDhhmm(item.arriDT)}</td>
                                    <td> {item.ecoCount}</td>
                                    <td> {Math.floor(item.mileage) / 1000}</td>
                                    <td>{item.eco1}</td>
                                    <td>{item.eco2}</td>
                                    {
                                        this.state.co2EmissionDisplay && this.state.co2EmissionDisplay === 'True' && <td>{item.co2 != null ? item.co2 : 0}</td>
                                    }
                                    {
                                        item.recAreas && item.recAreas.map((a: any) => (
                                            <>
                                                <td>{a.enterTime ? moment(a.enterTime).format("HH:mm") : ''}</td>
                                                <td>{a.leaveTime ? moment(a.leaveTime).format("HH:mm") : ''}</td>
                                            </>
                                        ))
                                    }
                                    <td>{item.eco3}</td>
                                    <td>{item.eco4}</td>
                                    <td>{item.eco5}</td>
                                    {
                                        Cookies.get('pv') === '1' && (
                                            <>
                                                <td>{item.weight_CarNo}</td>
                                                <td>
                                                    {
                                                        item.posDT !== null && (
                                                            <Moment format="HH:mm" date={item.posDT} />
                                                        )
                                                    }
                                                </td>
                                                <td>{Math.floor(item.planeWeight * 100) / 100}</td>
                                                <td>{Math.floor(item.maxWeight * 100) / 100}</td>
                                                <td>{Math.floor(item.netWeight * 100) / 100}</td>
                                                <td>{Math.floor(item.grossWeight * 100) / 100}</td>
                                            </>
                                        )
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>) :
                    (<table className="table table-bordered table-fixed">
                        <thead>
                            <tr>
                                <th className={sortArrow('operDT')} onClick={() => this.sort('operDT')} >作業日</th>
                                <th className={sortArrow('cliNm')} onClick={() => this.sort('cliNm')} >端末</th>
                                <th className={sortArrow('driverNm')} onClick={() => this.sort('driverNm')} >所有者名</th>
                                <th className={sortArrow('operCount')} onClick={() => this.sort('operCount')} >回</th>
                                <th className={sortArrow('direction')} onClick={() => this.sort('direction')} >方向</th>
                                <th className={sortArrow('cargoNm')} onClick={() => this.sort('cargoNm')} >種別</th>
                                <th className={sortArrow('routeName')} onClick={() => this.sort('routeName')} >ルート</th>
                                <th className={sortArrow('departure')} onClick={() => this.sort('departure')} >出発</th>
                                <th className={sortArrow('arrival')} onClick={() => this.sort('arrival')} >目的</th>
                                <th className={sortArrow('depaDT')} onClick={() => this.sort('depaDT')} >発時</th>
                                {
                                    Cookies.get('pv') === '2' && (
                                        <th className={sortArrow('pausDT')} onClick={() => this.sort('pausDT')} >現場到着</th>
                                    )
                                }

                                <th className={sortArrow('arriDT')} onClick={() => this.sort('arriDT')} >
                                    {Cookies.get('pv') !== '2' ? '着時' : '荷降し終了'}
                                </th>

                                <th className={sortArrow('mileage')} onClick={() => this.sort('mileage')} >走行距離(km)</th>
                                <th className={sortArrow('eco1')} onClick={() => this.sort('eco1')} >ルート逸脱回数</th>
                                <th className={sortArrow('eco2')} onClick={() => this.sort('eco2')} >速度超過回数</th>
                                {
                                    this.state.co2EmissionDisplay && this.state.co2EmissionDisplay === 'True' && <th className={sortArrow('co2EmissionDisplay')} onClick={() => this.sort('co2EmissionDisplay')} >CO2排出量(kg-CO2)</th>
                                }
                                {
                                    this.state.recAreas && this.state.recAreas.length > 0 && this.state.recAreas.map((a: any) => (
                                        <>
                                            <th>エリアID：{a.areaID}<br />入場時刻</th>
                                            <th>エリアID：{a.areaID}<br />退場時刻</th>
                                        </>
                                    ))
                                }
                                {
                                    Cookies.get('pv') === '1' && (
                                        <>
                                            <th className={sortArrow('weightCarNo')} onClick={() => this.sort('weightCarNo')} >車両番号</th>
                                            <th className={sortArrow('posDT')} onClick={() => this.sort('posDT')} >計量時刻</th>
                                            <th className={sortArrow('planeWeight')} onClick={() => this.sort('planeWeight')} >空車重量(kg)</th>
                                            <th className={sortArrow('maxWeight')} onClick={() => this.sort('maxWeight')} >最大積載重量(kg)</th>
                                            <th className={sortArrow('netWeight')} onClick={() => this.sort('netWeight')} >積載重量(kg)</th>
                                            <th className={sortArrow('grossWeight')} onClick={() => this.sort('grossWeight')} >総重量(kg)</th>
                                        </>)}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.OperationHistoryList.map((item: any, index: any) => (
                                    <tr key={"OperationHistoryList" + index}
                                        className={this.state.selectedIndex == index ? 'selectedRow' : ''}
                                        onClick={() => this.drawRoute(item, index)}>
                                        <td>
                                            <Moment format="MM/DD" date={item.operDT} />
                                        </td>
                                        <td>{item.cliNm}</td>
                                        <td>{item.driverNm}</td>
                                        <td>{item.operCount}</td>
                                        <td>{this.direction[item.direction]}</td>
                                        <td>{item.cargoNm}</td>
                                        <td>{item.routeName}</td>
                                        <td>{item.departure}</td>
                                        <td>{item.arrival}</td>
                                        <td>{(item.depaDT == '1970-01-01T09:00:00') ? '' : this.formatShortDateToMMDDhhmm(item.depaDT)}</td>
                                        {
                                            Cookies.get('pv') === '2' && (
                                                <td>{(item.pausDT == '1970-01-01T09:00:00') ? '' : this.formatShortDateToMMDDhhmm(item.pausDT)}</td>
                                            )
                                        }
                                        <td>{(item.arriDT == '1970-01-01T09:00:00') ? '' : this.formatShortDateToMMDDhhmm(item.arriDT)}</td>
                                        <td> {Math.floor(item.mileage) / 1000}</td>
                                        <td>{item.eco1}</td>
                                        <td>{item.eco2}</td>
                                        {
                                            this.state.co2EmissionDisplay && this.state.co2EmissionDisplay === 'True' && <td>{item.co2 != null ? item.co2 : 0}</td>
                                        }
                                        {
                                            item.recAreas && item.recAreas.map((a: any) => (
                                                <>
                                                    <td>{a.enterTime ? moment(a.enterTime).format("HH:mm") : ''}</td>
                                                    <td>{a.leaveTime ? moment(a.leaveTime).format("HH:mm") : ''}</td>
                                                </>
                                            ))
                                        }
                                        {
                                            Cookies.get('pv') === '1' && (
                                                <>
                                                    <td>{item.weight_CarNo}</td>
                                                    <td>
                                                        {
                                                            item.posDT !== null && (
                                                                <Moment format="HH:mm" date={item.posDT} />
                                                            )
                                                        }
                                                    </td>
                                                    <td>{Math.floor(item.planeWeight * 100) / 100}</td>
                                                    <td>{Math.floor(item.maxWeight * 100) / 100}</td>
                                                    <td>{Math.floor(item.netWeight * 100) / 100}</td>
                                                    <td>{Math.floor(item.grossWeight * 100) / 100}</td>
                                                </>
                                                )
                                        }
                                     
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>)
                }
            </div>
        );
    }
}
