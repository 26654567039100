import "./AreaManagement.css";
import React from "react";
import { HOST, UNSET_OPTION } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import { toastOptions, getValidNumber } from "../../common/Utils";
import Cookies from "js-cookie";
import MultiCheckBox from "../../common/MultiCheckBox/MultiCheckBox";
import { hexToRGB, rgbToHex } from "../../common/Utils";
import SoundList from "../../common/SoundList/SoundList";
import { toast } from 'react-toastify';
import BaseSelectList from "../../common/BaseSelectList/BaseSelectList";
import eventBus from "../../common/EventBus"

export default class EditAreaManagement extends React.Component<{
    onNavi: Function,
    path: any[],
    areaId: string,
    circleRadius: number,
    circleLatitude: number,
    circleLongitude: number,
}, {
    AreaType: number,
    AutoArea: number,
    AreaId: number,
    AreaName: string,
    Longitude: string,
    Latitude: string,
    NonChkOor: any,
    OutBound: any,
    InBound: any,
    Radius: number,
    Speed: number,
    Message: string,
    Sound: number,
    Color: string,
    ValidTime: number,
    ValidTimeStart: any,
    ValidTimeEnd: any,
    ValidMin: any,
    RouteLinking: any[],
    RouteLinkingShow: boolean,
    AreaRouteLinking: any[],
    AreaRouteLinkingShow: boolean,
    Approach: any,
    WaitSec: number,
    PreArea: any,
    MailOption: number,
    Path: any[],
    Pita_Version: string,
    LaneNum: number,
    ShowLaneNumSetting: boolean,
    RecArea: boolean
}> {
    constructor(props: any) {
        super(props);
        let Radius = props.circleRadius || 0;
        if (Radius > 2000) {
            Radius = 2000;
        }

        this.state = {
            AreaType: 0,
            AutoArea: 1,
            AreaId: props.AreaId,
            AreaName: '',
            Longitude: props.circleLongitude || 0,
            Latitude: props.circleLatitude || 0,
            NonChkOor: 0,
            OutBound: true,
            InBound: true,
            Radius,
            Speed: 0,
            Message: '',
            Sound: 0,
            Color: '',
            ValidTime: 1,
            ValidTimeStart: '00:00',
            ValidTimeEnd: '23:59',
            ValidMin: 10,
            RouteLinking: [],
            RouteLinkingShow: false,
            AreaRouteLinking: [],
            AreaRouteLinkingShow: false,
            WaitSec: 1,
            PreArea: UNSET_OPTION,
            MailOption: 1,
            Path: props.path,
            Approach: 1,
            Pita_Version: "",
            LaneNum: 0,
            ShowLaneNumSetting: false,
            RecArea: false
        };

        this.goBack = this.goBack.bind(this);
        this.delete = this.delete.bind(this);

    }

    userData = Cookies.get('user');
    user = JSON.parse(this.userData ? this.userData : '{}');
    displayMode: any = {
        0: {
            0: {
                cirle: {
                    Longitude: true,
                    Latitude: true,
                    Radius: true,
                    PreArea: true,
                    OutBound: true,
                    RouteLink: true,
                },
                path: {
                    PreArea: true,
                    OutBound: true,
                    RouteLink: true,
                }
            },
            1: {
                cirle: {
                    Longitude: true,
                    Latitude: true,
                    Radius: true,
                    ValidMin: true,
                    OutBound: true,
                    RouteLink: true,
                },
                path: {
                    ValidMin: true,
                    OutBound: true,
                    RouteLink: true,
                }
            },
            2: {
                cirle: {
                    Longitude: true,
                    Latitude: true,
                    Radius: true,
                    PreArea: true,
                    AutoArea: true,
                    RouteLink: true,
                },
                path: {
                    PreArea: true,
                    AutoArea: true,
                    RouteLink: true,
                }
            },
            3: {
                cirle: {
                    Longitude: true,
                    Latitude: true,
                    Radius: true,
                    PreArea: true,
                    AutoArea: true,
                    RouteLink: true,
                },
                path: {
                    PreArea: true,
                    AutoArea: true,
                    RouteLink: true,
                }
            },
        },
        1: {
            0: {
                cirle: {
                    Longitude: true,
                    Latitude: true,
                    Radius: true,
                    PreArea: true,
                    OutBound: true,
                    RouteLink: true,
                },
                path: {
                    PreArea: true,
                    OutBound: true,
                    RouteLink: true,
                }
            },
            1: {
                cirle: {
                    Longitude: true,
                    Latitude: true,
                    Radius: true,
                    ValidMin: true,
                    OutBound: true,
                    RouteLink: true,
                },
                path: {
                    ValidMin: true,
                    OutBound: true,
                    RouteLink: true,
                }
            },
            2: {
                cirle: {
                    Longitude: true,
                    Latitude: true,
                    Radius: true,
                    PreArea: true,
                    AutoArea: true,
                    RouteLink: true,
                },
                path: {
                    PreArea: true,
                    AutoArea: true,
                    RouteLink: true,
                }
            },
            3: {
                cirle: {
                    Longitude: true,
                    Latitude: true,
                    Radius: true,
                    PreArea: true,
                    AutoArea: true,
                    RouteLink: true,
                },
                path: {
                    PreArea: true,
                    AutoArea: true,
                    RouteLink: true,
                }
            },
        },
        2: {
            0: {
                cirle: {
                    Longitude: true,
                    Latitude: true,
                    Radius: true,
                    PreArea: true,
                    OutBound: true,
                    RouteLink: true,
                },
                path: {
                    PreArea: true,
                    OutBound: true,
                    RouteLink: true,
                }
            },
            1: {
                cirle: {
                    Longitude: true,
                    Latitude: true,
                    Radius: true,
                    ValidMin: true,
                    OutBound: true,
                    RouteLink: true,
                },
                path: {
                    ValidMin: true,
                    OutBound: true,
                    RouteLink: true,
                }
            },
            2: {
                cirle: {
                    Longitude: true,
                    Latitude: true,
                    Radius: true,
                    PreArea: true,
                    AutoArea: true,
                    RouteLink: true,
                },
                path: {
                    PreArea: true,
                    AutoArea: true,
                    RouteLink: true,
                }
            },
            3: {
                cirle: {
                    Longitude: true,
                    Latitude: true,
                    Radius: true,
                    PreArea: true,
                    AutoArea: true,
                    RouteLink: false,
                },
                path: {
                    PreArea: true,
                    AutoArea: true,
                    RouteLink: false,
                }
            },
        }
    };

    private getDisplay(name: string) {
        const isCirle = this.state.Radius ? 'cirle' : 'path';
        var isAreaType = this.state.AreaType;
        if (isAreaType == 2) {
            if (this.state.AutoArea == 1) {
                isAreaType = 2;
            } else if (this.state.AutoArea == 2) {
                isAreaType = 3;
            }
        }
        if (this.displayMode[this.state.Pita_Version]) {
            return this.displayMode[this.state.Pita_Version][isAreaType][isCirle][name];
        }
        return "";
    }

    private delete() {
        axiosInstance
            .delete(HOST() + "/Form/V1/Dat/Area/Delete?areaID=" + this.state.AreaId)
            .then((res: any) => {
                const data = JSON.parse(res.data.data);
                if (this.isDeleteOk(data)) {
                    toast.success('エリアを削除しました。', toastOptions);
                    this.goBack();
                    this.syncDataAllUser()
                } else {
                    this.showDeleteMessage(data);
                }
            });
    }

    private showDeleteMessage(data: any) {
        const isRouteLinking = data[1] && data[1].length > 0;
        const isAreaLinking = data[2] && data[2].length > 0;
        const isUnitCount = data[4];
        const isAutoMessage = data[5];
        const isRoute2Linking = data[6] && data[6].length > 0;
        if (isRouteLinking) {
            let message = `ルート「${data[1].join('」、ルート「')}」と紐づけされているため削除出来ません。\n削除する場合は設定を解除してください。`;
            toast.error(message, toastOptions);
        }
        if (isRoute2Linking) {
            let message = `ルート「${data[6].join('」、ルート「')}」と紐づけされているため削除出来ません。\n削除する場合は設定を解除してください。`;
            toast.error(message, toastOptions);
        }
        if (isAreaLinking) {
            let message = `エリア「${data[2].join('」、エリア「')}」と紐づけされているため削除出来ません。\n削除する場合は設定を解除してください。`;
            toast.error(message, toastOptions);
        }
        if (isUnitCount) {
            let message = `台数カウント設定をしているエリアのため削除できません。`;
            toast.error(message, toastOptions);
        }
        if (isAutoMessage) {
            let message = `このエリアは自動メッセージ送信で使用されているため削除できません。`;
            toast.error(message, toastOptions);
        }
        if (data[3]) {
            toast.error('エリアの削除に失敗しました。', toastOptions);
        }

    }

    private isDeleteOk(data: any) {
        return Object.keys(data).length === 1 && Object.keys(data)[0] === '0';
    }

    private async save() {
        let valid = true;
        if (!this.state.AreaName) {
            toast.error('エリア名を入力してください。', toastOptions);
            valid = false;
        }
        if (this.state.AreaName.indexOf(',') !== -1) {
            toast.error('エリア名に「,」が含まれています。「,」は使用不可文字です。', toastOptions);
            valid = false;
        }
        if (this.state.Radius && !this.state.Longitude) {
            toast.error('緯度を入力してください。', toastOptions);
            valid = false;
        }
        if (this.state.Radius && !this.state.Latitude) {
            toast.error('経度を入力してください。', toastOptions);
            valid = false;
        }
        if (!this.state.ValidTime || !this.state.ValidTimeStart || !this.state.ValidTimeEnd) {
            toast.error('有効時間(曜日)に無効な時間が設定されています。', toastOptions);
            valid = false;
        }

        if (!valid) {
            return;
        }

        const countPoly = await axiosInstance.get(HOST() + "/Form/V1/Dat/Area/CountPoly").then(res => res.data ? res.data.data : 9999);
        if (!this.state.AreaId && this.state.Radius == 0 && (this.state.Path && this.state.Path.length + countPoly > 160)) {
            toast.error('多角形エリアの作成上限を超過しています。\n多角形エリアを削除後お試しください。', toastOptions);
            return;
        }

        let AreaType = this.state.AreaType;
        let AutoArea = this.state.AutoArea;
        if (AreaType == 2) {
            AreaType = AutoArea == 2 ? 3 : 2;
        }

        var body = {
            AreaType,
            AreaId: this.state.AreaId,
            AreaName: this.state.AreaName,
            Longitude: Number(this.state.Longitude),
            Latitude: Number(this.state.Latitude),
            NonChkOor: this.state.NonChkOor,
            OutBound: this.state.OutBound,
            InBound: this.state.InBound,
            Radius: this.state.Radius,
            Speed: this.state.Speed,
            Message: this.state.Message,
            Sound: this.state.Sound,
            AreaColorRGB: hexToRGB(this.state.Color),
            ValidTime: this.state.ValidTime,
            ValidTimeStart: this.state.ValidTimeStart,
            ValidTimeEnd: this.state.ValidTimeEnd,
            ValidMin: this.state.ValidMin,
            RouteLinking: this.state.RouteLinking,
            AreaRouteLinking: this.state.AreaRouteLinking,
            Approach: this.state.Approach,
            WaitSec: this.state.WaitSec,
            PreArea: this.state.PreArea || null,
            MailOption: this.state.MailOption,
            UserId: this.user.id,
            LaneNum: this.state.LaneNum,
            RecArea: this.state.RecArea
        };

        const saveData = await axiosInstance.put(HOST() + "/Form/V1/Dat/Area/Update", body).then((res: any) => {
            if (res.data.code == '00') {
                toast.error(res.data.message, toastOptions);
                return null;
            }
            eventBus.dispatch("changeRecArea", {});

            return res.data;
        });
        if (saveData == null) {
            return;
        }
        if (!this.props.areaId && this.state.Path.length > 2) {
            const path = this.state.Path;
            path.forEach(e => e.AreaID = saveData.data.areaId);
            const pathData = {
                AreaID: saveData.data.areaId,
                Path: path
            };
            await axiosInstance.put(HOST() + "/Form/V1/Dat/AreaDetail/Update", pathData).then(res => {
                if (res.data && res.data.data) {
                    this.goBack();
                    this.syncDataAllUser();
                    toast.success('エリアを保存しました。', toastOptions);
                } else {
                    toast.error('エリアの保存に失敗しました', toastOptions);
                }
            });
        } else {
            if (saveData && !saveData.errors) {
                this.goBack();
                toast.success('エリアを保存しました。', toastOptions);
                this.syncDataAllUser();
            } else {
                toast.error('エリアの保存に失敗しました', toastOptions);
            }
        }

    }

    public syncDataAllUser() {
        axiosInstance.get(HOST() + "/Form/V1/Dat/Area/SyncDataAllUser");
    }

    async componentDidMount() {
        if (this.props.areaId) {
            this.loadArea();
        }

        const userData = Cookies.get('user');
        if (userData) {
            this.user = JSON.parse(userData ? userData : '{}');
            if (this.user) {
                this.setState({ Pita_Version: this.user.pitaVersion })
            }
        }

        eventBus.on("changeDetailSetting", (data: any) =>
            this.setState({ Pita_Version: data.pitaVersion })
        );
    }

    private loadArea() {
        axiosInstance
            .get(HOST() + `/Form/V1/Dat/Area/GetAreaByID?areaID=${this.props.areaId}`)
            .then(response => {
                const data = response.data.data;
                if (!data) {
                    return this.goBack();
                }
                let AreaType = data.areaType;
                let AutoArea = data.autoArea;
                if (AreaType == 2 || AreaType == 3) {
                    AutoArea = AreaType == 2 ? 1 : 2;
                    AreaType = 2;
                }
                this.setState({
                    AreaType: AreaType,
                    AutoArea: AutoArea,
                    AreaId: data.areaId,
                    AreaName: data.areaName,
                    Longitude: data.longitude,
                    Latitude: data.latitude,
                    NonChkOor: data.nonChkOor,
                    OutBound: data.outBound,
                    InBound: data.inBound,
                    Radius: data.radius,
                    Speed: data.speed,
                    Message: data.message,
                    Sound: data.sound,
                    Color: rgbToHex(data.areaColorRGB),
                    ValidTime: data.validTime,
                    ValidTimeStart: data.validTimeStart,
                    ValidTimeEnd: data.validTimeEnd,
                    ValidMin: data.validMin,
                    RouteLinking: data.routeLinking,
                    AreaRouteLinking: data.areaRouteLinking,
                    Approach: data.approach,
                    WaitSec: data.waitSec,
                    PreArea: data.preArea,
                    MailOption: data.mailOption,
                    Path: data.path,
                    LaneNum: data.laneNum,
                    RecArea: data.recArea
                });
                if (this.state.LaneNum > 0 && this.state.LaneNum <= 9) {
                    this.setState({ ShowLaneNumSetting: true });
                }
            });
    }

    private handleChangeAreaType(areaType: string) {
        this.setState({ AreaType: Number(areaType) });
    }

    private handleChangeShowLaneNumSetting(checked: boolean) {
        this.setState({ ShowLaneNumSetting: checked })
        if (checked) {
            this.setState({ LaneNum: 1 });
        } else {
            this.setState({ LaneNum: 0 });
        }


    }

    private handleChangeRecArea(checked: boolean) {

        //nếu toggle false to true
        if (checked) {
            //call api to check if the monitored area count exceeded 5
            axiosInstance
                .get(HOST() + `/Form/V1/Dat/Area/GetRecAreaCount?sysID=${this.user.siteId}`)
                .then(response => {
                    if (parseInt(response.data.data) >= 5) {
                        toast.error('監視対象エリア上限に達しています。');
                        return;
                    } else {
                        this.setState({ RecArea: checked });
                    }
                });
        } else {
            this.setState({ RecArea: checked });
        }
    }

    private goBack() {
        this.props.onNavi('/');
    }

    private getLaneNumValue(val: string) {
        //chỉ lấy giá trị nguyên từ 1 tới 9

        let value = parseInt(val);
        if (value < 1) value = 1;
        if (value > 9) value = 9;

        return value;
    }

    public async exportArea() {
        const link: any = document.createElement("a");

        var accessToken: any = { 'authorization': Cookies.get('jwt') };
        const result = await fetch('/Form/V1/Dat/AreaDetail/Export?areaID=' + this.props.areaId, {
            headers: new Headers(accessToken)
        });

        const blob = await result.blob()
        const href = window.URL.createObjectURL(blob)

        link.download = `Area_Detail_${this.state.AreaName}_${new Date().getTime()}.csv`;
        link.href = href;
        link.click();
    }

    public render() {
        return (
            <div className="areaEditContainer pt-0">
                <div className="box-title d-flex justify-content-between align-items-center mb-4">
                    <span className="page-header">特定エリア</span>
                    <div className="buttonEdit">
                        <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.exportArea()} hidden={!this.props.areaId || !!this.state.Radius}>出力</button>
                        <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.save()}>保存</button>
                        <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.delete()} hidden={!this.props.areaId}> 削除</button >
                        <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.goBack()}> キャンセル</button >
                    </div>
                </div>
                <div className="form-row row">
                    <label className="col-3 col-form-label form-control-label">ID</label>
                    <div className="col-9 mt-2">
                        {this.state.AreaId !== 0 ? this.state.AreaId : ''}
                    </div>
                </div>
                <div className="form-row row">
                    <label className="col-3 col-form-label form-control-label">エリア種別</label>
                    <div className="col-9">
                        <select className="form-control form-control-sm w-50 inline"
                            onChange={e => this.handleChangeAreaType(e.target.value)}
                            value={this.state.AreaType}
                        >
                            <option value="0">特定エリア</option>
                            <option value="1">プレエリア</option>
                            <option value="2">自動エリア</option>
                        </select>
                    </div>
                </div>
                <div className="form-row row" style={{ display: this.getDisplay('AutoArea') ? 'flex' : 'none' }}>
                    <label className="col-3 col-form-label form-control-label">自動認識種別</label>
                    <div className="col-9">
                        <select className="form-control form-control-sm w-50 inline"
                            onChange={e => this.setState({ AutoArea: Number(e.target.value) })}
                            value={this.state.AutoArea}>
                            <option value="1">自動積込み</option>
                            {
                                this.state.Pita_Version !== "2" ? (<option value="2">自動荷降し</option>) : (<option value="2">自動現場到着</option>)
                            }



                        </select>
                    </div>
                </div>
                <div className="form-row row" style={{ display: 'none' }}>
                    <label className="col-3 col-form-label form-control-label">エリアID</label>
                    <div className="col-9">
                        {this.state.AreaId !== 0 ? this.state.AreaId : ''}
                    </div>
                </div>
                <div className="form-row row">
                    <label className="col-3 col-form-label form-control-label">エリア名</label>
                    <div className="col-9">
                        <input
                            type="text"
                            value={this.state.AreaName}
                            maxLength={50}
                            className="form-control form-control-sm w-50"
                            onChange={e => this.setState({ AreaName: e.target.value })} />
                    </div>
                </div>
                <div className="form-row row" style={{ display: this.getDisplay('Latitude') ? 'flex' : 'none' }}>
                    <label className="col-3 col-form-label form-control-label">緯度</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control form-control-sm w-50"
                            value={this.state.Latitude}
                            onChange={e => this.setState({ Latitude: e.target.value })}
                        />
                    </div>
                </div>
                <div className="form-row row" style={{ display: this.getDisplay('Longitude') ? 'flex' : 'none' }}>
                    <label className="col-3 col-form-label form-control-label">経度</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control form-control-sm w-50"
                            value={this.state.Longitude}
                            onChange={e => this.setState({ Longitude: e.target.value })}
                        />
                    </div>
                </div>
                <div className="form-row row">
                    <label className="col-3 col-form-label form-control-label">ルート逸脱チェック</label>
                    <div className="col-9 mt-2">
                        <span className="mr-1">する</span>
                        <input type="radio"
                            className="inline mr-4"
                            name="NonChkOor"
                            checked={this.state.NonChkOor == 0}
                            onChange={e => this.setState({ NonChkOor: 0 })} />

                        <span className="mr-1">しない</span>
                        <input type="radio"
                            className="inline"
                            name="NonChkOor"
                            checked={this.state.NonChkOor == 1}
                            onChange={e => this.setState({ NonChkOor: 1 })} />

                    </div>

                </div>
                <div className="form-row row">
                    <label className="col-3 col-form-label form-control-label"></label>
                    <div className="col-9">※ルートから指定した距離を離れたら、ルート逸脱と判断します。</div>
                </div>
                <div className="form-row row" style={{ display: this.getDisplay('OutBound') ? 'flex' : 'none' }}>
                    <label className="col-3 col-form-label form-control-label">方向</label>
                    <div className="col-9">
                        <div className="mt-2">
                            <span className="mr-1">往路</span>
                            <input
                                type="checkbox"
                                className="inline mr-4"
                                checked={this.state.OutBound}
                                onChange={e => this.setState({ OutBound: e.target.checked })}
                            />

                            <span className="mr-1">復路</span>
                            <input
                                type="checkbox"
                                className="inline"
                                checked={this.state.InBound}
                                onChange={e => this.setState({ InBound: e.target.checked })}
                            />
                        </div>
                        <p>※プレエリアを設定する場合、往路復路のチェックに十分ご注意ください。</p>
                    </div>
                </div>
                <div className="form-row row" style={{ display: this.getDisplay('Radius') ? 'flex' : 'none' }}>
                    <label className="col-3 col-form-label form-control-label">半径</label>
                    <div className="col-9">
                        <input
                            type="number"
                            className="form-control form-control-sm w-50 inline"
                            value={this.state.Radius}
                            onChange={e => this.setState({ Radius: getValidNumber(e, { maxValue: 2000, minValue: 1, roundTo: 0 }) })}
                        /> m
                    </div>
                </div>
                <div className="form-row row">
                    <label className="col-3 col-form-label form-control-label">制限速度</label>
                    <div className="col-9">
                        <input
                            type="number"
                            className="form-control form-control-sm w-50 inline"
                            value={this.state.Speed}
                            onChange={e => this.setState({ Speed: getValidNumber(e, { maxValue: 200, minValue: 0, roundTo: 0 }) })}
                        /> km/h
                    </div>
                </div>
                <div className="form-row row">
                    <label className="col-3 col-form-label form-control-label">メッセージ</label>
                    <div className="col-9">
                        <input
                            type="text"
                            maxLength={200}
                            className="form-control form-control-sm w-50"
                            value={this.state.Message}
                            onChange={e => this.setState({ Message: e.target.value })}
                        />
                    </div>
                </div>
                <div className="form-row row">
                    <label className="col-3 col-form-label form-control-label">音声</label>
                    <SoundList
                        fetchUrl="/Form/V1/Dat/Sound/ListAll"
                        className='col-9'
                        type='areaFlg'
                        value={this.state.Sound}
                        onChange={(e: any) => this.setState({ Sound: Number(e.target.value) })}
                    />
                </div>
                <div className="form-row row">
                    <label className="col-3 col-form-label form-control-label">色</label>
                    <div className="col-9">
                        <input
                            className="form-control form-control-sm w-25"
                            value={this.state.Color}
                            type="color"
                            onChange={e => this.setState({ Color: e.target.value })} />
                    </div>
                </div>
                <div className="form-row row" >
                    <label className="col-3 col-form-label form-control-label">有効時間(曜日)</label>
                    <div className="col-9 d-flex">
                        <select className="form-control form-control-sm w-25 inline"
                            onChange={e => this.setState({ ValidTime: Number(e.target.value) })}
                            value={this.state.ValidTime}
                        >
                            <option value="1">毎日</option>
                            <option value="2">月-金</option>
                            <option value="3">月-土</option>
                            <option value="4">土</option>
                            <option value="5">日</option>
                            <option value="6">土日</option>
                        </select>
                        &nbsp;
                        <input
                            type="time"
                            className="form-control form-control-sm w-25 inline"
                            value={this.state.ValidTimeStart}
                            onChange={e => this.setState({ ValidTimeStart: e.target.value })}
                        />
                        <span className="space-time">～</span>
                        <input
                            type="time"
                            className="form-control form-control-sm w-25 inline"
                            value={this.state.ValidTimeEnd}
                            onChange={e => this.setState({ ValidTimeEnd: e.target.value })}
                        />
                    </div>
                </div>
                <div className="form-row row">
                    <label className="col-3 col-form-label form-control-label">
                        ルート紐付け
                    </label>
                    <div className="col-9 mt-2">
                        <span onClick={() => this.setState({ AreaRouteLinkingShow: !this.state.AreaRouteLinkingShow })} style={{ cursor: 'pointer' }}>
                            {this.state.AreaRouteLinkingShow ? "▲" : "▼"}
                        </span>
                        <div className={this.state.AreaRouteLinkingShow ? "col-show" : "col-0"}>
                            <MultiCheckBox
                                ckey='routeID'
                                cvalue='routeNm'
                                cname='routeList'
                                urlData="/Form/V1/Dat/Route/GetActiveRoute"
                                value={this.state.AreaRouteLinking}
                                onChange={(e: any) => this.setState({ AreaRouteLinking: e.data })}
                                isSingleCheck={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-row row" style={{ display: this.getDisplay('RouteLink') ? 'flex' : 'none' }}>
                    <label className="col-3 col-form-label form-control-label">
                        ルート自動有効化
                    </label>
                    <div className="col-9 mt-2">
                        <span onClick={() => this.setState({ RouteLinkingShow: !this.state.RouteLinkingShow })} style={{ cursor: 'pointer' }}>
                            {this.state.RouteLinkingShow ? "▲" : "▼"}
                        </span>
                        <div className={this.state.RouteLinkingShow ? "col-show" : "col-0"}>
                            <MultiCheckBox
                                ckey='routeID'
                                cvalue='routeNm'
                                cname='routeList'
                                urlData="/Form/V1/Dat/Route/GetActiveRoute"
                                value={this.state.RouteLinking}
                                onChange={(e: any) => this.setState({ RouteLinking: e.data })}
                                isSingleCheck={false}
                            />
                            <span className="MultiCheckBox-sub">※エリア進入時、チェックを入れたルートが自動的に選択されます。</span>
                        </div>
                    </div>

                </div>
                <div className="form-row row">
                    <label className="col-3 col-form-label form-control-label">認識待機時間</label>
                    <div className="col-9">
                        <input
                            type="number"
                            className="form-control form-control-sm w-50 inline"
                            value={this.state.WaitSec}
                            onChange={e => this.setState({ WaitSec: getValidNumber(e, { maxValue: 100, minValue: 0, roundTo: 0 }) })}
                        /> 秒
                    </div>
                </div>
                <div className="form-row row" style={{ display: this.getDisplay('ValidMin') ? 'flex' : 'none' }}>
                    <label className="col-3 col-form-label form-control-label">プレ有効時間</label>
                    <div className="col-9">
                        <input
                            type="number"
                            className="form-control form-control-sm w-50 inline"
                            value={this.state.ValidMin}
                            onChange={e => this.setState({ ValidMin: getValidNumber(e, { maxValue: 100, minValue: 1, roundTo: 0 }) })}
                        /> 分
                    </div>
                </div>
                <div className="form-row row" style={{ display: this.getDisplay('PreArea') ? 'flex' : 'none' }}>
                    <label className="col-3 col-form-label form-control-label">プレエリア</label>
                    <div className="col-9">
                        <BaseSelectList
                            className="w-50"
                            dkey='areaId'
                            dvalue='areaName'
                            nullLabel=''
                            value={this.state.PreArea}
                            fetchUrl={`/Form/V1/Dat/Area/GetAreaByType?areaType=1&areaID=${this.state.AreaId == null ? 0 : this.state.AreaId}`}
                            onChange={(e: any) => this.setState({ PreArea: Number(e.target.value) })}
                        />
                    </div>
                </div>
                <div className="form-row row">
                    <label className="col-3 col-form-label form-control-label">メール送信</label>
                    <div className="col-9">
                        <select className="form-control form-control-sm w-50 inline"
                            onChange={e => this.setState({ MailOption: Number(e.target.value) })}
                            value={this.state.MailOption}
                        >
                            <option value="1">しない</option>
                            <option value="2">入退時両方</option>
                            <option value="3">進入時のみ</option>
                            <option value="4">退出時のみ</option>
                        </select>
                    </div>
                </div>
                <div className="form-row row">
                    <label className="col-3 col-form-label form-control-label">近接判定</label>
                    <div className="col-9 mt-2">
                        <span className="mr-1">する</span>
                        <input type="radio"
                            className="inline mr-4"
                            name="Approach"
                            checked={this.state.Approach === 1}
                            onChange={e => this.setState({ Approach: 1 })} />

                        <span className="mr-1">しない</span>
                        <input type="radio"
                            className="inline"
                            name="Approach"
                            checked={this.state.Approach === 0}
                            onChange={e => this.setState({ Approach: 0 })} />
                    </div>
                </div>
                <div className="form-row row">
                    <label className="col-3 col-form-label form-control-label">入退場時刻監視</label>
                    <div className="col-9">
                        <div className="mt-2">
                            <input
                                type="checkbox"
                                className="inline mr-4"
                                checked={this.state.RecArea}
                                onChange={e => { this.handleChangeRecArea(e.target.checked) }}
                            />
                        </div>
                    </div>
                </div>
                {
                    this.state.Pita_Version === "1" && (
                        <div className="form-row row">
                            <label className="col-3 col-form-label form-control-label">計量エリア</label>
                            <div className="col-9">
                                <div className="mt-2">
                                    <input
                                        type="checkbox"
                                        className="inline mr-4"
                                        checked={this.state.ShowLaneNumSetting}
                                        onChange={e => { this.handleChangeShowLaneNumSetting(e.target.checked) }}
                                    />
                                </div>
                                {
                                    (this.state.ShowLaneNumSetting === true) && (
                                        <div className="form-row row">
                                            <label className="col-form-label form-control-label">レーン数</label>
                                            <div className="col-9">
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm inline w-50"
                                                    value={this.state.LaneNum}
                                                    onChange={e => this.setState({ LaneNum: this.getLaneNumValue(e.target.value) })}
                                                />
                                            </div>
                                        </div>)
                                }

                            </div>
                        </div>

                    )
                }

            </div >
        );
    }
}