import "./RouteManagement.css";
import React from "react";
import { toast } from "react-toastify";
import { toastOptions, calculatePath, drawPathToMap } from "../../common/Utils";

export default class RouteManagement extends React.Component<{
    onNavi: Function,
    map: any,
    routeSearching: boolean,
    routeLinking: boolean,
    updateStateFunc: Function,
    notResetMarker: boolean,
}, {
    saving: boolean,
    pathEncoded: any[],
    GoTime: any,
    ReTime: any,
    Distance: any,
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            saving: false,
            pathEncoded: [],
            GoTime: '',
            ReTime: '',
            Distance: '',
        };
        this.saveRoute = this.saveRoute.bind(this);
        this.drawGoogleResultRoute = this.drawGoogleResultRoute.bind(this);
    }

    public turnOnSearchingMode() {
        const _this = this;
        this.clickListener = window.google.maps.event.addListener(this.props.map, 'click', function (event: any) {
            _this.placeMarker(event.latLng);
        });
    }

    public turnOffSearchingMode() {
        this.clickListener && window.google.maps.event.removeListener(this.clickListener);
        this.clearState(true);
    }

    markerFrom: any;
    markerTo: any;

    drawedLine: any;
    clickListener: any = null;

    private placeMarker(position: any) {
        if (this.markerFrom == null) {
            this.setStartPoint(position);
            return;
        }

        if (this.markerTo == null) {
            this.setEndPoint(position);
            return;
        }
    }

    private setStartPoint(position: any) {
        this.markerFrom = new window.google.maps.Marker({
            map: this.props.map,
            position: position,
            draggable: true,
            animation: window.google.maps.Animation.DROP,
            title: 'ここから',
            label: { color: "white", text: "出" }
        });

        window.google.maps.event.addListener(this.markerFrom, 'dragend', () =>
            this.drawGoogleResultRoute(this.markerFrom, this.markerTo));
    }

    private setEndPoint(position: any) {
        this.markerTo = new window.google.maps.Marker({
            map: this.props.map,
            position: position,
            draggable: true,
            animation: window.google.maps.Animation.DROP,
            title: 'ここまで',
            label: { color: "white", text: "到" }
        });

        window.google.maps.event.addListener(this.markerTo, 'dragend', () =>
            this.drawGoogleResultRoute(this.markerFrom, this.markerTo));

        if (this.markerFrom) {
            this.setState({ saving: true })
            this.drawGoogleResultRoute(this.markerFrom, this.markerTo);
            return;
        }
    }

    private async drawGoogleResultRoute(startPoint: any, endPoint: any) {
        if (!startPoint || !endPoint) {
            return;
        }
        var start = {
            lat: startPoint.getPosition().lat(),
            lng: startPoint.getPosition().lng()
        };
        var dest = {
            lat: endPoint.getPosition().lat(),
            lng: endPoint.getPosition().lng()
        };

        if (this.drawedLine != null) {
            this.drawedLine.setMap(null);
            this.drawedLine = null;
        }

        this.drawedLine = new window.google.maps.DirectionsRenderer({
            draggable: true,
            map: this.props.map,
            suppressMarkers: true,
        });

        this.drawedLine.addListener("directions_changed", () => {
            var path = window.google.maps.geometry.encoding.decodePath(this.drawedLine.directions.routes[0].overview_polyline);
            var bounds = new window.google.maps.LatLngBounds();
            var pathEncoded = [];

            for (var i = 0; i < path.length; i++) {
                bounds.extend(path[i]);
                pathEncoded.push({
                    PosID: i,
                    PosLat: path[i].lat(),
                    PosLon: path[i].lng()
                });
            }

            this.setState({ pathEncoded });
            this.props.map.fitBounds(bounds);
        });

        const res: any = await calculatePath(start, dest);
        this.setState({
            GoTime: res.GoTime,
            ReTime: res.ReTime,
            Distance: res.Distance,
        })
        this.drawedLine.setDirections(res.response);
    }

    private clearState(all: boolean) {
        if (this.markerTo != null) {
            this.setState({
                saving: false,
                pathEncoded: [],
            });
            this.markerTo.setMap(null);
            this.markerTo = null;
            if (this.drawedLine != null) {
                this.drawedLine.setMap(null);
                this.drawedLine = null;
            }
            if (!all) return;
        }

        if (this.markerFrom != null) {
            this.markerFrom.setMap(null);
            this.markerFrom = null;
            if (!all) return;
        }
    }

    private saveRoute(routeLinking: boolean = false) {
        const naviData = {
            pathEncoded: this.state.pathEncoded,
            GoTime: Math.round(this.state.GoTime / 60),
            ReTime: Math.round(this.state.ReTime / 60),
            Distance: this.state.Distance,
            routeId: 0
        };
        if (routeLinking) {
            this.props.onNavi('/editRouteManagementLinking', naviData);
        } else {
            this.props.onNavi('/editRouteManagement', naviData);
        }
        this.turnOffSearchingMode();
    }

    private close() {
        this.props.updateStateFunc({ routeSearching: false });
        this.turnOffSearchingMode();
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.notResetMarker && nextProps.routeSearching) {
            this.setState({
                saving: false,
                pathEncoded: [],
            });
            this.markerFrom && this.markerFrom.setVisible(false);
            this.markerTo && this.markerTo.setVisible(false);
            this.markerTo = null;
            this.markerFrom = null;

            if (nextProps.routeLinking) {
                this.saveRoute(true);
            }
        }
    }

    public async drawRouteImportPath(routeImportPath: any) {
        const drawedData = drawPathToMap(routeImportPath, this.props.map, '115/185/255', true);
        this.drawedLine = drawedData.drawedPath.polyline;
        this.markerFrom = drawedData.drawedPath.fromMarker;
        this.markerTo = drawedData.drawedPath.toMarker;
        const res: any = await calculatePath(routeImportPath[0], routeImportPath[routeImportPath.length - 1]);
        this.setState({
            GoTime: res.GoTime,
            ReTime: res.ReTime,
            Distance: res.Distance,
            saving: true,
            pathEncoded: routeImportPath
        })
    }

    public render() {
        return (
            <div>
                <button
                    className="btn btn-primary btn-md mar-5 btn-135"
                    onClick={() => this.close()}
                    style={{ display: this.props.routeSearching ? 'inline-block' : 'none' }}
                >
                    キャンセル
                </button>

                <button
                    className="btn btn-primary btn-md mar-5 btn-135"
                    onClick={() => this.saveRoute()}
                    disabled={!this.state.saving}
                    style={{ display: this.props.routeSearching ? 'inline-block' : 'none' }}
                >
                    保存
                </button>
            </div >
        );
    }
}