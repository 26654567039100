import React from "react";
import "./SearchMarker.css";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
interface SearchMarkerState {
  latitude: string;
  longitude: string;
  currentMarker: google.maps.Marker | null;
}
export default class SearchMarker extends React.Component<
  {
    map: google.maps.Map;
  },
  SearchMarkerState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      latitude: "",
      longitude: "",
      currentMarker: null,
    };
  }

  public zoomToLocation = () => {
    const { latitude, longitude, currentMarker } = this.state;
    const lat = parseFloat(latitude);
    const lng = parseFloat(longitude);
    if ((isNaN(lat) && !isNaN(lng)) || (!isNaN(lat) && isNaN(lng))) {
      toast.warn("緯度と経度の両方を入力してください。", {
        position: "top-right",
        autoClose: 5000,
        draggable: true,
        closeOnClick: true,
      });
      return;
    }
    if (!isNaN(lat) && !isNaN(lng)) {
      const position = new google.maps.LatLng(lat, lng);
      this.props.map.setCenter(position);
      this.props.map.setZoom(14);
      if (currentMarker) {
        currentMarker.setMap(null);
      }
      const newMarker = new google.maps.Marker({
        position: position,
        map: this.props.map,
      });

      this.setState({ currentMarker: newMarker });
    } else {
      const mapDefault: any = Cookies.get("mapDefault");
      if (mapDefault) {
        const mapPosData = JSON.parse(mapDefault);
        const position = new google.maps.LatLng(mapPosData.lat, mapPosData.lng);
        this.props.map.setCenter(position);
        this.props.map.setZoom(mapPosData.zoom);
        if (currentMarker) {
          currentMarker.setMap(null);
        }
        const newMarker = new google.maps.Marker({
          position: position,
          map: this.props.map,
        });

        this.setState({ currentMarker: newMarker });
      }else {
        toast.warn("緯度と経度の両方を入力してください。", {
          position: "top-right",
          autoClose: 5000,
          draggable: true,
          closeOnClick: true,
        });
      }
    }
  };

  public render() {
    return (
      <div className="SearchMarker">
        <div className="coordinate-inputs">
          <input
            type="number"
            placeholder="緯度"
            onChange={(e) => this.setState({ latitude: e.target.value })}
          />
          <input
            type="number"
            placeholder="経度"
            onChange={(e) => this.setState({ longitude: e.target.value })}
          />
          <button onClick={this.zoomToLocation}>適用</button>
        </div>
      </div>
    );
  }
}
