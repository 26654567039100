import * as React from 'react';
import './RemoteRoute.css';
import axiosInstance from '../../common/interceptor';
import { HOST } from '../../common/constant';
import MultiCheckBox from '../../common/MultiCheckBox/MultiCheckBox';
import { toast } from 'react-toastify';
import { reloadRoute, reloadTerminal, toastOptions } from '../../common/Utils';
import Cookies from 'js-cookie';

export default class RemoteRoute extends React.Component<{
	map: any,
	onNavi: Function
}, {
	RouteId: any,
	Terminal: any[],
}> {
	constructor(props: any) {
		super(props);
		this.state = {
			Terminal: [],
			RouteId: ''
		};
	}

	async routeUpdate() {
		console.log(this.state);
		var body = {
			terminalIds: this.state.Terminal,
			routeId: Number(this.state.RouteId[0])
		};

		const saveData = await axiosInstance.post(HOST() + "/Form/V1/Dat/Map/ChangeRoute", body).then(res => res.data);
		if (saveData.result) {
			toast.success('ルートを変更しました。', toastOptions);
        }
	}   

	alllowChange() {
		return this.state.RouteId && this.state.RouteId.length && this.state.Terminal && this.state.Terminal.length > 0;
	}

	messageHistory() {
		this.props.onNavi('/messageHistory', {isRouteChange: true});
	}
	user: any = JSON.parse(Cookies.get('user') || "{}");

	public render() {
		return (
			<div className="remoteRoute-container">
				<div className="form-container">
					<div className="form-row row">
						<div className="col-6">
							<label className="col-form-label form-control-label">宛先</label>
							<span className="TerminalList" onClick={() => reloadTerminal(this)}></span>
							<div className="container-up">
								<MultiCheckBox
									ref='TerminalList'
									ckey='cliID'
									cvalue='cliNm'
									cname='Terminal'
									urlData={`/Form/V1/Mst/Terminal/GetList?sysID=${this.user.siteId}`}
									value={this.state.Terminal}
									onChange={(e: any) => this.setState({ Terminal: e.data })}
									isSingleCheck={false}
								/>
							</div>
						</div>
						<div className="col-6">
							<label className="col-form-label form-control-label">ルート</label>
							<span className="RouteList" onClick={() => reloadRoute(this)}></span>
							<div className="container-up">
								<MultiCheckBox
									ref='RouteList'
									ckey='routeID'
									cvalue='routeSoundName'
									cname='RouteToInterval'
									urlData="/Form/V1/Dat/RouteToInterval/ListAllAndSound?isUsingOnly=true"
									value={this.state.RouteId}
									onChange={(e: any) => this.setState({ RouteId: e.data })}
									isSingleCheck={true}
									extraValue="0"
									extraLable="リモートルート解除"
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="buttonGroup">
					<button
						type="button"
						className="btn btn-primary btn-sm mar-5"
						onClick={() => this.routeUpdate()}
						disabled={!this.alllowChange()}
					>ルート変更</button>
					<button
						type="button"
						className="btn btn-primary btn-sm mar-5"
						onClick={() => this.messageHistory()}
					> 変更履歴</button >
				</div>
			</div>
		);
	}
}
