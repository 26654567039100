import React from 'react'
import './SoundList.css'
import axiosInstance from '../interceptor'
import { HOST } from '../constant'
import PlayAudio from '../PlayAudio/PlayAudio'

export default class SoundList extends React.Component<{
    fetchUrl: string,
    onChange: Function,
    value: any, 
    className: string,
    type: string
}, {
    ListSound: any[],
    value: any,
    SoundUrl: string
}> {
    constructor(props: any) {
        super(props)
        this.state = {
            ListSound: [],
            value: this.props.value,
            SoundUrl: ''
        }
    }


    componentDidMount() {
        this.getData();
    }

    getData() {
        axiosInstance
            .get(HOST() + this.props.fetchUrl)
            .then(response => {
                let data = response.data.data || [];
                if (this.props.type) {
                    data = data.filter((e: any) => e[this.props.type]);
                }
                data.unshift({ soundID: 0, soundNm: '' });
                this.setState({ ListSound: data });
            })
	}

    componentWillReceiveProps(nextProps: any) {
        if (this.state.value != nextProps.value) {
            let SoundUrl = this.state.ListSound.filter(s => s.soundID.toString() === nextProps.value.toString())[0];
            SoundUrl = SoundUrl ? SoundUrl.soundFile : '';
            this.setState({ value: nextProps.value, SoundUrl });
        }
    }

    updateSoundValue(e: any) {
        const soundId = e.target.value;
        let SoundUrl = this.state.ListSound.filter(s => s.soundID.toString() === soundId.toString())[0];
        SoundUrl = SoundUrl ? SoundUrl.soundFile : '';

        this.setState({
            value: e.target.value,
            SoundUrl
        });
        this.props.onChange && this.props.onChange(e);
    }

    render() {
        return (
            <div className={this.props.className}>
                <select className="form-control form-control-sm w-75 inline"
                    onChange={e => this.updateSoundValue(e)}
                    value={this.state.value}>
                    {
                        this.state.ListSound.map((sound: any, index: any) => (
                            <option key={'s' + index} value={sound.soundID}>{sound.soundNm}</option>
                        ))
                    }
                </select>
                {
                    this.state.SoundUrl ? (<PlayAudio url={this.state.SoundUrl} />) :
                        (
                            <button
                                disabled={true}
                                className="btn btn-secondary mar-5 w-15"
                                style={{ fontSize: '13.5px' }}>
                                再生
                            </button>
                        )
                }
                
            </div>
        );
    }
}