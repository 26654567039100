import * as React from 'react';
import './MessageHistory.css';
import Moment from 'react-moment';

export default class MessageHistoryList extends React.Component<{
	MessageHistoryList: any[],
	isRouteChange: boolean
}, {
	MessageHistoryList: any[]
}> {
	constructor(props: any) {
		super(props);
		this.state = {
			MessageHistoryList: this.props.MessageHistoryList
		};
	}

	componentWillReceiveProps(nextProps: any) {
		this.setState({
			MessageHistoryList: nextProps.MessageHistoryList
		});
	}

	statusName: any = {
		0: "未送信",
		1: "未確認",
		2: "OK"
    }
	
	public render() {
		return this.props.isRouteChange ? 
			(
				<div className="table-responsive">
				<table className="table table-bordered">
					<thead>
						<tr>
							<th>端末</th>
							<th>送信日時</th>
							<th>ルート</th>
							<th>ステータス</th>
						</tr>
					</thead>
					<tbody>
					{
						this.props.MessageHistoryList.map((item: any, index: any) => (
							<tr key={ "MessageHistoryList" + index }>
								<td>{ item.terminalName }</td>
								<td><Moment format="YYYY-MM-DD HH:mm" date={ item.sentDate }/></td>
								<td>{item.routeName ? item.routeName :
									(item.content == "ルート変更:リモートルート解除" ? "リモートルート解除" : '')}</td>
								<td>{ this.statusName[item.mesSts]}</td>
							</tr>
						))
						}
					</tbody>
				</table>
			</div>
			)
			:(<div className="table-responsive">
				<table className="table table-bordered">
					<thead>
						<tr>
							<th>端末</th>
							<th>送信日時</th>
							<th>内容</th>
							<th>音声名</th>
							<th>ステータス</th>
						</tr>
					</thead>
					<tbody>
					{
						this.props.MessageHistoryList.map((item: any, index: any) => (
							<tr key={ "MessageHistoryList" + index }>
								<td>{ item.terminalName }</td>
								<td><Moment format="YYYY-MM-DD HH:mm" date={ item.sentDate }/></td>
								<td>{ item.content }</td>
								<td>{ item.voiceName }</td>
								<td>{ this.statusName[item.mesSts]}</td>
							</tr>
						))
						}
					</tbody>
				</table>
			</div>
		);
	}
}
