import React, {useState , forwardRef} from "react";
import "./Setting.css"
import "./EmailTemplateManagement.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import Cookies from 'js-cookie';
import {useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import icPlusGreen from "../../assets/img/ic_plus_green.png";
import icNotes from "../../assets/img/ic_notes.png";
import { Button } from "reactstrap";
import { isBlank, toastOptions } from "../../common/Utils";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function debounce(func: any, delay: any) {
  let timeoutId: any;

  return function (...args: any) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
export default class EmailTemplateManagement extends React.Component<
  {
    onNavi: Function;
  },
  {
    listData: any[];
    sectionTitle: any;
    sectionButton: any;
    mailID: any;
    mailSubject: any;
    mailBody: any;
    error: any;
    showEditComponent: boolean;
  }
> {
  // history = useHistory();
  addFormComponent: any;
  constructor(props: any) {
    super(props);
    this.state = {
      listData: [],
      sectionTitle: "メール編集",
      sectionButton: "操作",
      mailID: 0,
      mailSubject: "",
      mailBody: "",
      error: "",
      showEditComponent: false,
    };

    this.addFormComponent = React.createRef();
    this.getlistData = this.getlistData.bind(this);
    this.renderMailType = this.renderMailType.bind(this);
    this.onEditItem = this.onEditItem.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleSubmitAdd = this.handleSubmitAdd.bind(this);
    this.handleMailBodyChange = debounce(
      this.handleMailBodyChange.bind(this),
      300
    );
  }

  componentDidMount() {
    this.getlistData();
  }

  private renderMailType(id: number) {
    let message;

    switch (id) {
      case 101:
        message = "エリアを入力してください";
        break;
      case 102:
        message = "エリアを出力します";
        break;
      case 103:
        message = "速度超過";
        break;
      case 104:
        message = "道に迷った";
        break;
      case 105:
        message = "急加速";
        break;
      case 106:
        message = "近くに車があります"
        break;
      default:
        message = ""
        break;
    }

    return message;
  }

  private getlistData() {
    axiosInstance
      .get(HOST() + "/Form/V1/Mst/MailTemplate/GetList")
      .then((response) => {
        const mails = response.data.data;
        console.log("mails", mails);
        this.setState({ listData: mails });
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          toast.error(error.response.data.message, toastOptions);
        }
      });
  }

  public onCancel(path: any) {
    this.props.onNavi("/");
  }

  setItemData(item: any) {
    this.setState({ error: "" });
    if (item && item.mailID > 0) {
      this.setState({
        mailID: item.mailID,
        mailSubject: item.mailSubject,
        mailBody: item.mailBody,
      });
    }
  }

  handleMailBodyChange(value: any) {
    this.setState({ mailBody: value });
  }

  public handleSubmitAdd() {
    // if (!isValid) {
    //   this.setState({ error: "メールアドレスを入力してください。" });
    //   return;
    // }
    if (
      !this.state.mailSubject ||
      this.state.mailBody.trim() === "<p><br></p>"
    ) {
      const missingFields = [];
      if (!this.state.mailSubject) {
        missingFields.push("Email Subject");
      }
      if (this.state.mailBody.trim() === "<p><br></p>") {
        missingFields.push("Email Body");
      }
      toast.error(
        `${missingFields.join(", ")} のフィールドは空白にできません。`,
        {
          position: "top-right",
          autoClose: 5000,
          draggable: true,
          closeOnClick: true,
        }
      );
      return;
    }
    var body = {
      mailID: this.state.mailID,
      mailSubject: this.state.mailSubject,
      mailBody: this.state.mailBody,
    };
    const api = axiosInstance.post(
      HOST() + "/Form/V1/Mst/MailTemplate/Update",
      body
    );
    api
      .then((res) => {
        if (res.status == 200 && res.data) {
          this.getlistData();
          toast.success("メールアドレスを編集しました。", toastOptions);
          this.setState({ showEditComponent: false });
        } else {
          this.setState({ error: "メールテンプレートの更新に失敗しました。" });
        }
      })
      .catch((e) => {
        this.setState({ error: "Request Failed" });
      });

    // var div = document.getElementById('addComponent')
    // if(div) div.hidden = true;
  }

  tickIcon(value: Number) {
    if (value === 1) {
      return <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>;
    }
    return <span></span>;
  }

  public onEditItem(object: any) {
    this.setItemData(object);
    this.setState({ showEditComponent: true });
  }

  public render() {
    return (
      <div>
        <div className="box email-manage">
          <div className="box-title d-flex justify-content-between align-items-center">
            <span className="page-header">メールテンプレートの修正</span>
            <div className="buttonEdit">
              <button
                type="button"
                className="btn btn-primary btn-sm mar-5 btn-135"
                onClick={this.onCancel}>
                キャンセル
              </button>
            </div>
          </div>

          {/* <header className="th-header">
            <span
              style={{
                position: "absolute",
                marginTop: "10px",
                left: "45%",
                fontSize: "15px",
              }}>
              メールアドレス一覧
            </span>
          </header> */}
          <div
            className="table-container"
            style={{
              maxHeight: this.state.showEditComponent ? "24vh" : "80vh",
            }}>
            <table
              id="emails"
              className="table-sm"
              style={{ marginBottom: "10px" }}>
              <tbody>
                <tr>
                  <th style={{ width: "15%" }}>所属</th>
                  <th style={{ width: "15%" }}>件名</th>
                  <th style={{ width: "60%" }}>内容</th>
                  <th>アクション</th>
                </tr>
                {this.state.listData.map((item, index) => (
                  <tr key={`mailSetting${item.mailID}&${index}`}>
                    <td style={{ textAlign: "left" }}>
                      {this.renderMailType(item.mailID)}
                    </td>
                    <td>{item?.mailSubject}</td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: item?.mailBody,
                      }}></td>
                    <td>
                      <Button
                        onClick={() => this.onEditItem(item)}
                        className="button-img btn-50">
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="#000000"></FontAwesomeIcon>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            id="addComponent"
            style={{
              display: this.state.showEditComponent ? "block" : "none",
            }}>
            <form>
              <header className="th-header bg-blue">
                <span
                  style={{
                    position: "absolute",
                    marginTop: "10px",
                    left: "45%",
                    fontSize: "15px",
                  }}>
                  {this.state.sectionTitle}
                </span>
              </header>
              <table onSubmit={this.handleSubmitAdd} className="table-sm">
                <tbody>
                  <tr>
                    <th style={{ width: "15%" }}>所属</th>
                    <th style={{ width: "15%" }}>件名</th>
                    <th style={{ width: "60%" }}>内容</th>
                    <th style={{ width: "10%" }}>{this.state.sectionButton}</th>
                  </tr>
                  <tr>
                    <td>{this.renderMailType(this.state.mailID)}</td>
                    <td>
                      <input
                        name="mailAddress"
                        type="text"
                        value={this.state.mailSubject}
                        onChange={(e) =>
                          this.setState({ mailSubject: e.target.value })
                        }
                        style={{ width: "100%" }}
                        maxLength={100}
                      />
                      <br />
                      <span className="errClass">{this.state.error}</span>
                    </td>
                    <td>
                      <ReactQuill
                        value={`${this.state.mailBody}`}
                        onChange={this.handleMailBodyChange}
                      />
                    </td>
                    <td>
                      {/* <input type="submit" value="登録" className="cancel-button register"/> */}
                      <Button
                        onClick={this.handleSubmitAdd}
                        className="btn btn-primary btn-sm register">
                        {this.state.sectionButton}
                      </Button>
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({ showEditComponent: false })
                        }
                        className="btn btn-warning btn-sm register mt-1">
                        キャンセル
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    );
  }
}