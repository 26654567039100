import { Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import './CustomModal.css';
import EditAreaManagement from '../../components/Area/EditAreaManagement';
import AccountInfoChange from '../../components/AccountInfoChange/AccountInfoChange';
import EditRouteManagement from '../../components/RouteManagement/EditRouteManagement';
import EditTerminalItem from '../../components/Terminal/EditTerminalItem'
import TerminalInformation from '../../components/Terminal/TerminalInformation'
import RemindTime from '../../components/Login/RemindTime'
import MessageHistory from '../../components/BottomTabItems/MessageHistory';
import ContractDetail from '../../components/Settings/ContractDetail';
import VersionInfo from '../../components/Settings/VersionInfo';
import EmailManagement from '../../components/Settings/EmailManagement';
import UnitCountSetting from '../../components/Settings/UnitCountSetting';
import SoundRegistration from '../../components/Settings/SoundRegistration';
import ProximityAlarmSetting from '../../components/Settings/ProximityAlarmSetting';
import AccelerationWarningSetting from '../../components/Settings/AccelerationWarningSetting';
import DetailSetting from '../../components/Settings/DetailSetting';
import PressurePumpingTimeSetting from '../../components/Settings/PressurePumpingTimeSetting';
import CargoSetting from '../../components/Settings/CargoSetting';
import MoveArea from '../../components/Area/MoveArea';
import AutoSendMessage from '../../components/Settings/AutoSendMessage';
import EditTerminalIcon from '../../components/Terminal/EditTerminalIcon';
import EmailTemplateManagement from '../../components/Settings/EmailTemplateManagement';

export default function VerticallyCenteredModal(props: any) {
     
    function getBody() {
        if (props.path === '/accountInfoChange') {
            return (<AccountInfoChange onNavi={props.onNavi}/>);
        }
        if (props.path === '/editRouteManagement') {
            return (<EditRouteManagement
                onNavi={props.onNavi}
                path={props.data.pathEncoded}
                routeId={props.data.routeId}
                routeLinkingMode={false}
                goTime={props.data.GoTime}
                reTime={props.data.ReTime}
                distance={props.data.Distance}
            />);
        }
        if (props.path === '/editRouteManagementLinking') {
            return (<EditRouteManagement
                onNavi={props.onNavi}
                path={props.data.pathEncoded}
                routeId={props.data.routeId}
                routeLinkingMode={true}
                goTime={0}
                reTime={0}
                distance={0}
            />);
        }
        if (props.path === '/editAreaManagement') {
            return (<EditAreaManagement onNavi={props.onNavi}
                path={props.data.pathEncoded}
                areaId={props.data.areaId}
                circleRadius={props.data.circleRadius}
                circleLatitude={props.data.circleLatitude}
                circleLongitude={props.data.circleLongitude}
            />);
        }
        if (props.path === '/EditTerminalItem') {
            return (<EditTerminalItem onNavi={props.onNavi} id={props.data.cliID}/>);
        }
        if (props.path === '/EditTerminalIcon') {
            return (<EditTerminalIcon onNavi={props.onNavi} CliID={props.data.cliID}/>);
        }
        if (props.path === '/TerminalInformation') {
            return (<TerminalInformation onNavi={props.onNavi} />);
        }
        if (props.path === '/remindTime') {
            return (<RemindTime onNavi={props.onNavi} remainDays={props.data.remainDays}/>);
        }
        if (props.path === '/messageHistory') {
            return (<MessageHistory onNavi={props.onNavi} isRouteChange={props.data.isRouteChange}/>);
        }
        if (props.path === '/contractDetail') {
            return (<ContractDetail onNavi={props.onNavi} />);
        }
        if (props.path === '/version') {
            return (<VersionInfo onNavi={props.onNavi} />);
        }
        if (props.path === '/EmailManagement') {
	        return (<EmailManagement onNavi={props.onNavi} />);
        }
        if (props.path === "/EmailTemplateManagement") {
          return <EmailTemplateManagement onNavi={props.onNavi} />;
        }
        if (props.path === '/UnitCountSetting') {
	        return (<UnitCountSetting onNavi={props.onNavi} />);
        }
        if (props.path === '/SoundRegistration') {
	        return (<SoundRegistration onNavi={props.onNavi} />);
        }
        if (props.path === '/ProximityAlarmSetting') {
	        return (<ProximityAlarmSetting onNavi={props.onNavi} />);
        }
        if (props.path === '/AccelerationWarningSetting') {
	        return (<AccelerationWarningSetting onNavi={props.onNavi} />);
        }
        if (props.path === '/DetailSetting') {
	        return (<DetailSetting onNavi={props.onNavi} />);
        }
        if (props.path === '/CargoSetting') {
	        return (<CargoSetting onNavi={props.onNavi} />);
        }
        if (props.path === '/EditTerminalItem') {
            return (<EditTerminalItem onNavi={props.onNavi} id={ props.data.id }/>);
        }
        if (props.path === '/TerminalInformation') {
	        return (<TerminalInformation onNavi={props.onNavi} />);
        }
        if (props.path === '/AutoSendMessage') {
            return (<AutoSendMessage onNavi={props.onNavi} />);
        }
        if (props.path === '/moveArea') {
            return (<MoveArea
                onNavi={props.onNavi}
                drawedLine={props.data.drawedLine}
                area={props.data.area}
                listId={props.data.listId}
            />);
        }
        if (props.path === '/PressurePumpingTimeSetting') {
            return (<PressurePumpingTimeSetting onNavi={props.onNavi} />);
        }
        return (<div></div>);
    }

    function onShow() {
        let dialog: any = document.querySelector('[role="dialog"]');
        if(dialog) dialog.oncontextmenu = () => false;
    }

    function onHide() {
        if (props.path === '/moveArea') {
            props.onNavi('/', { target: 'areaListRedraw', area: props.data.area, listId: props.data.listId });
            return;
        }
        props.onHide();
    }

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName={props.data.sizeFitContent ? "" : "modal-full"}
            centered
            show={props.show}
            onHide={() => onHide()}
            onShow={() => onShow()}
        >
            <Modal.Body>
                {
                    getBody()
                }
            </Modal.Body>
        </Modal>
    );
}