import React, { useState } from "react";
import { FormCheck } from "react-bootstrap";
import Cookies from 'js-cookie';

export default function RemindTime(props: any) {
    const [skip, setSkip] = useState(false);

    function handleSubmit() {
        if (skip) {
            Cookies.set('skipRemindTime', "true", { expires: 365 });
        }

        props.onNavi('/');
    }

    return (
        <div>
            <div className="text-center" style={{ height: 77 }}>
                有効期限が残り{props.remainDays}日になりました。
            </div>
            <div className="text-center">
            <FormCheck
                label="次回から表示しない"
                onChange={(e: any) => setSkip(e.target.checked)}
                />
            </div>
            <div className="text-center">
            <button
                className="btn btn-md btn-primary"
                onClick={() => handleSubmit()}
                style={{ float: 'right' }}>
                    OK
            </button>
                </div>
        </div>
    );
}
