import * as React from 'react';
import './RouteList.css';
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Collapse, Dropdown } from 'react-bootstrap';
import { hideMenu, drawPathToMap, rgbToHex, toastOptions, tableResizable } from '../../common/Utils';
import Cookies from 'js-cookie';
import DragAndDrop from '../../common/DragAndDrop/DragAndDrop';
import { toast } from 'react-toastify';

export default class RouteList extends React.Component<{
    map: any,
    onNavi: Function,
}, {
    isOpenBlock: boolean,
    listRoute: any[]
    routeAreaDisplaying: any,
    }> {
	constructor(props: any) {
		super(props);
		this.state = {
            isOpenBlock: true,
            listRoute: [],
            routeAreaDisplaying: false,
        };
        this.openEditRouteManagementDialog = this.openEditRouteManagementDialog.bind(this);
    }

    drawedPath: any = {};
    listRouteArea: any = [];

    pathDrawedPosition: any[] = [];
    userData = Cookies.get('user');
    user = JSON.parse(this.userData ? this.userData : '{}');
	dataFirstLoad = false;

    public getListRoute(focusId: any = null) {
        this.dataFirstLoad = true;
        const ListRouteCheckedMap: any = {};
        this.state.listRoute.forEach((e: any) => ListRouteCheckedMap[e.routeID] = true);

        axiosInstance
            .get(HOST() + "/Form/V1/Dat/RouteToInterval/ListAll")
            .then(response => {
                const listRoute = response.data.data || [];
                listRoute.forEach((e: any, i: any) => {
					delete ListRouteCheckedMap[e.routeID];
					e.using = e.using == 1;
					e.display = e.display == 1;
					if (e.display) {
						this.updateDisplay(e, true, i, true);
					}
                });
                for (let routeID in ListRouteCheckedMap) {
                    if (this.drawedPath[routeID]) {
                        this.drawedPath[routeID].polyline.setMap(null);
                        this.drawedPath[routeID].fromMarker && this.drawedPath[routeID].fromMarker.setMap(null);
                        this.drawedPath[routeID].toMarker && this.drawedPath[routeID].toMarker.setMap(null);
                    }
                }
                this.setState({ listRoute }, () => {
                    tableResizable('routeList');
                    if (focusId) {
                        this.mapFocusTo({routeID: focusId, display: true})
                    }
				});
            });
    }

    private updateDisplay(data: any, display: boolean, index: any, skipUpdate = false) {
        const routeID = data.routeID;
        const listRoute = this.state.listRoute;
        if(listRoute[index]) listRoute[index].display = display;

        if (!display && !this.drawedPath[routeID]) {
			return;
        }

        if (!display && this.drawedPath[routeID]) {
            this.drawedPath[routeID].polyline.setMap(null);
            this.drawedPath[routeID].fromMarker && this.drawedPath[routeID].fromMarker.setMap(null);
            this.drawedPath[routeID].toMarker && this.drawedPath[routeID].toMarker.setMap(null);
            if (!skipUpdate) {
                this.setState({ listRoute });
            }
            return;
        }

        if (this.drawedPath[routeID]) {
            const color = rgbToHex(data.routeColorRGB)
            this.drawedPath[routeID].polyline.setMap(this.props.map);
            this.drawedPath[routeID].fromMarker && this.drawedPath[routeID].fromMarker.setMap(this.props.map);
            this.drawedPath[routeID].toMarker && this.drawedPath[routeID].toMarker.setMap(this.props.map);
			this.drawedPath[routeID].polyline.setOptions({ strokeColor: color, fillColor: color });
            !skipUpdate && this.props.map.setCenter(this.pathDrawedPosition[routeID]);
            if (!skipUpdate) {
                this.setState({ listRoute });
            }
            return;
        }
        this.drawRoute(routeID, data.routeColorRGB, listRoute, skipUpdate);
    }

    private updateDisplayDb(routeId: any, value: any) {
		const body = { RouteID: routeId, Use: value ? 1 : 0 };
		axiosInstance.put(HOST() + "/Form/V1/Dat/Route/UpdateDisplay", body).then((res) => {
			if (res && res.data && res.data.result) {
				toast.success(value ? '表示になりました。' : '非表示になりました。', toastOptions);
            }
		});
	}

    private updateDisplayAll(display: boolean) {
        hideMenu('dropdown-route-s-option');
        const listRoute = this.state.listRoute;
        listRoute.forEach((e, i) => {
            this.updateDisplay(e, display, i);
        });
        this.setState({ listRoute });
        const body = this.state.listRoute.map(e => { return { RouteID: e.routeID, Use: display ? 1 : 0 } });
		axiosInstance.put(HOST() + "/Form/V1/Dat/Route/UpdateListDisplay", body).then(res => {
			if (res && res.data && res.data.result) {
				toast.success(display ? 'すべて表示になりました。' : 'すべて非表示になりました。', toastOptions);
            }
		});
    }

    public showRouteArea = () => {
        hideMenu('dropdown-route-s-option');
        if (this.state.routeAreaDisplaying) {
            this.listRouteArea.forEach((e: any) => e.setMap(null));
            this.setState({ routeAreaDisplaying: false });
            this.listRouteArea = [];
            return;
        }

        this.state.listRoute.forEach((route: any) => {
            if (!(route.using && route.display && route.datRouteArea)) return;
            const { areaLat1, areaLat2, areaLon1, areaLon2 } = route.datRouteArea;
            const rectangle = new window.google.maps.Rectangle({
                strokeColor:  'red',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: rgbToHex(route.routeColorRGB),
                fillOpacity: 0.07,
                map: this.props.map,
                bounds: {
                    north: Math.max(areaLat1, areaLat2),
                    south: Math.min(areaLat1, areaLat2),
                    east: Math.max(areaLon1, areaLon2),
                    west: Math.min(areaLon1, areaLon2),
                },
            });
            this.listRouteArea.push(rectangle);
		})
        this.setState({ routeAreaDisplaying: true });

	}

    public mapFocusTo(data: any) {
        if (!data.display) {
			return;
        }
		const routeID = data.routeID;
		axiosInstance
			.get(HOST() + `/Form/V1/Dat/RouteDetail/GetByID?routeID=${routeID}`)
			.then(response => {
				var path = response.data.data;
				var bounds = new window.google.maps.LatLngBounds();
				for (var i = 0; i < path.length; i++) {
					path[i].lat = path[i].posLat;
					path[i].lng = path[i].posLon;
					bounds.extend(path[i]);
				}
				this.props.map.fitBounds(bounds);
			});
    }

    private updateUsingAll(enabled: boolean) {
        hideMenu('dropdown-route-s-option');
        const body = this.state.listRoute.map(e => { return { RouteID: e.routeID, Use: enabled ? 1 : 0 } });
        axiosInstance.put(HOST() + "/Form/V1/Dat/Route/UpdateListUsing ", body);
    }

    private showUpdateUsing(data: any, checkType: any, prefix: any = '') {
		if ([1].indexOf(checkType) >= 0 && data[checkType]) {
			toast.error((prefix ? `${prefix}: `: '') + `ルートが見つかりません。`, toastOptions);
        }

   //     if ([3].indexOf(checkType) >= 0 && data[checkType] && data[checkType].length > 0) {
			//toast.error((prefix ? `${prefix}: `: '') + `「${data[checkType].join('」「')}」と紐づけされている為、無効に出来ません。`, toastOptions);
   //     }

   //     if ([5].indexOf(checkType) >= 0 && data[checkType] && data[checkType].length > 0) {
   //         toast.error((prefix ? `${prefix}: ` : '') + `「${data[checkType].join('」「')}」と紐づけされている為、無効に出来ません。`, toastOptions);
   //     }

		//if ([2].indexOf(checkType) >= 0 && data[checkType]) {
  //          toast.error((prefix ? `${prefix}: ` : '') + `積込み種別設定で使用されているため解除できません。`, toastOptions);
  //      }

		if ([4].indexOf(checkType) >= 0 && data[checkType]) {
			toast.error((prefix ? `${prefix}: `: '') + `ルートの削除に失敗しました。`, toastOptions);
        }
        		
    }

	private isUpdateUsingOk(data: any) {
		return Object.keys(data).length === 1 && Object.keys(data)[0] === '0';
    }

    private drawRoute(routeID: any, colorRGB: any, listRoute: any, skipUpdate = false) {
        const _this = this;
        axiosInstance
            .get(HOST() + `/Form/V1/Dat/RouteDetail/GetByID?routeID=${routeID}`)
            .then(response => {
                if (!response || !response.data || !response.data.data) {
                    return;
                }
                const drawedData = drawPathToMap(response.data.data, _this.props.map, colorRGB, false, { hideMarker: true });
                if (this.drawedPath[routeID]) {
                    this.drawedPath[routeID].polyline.setMap(null);
                    this.drawedPath[routeID].fromMarker && this.drawedPath[routeID].fromMarker.setMap(null);
                    this.drawedPath[routeID].toMarker && this.drawedPath[routeID].toMarker.setMap(null);
                }
                
                // id to drawed array
                _this.drawedPath[routeID] = drawedData.drawedPath;
                _this.pathDrawedPosition[routeID] = drawedData.pathDrawedPosition;
                if (!skipUpdate) {
                    _this.setState({ listRoute });
                }
            })
    }

    private openEditRouteManagementDialog(route: any) {
        const modalData = {
            routeId: route.routeID,
        };
        this.props.onNavi("/editRouteManagement", modalData);
    }

    private updateUsing(routeID: number, value: any, index: any) {
        const body = { RouteID: routeID, Use: value ? 1 : 0 };
        const listRoute = this.state.listRoute;
        axiosInstance
            .put(HOST() + "/Form/V1/Dat/Route/UpdateUsing", body)
            .then(e => {
				const data = JSON.parse(e.data.data);
				if (this.isUpdateUsingOk(data)) {
					toast.success(value ? '有効になりました。' : '無効になりました。', toastOptions);
					listRoute[index].using = value;
                    this.setState({ listRoute }, () => {
                        axiosInstance.get(HOST() + "/Form/V1/Dat/Route/SyncDataAllUser?title=SyncRoute");
                    });
				} else {
					[1, 2, 3, 4, 5].forEach(v => this.showUpdateUsing(data, v));
				}
			});
    }

    private updateDisplayOrder(e: any) {
        this.setState(e);
        const body = e.listRoute.map((e: any) => { return { RouteID: e.routeID, Order: e.DisplayOrder }; });
        axiosInstance.put(HOST() + "/Form/V1/Dat/Route/SetOrder", body);
    }

    componentWillReceiveProps(nextProps: any) {
		if (nextProps.map && !this.dataFirstLoad) {
			this.getListRoute();
        }
    }

    componentWillMount() {
		if (this.props.map && !this.dataFirstLoad) {
			this.getListRoute();
        }
    }

	public render() {
		return (
            <div className="card">
                <Dropdown>
				    <div className="card-header" id="headingSix">
					    <a
						    href="#"
						    onClick={() => this.setState({ isOpenBlock: !this.state.isOpenBlock })}
						    className="collapsible-link d-block"
					    >
						    ルート一覧
				      </a>
                        <div className="controls-more">
                            <Dropdown.Toggle variant="link" id="dropdown-route-s-option" className="dropdown-route-dd">
						        <FontAwesomeIcon icon={faEllipsisV}></FontAwesomeIcon>
                            </Dropdown.Toggle>
					    </div>
				    </div>
				    <Collapse in={this.state.isOpenBlock}>
                        <div className="card-body route-list">
                            <div className="table-responsive">
							    <table className="table table-bordered" id='routeList' style={{ width: '520px' }} >
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>ルート名</th>
                                            <th>範囲<br></br>(m)</th>
                                            <th>時間<br></br>(秒)</th>
                                            <th>制限速度<br></br>(km/h)</th>
                                            <th>有効</th>
                                            <th>表示</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    {
                                        this.state.listRoute.map((route: any, index: any) => (
                                            <DragAndDrop
                                                key={"route" + index}
                                                index={index}
                                                onchange={(e: any) => this.updateDisplayOrder(e)}
                                                dataSource={this.state.listRoute}
                                                tableId={`routeList`}
                                                listName="listRoute"
                                            >
                                                <tr className='route-list-item clickable' onClick={() => this.mapFocusTo(route)} data-index={index}>
                                                    <td>{route.routeID}</td>
                                                    <td>{route.routeNm}</td>
                                                    <td>{route.radius}</td>
                                                    <td>{route.routeInterval}</td>
                                                    <td>{route.spdLim}</td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={route.using}
                                                            onClick={e => e.stopPropagation()}
                                                            onChange={e => this.updateUsing(route.routeID, e.target.checked, index)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={route.display}
                                                            onClick={(e: any) => e.stopPropagation()}
                                                            onChange={(e: any) => {
                                                                this.updateDisplay(route, e.target.checked, index);
                                                                this.updateDisplayDb(route.routeID, e.target.checked);
                                                                if (e.target.checked) this.mapFocusTo(route);

                                                            }}
                                                         />
                                                    </td>
                                                    <td>
                                                        <button onClick={(e: any) => {
                                                            this.openEditRouteManagementDialog(route);
                                                            e.stopPropagation();
                                                        }} >
                                                            <FontAwesomeIcon icon={faEdit} color="#000000"></FontAwesomeIcon>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </DragAndDrop>
                                        ))
                                    }
                                </table>
                            </div>
                        </div>
                    </Collapse>
                    <Dropdown.Menu>
                        <a className="dropdown-item" href="#" onClick={() => this.updateDisplayAll(true)}>全て表示</a>
                        <a className="dropdown-item" href="#" onClick={() => this.updateDisplayAll(false)}>全て非表示</a>
                        <a className="dropdown-item" href="#" onClick={this.showRouteArea}>全てルート逸脱範囲を表示</a>
                    </Dropdown.Menu>
                 </Dropdown>
			</div>
		);
	}
}
