import * as React from 'react';
import './AudioRecord.css';
import axiosInstance from '../../common/interceptor';
import { HOST } from '../../common/constant';
import { faMicrophoneAlt, faMicrophone, faMusic, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactMic } from 'react-mic';
import { toast } from 'react-toastify';
import { toastOptions } from '../../common/Utils';
import { Button } from 'reactstrap';

export default class AudioCall extends React.Component<{
    onNavi: Function,
}, {
    time: any,
    seconds: number,
    endShowing:boolean,
    msgShowing: boolean,
    isRecording: boolean,
}> {
    timer: any;
	constructor(props: any) {
		super(props);
		this.state = {
		    time: {},
            seconds: 35,
            endShowing: false,
            msgShowing: false,
            isRecording: false,
        }
        this.startTimer = this.startTimer.bind(this)
        this.countDown = this.countDown.bind(this)
        this.onStopRecord = this.onStopRecord.bind(this)
        this.onClickButtonOK = this.onClickButtonOK.bind(this);
        // this.startTimer();
        this.timer = 0;
	}

    public startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.setState({seconds: 35});
            this.timer = setInterval(this.countDown, 1000); // 30s
            this.setState({endShowing: true});
            this.setState({msgShowing: false});
        }
    }
    
    public secondsToTime(secs: number){
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {"h": hours, "m": minutes, "s": seconds };
        return obj;
    }

    public countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });
    
        // Check if we're at zero.
        if (seconds == 0) { 
            // clearInterval(this.timer);
            // this.timer = 0;
            // to fetch terminal status
            this.onStopRecord();
        }
    }

	onStopRecord() {
        clearInterval(this.timer);
        this.timer = 0;
		this.props.onNavi('/', { target: 'audio', action: 'endCall'});
	}

    public reset() {
        clearInterval(this.timer);
        this.timer = 0;
        this.setState({endShowing: true});
        this.setState({msgShowing: false});
    }

    public onAudioMessageSent() {
        this.setState({endShowing: false});
        this.setState({msgShowing: true});
    }

    onClickButtonOK() {
        this.props.onNavi('/', { target: 'audio', action: 'completed'});
    }

	public render() {
		return (
			<div id="audio-record-container">
                <Button onClick={() => this.onStopRecord()} className="buttonCall">
                    <FontAwesomeIcon icon={faMicrophoneAlt} color='#555555' size="9x" />
                </Button>        
                <div className="emergency-message audio-end-message" 
                    style={{ display: this.state.endShowing ? 'inline-block' : 'none' }}>
                    <br/><br/>
                    <ReactMic
                        record={this.state.isRecording}
                        className="sound-wave"
                        visualSetting="sinewave"
                        strokeColor="#000000"
                        mimeType="audio/wav" />
                </div>
                <div className="emergency-message audio-sent-message" 
                    style={{ display: this.state.msgShowing ? 'inline-block' : 'none' }}>
                    <br/>
                    通話を終了します。
                    <br/> <br/>
                    <button style={{backgroundColor:'#03a9f4', width:'100px'}}
                        onClick={this.onClickButtonOK}>OK</button>
                </div>
			</div>
		);
	}
}
