
import React, {useState , forwardRef} from "react";
import "./Setting.css"
import "./AccelerationWarningSetting.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import { toast } from 'react-toastify';
import { getValidNumber, toastOptions } from "../../common/Utils";


export default class AccelerationWarningSetting extends React.Component<{
    onNavi: Function
}, {
    listData: any[],
    errorStart: any,
    errorBraking: any,
    errorhandle: any,
    numForward: any,
    numBackward: any,
    numHandle: any,
    isForward: boolean,
    isBackward: boolean,
    isHandle: boolean,
    limitID: any
}> {
    constructor(props: any) {
        super(props)
        this.state = {
            listData: [],
            errorStart: '',
            errorBraking: '',
            errorhandle: '',
            numForward: 0.1,
            numBackward: 0.1,
            numHandle: 0.1,
            isForward: true,
            isBackward: true,
            isHandle: true,
            limitID: 1
        }
        
        this.onFetchData = this.onFetchData.bind(this);
        this.handleSubmitAdd = this.handleSubmitAdd.bind(this);
    };

    componentDidMount() {
        this.setState({errorStart: '',  errorBraking: '', errorhandle: ''});
        this.onFetchData();
    }
  
    // var data = {forward: false, backward: false, handle: false, numBackward: 3, numForward: 0, numHandle: 1};
    private onFetchData() {
        var api = "/Form/V1/Dat/Accel/GetAllSetting"
        axiosInstance
        .get(HOST() + api)
        .then(response => {
            var data = response.data.data;
            
            this.setState({
                isForward: data.forward,
                isBackward: data.backward,
                isHandle: data.handle,
                numForward: data.numForward,
                numBackward: data.numBackward,
                numHandle: data.numHandle,
                limitID: data.limitID
            });
           
        })
        .catch(error => {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, toastOptions);
            }
        });
    }

    public onNavi(path: any) {
        
    }

    public close() {
        this.props.onNavi('/');
    }

   
    public handleSubmitAdd() {
        var isValid = true;
        this.setState({errorStart: '',  errorBraking: '', errorhandle: ''});
        if(this.state.numForward < 0 || this.state.numForward > 9.9) {
            this.setState({errorStart: "「急加速警告」の各値は0-9.9まで"});
            isValid = false;
        }
        if(this.state.numBackward < 0 || this.state.numBackward > 9.9) {
            this.setState({errorBraking: "「急ブレーキ警告」の各値は0-9.9まで"});
            isValid = false;
        }
        if(this.state.numHandle < 0 || this.state.numHandle > 9.9) {
            this.setState({errorhandle: "「急ハンドル警告」の各値は0-9.9まで"});
            isValid = false;
        }
        if(!isValid) return;

        var body = {
            forward: this.state.isForward, 
            backward: this.state.isBackward, 
            handle: this.state.isHandle, 
            numBackward: this.state.numBackward, 
            numForward: this.state.numForward, 
            numHandle: this.state.numHandle,
            limitID: this.state.limitID,
        };
        
        var api = "/Form/V1/Dat/Accel/UpdateSetting"
        axiosInstance
            .put(HOST() + api, body)
            .then(res => {
                if(res.status == 200 && res.data) {
                    toast.success("更新に成功。");
                    this.close();
                }
                else {
                    toast.error("Request Failed。" + res.statusText);
                }
            }).catch(e => {
                toast.error("Request Failed。");
            });
    }
 
    public render() {
        return (
            <div>
                <div className="box accel-wanring">
                    <div className="box-title d-flex justify-content-between align-items-center">
                    <span className="page-header">加速度警告設定</span>
                    <div className="buttonEdit">
                        <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.close()} >キャンセル</button>
                            <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.handleSubmitAdd} >保存</button>
                    </div>
                </div>

                <table className="tb-no-border mt-3 mb-3">
                    <tr>
                        <td style={{width:"100px"}}>急加速警告</td>
                        <td style={{width:"125px"}}>
                            <input type="radio" id="startY" name="start" value="0" checked={!this.state.isForward} onChange={e => this.setState({isForward: !e.target.checked})} />&nbsp;しない
                            &nbsp;&nbsp;<input type="radio" id="startN" name="start" value="1" checked={this.state.isForward} onChange={e => this.setState({isForward: e.target.checked})}/>&nbsp;する	
                        </td>
                        <td style={{width:"170px"}}>
                        <input name="numForward" disabled={!this.state.isForward} type="number" min="0.1" max="9.9" step="0.1" value={this.state.numForward} onChange={e => this.setState({numForward: getValidNumber(e, {minValue: 0.1, maxValue: 9.9, roundTo: 1})})} className="accel-input"/>
                        </td>    
                        <td style={{textAlign:'left'}}>&nbsp;&nbsp;(G以上)&nbsp; &nbsp; <span className="errClass">{this.state.errorStart}</span></td>
                    </tr>
                    <tr>
                        <td style={{width:"100px"}}>急ブレーキ警告</td>
                        <td style={{width:"125px"}}>
                            <input type="radio" id="brakingY" name="braking" value="0" checked={!this.state.isBackward} onChange={e => this.setState({isBackward: !e.target.checked})} />&nbsp;しない
                            &nbsp;&nbsp;<input type="radio" id="brakingN" name="braking" value="1" checked={this.state.isBackward} onChange={e => this.setState({isBackward: e.target.checked})}/>&nbsp;する	
                        </td>
                        <td style={{width:"170px"}}>
                        <input name="numBackward" disabled={!this.state.isBackward} type="number" min="0.1" max="9.9" step="0.1" value={this.state.numBackward} onChange={e => this.setState({numBackward: getValidNumber(e, {minValue: 0.1, maxValue: 9.9, roundTo: 1})})} className="accel-input"/>
                        </td>    
                        <td style={{textAlign:'left'}}>&nbsp;&nbsp;(G以上)&nbsp; &nbsp; <span className="errClass">{this.state.errorBraking}</span></td>
                    </tr>
                    <tr>
                        <td style={{width:"100px"}}>急ハンドル警告</td>
                        <td style={{width:"125px"}}>
                            <input type="radio" id="hanldeY" name="hanlde" value="0" checked={!this.state.isHandle} onChange={e => this.setState({isHandle: !e.target.checked})} />&nbsp;しない
                            &nbsp;&nbsp;<input type="radio" id="hanldeN" name="hanlde" value="1" checked={this.state.isHandle} onChange={e => this.setState({isHandle: e.target.checked})}/>&nbsp;する	
                        </td>
                        <td style={{width:"170px"}}>
                        <input name="gpsValidTime" disabled={!this.state.isHandle} type="number" min="0.1" max="9.9" step="0.1" value={this.state.numHandle} onChange={e => this.setState({numHandle: getValidNumber(e, {minValue: 0.1, maxValue: 9.9, roundTo: 1})})} className="accel-input"/>
                        </td>    
                        <td style={{textAlign:'left'}}>&nbsp;&nbsp;(G以上)&nbsp; &nbsp; <span className="errClass">{this.state.errorhandle}</span></td>
                    </tr>
                   
                </table>
            </div>
            </div>
        )
    };
}