import React from 'react'
import './DateTimeFromTo.css'
import { formatDateFrom, jsonDiff } from '../Utils';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import {registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
registerLocale("ja", ja);

export default class DateTimeFromTo extends React.Component<{
    dateFrom: any,
    dateTo: any,
    timeFrom: any,
    timeTo: any,
    className: any,
    onChange: Function
}, {
    dateFrom: any,
    dateTo: any,
    timeFrom: any,
    timeTo: any,
}> {
    constructor(props: any) {
        super(props)
        this.state = {
            dateFrom: this.props.timeFrom,
            dateTo: this.props.dateTo,
            timeFrom: this.props.timeFrom,
            timeTo: this.props.timeTo
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const diff = jsonDiff(this.state, nextProps);
        if (Object.keys(diff).length > 0) {
            this.setState({
                dateFrom: nextProps.dateFrom,
                dateTo: nextProps.dateTo,
                timeFrom: nextProps.timeFrom,
                timeTo: nextProps.timeTo
            });
        }
    }

    private updateState(data: any) {
        this.setState(data);
        const changeDate = {
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            timeFrom: this.state.timeFrom,
            timeTo: this.state.timeTo
        }
        const mergeData = Object.assign({}, changeDate, data);
        this.props.onChange(mergeData);
    }

    public initDefaultTime() {
        this.updateState({
            dateFrom: new Date(),
            dateTo: new Date(),
            timeFrom: '00:00',
            timeTo: '23:59',
        });
    }

    public getDateTime() {
        let startDate = this.state.dateFrom;
        startDate = startDate == null ? '1970-01-01' : moment(startDate).format('yyyy-MM-DD');
        let startTime = this.state.timeFrom;
        startTime = startTime == '' ? '00:00' : startTime;

        let endDate = this.state.dateTo;
        endDate = endDate == null ? '2100-01-01' : moment(endDate).format('yyyy-MM-DD');
        let endTime = this.state.timeTo;
        endTime = endTime == '' ? '23:59' : endTime;

        const fromDate = `${startDate}T${startTime}:00`;
        const toDate = `${endDate}T${endTime}:59`;

        return { fromDate, toDate };
    }

    render() {
        return (
            <div className={this.props.className}>
                <div className="d-flex align-items-center">
                    <span>
                        <DatePicker
                            className="form-control form-control-sm input-date"
                            selected={this.state.dateFrom}
                            dateFormat="yyyy/MM/dd"
                            onBlur={(e: any) => formatDateFrom(this, e, 'dateFrom')}
                            onChange={(e: any) => this.updateState({ dateFrom: e })} />
                    </span>
                    <input
                        type="time"
                        className="form-control form-control-sm input-time"
                        value={this.state.timeFrom}
                        onChange={e => this.updateState({ timeFrom: e.target.value })}
                    />
                    <span className="ml-2 mr-2">～</span>
                    <span>
                        <DatePicker
                            className="form-control form-control-sm input-date"
                            selected={this.state.dateTo}
                            dateFormat="yyyy/MM/dd"
                            onBlur={(e: any) => formatDateFrom(this, e, 'dateTo')}
                            onChange={(e: any) => this.updateState({ dateTo: e })} />
                    </span>
                    <input
                        type="time"
                        className="form-control form-control-sm input-time"
                        value={this.state.timeTo}
                        onChange={e => this.updateState({ timeTo: e.target.value })}
                    />
                </div>
            </div>
        );
    }
}