
import React from "react";
import "./Setting.css"
import "./PressurePumpingTimeSetting.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import Cookies from 'js-cookie';
import { toastOptions, getValidNumber } from "../../common/Utils";
import { toast } from "react-toastify";
import PitaCon1 from '../../assets/img/PitaCon1.bmp';
import PitaCon2 from '../../assets/img/PitaCon2.bmp';

export default class PressurePumpingTimeSetting extends React.Component<{
    onNavi: Function
}, {
    data: any,
    error: any,
    limitId: string,
    totalLimitTime: number,
    unloadingTime: number,
    pumpingTime: number,
}> {
    user: any;
    constructor(props: any) {
        super(props)
        this.state = {
            data: {},
            error: '',
            limitId: '1',
            totalLimitTime: 0,
            unloadingTime: 0,
            pumpingTime: 0
        }

        this.onFetchData = this.onFetchData.bind(this);
        this.handleSubmitAdd = this.handleSubmitAdd.bind(this);
    };

    componentDidMount() {
        const userData = Cookies.get('user');
        if (userData) {
            this.user = JSON.parse(userData ? userData : '{}');
        }
        this.onFetchData();
    }

    private onFetchData() {

        var api = "/Form/V1/Dat/ConcreteLimit/Get";
        axiosInstance
            .get(HOST() + api)
            .then(res => {
                const data = res.data.data;
                this.updateStateData(data);
            }).catch(e => {
                this.setState({ error: "Data loading failed" });
            });

    }

    updateStateData(data: any) {
        this.setState({
            limitId: `${data.limitID}`,
            totalLimitTime: data.totalLimitTime,
            unloadingTime: data.unloadingTime,
            pumpingTime: data.pumpingTime
        });
    }

    public close(reloadTerminal = false) {
        this.props.onNavi('/');
    }


    public handleSubmitAdd() {
        if ((this.state.limitId === '1' && this.state.totalLimitTime - this.state.unloadingTime < 0) || (this.state.limitId === '2' && (this.state.totalLimitTime - this.state.unloadingTime - this.state.pumpingTime) < 0)) {
            toast.error('運行自主警戒時間が0分以下になっています');
            return;

        }
        var body = {
            limitId: this.state.limitId,
            totalLimitTime: this.state.totalLimitTime,
            unloadingTime: this.state.unloadingTime,
            pumpingTime: this.state.limitId === '1' ? 0 : this.state.pumpingTime,

        }
        var api = "/Form/V1/Dat/ConcreteLimit/Update"

        axiosInstance
            .put(HOST() + api, body)
            .then(res => {
                if (res) {
                    toast.success("更新に成功。", toastOptions);
                    this.close(true);

                }
                else {
                    toast.error("Request Failed", toastOptions);
                }
            }).catch(e => {
                toast.error("Request Failed: " + e.message, toastOptions);
            });
    }

    public render() {
        return (
            <div>
                <div className="box timelimit-setting">
                    <div className="box-title d-flex justify-content-between align-items-center">
                        <div className="page-header">コンクリート圧送時間設定</div>
                        <div className="buttonEdit">
                            <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.close()}>キャンセル</button>
                            <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.handleSubmitAdd}> 保存</button >
                        </div>
                    </div>
                    <p className="mt-3 designModeBoxTitle">打設制限モード:</p>
                    <div className="row container-fluid">
                        <div className="col-4 designModeBox">
                            <input type="radio"
                                className="inline mr-3"
                                name="routeCheck"
                                checked={this.state.limitId === '1'}
                                onChange={e => this.setState({ limitId: '1' })} />
                            <span className="mr-4">JISモード</span>

                            <input type="radio"
                                className="inline  mr-3"
                                name="routeCheck"
                                checked={this.state.limitId === '2'}
                                onChange={e => this.setState({ limitId: '2' })} />
                            <span className="mr-1">コンクリート標準示方モード</span>
                        </div>
                    </div>
                    <div className="row container-fluid mt-3 pl-3">
                        {
                            this.state.limitId === '1' ? (

                                <div className="col-9 ml-5" style={{ height: '500px', backgroundImage: `url('${PitaCon1}')`, backgroundSize: '100% 100%' }}>

                                    <div className="row mt-2 limitSettingRow">
                                        <div className="col-3 p-2 text-right">
                                            <span>打設制限時間(T1): </span>
                                        </div>
                                        <div className="col-2 row">
                                            <input
                                                type="number"
                                                style={{ width: '5em' }}
                                                className="form-control"
                                                value={this.state.totalLimitTime}
                                                onChange={(e: any) => { this.setState({ totalLimitTime: getValidNumber(e, { minValue: 0, maxValue: 999, roundTo: 0 }) }) }}
                                            />
                                            <span className="ml-1 mt-2">分</span>
                                        </div>

                                        <div className="col-7 p-2">
                                            <p className="text-center">T1</p>
                                        </div>

                                    </div>
                                    <div className="row limitSettingRow secondRow">
                                        <div className="col-7">

                                        </div>
                                        <div className="col-3 text-center">
                                            <span>T2</span>
                                        </div>

                                    </div>
                                    <div className="row limitSettingRow mt-6">
                                        <div className="col-5 p-1 text-right">
                                            <span>荷降し所要時間(T2): </span>
                                        </div>
                                        <div className="col-5 row">
                                            <input
                                                type="number"
                                                style={{ width: '5em' }}
                                                className="form-control"
                                                value={this.state.unloadingTime}
                                                onChange={(e: any) => { this.setState({ unloadingTime: getValidNumber(e, { minValue: 0, maxValue: 999, roundTo: 0 }) }) }}
                                            />
                                            <span className="ml-2 mt-2">分</span>
                                        </div>

                                    </div>
                                    <div className="row limitSettingRow mt-6 fourthRow">
                                        <div className="col-4 text-right">

                                        </div>
                                        <div className="col-3">
                                            <span className="ml-5">(荷降し開始) </span>
                                        </div>
                                        <div className="col-5 text-right">
                                            <span className="ml-2 mt-2">(荷降し終了)</span>
                                        </div>

                                    </div>

                                    <div className="row limitSettingRow mt-6 fifthRow">
                                        <div className="col-3 text-left">
                                            <span className="ml-1">(積込み) </span>
                                        </div>
                                        <div className="col-2 text-right">
                                            <span>(現場到着) </span>
                                        </div>

                                    </div>

                                    <div className="row limitSettingRow mt-6 sixthRow">
                                        <div className="col-6">

                                        </div>
                                        <div className="col-3">
                                            <span className="ml-5 pl-3">(打設開始)</span>
                                        </div>

                                        <div className="col-3 text-right">
                                            <span className="extraText">(荷降し終了)</span>
                                        </div>


                                    </div>

                                    <div className="row limitSettingRow mt-6 seventhRow">
                                        <div className="col-6 text-center">
                                            <span className="ml-5">T</span>
                                        </div>


                                    </div>

                                    <div className="row limitSettingRow mt-6 seventhRow">
                                        <div className="col-6 p-1 text-center">
                                            <span>運行自主警戒時間(T): {this.state.totalLimitTime - this.state.unloadingTime} 分</span>
                                        </div>



                                    </div>
                                </div>


                            ) : (


                                    <div className="col-9 ml-5 pl-3" style={{ height: '500px', backgroundImage: `url('${PitaCon2}')`, backgroundSize: '100% 100%' }}>

                                        <div className="row mt-2 limitSettingRow">
                                            <div className="col-3 p-2 text-right">
                                                <span>打設制限時間(T1): </span>
                                            </div>
                                            <div className="col-2 row">
                                                <input
                                                    type="number"
                                                    style={{ width: '5em' }}
                                                    className="form-control"
                                                    value={this.state.totalLimitTime}
                                                    onChange={(e: any) => { this.setState({ totalLimitTime: getValidNumber(e, { minValue: 0, maxValue: 999, roundTo: 0 }) }) }}
                                                />
                                                <span className="ml-1 mt-2">分</span>
                                            </div>

                                            <div className="col-7 p-2">
                                                <p className="text-center">T1</p>
                                            </div>

                                        </div>
                                        <div className="row limitSettingRow secondRow">
                                            <div className="col-7">

                                            </div>
                                            <div className="col-2 text-center">
                                                <span>T2</span>
                                            </div>

                                        </div>
                                        <div className="row limitSettingRow mt-6">
                                            <div className="col-5 p-1 text-right">
                                                <span>荷降し所要時間(T2): </span>
                                            </div>
                                            <div className="col-5 row">
                                                <input
                                                    type="number"
                                                    style={{ width: '5em' }}
                                                    className="form-control"
                                                    value={this.state.unloadingTime}
                                                    onChange={(e: any) => { this.setState({ unloadingTime: getValidNumber(e, { minValue: 0, maxValue: 999, roundTo: 0 }) }) }}
                                                />
                                                <span className="ml-2 mt-2">分</span>
                                            </div>

                                        </div>
                                        <div className="row limitSettingRow mt-6 fourthRow">
                                            <div className="col-3">

                                            </div>
                                            <div className="col-3 text-right">
                                                <span className="ml-5">(荷降し開始) </span>
                                            </div>
                                            <div className="col-3">

                                            </div>
                                            <div className="col-3 text-left">
                                                <span className="ml-4 mt-2">(荷降し終了)</span>
                                            </div>

                                        </div>

                                        <div className="row limitSettingRow mt-6 fifthRow">
                                            <div className="col-3 text-left">
                                                <span className="ml-1">(積込み) </span>
                                            </div>
                                            <div className="col-2 text-left">
                                                <span>(現場到着) </span>
                                            </div>

                                        </div>

                                        <div className="row limitSettingRow mt-6 sixthRow">
                                            <div className="col-6">

                                            </div>
                                            <div className="col-2 text-center">
                                                <span className="">(打設開始)</span>
                                            </div>

                                            <div className="col-3 text-right">
                                                <span className="extraText">(荷降し終了)</span>
                                            </div>
                                        </div>

                                        <div className="row limitSettingRow mt-6 seventhRow">
                                            <div className="col-6 text-center">
                                                <span className="ml-5">T</span>
                                            </div>

                                            <div className="col-1 text-center">
                                                <span className="ml-5">T3</span>
                                            </div>
                                            <div className="col-2 text-center">

                                            </div>
                                            <div className="col-2 text-center">
                                                <span className="pl-3">T3</span>
                                            </div>
                                        </div>

                                        <div className="row limitSettingRow eighthRow">
                                            <div className="col-6 p-1 text-center">
                                                <span>運行自主警戒時間(T): {this.state.totalLimitTime - this.state.unloadingTime - this.state.pumpingTime} 分</span>
                                            </div>
                                            <div className="col-4 text-right mt-2">
                                                <span className="ml-1" style={{ fontSize: '15px' }}>ポンプ滞留時間(T3):</span>
                                            </div>
                                            <div className="col-2 row">
                                                <input type="number"
                                                    style={{ width: '4em' }}
                                                    className="form-control"
                                                    value={this.state.pumpingTime}
                                                    onChange={(e: any) => { this.setState({ pumpingTime: getValidNumber(e, { minValue: 0, maxValue: 999, roundTo: 0 }) }) }}
                                                />
                                                <span className="ml-1 mt-2">分</span>
                                            </div>
                                        </div>
                                    </div>
                            )
                        }

                    </div>
                </div>
            </div>
        )
    };
}