
import React, {useState , forwardRef} from "react";
import "./Setting.css";
import "./UnitCountSetting.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import Cookies from 'js-cookie';
import {useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faMailBulk, faPlusCircle, faExternalLinkAlt, faEdit, faAddressBook, faEnvelope, faFileAlt, faFile, faRemoveFormat, faTrash} from '@fortawesome/free-solid-svg-icons';
import { Button } from "reactstrap";
import { getValidNumber, isBlank, toastOptions } from "../../common/Utils";
import icPlusGreen from "../../assets/img/ic_plus_green.png";
import icAlarm from "../../assets/img/ic_alarm.png";
import { toast } from "react-toastify";


export default class UnitCountSetting extends React.Component<{
    onNavi: Function
}, {
    listData: any[],
    listAreaData: any[],
    listAreaFull: any[],
    showAddComponent: boolean,
    error: any,
    errorName: any,
    areaID: any, 
    countID: any,
    name: any, 
    comment: any, 
    alert: any, 
    highlow: any, 
    go: boolean, 
    return: boolean, 
    mail:boolean, 
    link: boolean,
    using: any,
    userID: any,
    sectionTitle: any,
    sectionButton: any,
    selectedIndex: number,
}> {
    // history = useHistory();
    addFormComponent: any;
    user: any;
    areaIds: any;
    listAreaAvailable: any[];
    constructor(props: any) {
        super(props)
        this.state = {
            listAreaData: [],
            listAreaFull: [],
            listData: [],
            showAddComponent: false,
            error: '',
            errorName: '',
            areaID: 0, 
            countID: 0,
            name: '', 
            comment: 0, 
            alert: 0, 
            highlow: "以下", 
            go: false, 
            return: false, 
            mail: false, 
            link: false,
            using: 1,
            userID: 0,
            sectionTitle: '新規登録',
            sectionButton: '登録',
            selectedIndex: 0,
        }
        
        this.addFormComponent = React.createRef();
        this.getlistData = this.getlistData.bind(this);
        this.getlistAreaData = this.getlistAreaData.bind(this);
        this.onEditItem = this.onEditItem.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.onAddItem = this.onAddItem.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.handleSubmitAdd = this.handleSubmitAdd.bind(this);

        const userData = Cookies.get('user');
        if(userData) {
            this.user = JSON.parse(userData ? userData : '{}');
        }
        this.areaIds = "";
        this.listAreaAvailable = [];
    };

    componentDidMount() {
        this.getlistData();
    }
  
    private getlistAreaData() {
        axiosInstance
            // .get(HOST() + "/Form/V1/Dat/Area/ListAll?userid=" + this.user.id)
            .get(HOST() + "/Form/V1/Dat/Area/ListAvailable?userid=" + this.user.id)
            // .get(HOST() + "/Form/V1/Dat/Area/ListAvailable")
            .then(response => {
                const items = response.data.data;
                this.setState({ listAreaData: items, listAreaFull: items });
                this.filterAreaListAvailable();
            })
            .catch(error => {
                console.log("[Area/ListAll] error: " + error.response.data.messag);
            });
    }
    
    private filterAreaListAvailable() {
        if(this.areaIds && this.areaIds.length > 0) {
            var vals=[];
            for(var item of this.state.listAreaData){
                if(this.areaIds.includes(","+item.areaID+",")) continue;
                vals.push(item); 
            }
            this.setState({listAreaData: vals});
        }
    }

    private getlistData() {
        axiosInstance
            .get(HOST() + "/Form/V1/Dat/AreaCount/GetAreaCount?userid=" + this.user.id)
            .then(response => {
                const items = response.data.data;
                this.setState({ listData: items });
                var ids=",";
                for(var item of this.state.listData) {
                    ids = ids + item.areaID + ",";
                }
                this.areaIds = ids;
                this.filterAreaListAvailable();
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    toast.success(error.response.data.message, toastOptions);
                }
            });

        this.getlistAreaData();
    }

    public onNavi(path: any) {
        
    }

    public onCancel(path: any) {
        if(this.state.showAddComponent) {
            this.setState({showAddComponent: false});
        }
        else {
            this.props.onNavi('/');
        }
    }

    public onDeleteItem(object:any) {
        axiosInstance
            .delete(HOST() + "/Form/V1/Dat/AreaCount/DeleteAreaCount?CountID=" + object.countID)
            .then((res: any) => {
                var message = "Delete failed."
                if(res.data) {
                    const result = res.data.result;
                    if (result) {
                        message = '台数カウントを削除しました。';
                        this.getlistData();
                        toast.success(message, toastOptions);
                        return;
                    }
                    else message = res.data.message;
                }
                toast.error(message, toastOptions);
            }).catch(e => {
                toast.error("Delete failed: " + e, toastOptions);
            });
    }

    public onEditItem(object: any, index: number) {
        this.listAreaAvailable = [{ "areaNm": object.areaNm, "areaID": object.areaID}];
        this.setItemData(object);
        
        this.setState({ showAddComponent: true });
        this.setState({ selectedIndex: index, errorName: '' });
    }

    public onAddItem() {
        this.listAreaAvailable = this.state.listAreaData;
        
        if(this.listAreaAvailable.length == 0) {
            alert("All Area was created.");
            return;
        }

        this.setItemData(null);
        this.setState({areaID: ""});
        
        this.setState({ showAddComponent: true, errorName: '' });
    }
    
    setItemData(item: any) {
        this.setState({error: ''});
        if(item && item.areaID > 0) {
            this.setState({countID: item.countID});
            this.setState({areaID: item.areaID});
            this.setState({name: item.areaNm});
            this.setState({comment: item.countComment});
            this.setState({alert: item.countAlert});
            this.setState({highlow: item.isLower?"以下": "以上"});
            this.setState({go: item.isCountGo});
            this.setState({return: item.isCountReturn});
            this.setState({mail: item.isSendMail});
            this.setState({link: item.isLinkDevice});
            this.setState({using: item.using});
            this.setState({userID: item.userID});

            this.setState({sectionTitle: '編集'});
            this.setState({sectionButton: '編集'});
        }
        else {
            this.setState({countID: 0});
            this.setState({areaID: 0});
            this.setState({name: ''});
            this.setState({comment: 0});
            this.setState({alert: 0});
            this.setState({highlow: "以下"});
            this.setState({go: false});
            this.setState({return: false});
            this.setState({mail: false});
            this.setState({link: false});
            this.setState({using: 1});
            this.setState({userID: this.user.id})

            this.setState({sectionTitle: '新規登録'});
            this.setState({sectionButton: '登録'});
        }
    }

    public validAreaTime() {
        const area = this.state.listAreaFull.filter(e => e.areaID == this.state.areaID)[0];
        if (!area) {
            return false;
        }

        return area.enableTimeFr && area.enableTimeTo;
    }

    public checkAreaValidTime() {
        if (!this.validAreaTime()) {
            this.setState({ errorName: "台数カウント設定を行えません。" });
        } else {
            this.setState({ errorName: '' })
        }
    }
 
    public validateInput() {
        var isValid = true;
        
        var noteComment = this.state.comment;
        var warnAlert = this.state.alert;
        var highlow = this.state.highlow;

        if (!this.validAreaTime()) {
            isValid = false;
        }

        if (noteComment < 0 || noteComment > 999) {
            this.setState({ errorName: "0～999" });
            isValid = false
        }
        else if (warnAlert < 0 || warnAlert > 999) {
            this.setState({ errorName: "0～999" });
            isValid = false
        }
        else if (noteComment == warnAlert) {
            this.setState({ errorName: "注と警には同じ値は設定できません。" });
            isValid = false
        }
        else if (noteComment < warnAlert && highlow === "以下" ) {
            this.setState({ errorName: "以下を選択している場合は注より警の値を小さく設定してください。" });
            isValid = false
        }
        else if (noteComment > warnAlert && highlow === "以上") {
            this.setState({ errorName: "以上を選択している場合は警より注の値を小さく設定してください。" });
            isValid = false
        }
        else { this.setState({ errorName: '' }); }

        return isValid;
    }

    public handleSubmitAdd() {
        if (!this.validateInput()) {
            return;
        }
        
        const isLower = (this.state.highlow === '以下')
        var body = {
            countID: this.state.countID,
            areaID: this.state.areaID,
            areaNm: this.state.name,
            countComment: Number(this.state.comment), 
            countAlert: Number(this.state.alert), 
            isLower: isLower, 
            /* isCountGo: this.state.go, 
            isCountReturn: this.state.return,  */
            isSendMail: this.state.mail, 
            isLinkDevice: this.state.link,
            using: this.state.using,
            userID: this.user.id
        };
        axiosInstance
            .put(HOST() + "/Form/V1/Dat/AreaCount/SetAreaCount", body)
            .then(res => {
                if(res.status == 200 && res.data) {
                    if(this.state.countID > 0) {
                        this.addItemToListView(body);
                        toast.success("台数カウントを編集しました。", toastOptions);
                    }
                    else {
                        this.getlistData();
                        this.setState({showAddComponent: false});
                        toast.success("台数カウントを登録しました。", toastOptions);
                    }
                }
                else {
                    this.setState({error: "Request Failed " + res.data.message});
                    toast.error("Request Failed: " + res.data.message, toastOptions);
                }
            }).catch(e => {
                this.setState({error: "Request Failed"});
                toast.error("Request Failed: ", toastOptions);
            });
    }
    
    addItemToListView(body: any) {
        var list = this.state.listData
        if(this.state.countID > 0) {
            var index = this.state.selectedIndex;
            this.setState({
                listData: [
                  ...this.state.listData.slice(0, index),
                  body,
                  ...this.state.listData.slice(index + 1)
                ]
              });
        }
        else {
            list.push(body)
            this.setState({listData: list});
        }
        
        this.setState({showAddComponent: false});
    }

    tickIcon(value:any) {
        if(value === 1 || value === true) {
            return <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
        }
        return <span></span>
    }

    public render() {
        return (
            <div>
                <div className="box unit-count">
                    <div className="box-title d-flex justify-content-between align-items-center">
                    <span className="page-header">台数カウント-設定</span>
                    <div className="buttonEdit">
                        <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.onCancel} >キャンセル</button>
                    </div>
                </div>
                
                <header className="th-header">
                            <span style={{position:"absolute", marginTop:"10px",left: "45%", fontSize:"15px"}}>設定一覧</span>
                            <Button onClick={this.onAddItem} className="align-right plus-button button-img">
                            <img src={icPlusGreen} width="27" height="27" className="border rounded-circle"/>
                            </Button>
                </header>    
                <table id='emails' style={{marginBottom:"10px"}}>
                    <tbody>
                        <tr>
                            <th className="th-l">エリア名称</th>
                            <th className="th-s">注意</th>
                            <th className="th-s">警告</th>
                            <th className="th-l">以下／以上</th>
                            <th className="th-s"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></th>
                            <th className="th-s"><img src={icAlarm} width="30" height="30"/></th>
                            <th className="th-s">編集</th>
                        </tr>
                        {this.state.listData.map( (item, index) => (
                                <tr key={index}>
                                    <td>{item.areaNm}</td>
                                    <td>{item.countComment}</td>
                                    <td>{item.countAlert}</td>
                                    <td>{item.isLower? "以下": "以上"}</td>
                                    
                                    <td>{this.tickIcon(item.isSendMail)}</td>
                                    <td>{this.tickIcon(item.isLinkDevice)}</td>
                                    <td><Button onClick={() => this.onEditItem(item, index)} className="button-img">
                                        <FontAwesomeIcon icon={faEdit} color="#000000"></FontAwesomeIcon>
                                        </Button>
                                        <Button onClick={() => this.onDeleteItem(item)} className="button-img">
                                            <FontAwesomeIcon icon={faTrash} color="#000000"></FontAwesomeIcon>
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div id="addComponent" style={{ display: this.state.showAddComponent ? "block" : "none" }}>
                <form>
                    <header className="th-header bg-blue">
                        <span style={{position:"absolute", marginTop:"10px",left: "45%", fontSize:"15px"}}>{this.state.sectionTitle}</span>
                    </header>                      
                    <table>
                        <tbody>
                            <tr>
                                <th className="th-l">エリア名称</th>
                                <th className="th-s">注意</th>
                                <th className="th-s">警告</th>
                                <th className="th-l">以下／以上</th>
                                <th className="th-s"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></th>
                                <th className="th-s"><img src={icAlarm} width="30" height="30"/></th>
                                <th className="th-s">{this.state.sectionButton}</th>
                            </tr>
                            <tr>
                                <td>
                                    {/* <input name="name" type="text" value={this.state.name} onChange={e => this.setState({name: e.target.value})} style={{width:"100%", height:"100%"}}/> */}
                                    
                                    <select className="form-control form-control-sm inline"
                                        style={{width:"100%", height:"100%"}}
                                        onChange={e => this.setState({ areaID: Number(e.target.value), name: e.target.options[e.target.selectedIndex].innerHTML }, () => this.checkAreaValidTime())}
                                        value={String(this.state.areaID)}
                                        disabled={this.state.countID > 0}>
                                            <option value="" key="area0"></option>
                                            {this.listAreaAvailable.map(area => (
                                                <option value={area.areaID} key={"area" + area.areaID}>{area.areaNm}</option>
                                        ))}
                                   </select>
                                </td>
                                <td>
                                            <input name="note" type="number" min="0" value={this.state.comment} onChange={e => this.setState({ comment: getValidNumber(e, { maxValue: 999, minValue: 0, roundTo: 0 })})} style={{width:"100%", height:"100%"}}/>
                                </td>
                                        <td>
                                            <input name="warn" type="number" min="0" value={this.state.alert} onChange={e => this.setState({ alert: getValidNumber(e, { maxValue: 999, minValue: 0, roundTo: 0 }) })} style={{ width: "100%", height: "100%" }} />
                                </td>
                                <td>
                                    {/* <input name="highlow" type="text" value={this.state.highlow} onChange={e => this.setState({highlow: e.target.value})} style={{width:"100%", height:"100%"}}/> */}
                                    <select className="form-control" id="highlow" onChange={e => this.setState({highlow: e.target.value})} style={{width:"100%", height:"100%"}}
                                        value={this.state.highlow}>
                                        <option value="以下">以下</option>
                                        <option value="以上">以上</option>
                                    </select>
                                </td>
                                <td><input name="mail" type="checkbox" checked={this.state.mail} onChange={e => this.setState({mail: e.target.checked})}/></td>
                                <td><input name="link" type="checkbox" checked={this.state.link} onChange={e => this.setState({link: e.target.checked})}/></td>
                                <td>
                                    {/* <input type="submit" value="登録" className="cancel-button register"/> */}
                                    <Button onClick={this.handleSubmitAdd} className="btn btn-primary btn-sm register" disabled={!this.state.areaID || !this.validAreaTime()}>{this.state.sectionButton}</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                            
                            <div className="errClass mt-1">{this.state.errorName}</div>
                            <div className="errClass mt-1">{this.state.error}</div>
                    </form>
                </div>
                </div>
            </div>
        )
    };
}