
import React, {useState , forwardRef, RefObject, ChangeEvent} from "react";
import "./Setting.css";
import "./SoundRegistration.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import Cookies from 'js-cookie';
import {useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faMailBulk, faPlusCircle, faExternalLinkAlt, faEdit, faAddressBook, faEnvelope, faFileAlt, faFile, faMusic, faSort, faArrowDown, faCartArrowDown, faSortDown, faPlay, faPauseCircle, faPause, faTrash} from '@fortawesome/free-solid-svg-icons';
import { Button } from "reactstrap";
import { isBlank, toastOptions } from "../../common/Utils";
import icPlusGreen from "../../assets/img/ic_plus_green.png";
import { lstat } from "fs";
import { toast } from "react-toastify";

export default class SoundRegistration extends React.Component<{
    onNavi: Function
}, {
    listData: any[],
    showAddComponent: boolean,
    sectionTitle: any,
    sectionButton: any,
    error: any,
    soundID: any, 
    soundNm: any, 
    soundFile: any, 
    soundFileDisplay: any,
    areaFlg: boolean, 
    mesFlg: boolean, 
    routeFlg: boolean, 
    proximityFlg: boolean,
    sysID: any,
    userID: any,
    alertClose: any,
    index: any,
    selectedAudioFile: any,
    pageItems: any[],
    totalPage: any,
    audioPlay: any;
    sortKey: string,
    isAsc: boolean
}> {
    // history = useHistory();
    readonly inputOpenFileRef: any;
    addFormComponent: any;
    page = 0;
    PAGING = 10;
    audio: any;
    
    constructor(props: any) {
        super(props)
        this.inputOpenFileRef = React.createRef()
        this.state = {
            listData: [],
            pageItems: [],
            showAddComponent: false,
            sectionTitle: '新規登録',
            sectionButton: '登録',
            error: '',
            soundID:0, 
            soundNm: '', 
            soundFile: '', 
            soundFileDisplay: '',
            areaFlg: false, 
            mesFlg: false, 
            routeFlg: false, 
            proximityFlg: false,
            sysID: 0,
            userID: 0,
            alertClose: null,
            index: 0,
            selectedAudioFile: null,
            totalPage: 1,
            audioPlay: -1,
            sortKey: '',
            isAsc: false
        }
        
        this.addFormComponent = React.createRef();
        this.getlistData = this.getlistData.bind(this);
        this.onEditItem = this.onEditItem.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.onAddItem = this.onAddItem.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.handleSubmitAdd = this.handleSubmitAdd.bind(this);
        this.onSelectVoice = this.onSelectVoice.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.onPlayVoice = this.onPlayVoice.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.showPageNumber = this.showPageNumber.bind(this);
    };

    componentDidMount() {
        this.getlistData();
    }
  
    private calculatePaging() {
        var total = Math.floor(this.state.listData.length/this.PAGING);
        if(this.state.listData.length%this.PAGING > 0) total = total + 1;
        this.setState({totalPage: total})
    }

    private getlistData() {
        axiosInstance
            // .get(HOST() + "/Form/V1/Dat/Sound/ListAllView")
            .get(HOST() + "/Form/V1/Dat/Sound/ListAll")
            .then(response => {
                const items = response.data.data;
                items.forEach((e: any) => {
                    e.soundFile = e.soundFile || '';
                    e.soundFileDisplay = e.soundFile.replace(/(.*)\-(\d)+\.(.*?)/g, '$1\.$3');
                })
                const filters = items.filter(function(elem:any, pos:any) {
                    return items.indexOf(elem) == pos;
                });
                
                
                this.setState({ listData: filters });
                this.calculatePaging();
                this.onPageChange(this.page);
            })
    }

    public onNavi(path: any) {
        
    }

    public onCancel(path: any) {
        if(this.state.showAddComponent) {
            this.setState({showAddComponent: false});
        }
        else {
            this.props.onNavi('/');
        }
    }

    public handleFileChange(files: any) {
        var aFiles = files as FileList
        if(aFiles && aFiles.length > 0) {
            this.setState({selectedAudioFile: files[0]})
            this.setState({ soundFile: files[0].name, soundFileDisplay: files[0].name})
        }
    }

    public onSelectVoice(){
        if (this.inputOpenFileRef !== undefined) {
            this.inputOpenFileRef.current.click()
        }
    }
    accessToken = Cookies.getJSON('user').token;
    public onPlayVoice(object:any) {
        if(this.audio) {
            this.audio.pause();
        }
        if(this.state.audioPlay === object.soundID) {
            this.setState({audioPlay: 0});
        }
        else {
            this.setState({audioPlay: object.soundID});
            var url = HOST() + '/Form/V1/File/download?filePath=' + object.soundFile + '&access_token=' + this.accessToken;
            this.audio = new Audio(url);
            this.audio.play();
            this.audio.onerror = (e: any) => {
                axiosInstance.get(url)
                    .then((e: any) => {
                        if (this.audio) {
                            this.audio.pause();
                        }
                        if (e.status == 404) {
                            return toast.error("音声ファイルが存在されていません。", toastOptions);
                        }
                        return toast.error("エラーが発生されているため、再生できません。", toastOptions);
                    })
                    .catch((e: any) => {
                        if (this.audio) {
                            this.audio.pause();
                        }
                        return toast.error("エラーが発生されているため、再生できません。", toastOptions);
                    })
            }
        }
    }

    componentWillUnmount() {
        if (this.audio) {
            this.audio.pause();
        }
    }

    public onEditItem(object:any, index: Number) {
        this.setItemData(object);
        // this.addMailComponent.setMailData(this.state.aMail);
        this.setState({showAddComponent: true});
        this.setState({index: index});
    }

    public onDeleteItem(object:any, index: Number) {
        axiosInstance
            .delete(HOST() + "/Form/V1/Dat/Sound/Delete?SoundID=" + object.soundID)
            .then((res: any) => {
                var message = "Delete failed."
                if(res.data) {
                    const result = res.data.result;
                    if (result) {
                        message = '音声を削除しました。';
                        this.getlistData();
                        toast.success(message, toastOptions);
                        return;
                    }
                    else message = res.data.message;
                }
                toast.error(message, toastOptions);
            }).catch(e => {
                toast.error("Delete failed: " + e, toastOptions);
            });
    }

    public onAddItem() {
        this.setItemData(null);
        // this.addMailComponent.setMailData(this.state.aMail);
        this.setState({showAddComponent: true});
    }
    
    setItemData(item: any) {
        this.setState({error: ''});
        this.setState({index: 0});
        if(item && item.soundID > 0) {
            this.setState({soundID: item.soundID});
            this.setState({soundNm: item.soundNm});
            this.setState({ soundFile: item.soundFile });
            this.setState({ soundFileDisplay: item.soundFileDisplay });
            this.setState({areaFlg: item.areaFlg});
            this.setState({mesFlg: item.mesFlg});
            this.setState({routeFlg: item.routeFlg});
            this.setState({proximityFlg: item.proximityFlg});
            this.setState({sysID: item.sysID});
            this.setState({userID: item.userID});
            this.setState({routeFlg: item.routeFlg});

            this.setState({sectionTitle: '編集'});
            this.setState({sectionButton: '登録'});
        }
        else {
            this.setState({soundID: 0});
            this.setState({soundNm: ''});
            this.setState({ soundFile: '' });
            this.setState({ soundFileDisplay: '' });
            this.setState({areaFlg: false});
            this.setState({mesFlg: false});
            this.setState({routeFlg: false});
            this.setState({proximityFlg: false});
            this.setState({sysID: 0});
            this.setState({userID: 0});
            this.setState({alertClose: null});

            this.setState({sectionTitle: '新規登録'});
            this.setState({sectionButton: '登録'});
        }
    }
 
    fileUpload(file: File){
        const formData = new FormData();
        formData.append('files',file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        
        axiosInstance.post(HOST() + '/Form/V1/File/uploadfile', formData)
        // axiosInstance.post(HOST() + '/Form/V1/File/Upload', formData)
            .then(res => {
                if(res) {
                    if(res.data.fileName) {
                        const dataa = JSON.parse(res.data.fileName);
                        if(dataa) {
                            this.setState({soundFile: dataa[this.state.soundFile]});
                        }
                    }
                    this.invokeAPI();
                }
                else {
                    toast.error("Upload sound failed.", toastOptions);
                }
            }).catch(error => {
                if (error.response && error.response.data) {
                    this.setState({error: "音声ファイルを選択してください。"});
                    toast.error("音声ファイルを選択してください。", toastOptions);
                }
            });
    }

    public handleSubmitAdd() {
        var errorMsg = "";
        if(isBlank(this.state.soundNm)) {
            errorMsg = "音声名を入力してください。";
            this.setState({error: errorMsg});
            return
        }

        if(this.state.soundID == 0) {
            if(!this.state.selectedAudioFile) {
                errorMsg = "音声が無効です。 アップロードするサウンドを選択してください。"
                this.setState({error: errorMsg});
                return
            }
        }
       
        if(this.state.selectedAudioFile) {
            this.fileUpload(this.state.selectedAudioFile);
        }
        else {
            this.invokeAPI();
        }
        
    }

    invokeAPI() {
        // { mailID:1, address: '~gmail.com', area: false, spdOvr: 1, outroute: false, accell: 1, approach:1},
        var soundNmLen = this.state.soundNm.length;
        if (soundNmLen <= 60) {
            var body = {
                soundID: this.state.soundID,
                soundNm: this.state.soundNm,
                soundFile: this.state.soundFile,
                areaFlg: this.state.areaFlg ? 1 : 0,
                mesFlg: this.state.mesFlg ? 1 : 0,
                routeFlg: this.state.routeFlg ? 1 : 0,
                proximityFlg: this.state.proximityFlg ? 1 : 0,
                sysID: this.state.sysID,
                userID: this.state.userID,
                alertClose: this.state.alertClose,
            };

            const api = (this.state.soundID > 0) ?
                axiosInstance.put(HOST() + "/Form/V1/Dat/Sound/UpdateView ", body) :
                axiosInstance.post(HOST() + "/Form/V1/Dat/Sound/AddView", body);

            api.then(res => {
                if (res.status == 200 && res.data) {
                    this.getlistData();
                    document.querySelectorAll('.AudioList').forEach((e: any) => e.click()); // reload all soundList
                    this.setState({ showAddComponent: false });
                    if (this.state.soundID > 0) {
                        toast.success("正常に音声が編集されました", toastOptions);
                    }
                    else {
                        toast.success("音声を登録しました", toastOptions);
                    }
                }
                else {
                    toast.error("Request Failed " + res.data.message, toastOptions);
                }
            })
        } else {
            toast.error("音声名は60文字以内にしてください", toastOptions);
        }
    }

    tickIcon(value:Number) {
        if(value) {
            return <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
        }
        return <span></span>
    }

    onSortList(type: Number) {
        var list = this.state.listData;
        if(type === 0) { // 音声名
            list.sort((a, b) => ((a.name > b.name) ? 1 : (a.name === b.name) ? ((a.soundFile > b.soundFile) ? 1 : -1) : -1 ));
        }
        else {  // ファイル名
            list.sort((a, b) => ((a.soundFile > b.soundFile) ? 1 : (a.soundFile === b.soundFile) ? ((a.name > b.name) ? 1 : -1) : -1 ));
        }
        this.setState({listData: list});
        this.onPageChange(this.page);
    }

    onPageChange(page: number) {
        var items = [];
        const length = this.state.listData.length;
        const start = page*this.PAGING;
        var max = this.PAGING;
        if(start + max > length) max = length - start;
        for (let i = 0; i < max; i++) {
            items[i] = this.state.listData[start + i];
        }
        this.setState({pageItems: items});
        this.page = page;
    }

    showPageNumber(totalPage:number) {
        if(totalPage < 2) return <span></span>;
        let table = []
        for (let i = 0; i < totalPage; i++) {
            let key = 'page_' + i;
            if(i == this.page) {
                table.push(<Button key={key} onClick={(e)=> this.onPageChange(i)} style={{color:'#0000ff'}}>{i + 1}</Button>)
            }
            else {
                table.push(<Button key={key} onClick={(e)=> this.onPageChange(i)} active>{i + 1}</Button>)
            }
        }
        return table;
    }

    public getArrow(colName: any) {
        if (this.state.sortKey == colName) {
            return this.state.isAsc ? '▲' : '▼';
        }
    }

    public sort(colName: any) {
        const isAsc = colName != this.state.sortKey ? true : !this.state.isAsc;
        const sortKey = colName;
        const listData = this.state.listData.sort((a: any, b: any) => {
            if (a[colName] < b[colName]) {
                return isAsc ? 1 : -1;
            } else if (a[colName] > b[colName]) {
                return !isAsc ? 1 : -1;
            } else {
                return 0;
            }
        });
        this.setState({ isAsc, sortKey, listData });
        this.onPageChange(0);
    }
    
    public render() {
        return (
            <div>
                <div className="box voice-register">
                <div className="box-title d-flex justify-content-between align-items-center">
                    <span className="page-header">音声登録</span>
                    <div className="buttonEdit">
                        <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.onCancel}>キャンセル</button>
                    </div>
                </div>
                
                <header className="th-header">
                            <span style={{position:"absolute", marginTop:"10px",left: "45%", fontSize:"15px"}}>音声一覧</span>
                            <Button onClick={this.onAddItem} className="align-right plus-button button-img">
                            <img src={icPlusGreen} width="27" height="27" className="border rounded-circle"/>
                            </Button>
                </header>    
                <table id='listItems' style={{marginBottom:"10px"}}>
                    <tbody>
                            <tr>
                                <th className="th-large clickable" onClick={() => this.sort('soundNm')}>音声名 {this.getArrow('soundNm')}</th>
                                <th className="th-large clickable" onClick={() => this.sort('soundFileDisplay')}>ファイル名 {this.getArrow('soundFileDisplay')}</th>
                                <th>エリア名</th>
                                <th>メッセージ</th>
                                <th>リモートルート</th>
                                <th>近接警報</th>
                                <th>再生</th>
                                <th>編集</th>
                            </tr>
                        {this.state.pageItems.map( (item, index) => (
                                <tr key={index}>
                                    <td style={{textAlign:"left"}}>{item.soundNm}</td>
                                    <td style={{textAlign:"left"}}>{item.soundFileDisplay}</td>
                                    <td>{this.tickIcon(item.areaFlg)}</td>
                                    <td>{this.tickIcon(item.mesFlg)}</td>                                    
                                    <td>{this.tickIcon(item.routeFlg)}</td>
                                    <td>{this.tickIcon(item.proximityFlg)}</td>
                                    <td><Button onClick={() => this.onPlayVoice(item)} className="button-img">
                                        {(this.state.audioPlay === item.soundID)
                                        ?<FontAwesomeIcon icon={faPause} color="#000000"></FontAwesomeIcon>
                                        :<FontAwesomeIcon icon={faMusic} color="#000000"></FontAwesomeIcon>
                                        }
                                        </Button>
                                    </td>
                                    <td><Button onClick={() => this.onEditItem(item, index)} className="button-img">
                                        <FontAwesomeIcon icon={faEdit} color="#000000"></FontAwesomeIcon>
                                        </Button>
                                        <Button onClick={() => this.onDeleteItem(item, index)} className="button-img">
                                        <FontAwesomeIcon icon={faTrash} color="#000000"></FontAwesomeIcon>
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div className="page-item">{this.showPageNumber(this.state.totalPage)}</div>
                <div id="addComponent" style={{ display: this.state.showAddComponent ? "block" : "none" }}>
                <form>
                    <header className="th-header bg-blue">
                        <span style={{position:"absolute", marginTop:"10px",left: "45%", fontSize:"15px"}}>{this.state.sectionTitle}</span>
                    </header>                      
                    <table onSubmit={this.handleSubmitAdd} >
                        <tbody>
                            <tr>
                                <th style={{width:"25%"}}>音声名</th>
                                <th style={{width:"25%"}}>ファイル名</th>
                                <th style={{width:"9%"}}>エリア名</th>
                                <th style={{width:"9%"}}>メッセージ</th>
                                <th style={{width:"9%"}}>リモートルート</th>
                                <th style={{width:"9%"}}>近接警報</th>
                                <th style={{width:"9%"}}>{this.state.sectionButton}</th>
                            </tr>
                            <tr>
                                        <td>
                                            <input name="name" type="text" value={this.state.soundNm} onChange={e => this.setState({ soundNm: e.target.value })} style={{ width: "100%", height: "100%" }} maxLength={60} />
                                </td>
                                <td>
                                    <input type="file" accept="audio/wav" id="file" ref={this.inputOpenFileRef} hidden={true}
                                            onChange={(e) => this.handleFileChange(e.target.files)}/>
                                    <input name="soundFile" type="text" readOnly min="0" value={this.state.soundFileDisplay} contentEditable="false" style={{width:"69%", height:"100%"}} onChange={e => null}/>
                                    <Button onClick={this.onSelectVoice} className="btn btn-primary btn-sm register" style={{width:"27%", height:"100%", padding:"0px 10px", marginLeft:"7px"}}>参照</Button>
                                </td>
                                <td><input name="area" type="checkbox" checked={this.state.areaFlg} onChange={e => this.setState({areaFlg: e.target.checked})}/></td>
                                <td><input name="message" type="checkbox" checked={this.state.mesFlg} onChange={e => this.setState({mesFlg: e.target.checked})}/></td>
                                <td><input name="remoteRoute" type="checkbox" checked={this.state.routeFlg} onChange={e => this.setState({routeFlg: e.target.checked})}/></td>
                                <td><input name="proximity" type="checkbox" checked={this.state.proximityFlg} onChange={e => this.setState({proximityFlg: e.target.checked})}/></td>
                                <td>
                                    {/* <input type="submit" value="登録" className="cancel-button register"/> */}
                                    <Button onClick={this.handleSubmitAdd} className="btn btn-primary btn-sm register">{this.state.sectionButton}</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/><span className="errClass">{this.state.error}</span>
                    </form>
                </div>
                </div>
            </div>
        )
    };
}