import React from "react";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import { toast } from "react-toastify";
import { toastOptions } from "../../common/Utils";

export default class MoveArea extends React.Component<{
    area: any,
    listId: any,
    drawedLine: any,
    onNavi: Function
}, {}> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    private update() {
        const areaId = this.props.area.areaId;
        if (this.props.drawedLine.center) {
            const body = {
                AreaId: areaId,
                Longitude: this.props.drawedLine.center.lng(),
                Latitude: this.props.drawedLine.center.lat(),
            }
            axiosInstance.put(HOST() + "/Form/V1/Dat/Area/UpdateCenter", body).then((res: any) => {
                if (res.data && res.data.data) {
                    toast.success('エリアが移動されました。', toastOptions);
                    this.goBack();
                    this.syncDataAllUser();
                }
            });
        } else {
            const polygonPath = this.props.drawedLine.getPath();
            const paths: any = [];
            for (var i = 0; i < polygonPath.length; i++) {
                var point = {
                    PosLat: polygonPath.getAt(i).lat(),
                    PosLon: polygonPath.getAt(i).lng(),
                    AreaID: areaId,
                    PosID: i
                };
                paths.push(point);
            }

            const pathData = {
                AreaID: areaId,
                Path: paths
            };
            axiosInstance.put(HOST() + "/Form/V1/Dat/AreaDetail/Update", pathData).then(res => {
               if (res.data && res.data.data) {
                    toast.success('エリアが移動されました。', toastOptions);
                    this.goBack();
                    this.syncDataAllUser();
                } 
            });
        }
    }

    componentDidMount() {
    }

    public syncDataAllUser() {
        axiosInstance.get(HOST() + "/Form/V1/Dat/Area/SyncDataAllUser");
    }

    private goBack(redraw = false) {
        if (redraw) {
            this.props.onNavi('/', { target: 'areaListRedraw', area: this.props.area, listId: this.props.listId });
            return;
        }

        this.props.onNavi('/');
    }

    public render() {
        return (
            <div>
                エリア移動してもよろしいですか？
                <div style={{textAlign: 'center'}}>
                    <button
                        className="btn btn-primary btn-md mar-5 btn-135"
                        onClick={() => this.goBack(true)}
                    >
                        キャンセル
                    </button>

                    <button
                        className="btn btn-primary btn-md mar-5 btn-135"
                        onClick={() => this.update()}
                    >
                        ＯＫ
                    </button>
                </div>
            </div>
        );
    }
}
