import axios from 'axios';
import promise from 'promise';
import Cookies from 'js-cookie';
import { useState, useCallback, useMemo, useEffect } from 'react';

// Add a request interceptor 
var axiosInstance = axios.create();
axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    // TODO
    // If the header does not contain the token and the url not public, redirect to login  
    var accessToken = Cookies.get('jwt');

    //if token is found add it to the header
    if (accessToken) {
        if (config.method !== 'OPTIONS') {
            config.headers.authorization = accessToken;
        }
    } else if (config.url && config.url.includes("Form/V1/Authenticate")) {
    } else if (config.url && config.url.includes("Form/V1/Mst/Version")) {
    } else {
        window.location.href = '/login';

    }
    return config;
}, function (error: any) {
    // Do something with request error 
    return promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && 401 === error.response.status) {
        window.location.replace('/login');
    }

    try {
        if (error.response && !error.response.data.data) error.response.data.data = [];
    } catch (e) {}
    return error.response;
});

export const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0);
  const inc = useCallback(() => setCounter(counter => counter + 1), [setCounter]); // add to counter
  const dec = useCallback(() => setCounter(counter => counter - 1), [setCounter]); // remove from counter
  
  const interceptors = useMemo(() => ({
    request: (config: any) => (inc(), config),
    response: (response: any) => (dec(), response),
    error: (error: any) => (dec(), Promise.reject(error)),
  }), [inc, dec]); // create the interceptors
  
  useEffect(() => {
    // add request interceptors
    const reqInterceptor = axiosInstance.interceptors.request.use(interceptors.request, interceptors.error);
    // add response interceptors
    const resInterceptor =  axiosInstance.interceptors.response.use(interceptors.response, interceptors.error);
    return () => {
      // remove all intercepts when done
      axiosInstance.interceptors.request.eject(reqInterceptor);
      axiosInstance.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);
  
  return [counter > 0];
};

export default axiosInstance;