import React from 'react'
import './BaseSelectList.css'
import axiosInstance from '../interceptor'
import { HOST } from '../constant'

export default class BaseSelectList extends React.Component<{
    fetchUrl: string,
    onChange: Function,
    value: any, 
    className?: string,
    dkey: string,
    dvalue: string,
    nullLabel?: any,
    optKey?: any
}, {
    listData: any[],
    value: any,
}> {
    constructor(props: any) {
        super(props)
        this.state = {
            listData: [],
            value: this.props.value || '',
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData(url: any = this.props.fetchUrl) {
        axiosInstance
            .get(HOST() + url)
            .then(response => {
                const listData = response.data.data || [];
                if (this.props.nullLabel !== null) {
                    const nullRow: any = {};
                    nullRow[this.props.dkey] = '';
                    nullRow[this.props.dvalue] = this.props.nullLabel;
                    listData.unshift(nullRow);
                }
                this.setState({ listData }, () => {
                    if (!this.state.value) {
                        const value = listData[0] ? listData[0][this.props.dkey] : null;
                        this.updateValue({ target: { value } });
                    }
                })
            })
	}

    componentWillReceiveProps(nextProps: any) {
        if (this.state.value != nextProps.value) {
            this.setState({ value: nextProps.value || '' });
        } 
        if (nextProps.fetchUrl != this.props.fetchUrl) {
            this.getData(nextProps.fetchUrl);
		}
    }

    updateValue(e: any) {
        this.setState({
            value: e.target.value,
        });
        let opt = this.state.listData.filter(f => f[this.props.dkey] == e.target.value)[0] || {};
        let label = opt ? opt[this.props.dvalue] : '';
        let optValue = opt[this.props.optKey || ""];
        if (e.target.value == '' && this.props.optKey) {
            optValue = this.state.listData.map(f => f[this.props.optKey || ""])
		}
        this.props.onChange && this.props.onChange(e, label, optValue);
    }

    render() {
        return (
            <select className={"form-control form-control-sm " + this.props.className}
                onChange={e => this.updateValue(e)}
                value={this.state.value}>
                {
                    this.state.listData.map((data: any, index: any) => (
                        <option key={'s' + index} value={data[this.props.dkey]}>{data[this.props.dvalue]}</option>
                    ))
                }
            </select>
        );
    }
}