import * as React from 'react';
import './MessageHistory.css';
import axiosInstance from '../../common/interceptor';
import { HOST, UNSET_OPTION } from '../../common/constant';
import MessageHistoryList from './MessageHistoryList';
import DateTimeFromTo from '../../common/DateTimeFromTo/DateTimeFromTo';
import BaseSelectList from '../../common/BaseSelectList/BaseSelectList';
import Cookies from 'js-cookie';
import { reloadTerminal } from '../../common/Utils';

export default class MessageHistory extends React.Component<{
	onNavi: Function,
	isRouteChange: boolean
}, {
	Terminal: any,
	dateFrom: any,
	timeFrom: any,
	dateTo: any,
	timeTo: any,
	MessageHistoryList: any[],
	status: any,
	Path: any[]
}> {
	constructor(props: any) {
		super(props);
		this.state = {
			Terminal: UNSET_OPTION,
			dateFrom: undefined,
			dateTo: undefined,
			timeFrom: '',
			timeTo: '',
			MessageHistoryList: [],
			Path: [],
			status: -1
		};
	}

	componentDidMount() {
		const DateTimeFromTo: any = this.refs.DateTimeFromTo;
		DateTimeFromTo.initDefaultTime();
	}

	search() {
		const DateTimeFromTo : any = this.refs.DateTimeFromTo;
		const { fromDate, toDate } = DateTimeFromTo.getDateTime();

		const body: any = {
			dateFrom: fromDate,
			dateTo: toDate,
			mesStatus: Number(this.state.status),
			isRouteChange: this.props.isRouteChange
		};

		if (this.state.Terminal) {
			body.CliId = Number(this.state.Terminal)
        }

		axiosInstance
			.post(HOST() + `/Form/V1/Dat/Message/HistorySent/List`, body)
			.then(response => {
				this.setState({ MessageHistoryList: response.data.data });
			});
	}
	user: any = JSON.parse(Cookies.get('user') || "{}");

	public render() {
		return (
			<div className="operation-history-container">
				<div className="form-container">
					<div className="form-row d-flex justify-content-xl-between mb-2">
						<div className="inline">
							<div className="container">
								<div className="form-inline">
									<label className="col-form-label form-control-label">端末</label>
									<BaseSelectList
										className="inline"
										dkey="cliID"
										dvalue="cliNm"
										nullLabel='すべて端末'
										fetchUrl={`/Form/V1/Mst/Terminal/GetList?sysID=${this.user.siteId}`}
										onChange={(e: any) => this.setState({ Terminal: e.target.value })}
										value={this.state.Terminal}
									/>
								</div>
							</div>
						</div>

						<div className="inline">
							<div className="container">
								<div className="form-inline">
									<label className="col-form-label form-control-label">ステータス</label>
									<select className="form-control form-control-sm inline"
										onChange={e => this.setState({ status: e.target.value })}
										value={this.state.status}
									>
										<option value="-1"></option>
										<option value="1">未確認</option>
										<option value="2">OK</option>
									</select>
								</div>
							</div>
						</div>

						<div className="inline">
							<div className="container">
								<div className="form-inline">
									<label className="col-form-label form-control-label">送信日</label>
									<DateTimeFromTo
										ref="DateTimeFromTo"
										className="d-flex justify-content-between align-items-center"
										dateFrom={this.state.dateFrom}
										timeFrom={this.state.timeFrom}
										dateTo={this.state.dateTo}
										timeTo={this.state.timeTo}
										onChange={(e: any) => this.setState(e)}
									/>
								</div>
							</div>
						</div>

						<button type="button" className="btn btn-primary btn-sm" onClick={() => this.search()}>検索</button>
					</div>
				</div>
				<MessageHistoryList
					MessageHistoryList={this.state.MessageHistoryList}
					isRouteChange={this.props.isRouteChange}
				/>

			</div>
		);
	}
}
