import React from 'react'
import './SearchPlace.css'
import redCircle from '../../assets/img/red_circle.png';
import { toast } from 'react-toastify';
import { countAPIGoogle, toastOptions } from '../Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { API_NAME } from '../constant';

export default class SearchPlace extends React.Component<{
    map: any,
    display: any,
}, {
}> {
    constructor(props: any) {
        super(props)
        this.state = {}
    }

    autocomplete: any = null;
    infowindow: any = null;
    marker: any = null;

    init() {
        if (this.autocomplete != null) {
            return;
        }
        const searchTextField: any = document.getElementById('searchTextField');
        this.autocomplete = new window.google.maps.places.Autocomplete(searchTextField);
        this.autocomplete.bindTo("bounds", this.props.map);
        this.autocomplete.setFields([
            "address_components",
            "geometry",
            "icon",
            "name",
        ]);

        const infowindow = new window.google.maps.InfoWindow();
        const infowindowContent: any = document.createElement( 'div' );
        infowindowContent.innerHTML =
            `<div id="infowindow-content">
                <img src="" width="16" height="16" id="place-icon" />
                <span id="place-name" className="title"></span><br />
                <span id="place-address"></span>
            </div>`;
        infowindow.setContent(infowindowContent);

        this.marker = new window.google.maps.Marker({
            position: { lat: 0, lng: 0 },
            map: this.props.map,
            icon: redCircle,
            clickable: false,
            visible: false,
        });
        const _this = this;
        this.autocomplete.addListener("place_changed", () => {
            countAPIGoogle(API_NAME.PLACE);
            _this.marker.setVisible(false);
            const place = _this.autocomplete.getPlace();

            if (!place.geometry) {
                toast.error("該当する結果がありません。", toastOptions);
                return;
            }

            if (place.geometry.viewport) {
                _this.props.map.fitBounds(place.geometry.viewport);
            } else {
                _this.props.map.setCenter(place.geometry.location);
                _this.props.map.setZoom(17);
            }
            _this.marker.setPosition(place.geometry.location);
            _this.marker.setVisible(true);
            _this.marker.setMap(_this.props.map);
            let address = "";

            if (place.address_components) {
                const addr = place.address_components;
                address = [
                    (addr[0] && addr[0].short_name) || "",
                    (addr[1] && addr[1].short_name) || "",
                    (addr[2] && addr[2].short_name) || "",
                ].join(" ");
            }
            infowindowContent.children[0].children["place-icon"].src = place.icon;
            infowindowContent.children[0].children["place-name"].textContent = place.name;
            infowindowContent.children[0].children["place-address"].textContent = address;
            infowindow.open(_this.props.map, _this.marker);
        });
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.display) {
            this.init();
        }
    }

    clearAllMarker() {
        this.marker && this.marker.setMap(null);
        this.infowindow && this.infowindow.close();
        const searchTextField: any = document.getElementById('searchTextField');
        searchTextField.value = '';
    }

    render() {
        return (
            <div style={{ display: this.props.display ? 'inline-block' : 'none' }}>
                <div className="btn-group">
                    <input id="searchTextField" className="form-control form-control-sm no-border-right" />
                    <div className="form-control form-control-sm w-30px no-border-left"
                        onClick={() => this.clearAllMarker()}>
                        <span id="searchclear">
                            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}