import * as React from 'react';
import './AudioCall.css';
import axiosInstance from '../../common/interceptor';
import { HOST } from '../../common/constant';
import MultiCheckBox from '../../common/MultiCheckBox/MultiCheckBox';
import { faMicrophoneAlt, faMicrophone, faMicrophoneSlash, faMusic, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactMic } from 'react-mic';
import { toast } from 'react-toastify';
import { reloadTerminal, toastOptions } from '../../common/Utils';
import { Button } from 'reactstrap';
import { error } from 'console';
import { type } from 'os';
import Cookies from 'js-cookie';

export default class AudioCall extends React.Component<{
	onNavi: Function
}, {
	time: any,
    timerSeconds: number,
	Terminal: any[],
	isRecording: boolean,
	recordedBlob: any,
	audioFile: any,
	blobURL: any,
	allowRecord: boolean,
	recordedBlobData: any
}> {
	audio: any;
	timer: any;
	constructor(props: any) {
		super(props);
		this.state = {
			time: {},
            timerSeconds: 2,
			Terminal: [],
			isRecording: false,
			recordedBlob: null,
			audioFile: null,
			blobURL: '',
			allowRecord: true,
			recordedBlobData: null
		};
		this.onCallData = this.onCallData.bind(this);
		this.onStop = this.onStop.bind(this);

		this.startTimer = this.startTimer.bind(this)
        this.countDown = this.countDown.bind(this)
        this.onCheckAudioError = this.onCheckAudioError.bind(this)
	}

	startTimer() {
		

    }
    
    secondsToTime(secs: number){
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {"h": hours, "m": minutes, "s": seconds };
        return obj;
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let secondValue = this.state.timerSeconds - 1;
        this.setState({
            time: this.secondsToTime(secondValue),
            timerSeconds: secondValue,
        });
    
        // Check if we're at zero.
        if (secondValue == 0) { 
            this.onCheckAudioError();
        }
    }

	onCheckAudioError() {
		clearInterval(this.timer);
        if(this.state.recordedBlobData) {
			this.props.onNavi('/', { target: 'audio', action: 'startCall' });
		}
		else{
			toast.error("オーディオが接続、許可されているかどうかを確認してください。", toastOptions);
			this.setState({isRecording: false });
			this.setState({allowRecord: true});
		}
	}

	
	startCall() {
		if(this.state.Terminal.length == 0) {
			toast.info("端末を選択してください。", toastOptions);
			return;
		}
		this.setState({time: {}, timerSeconds: 3,});
		if(!this.state.allowRecord) {
			if(this.state.timerSeconds > 0) return;
			else {
				this.stopCall();
				return;
			}
		}

		if(this.timer) clearInterval(this.timer);
		
        this.timer = setInterval(this.countDown, 1000); // 30s
		
		let status = this.state.isRecording;
		this.setState({isRecording: !status });
		this.setState({allowRecord: false});

		this.setState({ recordedBlob: null });
		this.setState({ recordedBlobData: null });
		
		// this.onPlayVoice();
	}
	
	public stopCall() {
		this.setState({ isRecording: false });
		
	}

	onCallData(recordedBlob: any) {
		// console.log('chunk of real-time data is: ', recordedBlob);
		this.setState({recordedBlobData: recordedBlob})
	}
	 
	onStop(recordedBlob: any) {
		// console.log('recordedBlob is: ', recordedBlob);
		this.setState({recordedBlob: recordedBlob});
		// this.setState({blobURL: recordedBlob.blobURL});
		this.audioUpload();
	}
	
	
	public onPlayVoice () {
		if(this.audio) {
            this.audio.pause();
        }
        if(this.state.blobURL.length > 0) {
            this.setState({blobURL: ''});
        }
        else {
            this.setState({blobURL: this.state.recordedBlob.blobURL});
            this.audio = new Audio(this.state.blobURL);
            this.audio.play();
        }
	}

	sendMessageDone(success: boolean) {
		this.props.onNavi('/', { target: 'audio', action: success? 'msgSentSuccess': 'completed'});
		if(!success) this.onAudioMessageSent();
	}

	public onAudioMessageSent() {
		this.setState({allowRecord: true});
	}

	sendMessge() {
		// {"ListCliID": [1,2,3],"Title": "test message","RecType": "Audio/Image/Document", "PathFile":"path to file"}
		var body = {
			ListCliID: this.state.Terminal,
			Title: '通話モード',
			RecType: 'Audio',
			PathFile: this.state.audioFile
		};
		
		axiosInstance
			.post(HOST() + "/Form/V1/Dat/Audio/AddRecord", body)
			.then((res: any) => {
				if(res.status == 200 && res.data) {
					toast.success("通話モードを終了する.", toastOptions);
					this.sendMessageDone(true);
				}
				else {
					toast.error("Send Audio Message Failed " + res.data.message, toastOptions);
					this.sendMessageDone(false);
				}
			}).catch(error => {
				if (error.response && error.response.data) {
					toast.error("Send Audio Message Failed " + error.response.message, toastOptions);
					this.sendMessageDone(false);
				}
				else {
					toast.error("Send Audio Message Failed " + error, toastOptions);
					this.sendMessageDone(false);
				}
			});
	}


	
    public audioUpload(){
		let reader = new FileReader();
		reader.onload = (event: any) => {
			this.doUploadFileAudio(event.target.result);
			
		};
		reader.readAsDataURL(this.state.recordedBlob.blob);
	}

	private doUploadFileAudio(audioFile: any) {
		const formData = new FormData();
		// formData.append('files',file)

		// blob: Blob {size: 101683, type: "audio/wav"}
		// blobURL: "blob:https://../da82ae4e-d49c-4847-9fbb-76d79209c0ad"
		// options: {audioBitsPerSecond: 128000, mimeType: "audio/wav"}
		// startTime: 1605115584855	stopTime: 1605115602586
		var recorder = this.state.recordedBlob;
		var length = recorder.blobURL.length;
		let filename = recorder.blobURL.substring(length - 36, length) + '.wav';
		this.setState({audioFile: filename});
		var file = new File([recorder.blob], filename);
		formData.append('files', file);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
		
		// this.setState({audioFile: this.});
        axiosInstance.post(HOST() + '/Form/V1/File/uploadfile', formData)
        // axiosInstance.post(HOST() + '/Form/V1/File/Upload', formData)
            .then(res => {
                if(res) {
                    if(res.data.fileName) {
                        const dataa = JSON.parse(res.data.fileName);
                        if(dataa) {
                            this.setState({audioFile: dataa[this.state.audioFile]});
                        }
                    }
                    this.sendMessge();
                }
                else {
                    toast.error("Upload sound failed.", toastOptions);
                }
            }).catch(error => {
                if (error.response && error.response.data) {
                    toast.error("Request Failed: " + error.response.message, toastOptions);
                }
            });
    }
	user: any = JSON.parse(Cookies.get('user') || "{}");

	public render() {
		return (
			<div className="audio-call-container">
				<div className="form-container">
					<div className="form-row row">
						<div className="col-6">
							<label className="col-form-label form-control-label">宛先</label>
							<span className="TerminalList" onClick={() => reloadTerminal(this)}></span>
							<div className="container-up">
								<MultiCheckBox
									ref='TerminalList'
									ckey='cliID'
									cvalue='cliNm'
									cname='Terminal'
									urlData={`/Form/V1/Mst/Terminal/GetList?sysID=${this.user.siteId}`}
									value={this.state.Terminal}
									onChange={(e: any) => this.setState({ Terminal: e.data })}
									isSingleCheck={false}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="sound-wave-b">
				<ReactMic
						record={this.state.isRecording}
						className="sound-wave"
						visualSetting="sinewave"
						onStop={this.onStop}
						onData={this.onCallData}
						strokeColor="#000000"
						backgroundColor="#ffffff" 
						mimeType="audio/wav" />
				</div>
				{/* <div className="audioUpload" style={{display: (this.state.recordedBlob != null)? "block": "none"}}>
					<Button onClick={() => this.audioUpload()} className="bg-blue">Send Void MES</Button>
				</div>	 */}
				{/* <div className="audioCall" style={{display: (this.state.recordedBlob != null)? "block": "none"}}>
						<Button onClick={() => this.onPlayVoice()} className="button-img">
                                        {(this.state.blobURL.length > 0)
                                        ?<FontAwesomeIcon icon={faPause} ></FontAwesomeIcon>
                                        :<FontAwesomeIcon icon={faMusic} ></FontAwesomeIcon>
                                        }
                        </Button>			
				</div>			 */}
				<div className="buttonCall" onClick={() => this.startCall()} /*style={{display: this.state.allowRecord ? "block": "none"}}*/>
					<FontAwesomeIcon icon={faMicrophone} size="2x" style={{display: !this.state.isRecording? "block": "none"}}></FontAwesomeIcon>
					<FontAwesomeIcon icon={faMicrophoneAlt} size="2x" style={{display: this.state.isRecording? "block": "none"}}></FontAwesomeIcon> 
				</div>
			</div>
		);
	}
}
