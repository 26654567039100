import React from 'react'
import './PlayAudio.css'
import { HOST } from '../constant'
import Cookies from 'js-cookie'
import axiosInstance from '../../common/interceptor';
import { toast } from 'react-toastify';
import { toastOptions } from '../Utils';

export default class PlayAudio extends React.Component<{
    url: string
}, {
    play: boolean
}> {
    constructor(props: any) {
        super(props)
        this.state = {
            play: false
        }
    }
    accessToken = Cookies.getJSON('user').token;
    audioUrl = (url : string) => HOST() + '/Form/V1/File/download?filePath=' + url + '&access_token=' + this.accessToken;
    audio = new Audio(this.audioUrl(this.props.url));

    componentDidMount() {
        this.audio.addEventListener('ended', () => this.setState({ play: false }));
        this.audio.onerror = this.audioError;
    }

    audioError = (e: any) => {
        axiosInstance.get(this.audioUrl(this.props.url))
            .then((e: any) => {
                this.setState({ play: false });
                if (e.status == 404) {
                    return toast.error("音声ファイルが存在されていません。", toastOptions);
                }
                return toast.error("エラーが発生されているため、再生できません。", toastOptions);
            })
            .catch((e: any) => {
                this.setState({ play: false });
                return toast.error("エラーが発生されているため、再生できません。", toastOptions);
            })
    }

    componentWillUnmount() {
        this.audio.removeEventListener('ended', () => this.setState({ play: false }));
    }

    togglePlay = () => {
        this.setState({ play: !this.state.play }, () => {
            this.state.play ? this.audio.play() : this.audio.pause();
        });
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.url == this.props.url) return;
        this.audio = new Audio(this.audioUrl(nextProps.url));
        this.audio.onerror = this.audioError;
        this.setState({ play: false });
    }

    render() {
        return (
            <button
                disabled={!this.props.url}
                className={this.state.play ? "btn btn-secondary mar-5 w-20" : "btn btn-secondary mar-5 w-15"}
                style={{ fontSize: '13.5px' }}
                onClick={this.togglePlay}>
                {this.state.play ? '一時停止' : '再生'}
            </button>
        );
    }
}