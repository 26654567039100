import React, {useState , forwardRef} from "react";
import "./Setting.css"
import "./EmailManagement.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import Cookies from 'js-cookie';
import {useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import icPlusGreen from "../../assets/img/ic_plus_green.png";
import icNotes from "../../assets/img/ic_notes.png";
import { Button } from "reactstrap";
import { isBlank, toastOptions } from "../../common/Utils";
import { toast } from "react-toastify";


export default class EmailManagement extends React.Component<{
    onNavi: Function
}, {
    listData: any[],
    showAddComponent: boolean,
    sectionTitle: any,
    sectionButton: any,
    mailID: any,
    mailAddress: any,
    isArea: boolean,
    isSpdOvr: boolean,
    isOutRoute: boolean,
    isAccell: boolean,
    isApproach: boolean,
    isUnitCount: boolean,
    error: any
}> {
    // history = useHistory();
    addFormComponent: any;
    constructor(props: any) {
        super(props)
        this.state = {
            listData: [],
            showAddComponent: false,
            sectionTitle: '新規登録',
            sectionButton: '登録',
            mailID: 0,
            mailAddress: '',
            isArea: false,
            isSpdOvr: false,
            isOutRoute: false,
            isAccell: false,
            isApproach: false,
            isUnitCount: false,
            error: ''
        }
        
        this.addFormComponent = React.createRef();
        this.getlistData = this.getlistData.bind(this);
        this.onEditDeleteItem = this.onEditDeleteItem.bind(this);
        this.onAddItem = this.onAddItem.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.handleSubmitAdd = this.handleSubmitAdd.bind(this);
    };

    componentDidMount() {
        this.getlistData();
    }
  
    
    private getlistData() {
        axiosInstance
            .get(HOST() + "/Form/V1/Dat/Mail/GetAllMail")
            .then(response => {
                const mails = response.data.data;
                this.setState({ listData: mails });
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    toast.error(error.response.data.message, toastOptions);
                }
            });
    }

    public onCancel(path: any) {
        if(this.state.showAddComponent) {
            this.setState({showAddComponent: false});
        }
        else {
            this.props.onNavi('/');
        }
    }

    public onEditDeleteItem(object:any) {
        this.setItemData(object);
        this.setState({ showAddComponent: true });
    }

    public onDeleteItem(id: any) {
        axiosInstance.delete(HOST() + "/Form/V1/Dat/Mail/DeleteMail?mailID=" + id).then(() => {
            this.setState({ showAddComponent: false });
            this.getlistData();
            toast.success('メールアドレスを削除しました。', toastOptions);
        });
    }

    public onAddItem() {
        this.setItemData(null);
        // this.addMailComponent.setMailData(this.state.aMail);
        this.setState({showAddComponent: true});
    }
    
    setItemData(item: any) {
        this.setState({error: ''});
        if(item && item.mailID > 0) {
            this.setState({
                mailID: item.mailID,
                mailAddress: item.mail,
                isArea: item.sendArea,
                isSpdOvr: item.sendSpdOvr,
                isOutRoute: item.sendOutRoute,
                isAccell: item.sendAccell,
                isUnitCount: item.unitCount,
                isApproach: item.sendApproach,
                sectionTitle: '編集',
                sectionButton: '編集',
            });
        }
        else {
            this.setState({mailID: 0,
                mailAddress: '',
                isArea: false,
                isSpdOvr: false,
                isOutRoute: false,
                isAccell: false,
                isUnitCount: false ,
                isApproach: false,
                sectionTitle: '新規登録',
                sectionButton: '登録'
            });
        }
    }

    validateForm = () => {
        var str = this.state.mailAddress;
        return !isBlank(str) && /^[A-Za-z0-9_\.]{5,32}@[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,4}){1,2}$/.test(str);
    
    }

    public validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.state.mailAddress);
    }
 
    public handleSubmitAdd() {
        var isValid = this.state.mailAddress && this.state.mailAddress.length > 0
        if(!isValid) {
            this.setState({error: "メールアドレスを入力してください。"});
            return;
        }
        if(!this.validateEmail()) {
            this.setState({error: "メールアドレス形式が正しくありません。"});
            return;
        }

        var user: any = JSON.parse(Cookies.get('user') || "{}");

        var body = {
            mailID: this.state.mailID,
            mail: this.state.mailAddress,
            sendArea: this.state.isArea? 1: 0, 
            sendSpdOvr: this.state.isSpdOvr? 1: 0, 
            sendOutRoute: this.state.isOutRoute? 1: 0, 
            sendAccell: this.state.isAccell? 1: 0, 
            sendApproach: this.state.isApproach ? 1 : 0,
            unitCount: this.state.isUnitCount ? 1 : 0,
            siteName: user.siteName
        };

        const api = (this.state.mailID > 0) ?
        axiosInstance.put(HOST() + "/Form/V1/Dat/Mail/UpdateMail ", body) :
        axiosInstance.post(HOST() + "/Form/V1/Dat/Mail/AddMail", body);

        api.then(res => {
            if(res.status == 200 && res.data) {
                this.getlistData();
                toast.success((this.state.mailID > 0)? "メールアドレスを編集しました。" : "メールアドレスを登録しました。", toastOptions);
                this.setState({ showAddComponent: false });

                axiosInstance.post(HOST() + "/Form/V1/Dat/Email/Sender", body);

            }
            else {
                this.setState({ error: "同じメールアドレスは登録できません。"});
            }
            }).catch(e => {
                this.setState({error: "Request Failed"});
            });


        // var div = document.getElementById('addComponent')
        // if(div) div.hidden = true;
        
    }

    tickIcon(value:Number) {
        if(value === 1) {
            return <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
        }
        return <span></span>
    }

    public renderTableData() {
        
        return this.state.listData.map((mail, index) => {
            const { id, address, area, spdOvr, outRoute, accell, approach, unitCount} = mail //destructuring
            return (
                <tr key={id}>
                    <td>{address}</td>
                    <td>{this.tickIcon(area)}</td>
                    <td>{this.tickIcon(spdOvr)}</td>
                    <td>{this.tickIcon(accell)}</td>
                    <td>{this.tickIcon(approach)}</td>
                    <td>{this.tickIcon(unitCount)}</td>
                    <td>{this.tickIcon(outRoute)}</td>
                    <td><a onClick={() => this.onEditDeleteItem({mail})}>
                    <img src={icNotes} width="35" height="35"/>
                        </a>
                    </td>
                </tr>
            )
        })
    }
    public render() {
        return (
            <div>
                <div className="box email-manage">
                <div className="box-title d-flex justify-content-between align-items-center">
                    <span className="page-header">メールアドレス登録</span>
                    <div className="buttonEdit">
                        <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.onCancel} >キャンセル</button>
                    </div>
                </div>
                
                <header className="th-header">
                            <span style={{position:"absolute", marginTop:"10px",left: "45%", fontSize:"15px"}}>メールアドレス一覧</span>
                            <Button onClick={this.onAddItem} className="align-right plus-button button-img">
                                    <img src={icPlusGreen} width="27" height="27" className="border rounded-circle"/>
                                    </Button>
                </header>    
                <table id='emails' style={{marginBottom:"10px"}}>
                    <tbody>
                        <tr>
                            <th>メールアドレス</th>
                            <th>エリア</th>
                            <th>速度超過</th>
                            <th>ルート逸脱</th>
                            <th>加速度警告 </th>
                            <th>近接警報</th>
                            <th>台数カウント</th>
                            <th>編集</th>
                        </tr>
                        {this.state.listData.map((item, index) => (
                                <tr key={`mailSetting${item.id}&${index}`}>
                                    <td style={{textAlign: 'left'}}>{item.mail}</td>
                                    <td>{this.tickIcon(item.sendArea)}</td>
                                    <td>{this.tickIcon(item.sendSpdOvr)}</td>
                                    <td>{this.tickIcon(item.sendOutRoute)}</td>
                                    <td>{this.tickIcon(item.sendAccell)}</td>
                                    <td>{this.tickIcon(item.sendApproach)}</td>
                                    <td>{this.tickIcon(item.unitCount)}</td>
                                    
                                <td>
                                    <Button onClick={() => this.onEditDeleteItem(item)} className="button-img btn-50">
                                    <FontAwesomeIcon icon={faEdit} color="#000000"></FontAwesomeIcon></Button>
                                    <Button onClick={() => this.onDeleteItem(item.mailID)} className="button-img">
                                        <FontAwesomeIcon icon={faTrash} color="#000000"></FontAwesomeIcon>
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div id="addComponent" style={{ display: this.state.showAddComponent ? "block" : "none" }}>
                <form>
                    <header className="th-header bg-blue">
                        <span style={{position:"absolute", marginTop:"10px",left: "45%", fontSize:"15px"}}>{this.state.sectionTitle}</span>
                    </header>                      
                    <table onSubmit={this.handleSubmitAdd} >
                        <tbody>
                            <tr>
                                <th>メールアドレス</th>
                                <th>エリア</th>
                                <th>速度超過</th>
                                <th>ルート逸脱</th>
                                <th>加速度警告</th>
                                <th>近接警報</th>
                                <th>台数カウント</th>
                                <th style={{width:"10%"}}>{this.state.sectionButton}</th>
                            </tr>
                            <tr>
                                <td>
                                    <input name="mailAddress" type="text" value={this.state.mailAddress} onChange={e => this.setState({ mailAddress: e.target.value })} style={{ width: "100%" }} maxLength={ 100 } />
                                    <br/><span className="errClass">{this.state.error}</span>
                                </td>
                                <td><input name="isArea" type="checkbox" checked={this.state.isArea} onChange={e => this.setState({isArea: e.target.checked})}/></td>
                                <td><input name="isSpdOvr" type="checkbox" checked={this.state.isSpdOvr} onChange={e => this.setState({ isSpdOvr: e.target.checked })} /></td>
                                <td><input name="isOutRoute" type="checkbox" checked={this.state.isOutRoute} onChange={e => this.setState({ isOutRoute: e.target.checked })} /></td>
                                <td><input name="isAccell" type="checkbox" checked={this.state.isAccell} onChange={e => this.setState({isAccell: e.target.checked})}/></td>
                                <td><input name="isApproach" type="checkbox" checked={this.state.isApproach} onChange={e => this.setState({ isApproach: e.target.checked })} /></td>
                                <td><input name="unitCount" type="checkbox" checked={this.state.isUnitCount} onChange={e => this.setState({ isUnitCount: e.target.checked })} /></td>
                                <td>
                                    {/* <input type="submit" value="登録" className="cancel-button register"/> */}
                                            <Button onClick={this.handleSubmitAdd} className="btn btn-primary btn-sm register">{this.state.sectionButton}</Button>
                                           
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </form>
                </div>
                </div>
            </div>
        )
    };
}