import React from 'react';
import { isMobile, showContextMenu } from './Utils';

export function Holdable(props: any) {
    const [timer, setTimer] = React.useState(0);
    const [x, setX] = React.useState(0);
    const [y, setY] = React.useState(0);

    function onPointerDown(evt: any) {
        const event = { ...evt };
        const timeoutId: any = window.setTimeout(timesup.bind(null, event), 500);
        setTimer(timeoutId);
        setX(evt.clientX);
        setY(evt.clientY);
    }

    function onPointerUp(evt: any) {
        if (props.onClick) {
            props.onClick(evt);
        }

        if (timer) {
            window.clearTimeout(timer);
            setTimer(0);
        }
    }

    function timesup(evt: any) {
        setTimer(0);
        if ((0 == x && 0 == y) || (Math.abs(evt.clientX - x) < 100 && Math.abs(evt.clientY - y) < 100)) {
            props.onHold(evt);
        }
    }

    return (
        <div onPointerDown={onPointerDown} onPointerUp={onPointerUp} className="h-100">
          {props.children}
        </div>
      );
}

export default function HomeLongPress(props: any) {
    const onHold = (ev: any) => {
        const ev_t: any = {
			pixel: {
				x: ev.clientX,
				y: ev.clientY - 48
			},
			latLng: {
				lat: () => props.map.center.lat(),
				lng: () => props.map.center.lng()
            }
		};
		const this_t: any = {
			state: {
				map: props.map
            }
        }
        
        if (isMobile()) {
            showContextMenu(ev_t, this_t);
        }
    };

    return (
        <Holdable onHold={onHold}>
          {props.children}
        </Holdable>
        );
};