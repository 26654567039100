import { faCheck, faEdit, faEnvelope, faExternalLinkSquareAlt, faMailBulk, faSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';
import * as React from 'react';
import { Button } from 'reactstrap';
import { HOST } from '../../common/constant';
import axiosInstance from '../../common/interceptor';
import './TopTab.css';
import icAlarm from "../../assets/img/ic_alarm.png";

export default class TopTab extends React.Component<{
	bodyContainer: any,
	map: any,
	onNavi: Function
}, {
    listData: any[],
    isOpen: boolean,
    sortKey: string,
    isAsc: boolean
}> {
    constructor(props: any) {
        super(props);
        this.state = 
        {
            listData: [],
            isOpen: false,
            sortKey: '',
            isAsc: false
        };

        this.getlistData = this.getlistData.bind(this);
    }

    componentDidMount() {
        this.setOpen(this.state.isOpen);
    }
    bodyContainer = null;
    componentWillReceiveProps(nextProp: any) {
        if (this.bodyContainer) return;
        const element: any = document.querySelector('#topTab');
       /* const thisHeight = this.state.isOpen ? element.dataset.height : "0px";*/
        const thisHeight = this.state.isOpen ? element.offsetHeight : "0px";
        const otherElement: any = document.querySelector('#bottomTab');
        const thatHeight = `${otherElement.offsetHeight - 5}px`;
        this.setHeight(thisHeight);
        if (nextProp.bodyContainer) {
            this.bodyContainer = nextProp.bodyContainer;
            nextProp.bodyContainer.style.height = `calc(100vh - 51px - ${thisHeight} - ${thatHeight})`;
        }
    }

    public setOpen(isOpen: boolean) {
        this.setState({ isOpen }, () => {
            this.updateTopBarHeight(this.state.listData.length);
            this.getlistData();
        });
    }

    public updateTopBarHeight(itemLength: number, height = '') {
        const element: any = document.querySelector('#topTab');
        // const thisHeight = isOpen ? element.dataset.height : "0px";
        var tableHeight = `${(itemLength) * 50 + 100}px`;
        if(itemLength == 0) tableHeight = element.dataset.height;
        const thisHeight = this.state.isOpen ? (height || tableHeight) : "0px";
        const otherElement: any = document.querySelector('#bottomTab');
        const thatHeight = `${otherElement.offsetHeight - 5}px`; // -5 due to padding top of bottom tab
        var _height = thisHeight;
        if (parseFloat(thisHeight) > 500) {
            _height = "500px";
        }
        this.setHeight(_height);
        if (this.props.bodyContainer) {
            this.props.bodyContainer.style.height = `calc(100vh - 51px - ${_height} - ${thatHeight})`;
        }
    }    

    private setHeight(height: any) {
        const element: any = this.refs.container;
        if (element) {
            element.style.height = height;
        }
    }

    private getlistData() {
        const userData = Cookies.get('user');
        var userId: any;
        if(userData) {
            const user = JSON.parse(userData ? userData : '{}');
            userId = user.id;
        }

        if(userId) {
            axiosInstance
            .get(HOST() + "/Form/V1/Dat/AreaCount/GetAreaCount?userid=" + userId)
            .then(response => {
                const items = response.data.data || [];
                this.setState({ listData: items }, () => this.updateTopBarHeight(this.state.listData.length));
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(error.response.data.message);
                }
            });
        }
    }

    updateData(items: any) {
        this.setState({ listData: items }, () => {
            this.updateTopBarHeight(this.state.listData.length);
        });
	}

    private tickIcon(value:Number) {
        if(value) {
            return <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
        }
        return <span></span>
    }

    private textLowHigh(value:Number, isLower: Boolean) {
        // 以下/以上
        if(isLower) return value + " 以下";
        else return value + " 以上";
    }

    private bgColor(object: any) {
        if (object.mailToSend == "3.6A" || object.mailToSend == "3.8A") {
            return "bgRed";
        }

        if (object.mailToSend == "3.6B" || object.mailToSend == "3.8B") {
            return "bgYellow";
        }

        return "bgWhite";
    }

    public getArrow(colName: any) {
        if (this.state.sortKey == colName) {
            return this.state.isAsc ? '▲' : '▼';
        }
    }

    public sort(colName: any) {
        const isAsc = colName != this.state.sortKey ? true : !this.state.isAsc;
        const sortKey = colName;
        const listData = this.state.listData.sort((a: any, b: any) => {
            if (a[colName] < b[colName]) {
                return isAsc ? 1 : -1;
            } else if (a[colName] > b[colName]) {
                return !isAsc ? 1 : -1;
            } else {
                return 0;
            }
        });
        this.setState({ isAsc, sortKey, listData });
    }

    public render() {
		return (
            <div className={(this.state.isOpen ? 'show' : 'hide')} id="topTab" ref="container" data-height="120px">
                <div className="card-body h-100 p-2 pb-0" id="div-terminal-list">
                    <div className="toptab-title">
                        台数カウント
                    </div>
                    <div className="bg-white p-2">
                        <table className="table table-bordered mb-0 mr-0">
                            <thead style={{ backgroundColor: '#aaaaaa' }}>
                                <tr>
                                    <th onClick={() => this.sort('areaNm')} className="clickable">エリアの名称 {this.getArrow('areaNm')}</th>
                                    <th onClick={() => this.sort('countCli') } className="clickable">台数 {this.getArrow('countCli')}</th>
                                    <th>注意</th>
                                    <th>警告</th>
                                    <th><FontAwesomeIcon icon={faEnvelope} /></th>
                                    <th><img src={icAlarm} width="30" height="30"/></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.listData.map((item, index) => (
                                    <tr key={"area_count_1" + index}>
                                        <td className={this.bgColor(item)}>{item.areaNm}</td>
                                        <td className={this.bgColor(item)}>{item.countCli}</td>
                                        <td>{this.textLowHigh(item.countComment, item.isLower)}</td>
                                        <td>{this.textLowHigh(item.countAlert, item.isLower)}</td>
                                        <td>{this.tickIcon(item.isSendMail)}</td>
                                        <td>{this.tickIcon(item.isLinkDevice)}</td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
