import React from "react";
import "./UserManagement.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faEdit, faSignOutAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import icPlusGreen from "../../assets/img/ic_plus_green.png";
import { Button } from "reactstrap";
import { getValidNumber, tableResizable, toastOptions } from "../../common/Utils";
import { toast, ToastContainer } from "react-toastify";
import GlobalLoader from "../../common/interceptorLoader";
import "react-datepicker/dist/react-datepicker.css"
import Cookies from "js-cookie";

export default class SiteManagement extends React.Component<{
}, {
    listData: any[],
    showAddComponent: boolean,
    sectionButton: any,
    sectionTitle: any,
    dbNm: any,
    ipAdrs: any,
    siteID: any,
    siteNm: any,
    endMonth: any,
    useTraffic: any
}> {
    addFormComponent: any;
    constructor(props: any) {
        super(props)
        this.state = {
            listData: [],
            showAddComponent: false,
            sectionTitle: '新規登録',
            sectionButton: '登録',
            dbNm: '',
            ipAdrs: '',
            siteID: '',
            siteNm: '',
            endMonth: 1,
            useTraffic: 2
        }

        let user = Cookies.getJSON('user');
        if (user.roleID !== -1) {
            window.location.href = '/';
        }
    };

    componentDidMount() {
        this.getlistData();
    }

    private getlistData() {
        axiosInstance
            .get(HOST() + `/Form/V1/Authenticate/getallsite`)
            .then(response => {
                this.setState({ listData: response.data.data });
            })
    }

    public onCancel() {
        if (this.state.showAddComponent) {
            this.setState({ showAddComponent: false });
        }
        else {
            Cookies.remove('jwt');
            Cookies.remove('user');
            Cookies.remove('pv');
            window.location.href = '/login';
        }
    }
    
    public onDeleteItem(id: any) {
        axiosInstance
            .delete(HOST() + `/Form/V1/Authenticate/deleteSite?siteID=${id}`)
            .then(() => {
                this.setState({ showAddComponent: false });
                this.getlistData();
                toast.success('サイト情報を削除しました。', toastOptions);
            });
    }

    public onEditItem(object: any) {
        object.showAddComponent = true;
        object.sectionTitle = 'サイト情報編集';
        object.sectionButton = '編集';
        this.setState(object);
    }

    public onAddItem() {
        this.setState({
            showAddComponent: true,
            dbNm: '',
            ipAdrs: '',
            siteID: '',
            siteNm: '',
            endMonth: 1,
            useTraffic: 2,
            sectionTitle: '新規登録',
            sectionButton: '登録',
        });
    }

    public handleSubmitAdd() {
        let valid = true;
        if (!this.state.siteNm) {
            toast.error('サイト名を入力してください', toastOptions);
            valid = false;
        }
        if (!this.state.dbNm) {
            toast.error('データベース名を入力してください', toastOptions);
            valid = false;
        }
        if (!this.state.ipAdrs) {
            toast.error('データベースIPを入力してください', toastOptions);
            valid = false;
        }

        if (!valid) {
            return;
        }

        var body = {
            SiteID: this.state.siteID ? this.state.siteID : 0,
            SiteNm: this.state.siteNm,
            IpAdrs: this.state.ipAdrs,
            EndMonth: this.state.endMonth,
            DbNm: this.state.dbNm,
            UseTraffic: Number(this.state.useTraffic),
        };

        axiosInstance.post(HOST() + "/Form/V1/Authenticate/updateSite", body).then(res => {
            if (res.data.data) {
                this.setState({ showAddComponent: false });
                this.getlistData();
                toast.success('サイト情報を保存しました。', toastOptions);
            } else if (!body.SiteID){
                toast.error('データベース名が重複しています。', toastOptions);
            }
        });
    }

    public switchSite(siteId: any) {
        axiosInstance.post(HOST() + `/Form/V1/Authenticate/admin/activateSite/${siteId}`, {}).then(res => {
            if (res.data.data && res.data.data.token) {
                let user = res.data.data;
                Cookies.set('jwt', "bearer " + user.token, { expires: 365 });
                Cookies.set('user', user, { expires: 365 });
                Object.assign(document.createElement('a'), {
                    target: '_blank',
                    href: '/',
                }).click();
            }
        });
    }

    trafficSetting: any = {
        //"0": "オプションなし",
        //"1": "交通情報あり",
        "2": "航空写真",
        "3": "交通情報、航空写真"
    }

    public render() {
        return (
            <div>
                <GlobalLoader />
                <ToastContainer />
                <div className="box">
                    <div className="box-title d-flex justify-content-between align-items-center">
                        <span className="page-header">サイト登録</span>
                        <div className="buttonEdit">
                            {
                                this.state.showAddComponent ? 
                                    (<button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.onCancel()}>キャンセル</button>) :
                                    (
                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => this.onCancel()}>
                                            <span>ログアウト</span>
                                            <FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon>
                                        </button>
                                    )
                            }
                        </div>
                    </div>

                    <header className="th-header">
                        <span style={{ position: "absolute", marginTop: "10px", left: "45%", fontSize: "15px" }}>サイト一覧</span>
                        <Button onClick={() => this.onAddItem()} className="align-right plus-button button-img">
                            <img src={icPlusGreen} width="27" height="27" className="border rounded-circle" />
                        </Button>
                    </header>
                    <div className="tableUser">
                        <table id='sites' style={{ marginBottom: "10px" }}>
                            <tbody>
                                <tr>
                                    <th>サイトID</th>
                                    <th>サイト名</th>
                                    <th>データベース名</th>
                                    <th>データベースIP</th>
                                    <th>月数</th>
                                    <th>オプション設定</th>
                                    <th></th>
                                </tr>
                                {this.state.listData.map((item, index) => (
                                    <tr key={`user${item.id}&${index}`}>
                                        <td >{item.siteID}</td>
                                        <td >{item.siteNm}</td>
                                        <td >{item.dbNm}</td>
                                        <td >{item.ipAdrs}</td>
                                        <td >{item.endMonth}</td>
                                        <td >{this.trafficSetting[item.useTraffic]}</td>
                                        <td>
                                            <Button onClick={() => this.onEditItem(item)} className="button-img btn-50">
                                                <FontAwesomeIcon icon={faEdit} color="#000000"></FontAwesomeIcon></Button>
                                            {/*<Button onClick={() => this.onDeleteItem(item.siteID)} className="button-img">*/}
                                            {/*    <FontAwesomeIcon icon={faTrash} color="#000000"></FontAwesomeIcon>*/}
                                            {/*</Button>*/}
                                            {/*<Button onClick={() => this.switchSite(item.siteID)} className="button-img">*/}
                                            {/*    <FontAwesomeIcon icon={faArrowRight} color="#000000"></FontAwesomeIcon>*/}
                                            {/*</Button>*/}
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div id="addComponent" style={{ display: this.state.showAddComponent ? "block" : "none" }}>
                        <form>
                            <header className="th-header bg-blue">
                                <span style={{ position: "absolute", marginTop: "10px", left: "45%", fontSize: "15px" }}>{this.state.sectionTitle}</span>
                            </header>
                            <table onSubmit={this.handleSubmitAdd} >
                                <tbody>
                                    <tr>
                                        <th>サイトID</th>
                                        <th>サイト名</th>
                                        <th>データベース名</th>
                                        <th>データベースIP</th>
                                        <th>月数</th>
                                        <th>オプション設定</th>
                                        <th style={{ width: "10%" }}>{this.state.sectionButton}</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input
                                                className='w-100'
                                                value={this.state.siteID}
                                                onChange={e => this.setState({ siteID: e.target.value })}
                                                style={{ background: '#8080805e' }}
                                                readOnly />
                                        </td>
                                        <td>
                                            <input
                                                className='w-100'
                                                value={this.state.siteNm}
                                                maxLength={50}
                                                onChange={e => this.setState({ siteNm: e.target.value })}/>
                                        </td>
                                        <td>
                                            <input
                                                className='w-100'
                                                maxLength={50}
                                                value={this.state.dbNm}
                                                onChange={e => this.setState({ dbNm: e.target.value })}/>
                                        </td>
                                        <td>
                                            <input
                                                className='w-100'
                                                maxLength={15}
                                                value={this.state.ipAdrs}
                                                onChange={e => this.setState({ ipAdrs: e.target.value })} />
                                        </td>
                                        <td>
                                            <input
                                                className='w-100'
                                                type='number'
                                                value={this.state.endMonth}
                                                onChange={e => this.setState({ endMonth: getValidNumber(e, { maxValue: 999, minValue: 1 }) })} />
                                        </td>
                                        <td>
                                            <select className='w-100'
                                                value={this.state.useTraffic}
                                                onChange={e => this.setState({ useTraffic: e.target.value })}>

                                                {/*<option value="0">オプションなし</option>*/}
                                                {/*<option value="1">交通情報あり</option>*/}
                                                <option value="2">航空写真</option>
                                                <option value="3">交通情報、航空写真</option>
                                            </select>
                                        </td>
                                        <td>
                                            <Button onClick={() => this.handleSubmitAdd()} className="btn btn-primary btn-sm register">{this.state.sectionButton}</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
        )
    };
}