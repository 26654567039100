import * as React from 'react';
import './AccelerationHistory.css';
import Moment from 'react-moment';

export default class AccelerationHistoryList extends React.Component<{
    AccelerationHistoryList: any[],
    focusToEvent: Function
}, {
	AccelerationHistoryList: any[]
}> {
	constructor(props: any) {
		super(props);
		this.state = {
			AccelerationHistoryList: this.props.AccelerationHistoryList
		};
	}

	componentWillReceiveProps(nextProps: any) {
		this.setState({
			AccelerationHistoryList: nextProps.AccelerationHistoryList
		});
	}
	
	public render() {
		return (
			<div className="table-responsive AccelerationHistoryList" id="AccelerationHistoryList">
				<table className="table table-bordered table-fixed">
					<thead>
						<tr>
							<th>発生日時</th>
							<th>端末名</th>
							<th>警告種別</th>
							<th>緯度</th>
							<th>経度</th>
						</tr>
					</thead>
					<tbody>
						{
							this.props.AccelerationHistoryList.map((item: any, index: any) => (
								<tr key={"AccelerationHistoryList" + index} onClick={ () => this.props.focusToEvent(index) }>
								<td><Moment format="MM/DD HH:mm" date={item.eventDT} /></td>
								<td>{item.cliNm}</td>
								<td>{item.eventKind}</td>
								<td>{item.posLat}</td>
								<td>{item.posLon}</td>
							</tr>
						))
						}
					</tbody>
				</table>
			</div>
		);
	}
}
