import * as React from 'react';
import './BottomTab.css';
import { Tabs, Tab } from 'react-bootstrap';
import Trajectory from '../BottomTabItems/Trajectory';
import OperationHistory from '../BottomTabItems/OperationHistory';
import AccelerationHistory from '../BottomTabItems/AccelerationHistory';
import Message from '../BottomTabItems/Message';
import AudioCall from '../BottomTabItems/AudioCall';
import RemoteRoute from '../BottomTabItems/RemoteRoute';

export default class BottomTab extends React.Component<{
	bodyContainer: any,
	map: any,
	onNavi: Function
}, {
    isOpen: boolean,
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    componentDidMount() {
        this.setOpen(this.state.isOpen);
    }
    bodyContainer = null;
    componentWillReceiveProps(nextProp: any) {
        if (this.bodyContainer) return;
        const element: any = document.querySelector('#bottomTab');
        const thisHeight = this.state.isOpen ? element.offsetHeight : "0px";
        const otherElement: any = document.querySelector('#topTab');
        const thatHeight = otherElement.offsetHeight >= 6 ? `${otherElement.offsetHeight - 6}px` : "0px";
        this.setHeight(thisHeight);
        if (nextProp.bodyContainer) {
            nextProp.bodyContainer.style.height = `calc(100vh - 51px - ${thisHeight} - ${thatHeight})`;
            this.bodyContainer = nextProp.bodyContainer;
        }
    }

    public setOpen(isOpen: boolean, height = '') {
        this.setState({ isOpen });
        const element: any = document.querySelector('#bottomTab');
        const thisHeight = isOpen ? (height || element.dataset.height) : "0px";
        const otherElement: any = document.querySelector('#topTab');
        const thatHeight = otherElement.offsetHeight >= 6 ? `${otherElement.offsetHeight - 6}px` : "0px";
        const heightTabcontent = parseFloat(thisHeight) - 130;
        if (heightTabcontent > 65) {
            const OperationHistoryList: any = document.getElementById("OperationHistoryList");
            if (OperationHistoryList) OperationHistoryList.style.height = `${heightTabcontent}px`;
            const AccelerationHistoryList: any = document.getElementById("AccelerationHistoryList");
            if (AccelerationHistoryList)  AccelerationHistoryList.style.height = `${heightTabcontent}px`;
        }
        this.setHeight(thisHeight);
        if (this.props.bodyContainer) {
            this.props.bodyContainer.style.height = `calc(100vh - 51px - ${thisHeight} - ${thatHeight})`;
        }
    }

    private setHeight(height: any) {
        const element: any = this.refs.container;
        if (element) element.style.height = height;
    }

    private checkPitaVersion(pitaVersion: string) {
        this.setState({ pitaVersion: pitaVersion });
    }

    setActiveTab(key: any) {
        this.setOpen(true);
        const tab: any = document.getElementById(`navBottomTab-tab-${key}`);
        tab && tab.click();
    }

    clearTabData() {
        const Trajectory: any = this.refs.Trajectory;
        Trajectory && Trajectory.clearPath();

        const OperationHistory: any = this.refs.OperationHistory;
        OperationHistory && OperationHistory.clearPath();
    }

    public stopAudioCall() {
        let AudioCall: any = this.refs.AudioCall;
        AudioCall.stopCall();
    }

    public onAudioMessageSent() {
        let AudioCall: any = this.refs.AudioCall;
        AudioCall.onAudioMessageSent();
    }

    public render() {
		return (
            <div className={(this.state.isOpen ? 'show' : 'hide')} id="bottomTab" ref="container" data-height="300px">
                <Tabs defaultActiveKey="trajectory" id="navBottomTab" mountOnEnter={true} onSelect={() => this.clearTabData() }>
					<Tab eventKey="trajectory" title="軌跡">
						<Trajectory map={this.props.map} onNavi={this.props.onNavi} ref="Trajectory"/>
					</Tab>
					<Tab eventKey="operationHistory" title="運行履歴">
						<OperationHistory map={this.props.map} onNavi={this.props.onNavi} ref="OperationHistory"/>
					</Tab>
					<Tab eventKey="accelerationHistory" title="加速度履歴">
                        <AccelerationHistory ref="AccelerationHistory" map={this.props.map} onNavi={this.props.onNavi}/>
					</Tab>
					<Tab eventKey="message" title="メッセージ">
                        <Message map={this.props.map} onNavi={this.props.onNavi}/>
                    </Tab>
                    <Tab eventKey="remoteRoute" title="リモートルート">
                        <RemoteRoute map={this.props.map} onNavi={this.props.onNavi}/>
                    </Tab>
                    <Tab eventKey="voiceCall" title="音声通話">
                        <AudioCall onNavi={this.props.onNavi} ref="AudioCall"/>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}
