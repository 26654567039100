import React from "react";
import "./EmergencyMessage.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import * as signalR from "@microsoft/signalr";
import { toastOptions } from "../../common/Utils";
import { toast } from "react-toastify";
const emergencySound = require('../../assets/sound/emergencyMessage.mp3');


export default class EmergencyMessage extends React.Component<{
    onEmergencyMessage: Function,
}, {
    message: any,
    showing: boolean
    cliIDs: any[]
}> {
    constructor(props: any) {
        super(props)
        this.state = {
            cliIDs: [],
            message: '',
            showing: false
        }

        this.onEmergencyMessageComming = this.onEmergencyMessageComming.bind(this);
        this.onClickButtonOK = this.onClickButtonOK.bind(this);
    }

    componentDidMount() {
        //const hubConnection = new signalR.HubConnectionBuilder()
        //    .withUrl("/signalrnotificationhub")
        //    .configureLogging(signalR.LogLevel.Information)
        //    .build();

        //// Starts the SignalR connection
        //hubConnection.start().then(a => {
        //    // Once started, invokes the sendConnectionId in our ChatHub inside our ASP.NET Core application.
        //    //if (hubConnection.connectionId) {
        //    //    hubConnection.invoke("sendConnectionId", hubConnection.connectionId);
        //    //}
        //});
        //hubConnection.on("ReceiveMessage", (title, message) => {
        //    this.queryMessage(title);
        //});
    }

    componentWillUnmount() {
    }

    public onClickButtonOK() {
        // [1,2,3]
        var ids: any[] = this.state.cliIDs.map((e: any) => Number(e));

        var body = ids;
        
        var api = "/Form/V1/Dat/Terminal/UpdateEmergency"
        axiosInstance
            .put(HOST() + api, body)
            .then(res => {
                console.log('Terminal/UpdateStatus success: ' + res.data);
                this.props.onEmergencyMessage('')
            }).catch(e => {
                console.log('Request Failed。', toastOptions);
                this.props.onEmergencyMessage('')
            });
    }

    public onEmergencyMessageComming(terminalData: any) {
        // {cliNms:  latest, cliIDs: ids}});
        var cliNms = terminalData.cliNms;
        
        if(cliNms.length === 0) return;

        this.setState({cliIDs: terminalData.cliIDs})
        
        var dataName = cliNms.join('と');

        this.props.onEmergencyMessage(dataName);

        var body = dataName + "から緊急ボタンが押されました。";
        this.setState({ message: body });
        this.setState({ showing: true });
        let audio = new Audio(emergencySound);
        audio.play();
    }
    
    // queryMessage(id: any) {
    //     var terminalData = [{ id: 1, status: 0, name: '端末001', pic: 'A', battery: '', arrival: '12:00', remain: '30m', display: true, emergency: false },
    //     { id: 2, status: 3, name: '端末002', pic: 'B', battery: '', arrival: '13:00', remain: '1h30m', display: true, emergency: false },
    //     { id: 3, status: 1, name: '端末003', pic: 'C', battery: '', arrival: '14:00', remain: '2h30m', display: true, emergency: false },
    //     { id: 4, status: 0, name: '端末004', pic: 'D', battery: '', arrival: '15:00', remain: '3h30m', display: true, emergency: false },
    //     { id: 5, status: 2, name: '端末005', pic: 'E', battery: '', arrival: '16:00', remain: '4h30m', display: true, emergency: false }];

    //     if (terminalData.find(x => x.id == id) != null) {
    //         let data = terminalData.find(x => x.id == id);
    //         if (data != null) {
    //             data.emergency = true;
    //             this.props.onEmergencyMessage(data);

    //             var body = data.name + "から緊急ボタンが押されました。";
    //             this.setState({ message: body });
    //             this.setState({ showing: true });
    //         }
    //         // alert(this.state.message);
    //     }
    // }


    public render() {
        return (
            
            <div id="emergency-message">
                {this.state.message}
                <br/>
            至急確認してください。<br/>
                {/* <button onClick={(e) => this.props.onEmergencyMessage('')}>OK</button> */}
                <button onClick={this.onClickButtonOK}>OK</button>
            </div>
        );
    }
};