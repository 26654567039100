import * as React from 'react';
import './AreaList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Collapse, Dropdown } from 'react-bootstrap';
import axiosInstance from '../../common/interceptor';
import { HOST } from '../../common/constant';
import DragAndDrop from '../../common/DragAndDrop/DragAndDrop';
import { rgbToHex, hideMenu, toastOptions, tableResizable, showContextMenu } from '../../common/Utils';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import eventBus from "../../common/EventBus"

export default class AreaList extends React.Component<{
	areaType: number,
	map: any,
	onNavi: Function
}, {
	isOpenBlock: boolean,
	ListArea: any[],
}> {
	constructor(props: any) {
		super(props);
		this.state = {
			isOpenBlock: true,
			ListArea: [],
		};
	}

	validTimeMap: any = {
		1 : "毎日",
		2 : "月-金",
		3 : "月-土",
		4 : "土",
		5 : "日",
		6 : "土日"
	};

	areaTypeMap: any = {
		0: {
			title: "特定エリア",
		},
		1: {
			title: "プレエリア",
		},
		2: {
			title: "自動エリア",
		}
	}

	drawedPath: any = {};
	dataFirstLoad = false;

	comments: any = {};
	commentsEvent: any = {};

	private updateDisplayOrder(e: any) {
		this.setState(e);
		const body = e.ListArea.map((e: any) => { return { AreaID: e.areaId, Order: e.DisplayOrder }; });
		axiosInstance.put(HOST() + "/Form/V1/Dat/Area/SetOrder", body);
	}

    private updateUsing(areaId: number, value: any, index: any) {
        //check area limit
        var user: any = JSON.parse(Cookies.get('user') || "{}");
        if (user && user.siteId) {
			const body = { AreaID: areaId, Use: value ? 1 : 0 };
			const ListArea = this.state.ListArea;
			axiosInstance
				.put(HOST() + "/Form/V1/Dat/Area/UpdateUsing", body)
				.then(e => {
					const data = JSON.parse(e.data.data);
					if (this.isUpdateUsingOk(data)) {
						toast.success(value ? '有効になりました。' : '無効になりました。', toastOptions);
						ListArea[index].using = value;
						eventBus.dispatch("changeRecArea", {});
						this.setState({ ListArea }, () => {
							axiosInstance.get(HOST() + "/Form/V1/Dat/Area/SyncDataAllUser?title=SyncAreaEnable");
						});
					} else {
						this.showUpdateUsing(data);
					}
				});
        }
    }

	private showUpdateUsing(data: any, prefix: any = '') {
		const isRouteLinking = data[1] && data[1].length > 0;
		const isRouteLinking2 = data[5] && data[5].length > 0;
		const isAreaLinking = data[2] && data[2].length > 0;
		const isUnitCount = data[3];
		const isAutoMessage = data[4];
		const isExceedLimitPoints = data[6];
		const isAreaRecCount = data[7];
		const isAreaCount = data[8];
		//if (isRouteLinking) {
		//	let message = `ルート「${data[1].join('」、ルート「')}」と紐づけされている為、無効に出来ません。\n無効にする場合は設定を解除してください。`;
		//	toast.error((prefix ? `${prefix}: ` : '') + message, toastOptions);
		//}
		//if (isRouteLinking2) {
		//	let message = `ルート「${data[5].join('」、ルート「')}」と紐づけされている為、無効に出来ません。\n無効にする場合は設定を解除してください。`;
		//	toast.error((prefix ? `${prefix}: ` : '') + message, toastOptions);
		//}
		//if (isAreaLinking) {
		//	let message = `エリア「${data[2].join('」、エリア「')}」と紐づけされている為、無効に出来ません。\n無効にする場合は設定を解除してください。`;
		//	toast.error((prefix ? `${prefix}: ` : '') + message, toastOptions);
		//}

		//if (isUnitCount) {
		//	let message = `台数カウント設定をしているエリアの為無効化できません。`;
		//	toast.error((prefix ? `${prefix}: ` : '') + message, toastOptions);
		//}
		//if (isAutoMessage) {
		//	let message = `このエリアは自動メッセージ送信で使用されているため無効化できません。`;
		//	toast.error((prefix ? `${prefix}: ` : '') + message, toastOptions);
		//}
		if (isExceedLimitPoints) {
			let message = `多角形エリアの作成上限を超過しています。\n不要な多角形エリアを無効化又は削除してください。`;
			toast.error((prefix ? `${prefix}: ` : '') + message, toastOptions);
		}
		if (isAreaRecCount) {
			let message = `監視対象エリア上限に達しています。`;
			toast.error((prefix ? `${prefix}: ` : '') + message, toastOptions);
		}
		if (isAreaCount) {
			let message = `エリア有効上限数を超過しています。エリアの無効化をしてください。\nエリア有効上限数を変更したい場合は担当者へお問い合わせください。`;
			toast.error((prefix ? `${prefix}: ` : '') + message, toastOptions);
		}
    }

	private isUpdateUsingOk(data: any) {
		return Object.keys(data).length === 1 && Object.keys(data)[0] === '0';
    }

	private updateDisplayDb(areaId: any, value: any) {
		const body = { AreaID: areaId, Use: value ? 1 : 0 };
		axiosInstance.put(HOST() + "/Form/V1/Dat/Area/UpdateDisplay", body).then((res) => {
			if (res && res.data && res.data.result) {
				toast.success(value ? '表示になりました。' : '非表示になりました。', toastOptions);
            }
		});
	}

	private updateDisplay(data: any, display: boolean, index: any, skipUpdateState: boolean = false) {
		const areaId = data.areaId;
		const ListArea = this.state.ListArea;
		const _this = this;
        if(ListArea[index]) ListArea[index].display = display;

		if (!display && !this.drawedPath[areaId]) {
			return;
		}

		if (!display && this.drawedPath[areaId]) {
			this.drawedPath[areaId].setMap(null);
			if(this.comments[areaId]) {
				this.comments[areaId].close();
				this.comments[areaId].setMap(null);
				delete this.comments[areaId];
			}
			if (!skipUpdateState) {
				this.setState({ ListArea });
            }
			return;
		}

		if (this.drawedPath[areaId]) {
			const color = rgbToHex(data.areaColorRGB)
			this.drawedPath[areaId].setOptions({ strokeColor: color, fillColor: color });
			if (data.radius) {
				this.drawedPath[areaId].setOptions({
					center: { lat: data.latitude, lng: data.longitude },
					radius: data.radius
				});
			}

			if (this.commentsEvent[areaId] && this.commentsEvent[areaId].click) {
				window.google.maps.event.removeListener(this.commentsEvent[areaId].click);
			}
			const click = this.leftClickArea(areaId, this.drawedPath[areaId], data);
			this.commentsEvent[areaId] = { ...this.commentsEvent[areaId], click };
			if (this.comments[areaId]) {
				this.leftClickAreaHandle(areaId, this.drawedPath[areaId], data);
			}

			this.drawedPath[areaId].setMap(this.props.map);
			if (!skipUpdateState) {
				this.setState({ ListArea });
			}
			
			return;
		}

		axiosInstance
			.get(HOST() + `/Form/V1/Dat/AreaDetail/GetByID?areaID=${areaId}`)
			.then(async response => {
				var path = response.data.data;
				if (!path) {
					return;
				}
				let drawedLine: any;
				const dbArea = await axiosInstance
					.get(HOST() + `/Form/V1/Dat/Area/GetAreaByID?areaID=${data.areaId}`)
					.then(response => response.data.data)
				const color = rgbToHex(dbArea.areaColorRGB)
				if (path.length === 0) {
					drawedLine = new window.google.maps.Circle({
						strokeColor: color,
						strokeOpacity: 0.8,
						strokeWeight: 2,
						fillColor: color,
						fillOpacity: 0.35,
						clickable: true,
						draggable: true,
						center: { lat: dbArea.latitude, lng: dbArea.longitude },
						radius: data.radius
					});
					this.drawedPath[areaId] = drawedLine;
					this.drawedPath[areaId].setMap(this.props.map);
					if (!skipUpdateState) {
						this.setState({ ListArea });
					}
				} else {
					for (var i = 0; i < path.length; i++) {
						path[i].lat = path[i].posLat;
						path[i].lng = path[i].posLon;
					}
					drawedLine = new window.google.maps.Polygon({
						path: path,
						geodesic: true,
						strokeColor: color,
						strokeOpacity: 1.0,
						strokeWeight: 2,
						fillColor: color,
						clickable: true,
						draggable: true,
						fillOpacity: 0.35
					});
					this.drawedPath[areaId] = drawedLine;
					this.drawedPath[areaId].setMap(this.props.map);
					if (!skipUpdateState) {
						this.setState({ ListArea });
                    }
				}

				let prevState: any = {};
				window.google.maps.event.addListener(drawedLine, "dblclick", () => {
					_this.onEditItem(areaId);
				})
				const click = this.leftClickArea(areaId, drawedLine, dbArea);
				window.google.maps.event.addListener(drawedLine, 'mousedown', (ev: any) => {
					prevState = ev;
                    if (_this.button(ev) != 2) {
						drawedLine.setOptions({ draggable: false });
                    }
				});
				window.google.maps.event.addListener(drawedLine, 'mouseup', (ev: any) => {
					if (_this.button(ev) != 2) {
						drawedLine.setOptions({ draggable: true });
					} else {
						if (_this.isSimpleRightClick(prevState, ev)) {
							const topTab: any = document.getElementById('topTab');
							const navLeft: any = document.getElementById('navLeft');
							
							const ev_t: any = {
								pixel: {
									x: _this.buttonX(ev) - navLeft.offsetWidth,
									y: _this.buttonY(ev) - 48 - topTab.offsetHeight
								},
								latLng: {
									lat: () => ev.latLng.lat(),
									lng: () => ev.latLng.lng()
                                }
							};
							const this_t: any = {
								state: {
									map: _this.props.map
                                }
							}
							showContextMenu(ev_t, this_t);
                        }
                    }
				});
				window.google.maps.event.addListener(drawedLine, 'dragend', () => {
					_this.props.onNavi("/moveArea", { drawedLine,  area: data, listId: _this.props.areaType, sizeFitContent: true});
				});

				this.commentsEvent[areaId] = {...this.commentsEvent[areaId], click };
			});
	}

	private leftClickArea(areaId: any, drawedLine: any, data: any) {
		return window.google.maps.event.addListener(drawedLine, "click", () => {
			this.leftClickAreaHandle(areaId, drawedLine, data);
		})
	}

	private leftClickAreaHandle(areaId: any, drawedLine: any, data: any) {
		const _this = this;
		const infowindow = this.comments[areaId] || new window.google.maps.InfoWindow({disableAutoPan: true});

		const infowindowContent: any = document.createElement('div');
		let posAngle = '往路／復路';
		if (data.inBound && data.outBound) {
			posAngle = '往路／復路';
		} else if (data.inBound) {
			posAngle = '復路';
		} else if (data.outBound) {
			posAngle = '往路';
		}
		infowindowContent.innerHTML =
			`<div class="area-comment">
				<span>${data.areaName}</span>`
			+ (data.radius ? `<span>半径：${data.radius} (m)</span>` : '') +
			`	<span>制限速度：${data.speed} (km/h)</span>
				<span>方向：${posAngle}</span>
				<span>メッセージ：${data.message}</span>
			</div>`;
		infowindow.setContent(infowindowContent);
		google.maps.event.addListener(infowindow, 'closeclick', function () {
			_this.comments[areaId] = null;
		});
		infowindow.setPosition({ lat: data.latitude, lng: data.longitude });
		this.comments[areaId] = infowindow;

		if (!this.comments[areaId].map) {
			infowindow.open({ shouldFocus: false, map: this.props.map });
		}
	}

	private isSimpleRightClick(prev: any, curr: any) {
		return prev.latLng.lat() == curr.latLng.lat()
			&& prev.latLng.lng() == curr.latLng.lng();
    }

	private button(ev: any) {
		return Object.keys(ev).filter(e => ev[e] && ev[e].button).map(e => ev[e].button)[0];
	}

	private buttonX(ev: any) {
		return Object.keys(ev).filter(e => ev[e] && ev[e].x).map(e => ev[e].x)[0];
	}

	private buttonY(ev: any) {
		return Object.keys(ev).filter(e => ev[e] && ev[e].y).map(e => ev[e].y)[0];
    }

	public mapFocusTo(data: any) {
		if (!data.display) {
			return;
        }
		const areaId = data.areaId;
		axiosInstance
			.get(HOST() + `/Form/V1/Dat/AreaDetail/GetByID?areaID=${areaId}`)
			.then(response => {
				var path = response.data.data;
				var bounds = new window.google.maps.LatLngBounds();
				if (path.length === 0) {
					const circle = new window.google.maps.Circle({
						center: { lat: data.latitude, lng: data.longitude },
						radius: data.radius
					});
					bounds.union(circle.getBounds());
				} else {
					for (var i = 0; i < path.length; i++) {
						path[i].lat = path[i].posLat;
						path[i].lng = path[i].posLon;
						bounds.extend(path[i]);
					}
				}
				this.props.map.fitBounds(bounds);
			});
    }

	public redraw(area: any) {
		if (!area) {
			return;
		}

		const areaId = area.areaId;
		if (areaId && this.drawedPath[areaId]) {
			this.drawedPath[areaId].setMap(null);
			delete this.drawedPath[areaId];
		}

		axiosInstance
			.get(HOST() + `/Form/V1/Dat/Area/GetAreaByType?areaType=${this.props.areaType}`)
			.then(response => {
				this.setState({ ListArea: response.data.data || [] });
				this.updateDisplay(area, true, 0, true);
			});
    }

	private onEditItem(areaId: any) {
		const modalData = {
			areaId
		};
		this.props.onNavi("/editAreaManagement", modalData);
	}

	public setMapObjectClickable(clickable: boolean) {
		for (let path in this.drawedPath) {
			const object = this.drawedPath[path];
			object.setOptions({ clickable: clickable });
        }
    }

	public loadData(syncDataOnly = false) {
		this.dataFirstLoad = true;
		const ListAreaCheckedMap: any = {};
		this.state.ListArea.forEach((e: any) => ListAreaCheckedMap[e.areaId] = true);

		axiosInstance
			.get(HOST() + `/Form/V1/Dat/Area/GetAreaByType?areaType=${this.props.areaType}&isGetAuto=true`)
			.then(response => {
				const ListArea = response.data.data || [];
				if (syncDataOnly) {
					ListArea.forEach((e: any, i: any) => {
						e.using = e.using == 1;
						e.display = e.display == 1;
					});
					this.setState({ ListArea });
					return;
                }
				ListArea.forEach((e: any, i: any) => {
					delete ListAreaCheckedMap[e.areaId];
					e.using = e.using == 1;
					e.display = e.display == 1;
					if (e.display) {
						this.updateDisplay(e, true, i, true);
					}
				});
				for (let areaId in ListAreaCheckedMap) {
					this.drawedPath[areaId] && this.drawedPath[areaId].setMap(null);
                }
				this.setState({ ListArea }, () => {
                    tableResizable(`areaList${this.props.areaType}`);
				});
			});

    }

	componentWillReceiveProps(nextProps: any) {
		if (nextProps.map && !this.dataFirstLoad) {
			this.loadData();
        }
	}

	componentWillMount() {
		if (this.props.map && !this.dataFirstLoad) {
			this.loadData();
        }
    }


	private updateDisplayAll(display: boolean) {
		hideMenu("dropdown-area-" + this.props.areaType + "-option");
		const ListArea = this.state.ListArea;
		ListArea.forEach((e, i) => {
			this.updateDisplay(e, display, i);
		});

		const body = this.state.ListArea.map(e => { return { AreaID: e.areaId, Use: display ? 1 : 0 } });
		axiosInstance.put(HOST() + "/Form/V1/Dat/Area/UpdateListDisplay", body).then(res => {
			if (res && res.data && res.data.result) {
				toast.success(display ? 'すべて表示になりました。' : 'すべて非表示になりました。', toastOptions);
            }
		});
	}

	private updateUsingAll(enabled: boolean) {
		hideMenu("dropdown-area-" + this.props.areaType + "-option");
		const body = this.state.ListArea.map(e => { return { AreaID: e.areaId, Use: enabled ? 1 : 0 } });
		axiosInstance.put(HOST() + "/Form/V1/Dat/Area/UpdateListUsing", body).then(res => {
			if (res.data && res.data.data) {
				const ListArea = this.state.ListArea;
				const resData = JSON.parse(res.data.data);
				let isOK = false;
				let isFail = false;
				ListArea.forEach(e => {
					const data = resData[e.areaId];
					if (this.isUpdateUsingOk(data)) {
						isOK = true;
						e.using = enabled;
					} else {
						isFail = true;
						this.showUpdateUsing(data, `エリア「${e.areaName}」`);
					}
				});

				this.setState({ ListArea }, () => {
					axiosInstance.get(HOST() + "/Form/V1/Dat/Area/SyncDataAllUser?title=SyncAreaEnable");
				});
				let apart = isOK && isFail ? '一部' : '';
				toast.success(enabled ? (apart + '有効になりました。') : (apart + '無効になりました。'), toastOptions);
            }
		});
	}

	showCommentAll(show: boolean) {
		hideMenu("dropdown-area-" + this.props.areaType + "-option");

		if (show) {
			let lastK;
			Object.keys(this.drawedPath).forEach(k => {
				this.drawedPath[k] && this.drawedPath[k].map && google.maps.event.trigger(this.drawedPath[k], 'click');
				lastK = k;
			})
			this.mapFocusTo({areaId: lastK, display: true})
		} else {
			Object.keys(this.comments).forEach(k => {
				this.comments[k] && this.comments[k].close();
			})
			this.comments = {}
		}
	}

	public render() {
		return (
			<div className="card">
				<Dropdown>
					<div className="card-header" id="headingFive">
						<a
							href="#"
							onClick={() => this.setState({ isOpenBlock: !this.state.isOpenBlock })}
							className="collapsible-link d-block"
						>
							{this.areaTypeMap[this.props.areaType].title}
						</a>
						<div className="controls-more">
							<Dropdown.Toggle variant="link" id={"dropdown-area-" + this.props.areaType +"-option"}>
								<FontAwesomeIcon icon={faEllipsisV}></FontAwesomeIcon>
							</Dropdown.Toggle>
						</div>
					</div>
					<Collapse in={this.state.isOpenBlock}>
						<div className="card-body area-list">
							<div className="table-responsive">
								<table className="table table-bordered" style={{ width: '520px' }} id={`areaList${this.props.areaType}`}>
									<thead>
										<tr>
											<th ></th>
											<th>エリア名</th>
											<th>半径<br></br>(m)</th>
											<th>制限速度<br></br>(km/h)</th>
											<th>有効時間</th>
											<th>有効</th>
											<th>表示</th>
											<th></th>
										</tr>
									</thead>
									{
										this.state.ListArea.map((area: any, index: any) => (
											<DragAndDrop
												key={"sArea" + index}
												index={index}
												onchange={(e: any) => this.updateDisplayOrder(e)}
												dataSource={this.state.ListArea}
												tableId={`areaList${this.props.areaType}`}
												listName="ListArea"
											>
												<tr className="area-list-item clickable" onClick={() => this.mapFocusTo(area)} data-index={index}>
													<td>{ area.areaId}</td>
													<td className="w-100px">{ area.areaName }</td>
													<td>{ area.radius }</td>
													<td>{ area.speed }</td>
													<td>{ area.validTimeStart == '00:00' && area.validTimeEnd == '23:59' ? "" : "*"}
													</td>
													<td>
														<input
															type="checkbox"
															checked={area.using}
															onClick={(e: any) => e.stopPropagation()}
															onChange={(e: any) => this.updateUsing(area.areaId, e.target.checked, index) }
														/>
													</td>
													<td>
														<input
															type="checkbox"
															checked={area.display}
															onClick={(e: any) => e.stopPropagation() }
															onChange={(e: any) => {
																this.updateDisplay(area, e.target.checked, index);
																this.updateDisplayDb(area.areaId, e.target.checked);
																if (e.target.checked) this.mapFocusTo(area);
															}}
														/>
													</td>
													<td>
														<button onClick={(e: any) => {
															this.onEditItem(area.areaId);
															e.stopPropagation();
														}} >
															<FontAwesomeIcon icon={faEdit} color="#000000"></FontAwesomeIcon>
														</button>
													</td>
												</tr>
											</DragAndDrop>
										))
									}
								</table>
							</div>
						</div>
					</Collapse>
					<Dropdown.Menu>
						<a className="dropdown-item" href="#" onClick={this.updateDisplayAll.bind(this, true)}>全て表示</a>
						<a className="dropdown-item" href="#" onClick={this.updateDisplayAll.bind(this, false)}>全て非表示</a>
						<a className="dropdown-item" href="#" onClick={this.updateUsingAll.bind(this, true)}>全て有効</a>
						<a className="dropdown-item" href="#" onClick={this.updateUsingAll.bind(this, false)}>全て無効</a>
						<a className="dropdown-item" href="#" onClick={this.showCommentAll.bind(this, true)}>エリア情報の表示</a>
						<a className="dropdown-item" href="#" onClick={this.showCommentAll.bind(this, false)}>エリア情報の非表示</a>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		);
	}
}
