
import React, {useState , forwardRef} from "react";
import "../Settings/Setting.css"
import "./TerminalInformation.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import Cookies from 'js-cookie';
import {useHistory} from 'react-router-dom';
import { Button } from "reactstrap";
import { isBlank, toastOptions } from "../../common/Utils";
import { toast } from "react-toastify";


export default class EditTerminalItem extends React.Component<{
    onNavi: Function,
}, {
    data: any,
    errorName: any,
    errorSpeed:any,
    errorType: any,
    errorPhone: any,
    errorPIC: any,
    errorBelong: any,
    errorGpsRecord: any,
    errorGpsSend: any,
    errorGpsDensity: any,
    errorMes: any,
}> {
    constructor(props: any) {
        super(props)
        this.state = {
            data: this.initData(),
            errorName: '',
            errorSpeed: '',
            errorType: '',
            errorPhone: '',
            errorPIC: '',
            errorBelong: '',
            errorGpsRecord: '',
            errorGpsSend: '',
            errorGpsDensity: '',
            errorMes: '',
        }
        
        this.onFetchData = this.onFetchData.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.handleSubmitAdd = this.handleSubmitAdd.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onValidInputNumber = this.onValidInputNumber.bind(this);
    };

    initData() {
        var data = {terminalID: "xxxx", name: '', speedLimit: 0, terminalType: '',
        phoneNumber:'', personInCharge: '', belongTo: '',
        gpsRecordInterval: 3, gpsSendInterval: 10, gpsDensity: 0, mesReceiveInterval: 0
                    };
        return data;
        
    }
    componentDidMount() {
        this.onFetchData();
    }
  
    private onFetchData() {
        // var api = "/Form/V1/Dat/Cargo/ById?userid=5&id=1"
        // axiosInstance
        // .get(HOST() + api)
        // .then(response => {
            
        // })
        // .catch(error => {
        //     if (error.response && error.response.data) {
        //         alert(error.response.data.message);
        //     }
        // });
    }

    private goBack(refresh: boolean) {
        if(refresh) this.props.onNavi('/', 'terminalList');
        else this.props.onNavi('/');
    }

    public onCancel(path: any) {
        // window.history.back();
        this.goBack(false);
    }

    
    setItemData(item: any) {
        
    }

    // {terminalID: "xxxx", name: '', speedLimit: 0, phoneNumber:'', personInCharge: '', belongTo: '',
    //     gpsRecordInterval: 3, gpsSendInterval: 10, gpsDensity: 0, mesReceiveInterval: 0};
    public validateInput() {
        var isValid = true;
        if(isBlank(this.state.data.name)) {
            this.setState({errorName: "端末名が空です. 有効な端末名を入力してください"});
            isValid = false
        }
        else { this.setState({errorName: ''});  }
        
        if(this.state.data.speedLimit < 0) {
            this.setState({errorSpeed: "制限速度が無効です. 有効な制限速度を入力してください"});
            isValid = false
        }
        else { this.setState({errorSpeed: ''}); }
        
        if(isBlank(this.state.data.terminalType)) {
            this.setState({errorType: "端末種類が空です. 有効な端末種類を入力してください"});
            isValid = false
        }
        else { this.setState({errorType: ''}); }

        if(isBlank(this.state.data.phoneNumber)) {
            this.setState({errorPhone: "電話番号が空です. 有効な電話番号を入力してください"});
            isValid = false
        }
        else { this.setState({errorPhone: ''});}

        if(isBlank(this.state.data.personInCharge)) {
            this.setState({errorPIC: "所有者が空です. 有効な所有者を入力してください"});
            isValid = false
        }
        else {this.setState({errorPIC: ''}); }

        if(isBlank(this.state.data.name)) {
            this.setState({errorBelong: "所属が空です. 有効な所属を入力してください"});
            isValid = false
        }
        else { this.setState({errorBelong: ''}); }

        //     gpsRecordInterval: 3, gpsSendInterval: 10, gpsDensity: 0, mesReceiveInterval: 0};
        var gpsRecordInterval = this.state.data.gpsRecordInterval;
        if(gpsRecordInterval < 3 || gpsRecordInterval > 10) {
            this.setState({errorGpsRecord: "GPS記録間隔が無効です. 有効な値を入力してください"});
            isValid = false
        }
        else { this.setState({errorGpsRecord: ''}); }

        var gpsSendInterval = this.state.data.gpsSendInterval;
        if(gpsSendInterval < 3 || gpsSendInterval > 10) {
            this.setState({errorGpsSend: "GPS送信間隔が無効です. 有効な値を入力してください"});
            isValid = false
        }
        else { this.setState({errorGpsSend: ''}); }

        if(this.state.data.gpsDensity < 0) {
            this.setState({errorGpsDensity: "GPS許容誤差が無効です. 有効な値を入力してください"});
            isValid = false
        }
        else { this.setState({errorGpsDensity: ''}); }

        if(this.state.data.mesReceiveInterval < 0) {
            this.setState({errorMes: "MES受信間隔が無効です. 有効な値を入力してください"});
            isValid = false
        }
        else { this.setState({errorMes: ''}); }

        return isValid;
    }
    
    public handleSubmitAdd() {
        if(!this.validateInput()) {
            return;
        }
        this.setState({errorName: ""});
        var body = {
            
        };
        var api = "/Form/V1/Dat/Cargo/ById?userid=5&id=1"
        
        // axiosInstance
        //     .post(HOST() + api, body)
        //     .then(res => {
        //         this.addItemToListView(body);
        //     }).catch(e => {
        //         this.setState({error: "Request Failed"});
        //     });
        toast.success('更新に成功。', toastOptions);
    }
 
    public onInputChange(event:any) {
       var inputName = event.target.name;
       var value = event.target.value;
       var aData = this.state.data;
        if(inputName === "name") aData.name = value;
       else if(inputName === "speedLimit") aData.speedLimit = value;
       else if(inputName === "terminalType") aData.terminalType = value;
       else if(inputName === "phoneNumber") aData.phoneNumber = value;
       else if(inputName === "personInCharge") aData.personInCharge = value;
       else if(inputName === "belongTo") aData.belongTo = value;
       else if(inputName === "gpsRecordInterval") aData.gpsRecordInterval = value;
       else if(inputName === "gpsSendInterval") aData.gpsSendInterval = value;
       else if(inputName === "gpsDensity") aData.gpsDensity = value;
       else if(inputName === "mesReceiveInterval") aData.mesReceiveInterval = value;

       this.setState({data: aData});
    }
    
    public onValidInputNumber(e: any, min:number, max:number) {
        var inputName = e.target.name;
        var value = e.target.value;
        if(value <= max) {
            var aData = this.state.data;
            if(inputName === "gpsRecordInterval") aData.gpsRecordInterval = value;
            else if(inputName === "gpsSendInterval") aData.gpsSendInterval = value;
        }
        this.setState({data: aData});
    }

    public render() {
        return (
            <div>
                <div className="box terminal-info">
                <div>
                    <span className="page-header">端末情報</span>
                    <div className="buttonEdit">
                        <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.onCancel} >キャンセル</button>
                        <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.handleSubmitAdd} >登録</button>
                    </div>
                </div>             
                <table>
                    <tr>
                        <td className="col5">端末ID</td>
                        <td>
                            <span>{this.state.data.terminalID}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="col5">端末名</td>
                        <td>
                            <input name="name" type="text" value={this.state.data.name} onChange={this.onInputChange} className="input-edit"/>
                            &nbsp; <span className="errClass">{this.state.errorName}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="col5" style={{verticalAlign:"top", paddingTop:"18px"}}>制限速度</td>
                        <td>
                            <input name="speedLimit" type="number" min={0} value={this.state.data.speedLimit} onChange={this.onInputChange} className="input-edit"/>
                            &nbsp;&nbsp;(km/h)
                            <br/>※端末の通常(エリア以外)制限速度を設定してください。
                            <br/>※0に設定した場合は、速度を制限しません。
                        </td>
                    </tr>
                    <tr>
                        <td className="col5">端末種類</td>
                        <td>
                            <input name="terminalType" type="text" value={this.state.data.terminalType} onChange={this.onInputChange} className="input-edit"/>
                            &nbsp; <span className="errClass">{this.state.errorType}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="col5">電話番号</td>
                        <td>
                            <input name="phoneNumber" type="text" value={this.state.data.phoneNumber} onChange={this.onInputChange} className="input-edit"/>
                            &nbsp; <span className="errClass">{this.state.errorPhone}</span>
                        </td>
                    </tr>
                    <tr><td colSpan={2}></td></tr>
                    <tr>
                        <td className="col5">所有者</td>
                        <td>
                            <input name="personInCharge" type="text" value={this.state.data.personInCharge} onChange={this.onInputChange} className="input-edit"/>
                            &nbsp; <span className="errClass">{this.state.errorPIC}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="col5">所属</td>
                        <td>
                            <input name="belongTo" type="text" value={this.state.data.belongTo} onChange={this.onInputChange} className="input-edit"/>
                            &nbsp; <span className="errClass">{this.state.errorBelong}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="col5">GPS記録間隔</td>
                        <td>
                            <input name="gpsRecordInterval" type="number" min={3} onChange={e => this.onInputChange(e)} value={this.state.data.gpsRecordInterval}  className="input-edit"/>
                            &nbsp;&nbsp;(s) &nbsp;&nbsp;<span className="errClass">{this.state.errorGpsRecord}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="col5">GPS送信間隔</td>
                        <td>
                            <input name="gpsSendInterval" type="number" min={10} onChange={e => this.onInputChange(e)} value={this.state.data.gpsSendInterval}  className="input-edit"/>
                            &nbsp;&nbsp;(s) &nbsp;&nbsp;<span className="errClass">{this.state.errorGpsSend}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="col5">GPS許容誤差</td>
                        <td>
                            <input name="gpsDensity" type="number" value={this.state.data.gpsDensity} onChange={this.onInputChange} className="input-edit"/>
                            &nbsp;&nbsp;(m) &nbsp;&nbsp;<span className="errClass">{this.state.errorGpsDensity}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="col5">MES受信間隔</td>
                        <td>
                            <input name="mesReceiveInterval" type="number" min={0} value={this.state.data.mesReceiveInterval} onChange={this.onInputChange} className="input-edit"/>
                            &nbsp;&nbsp;(s) &nbsp;&nbsp;<span className="errClass">{this.state.errorMes}</span>
                        </td>
                    </tr>
                    <tr><td colSpan={2}></td></tr>
                    <tr><td colSpan={2}></td></tr>
                </table>
            </div>
            </div>
        )
    };
}