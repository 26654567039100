import { useAxiosLoader } from "./interceptor";
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import React from 'react';

export default function GlobalLoader() {
    const [loading] = useAxiosLoader();
    const loadingCss = css`
        position: absolute;
        z-index: 10001;
        left: 50vw;
        top: 50vh;
    `;

    return (
        <div className={loading ? "loadingCssContainer" : ''}>
            <FadeLoader
                css={loadingCss}
                color={"#fff"}
                height={20}
                loading={loading}
            />
        </div>
    );
}