import * as React from 'react';
import './AsideRight.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faTimes, faPlus, faMap } from '@fortawesome/free-solid-svg-icons';
import AreaList from '../Area/AreaList';
import RouteList from '../RouteManagement/RouteList';
import { Tabs, Tab, Dropdown } from 'react-bootstrap';
import { hideMenu, toastOptions } from '../../common/Utils';
import axiosInstance from '../../common/interceptor';
import { HOST } from '../../common/constant';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

export default class AsideRight extends React.Component<{
    map: any,
    onNavi: Function,
    updateStateFunc: Function,
}, {
    isOpen: boolean,
    freeSizeOpen: boolean
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false,
            freeSizeOpen: false
        };
        this.updateStateFunc = this.updateStateFunc.bind(this);
    }
    uploadRef: any = null;
    uploadAreaRef: any = null;

    componentDidMount() {
        this.uploadRef = document.getElementById('routeImportUploadFile');
        this.uploadAreaRef = document.getElementById('areaImportUploadFile');
    }

    public getListArea(syncEnableOnly = false) {
        for (let i = 0; i <= 2; i++) {
            const element: any = this.refs["Area" + i];
            element.loadData(syncEnableOnly);
        }
    }

    public areaClickable(clickable: boolean) {
        for (let i = 0; i <= 2; i++) {
            const element: any = this.refs["Area" + i];
            element.setMapObjectClickable(clickable);
        }
    }

    public redraw(area: any, listId: any) {
        const element: any = this.refs["Area" + listId];
        element.redraw(area);
    }

    addRouteFromMap = () => {
        axiosInstance.get(HOST() + "/Form/V1/Dat/Route/CountPoly").then(res => {
            let count = res.data ? res.data.data : 99999;
            if (count < 10000) {
                this.updateStateFunc({ routeSearching: true, routeLinking: false })
            } else {
                toast.error('ルートの作成上限を超過しています。\nルートを削除。又は、無効化した後お試しください。', toastOptions);
            }
        });
    }

    private updateStateFunc(data: any, options: any = {}) {
        this.props.updateStateFunc(data, options);
        hideMenu('dropdown-route-option');
        hideMenu('dropdown-area-option');
    }

    private onNavi(path: any, data: any) {
        this.props.onNavi(path, data);
        hideMenu('dropdown-route-option');
        hideMenu('dropdown-area-option');
    }

    private getOpenClass() {
        if (this.state.isOpen) {
            return this.state.freeSizeOpen ? 'full-width' : 'show';
        } else {
            return 'hide';
        }
    }

    public setOpen(isOpen: boolean, freeSizeOpen = false) {
        this.setState({ isOpen, freeSizeOpen });
        const navLeft: any = document.getElementById('navRight');
        navLeft.style.width = '';
    }

    onFileChange(event: any) {
        if (!event.target.files[0]) return;

        const formData = new FormData();
        formData.append(
            "files",
            event.target.files[0],
            event.target.files[0].name
        );

        axiosInstance
            .post(HOST() + `/Form/V1/Dat/Route/ImportRouteDetail`, formData)
            .then(res => {
                this.updateStateFunc({ routeSearching: true }, { routeImportPath: res.data.data })
                const importFile: any = document.getElementById('routeImportUploadFile');
                importFile.value = '';
            });


    };
    onAreaFileChange(event: any) {
        hideMenu('dropdown-area-option');
        if (!event.target.files[0]) return;
        const formData = new FormData();
        formData.append(
            "files",
            event.target.files[0],
            event.target.files[0].name
        );

        axiosInstance
            .post(HOST() + `/Form/V1/Dat/Area/ImportAreaDetail`, formData)
            .then(res => {
                if (res.status != 200) {
                    const errors = JSON.parse(res.data.data);
                    for (const k in errors) {
                        console.error(`行${k}: ${errors[k]}`);
                    }
                    toast.error('インポートファイルの内容に誤りがあり、取り込むことができません。', toastOptions);
                } else {
                    var data = res.data.data;
                    if (!data || data.length < 2) {
                        toast.error('ファイルの要素が不足しています。2行以上入力してください。', toastOptions);
                    } else {
                        this.props.updateStateFunc({ areaDrawing: true }, { areaImportPath: data })
                    }
                }
                const importFile: any = document.getElementById('areaImportUploadFile');
                importFile.value = '';
            });
    };

    handleTabSelect(tabKey: any) {
        if (tabKey === 'area') {
            this.props.updateStateFunc({ routeSearching: false });
        } else if (tabKey === 'route') {
            this.props.updateStateFunc({ areaDrawing: false });
        }
    }

    public setWidth(width: number) {
        const navLeft: any = document.getElementById('navRight');
        this.setState({ freeSizeOpen: false });
        navLeft.style.width = width + 'px';
    }

    public handleAddClick(areaDrawing: boolean) {
        //check area limit
        var user: any = JSON.parse(Cookies.get('user') || "{}");
        if (user && user.siteId) {
            axiosInstance.get(`${HOST()}/Form/V1/Dat/Setting/CheckAreaLimitExceeded?sysID=${user.siteId}`).then((res) => {
                if (res.data.data.isAreaLimitExceeded) {
                    toast.error(`エリア有効上限数を超過しています。エリアの無効化をしてください。						
                    エリア有効上限数を変更したい場合は担当者へお問い合わせください。`);
                } else {
                    this.updateStateFunc({ areaDrawing: areaDrawing })

                    if (!areaDrawing) {
                        this.uploadAreaRef.click();
                    }
                }
            })
        }
    }

    public render() {
        return (
            <aside className={"text-center " + this.getOpenClass()} id="navRight">
                <Tabs defaultActiveKey="area" id="navRightTab" onSelect={(e: any) => this.handleTabSelect(e)}>
                    <Tab eventKey="area" title="エリア">
                        <Dropdown>
                            <div className="area-title">
                                <FontAwesomeIcon icon={faMapMarkedAlt}></FontAwesomeIcon>
                                <span>エリア一覧</span>
                                <Dropdown.Toggle variant="link" id="dropdown-area-option">
                                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                </Dropdown.Toggle>
                            </div>
                            <div className="listChild">
                                <AreaList ref="Area0" areaType={0} map={this.props.map} onNavi={this.props.onNavi} />
                                <AreaList ref="Area1" areaType={1} map={this.props.map} onNavi={this.props.onNavi} />
                                <AreaList ref="Area2" areaType={2} map={this.props.map} onNavi={this.props.onNavi} />
                            </div>
                            <Dropdown.Menu>
                                <a className="dropdown-item" href="#" onClick={() => this.handleAddClick(true)}>
                                    マップで作成
                                </a>
                                <a className="dropdown-item" href="#" onClick={() => this.handleAddClick(false)}>
                                    ファイルをインポート
                                </a>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Tab>
                    <Tab eventKey="route" title="ルート">
                        <Dropdown>
                            <div className="area-title">
                                <FontAwesomeIcon icon={faMapMarkedAlt}></FontAwesomeIcon>
                                <span>ルート一覧</span>
                                <Dropdown.Toggle variant="link" id="dropdown-route-option">
                                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                </Dropdown.Toggle>
                            </div>
                            <div className="listChild">
                                <RouteList ref="RouteList" map={this.props.map} onNavi={this.props.onNavi} />
                            </div>
                            <div>
                                <Dropdown.Menu>
                                    <a className="dropdown-item" href="#" onClick={this.addRouteFromMap}>マップで作成</a>
                                    <a className="dropdown-item" href="#" onClick={() => {
                                        this.onNavi('/bottomTab', 'operationHistory');
                                        this.updateStateFunc({ routeSearching: false, routeLinking: false });
                                    }}>走った所から</a>
                                    <a className="dropdown-item" href="#" onClick={() =>
                                        this.updateStateFunc({ routeSearching: true, routeLinking: true })
                                    }>既存ルートをグループ化</a>
                                    <a className="dropdown-item" href="#" onClick={() => {
                                        this.uploadRef.click();
                                        this.updateStateFunc({ routeSearching: false, routeLinking: false });
                                    }}>ファイルをインポート</a>
                                </Dropdown.Menu>
                            </div>
                        </Dropdown>
                    </Tab>
                </Tabs>
                <input type="file" id="routeImportUploadFile" onChange={(e: any) => this.onFileChange(e)} style={{ display: 'none' }} />
                <input type="file" id="areaImportUploadFile" onChange={(e: any) => this.onAreaFileChange(e)} style={{ display: 'none' }} />
            </aside>
        );
    }
}
