import './DragAndDrop.css'
import React from 'react'
import { isMobile } from '../Utils';

export default function DragAndDrop(props: any) {

    function onDragStart(event: any) {
        event.dataTransfer.dropEffect = "move";
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setData("text/plain", event.target.dataset.index);
        event.dataTransfer.setDragImage(event.target, 20, 20);
    }

    function onDragOver(event: any) {
        event.stopPropagation();
        event.preventDefault();

        var rect = event.target.getBoundingClientRect();
        var height = rect.height;

        let target = event.target;
        while (target.tagName.toLowerCase() !== 'table') {
            target = target.parentNode;
        }
        var y = event.clientY - rect.top;  //y position within the element.
        const active = target.parentNode.querySelectorAll('tr[class*="border-"') || [];
        active.forEach((element: any) => {
            element.classList.remove('border-top');
            element.classList.remove('border-bottom');
        });

        target = event.target;
        while (target.tagName.toLowerCase() !== 'tr') {
            target = target.parentNode;
        }
        if (y < height / 2) {
            target.classList.add("border-top");
        } else {
            target.classList.add("border-bottom");
        }
    }

    function onDragEnd(event: any) {
        let target = event.target;
        while (target.tagName.toLowerCase() !== 'table') {
            target = target.parentNode;
        }
        const active = target.parentNode.querySelectorAll('tr[class*="border-"') || [];
        active.forEach((element: any) => {
            element.classList.remove('border-top');
            element.classList.remove('border-bottom');
        });
    }

    function onDropHandler(event: any) {
        var rect = event.target.getBoundingClientRect();
        var height = rect.height;
        var y = event.clientY - rect.top;  //y position within the element.
        const currentIndex = Number(event.dataTransfer.getData("text"));
        const targetIndex = Number(event.currentTarget.dataset.index);
        const list = props.dataSource;
        const moveItem = list.splice(currentIndex, 1);
        if (y < height / 2) {
            list.splice(targetIndex + (currentIndex > targetIndex ? 0 : -1), 0, moveItem[0]);
        } else {
            list.splice(targetIndex + (currentIndex > targetIndex ? 1 : 0), 0, moveItem[0]);
        }
        list.forEach((e: any, i: number) => e.DisplayOrder = i + 1);
        const emitData: any = {};
        emitData[props.listName] = list;
        props.onchange(emitData);
    }

    //////////////////////////////////////////////////////////
    let uuid: any = Date.now();
    let dragMode = false;
    let target: any = document.createElement('div');
    let sourceIndex = 0;
    let timer: any = 0;
    function onTouchStart(evt: any) {
        let sourceNode: any = evt.currentTarget.querySelector('tr');
        let navRight: any = document.getElementById('navRight');
        let tr = sourceNode.cloneNode(true);
        sourceIndex = tr.dataset.index;
        let table: any = document.createElement('table');

        // header td
        let headerTd: any = evt.currentTarget.parentNode.childNodes[0].querySelectorAll('th');
        let targetTd: any = tr.querySelectorAll('td');
        targetTd.forEach((e: any, index: any) => {
            e.style.width = headerTd[index].style.width;
        });

        table.style.width = sourceNode.clientWidth + "px";
        table.appendChild(tr);

        target.innerHTML = '';
        target.appendChild(table);
        target.style.position = 'absolute';
        target.style.background = '#03a9f4';
        target.style.width = navRight.clientWidth + "px";
        target.style.overflowY = 'hidden';
        target.id = uuid;

        timer = window.setTimeout(function () {
            dragMode = true;
            document.body.appendChild(target);
        }, 500);
    }

    function onTouchEnd(evt: any) {
        if (timer) {
            window.clearTimeout(timer);
            timer = 0;
        }

        dragMode = false;
        let object: any = document.getElementById(uuid);
        object && object.remove();

        let rect = evt.currentTarget.getBoundingClientRect();
        let event = (typeof evt.originalEvent === 'undefined') ? evt : evt.originalEvent;
        let touch = event.touches[0] || event.changedTouches[0];
        let target: any = document.elementFromPoint(touch.clientX, touch.clientY);
        let parentTable: any = document.getElementById(props.tableId);
        
        if (!parentTable.contains(target)) {
            return;
        }

        var y = touch.clientY - rect.top;  //y position within the element.
        const currentIndex = Number(sourceIndex);
        const targetIndex = Number(target.parentElement.dataset.index) || 0;
        const list = props.dataSource;
        const moveItem = list.splice(currentIndex, 1);
        if (y < rect.height / 2) {
            list.splice(targetIndex + (currentIndex > targetIndex ? 0 : -1), 0, moveItem[0]);
        } else {
            list.splice(targetIndex + (currentIndex > targetIndex ? 1 : 0), 0, moveItem[0]);
        }
        list.forEach((e: any, i: number) => e.DisplayOrder = i + 1);
        const emitData: any = {};
        emitData[props.listName] = list;
        props.onchange(emitData);
    }

    function onTouchMove(e: any) {
        if (dragMode) {
            var evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
            var touch = evt.touches[0] || evt.changedTouches[0];
            target.style.left = (document.body.offsetWidth - target.offsetWidth) + "px";
            if (touch.clientY + target.offsetHeight > document.body.offsetHeight) {
                target.style.top = (document.body.offsetHeight - target.offsetHeight) + "px";
            } else {
                target.style.top = touch.clientY + "px";
            }
        }
    }

    return isMobile() ?
        (
            <tbody
                onTouchStart={e => onTouchStart(e)}
                onTouchEnd={e => onTouchEnd(e)}
                onTouchMove={e => onTouchMove(e)}
            >
                {props.children}
            </tbody>
        ) :
        (
            <tbody draggable="true"
                data-index={props.index}
                onDrop={e => onDropHandler(e)}
                onDragStart={e => onDragStart(e)}
                onDragEnd={e => onDragEnd(e)}
                onDragOver={e => onDragOver(e)}>
                {props.children}
            </tbody>
        );
}
