
import React from "react";
import "./EditTerminalItem.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import { toastOptions } from "../../common/Utils";
import { toast } from "react-toastify";

import image1File from '../../assets/img/ダンプ車_未運行.png'
import image2File from '../../assets/img/ダンプ車_積込.png'
import image3File from '../../assets/img/ダンプ車_荷降し.png'
import image4File from '../../assets/img/ミキサー車_黒.png'
import image5File from '../../assets/img/ミキサー車_赤.png'
import image6File from '../../assets/img/ミキサー車_青.png'
import image7File from '../../assets/img/ミキサー車_黄.png'

const image1 = 'ダンプ車_未運行';
const image2 = 'ダンプ車_積込';
const image3 = 'ダンプ車_荷降し';
const image4 = 'ミキサー車_黒';
const image5 = 'ミキサー車_赤';
const image6 = 'ミキサー車_青';
const image7 = 'ミキサー車_黄';

export default class EditTerminalIcon extends React.Component<
    {
        onNavi: Function,
        CliID: any
    },
    {
        MaxIcon1: any,
        MaxIcon2: any,
        MaxIcon3: any,
        MinIcon1: any,
        MinIcon2: any,
        MinIcon3: any,
    }> {
    constructor(props: any) {
        super(props)
        this.state = {
            MaxIcon1: getDefault('MaxIcon1'),
            MaxIcon2: getDefault('MaxIcon2'),
            MaxIcon3: getDefault('MaxIcon3'),
            MinIcon1: getDefault('MinIcon1'),
            MinIcon2: getDefault('MinIcon2'),
            MinIcon3: getDefault('MinIcon3'),
        }
    };

    componentDidMount() {
        if (this.props.CliID) {
            this.onFetchData();
        } else {
            this.goBack(false);
        }
    }

    private onFetchData() {
        var api = "/Form/V1/Dat/Terminal/GetTerminalIcon?CliID=" + this.props.CliID
        axiosInstance
            .get(HOST() + api)
            .then(response => {
                const terminal = response.data.data || {};
                this.setState({
                    MaxIcon1: terminal.maxIcon1 || getDefault('MaxIcon1'),
                    MaxIcon2: terminal.maxIcon2 || getDefault('MaxIcon2'),
                    MaxIcon3: terminal.maxIcon3 || getDefault('MaxIcon3'),
                    MinIcon1: terminal.minIcon1 || getDefault('MinIcon1'),
                    MinIcon2: terminal.minIcon2 || getDefault('MinIcon2'),
                    MinIcon3: terminal.minIcon3 || getDefault('MinIcon3'),
                })
            })
    }

    private goBack(refresh: boolean) {
        if (refresh) this.props.onNavi('/', 'terminalList');
        else this.props.onNavi('/');
    }

    public onCancel(path: any) {
        this.goBack(false);
    }

    public handleSubmitAdd() {
        var body = {
            CliID: this.props.CliID,
            MaxIcon1: this.state.MaxIcon1,
            MaxIcon2: this.state.MaxIcon2,
            MaxIcon3: this.state.MaxIcon3,
            MinIcon1: this.state.MinIcon1,
            MinIcon2: this.state.MinIcon2,
            MinIcon3: this.state.MinIcon3,
        };

        axiosInstance
            .put(HOST() + "/Form/V1/Dat/Terminal/UpdateTerminalIcon", body)
            .then(res => {
                if (res.status == 200 && res.data) {
                    toast.success('更新に成功。', toastOptions);
                    this.goBack(true);
                    document.querySelectorAll('.TerminalList').forEach((e: any) => e.click()); // reload all terminal
                }
            })
    }

    public render() {
        return (
            <div>
                <div className="box edit-terminal">
                    <div className="box-title d-flex justify-content-between align-items-center mb-4">
                        <span className="page-header">端末登録</span>
                        <div className="buttonEdit">
                            <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.onCancel(false)} >キャンセル</button>
                            <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.handleSubmitAdd()} >保存</button>
                        </div>
                    </div>
                    <div className="row pb-3">
                        <div className="col-md-6">
                            <strong>車両アイコン</strong>
                            <EditTerminalIconEach type={1} label="未運行時" value={this.state.MaxIcon1} onChanged={(e: string) => this.setState({MaxIcon1: e})}/>
                            <EditTerminalIconEach type={1} label="積込み時" value={this.state.MaxIcon2} onChanged={(e: string) => this.setState({MaxIcon2: e})}/>
                            <EditTerminalIconEach type={1} label="荷降し時" value={this.state.MaxIcon3} onChanged={(e: string) => this.setState({MaxIcon3: e})}/>
                        </div>
                        <div className="col-md-6">
                            <strong>小アイコン</strong>
                            <EditTerminalIconEach type={2} label="未運行時" value={this.state.MinIcon1} onChanged={(e: string) => this.setState({MinIcon1: e})}/>
                            <EditTerminalIconEach type={2} label="積込み時" value={this.state.MinIcon2} onChanged={(e: string) => this.setState({MinIcon2: e})}/>
                            <EditTerminalIconEach type={2} label="荷降し時" value={this.state.MinIcon3} onChanged={(e: string) => this.setState({MinIcon3: e})}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

export function getDefault(key: string) {
    switch (key) {
        case 'MaxIcon1':
            return image1;
        case 'MaxIcon2':
            return image2;
        case 'MaxIcon3':
            return image3;
        case 'MinIcon1':
            return 'gray';
        case 'MinIcon2':
            return 'red';
        case 'MinIcon3':
            return 'green';
    }

    return ''
}

export function getImageFromKey(key: string, fileOnly = false) : any {
    switch (key) {
        case image1:
            return fileOnly ? image1File : (<img src={image1File} />);
        case image2:
            return fileOnly ? image2File : (<img src={image2File} />);
        case image3:
            return fileOnly ? image3File : (<img src={image3File} />);
        case image4:
            return fileOnly ? image4File : (<img src={image4File} />);
        case image5:
            return fileOnly ? image5File : (<img src={image5File} />);
        case image6:
            return fileOnly ? image6File : (<img src={image6File} />);
        case image7:
            return fileOnly ? image7File : (<img src={image7File} />);
        case 'black':
        case 'gray':
        case 'brown':
        case 'red':
        case 'orange':
        case 'yellow':
        case 'green':
        case 'blue':
        case 'purple':
            return (
                <svg>
                    <circle cx="32" cy="24" r="16" fill={key} />
                </svg>
            )
    }

    return ''
}

class EditTerminalIconEach extends React.Component<
    {
        type: number,
        label: string
        value: string,
        onChanged: Function
    }> {
    constructor(props: any) {
        super(props)
    };

    getOptions(): Array<any> {
        if (this.props.type == 1) {
            return [
                {label: 'ダンプ車_未運行', image: image1},
                {label: 'ダンプ車_積込み', image: image2},
                {label: 'ダンプ車_荷降し', image: image3},
                {label: 'ミキサー車_黒', image: image4},
                {label: 'ミキサー車_赤', image: image5},
                {label: 'ミキサー車_青', image: image6},
                {label: 'ミキサー車_黄', image: image7}
            ]
        } else if (this.props.type == 2) {
            return [
                {label: '黒', image: 'black'},
                {label: '灰色', image: 'gray'},
                {label: '茶色', image: 'brown'},
                {label: '赤', image: 'red'},
                {label: 'オレンジ', image: 'orange'},
                {label: '黄色', image: 'yellow'},
                {label: '緑', image: 'green'},
                {label: '青', image: 'blue'},
                {label: '紫', image: 'purple'},
            ]
        }
        return [];
    }

    public render() {
        return (
            <div className="icon-select-container">
                <div className="contain-left">
                    <div className="label">{this.props.label}</div>
                    <div className="icon-preview">
                        {getImageFromKey(this.props.value)}
                    </div>
                </div>
                <div className="contain-right">
                    {
                        this.getOptions().map(opt => (
                            <div key={this.props.label + opt.image}>
                                <input type="radio" 
                                    name={`${this.props.label}_${this.props.type}`} 
                                    id={this.props.label + opt.image} 
                                    checked={this.props.value == opt.image}
                                    onChange={() => this.props.onChanged(opt.image)}
                                />
                                <label htmlFor={this.props.label + opt.image}>{opt.label}</label>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

