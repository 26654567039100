import "./AreaManagement.css";
import React from "react";
import { haversineDistance, toastOptions } from "../../common/Utils";
import { HOST } from "../../common/constant";
import axiosInstance from "../../common/interceptor";
import { toast } from "react-toastify";

export default class AreaManagement extends React.Component<{
    onNavi: Function,
    map: any,
    areaDrawing: boolean,
    updateStateFunc: Function
}, {
    pathEncoded: any[],
    saving: boolean,
    polyAllow: boolean
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            pathEncoded: [],
            saving: false,
            polyAllow: true
        };
    }

    clickListener: any = null;
    markerList: any[] = [];
    drawedLine: any;

    componentDidMount() {

	}

    public turnOnDrawingMode() {
        const _this = this;
        axiosInstance.get(HOST() + "/Form/V1/Dat/Area/CountPoly").then(res => {
            let count = res.data ? res.data.data : 9999;
            this.setState({ polyAllow: count < 160 });
        });
        this.clickListener = window.google.maps.event.addListener(this.props.map, 'click', function (event: any) {
            _this.placeMarker(event.latLng);
        });
    }

    public turnOffDrawingMode() {
        window.google.maps.event.removeListener(this.clickListener);
        this.clearAllState();
    }

    private placeMarker(position: any) {
        if (!this.state.polyAllow && this.markerList.length >= 2) {
            toast.error('多角形エリアの作成上限を超過しています。\n多角形エリアを削除後お試しください。', toastOptions);
            return;
		}
        var marker = new window.google.maps.Marker({
            position: position,
            map: this.props.map
        });
        this.markerList.push(marker);
        this.drawPolyArea();
        this.setState({ saving: this.markerList.length >= 2 })
    }

    private clearAllState() {
        this.setState({
            pathEncoded: [],
            saving: false
        });
        this.markerList.forEach(e => e.setMap(null));
        this.markerList = [];
        if (this.drawedLine != null) {
            this.drawedLine.setMap(null);
            this.drawedLine = null;
        }
    }

    private close() {
        this.turnOffDrawingMode();
        this.props.updateStateFunc({ areaDrawing: false, forceArea: true });
    }

    private drawPolyArea(focus = false) {
        if (this.drawedLine != null) {
            this.drawedLine.setMap(null);
            this.drawedLine = null;
        }

        var pathPlanCoordinates = [];
        var pathEncoded = [];
        var bounds = new window.google.maps.LatLngBounds();
        for (var i = 0; i < this.markerList.length; i++) {
            pathPlanCoordinates.push({ lat: this.markerList[i].position.lat(), lng: this.markerList[i].position.lng() });
            pathEncoded.push({
                PosID: i,
                PosLat: this.markerList[i].position.lat(),
                PosLon: this.markerList[i].position.lng()
            })

            bounds.extend(pathPlanCoordinates[i]);
        }

        this.setState({ pathEncoded });

        if (this.markerList.length === 2) {
            const radius = haversineDistance(this.markerList[0], this.markerList[1]);
            this.drawedLine = new window.google.maps.Circle({
                strokeColor: '#ff0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#ff0000',
                fillOpacity: 0.35,
                clickable: false,
                center: pathPlanCoordinates[0],
                radius: radius
            });
            this.drawedLine.setMap(this.props.map);
        } else if (this.markerList.length > 2 ) {
            this.drawedLine = new window.google.maps.Polygon({
                path: pathPlanCoordinates,
                geodesic: true,
                strokeColor: '#ff0000',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                fillColor: '#ff0000',
                clickable: false,
                fillOpacity: 0.35
            });
            this.drawedLine.setMap(this.props.map);
        }

        if (focus) {
            if (this.markerList.length === 2) {
                this.props.map.fitBounds(this.drawedLine.getBounds());
            } else if (this.markerList.length > 2) {
                this.props.map.fitBounds(bounds);
			}
        }
    }

    public drawAreaImportPath(areaImportPath: any) {
        if (!Array.isArray(areaImportPath)) return;

        this.markerList = [];
        areaImportPath.forEach((e: any) => {
            this.markerList.push({
                position: {
                    lat: () => e.posLat,
                    lng: () => e.posLon
                },
                setMap: () => {}
            })
        });

        this.drawPolyArea(true);
        this.setState({ saving: this.markerList.length >= 2 })
    }

    public undoLatestDraw() {
        this.markerList[this.markerList.length - 1].setMap(null);
        this.markerList.splice(this.markerList.length - 1, 1);
        this.drawPolyArea(true);
    }

    private saveArea() {
        if (this.markerList.length < 2) {
            return;
        }

        let isCircle = this.markerList.length === 2;
        let circleLongitude = 0;
        let circleLatitude = 0;
        let circleRadius = 0;

        if (isCircle) {
            circleLongitude = this.markerList[0].position.lng();
            circleLatitude = this.markerList[0].position.lat();
            circleRadius = haversineDistance(this.markerList[0], this.markerList[1]);
        }
        const naviData = {
            pathEncoded: this.state.pathEncoded,
            areaId: 0,
            circleLongitude,
            circleLatitude,
            circleRadius: Math.round(circleRadius),
        };
        this.props.onNavi('/editAreaManagement', naviData);
        this.close();
    }

    public render() {
        return (
            <div>
                <button
                    className="btn btn-primary btn-md mar-5 btn-120"
                    onClick={() => this.close()}
                    style={{ display: this.props.areaDrawing ? 'inline-block' : 'none' }}
                >
                    キャンセル
                </button>
                <button
                    className="btn btn-primary btn-md mar-5 btn-120"
                    onClick={() => this.saveArea()}
                    disabled={!this.state.saving}
                    style={{ display: this.props.areaDrawing ? 'inline-block' : 'none' }}
                >
                    保存
                </button>
                <button
                    className="btn btn-primary btn-md mar-5 btn-120"
                    onClick={() => this.undoLatestDraw()}
                    disabled={!this.state.saving}
                    style={{ display: this.props.areaDrawing ? 'inline-block' : 'none' }}
                >
                    戻る
                </button>
            </div >
        );
    }
}