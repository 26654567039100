
import React, {useState , forwardRef} from "react";
import "./Setting.css"
import "./CargoSetting.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import Cookies from 'js-cookie';
import {useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faClosedCaptioning, faEdit, faEnvelope, faExternalLinkAlt, faExternalLinkSquareAlt, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from "reactstrap";
import { toastOptions } from "../../common/Utils";
import icPlusGreen from "../../assets/img/ic_plus_green.png";
import { toast } from "react-toastify";
import { throws } from "assert";


export default class CargoSetting extends React.Component<{
    onNavi: Function
}, {
    listData: any[],
    listRoute: any[],
    listRoutesTemp: any[],
    showAddComponent: boolean,
    error: any,
    cargoNm: any,
    cargoID: any,
    routeID: any,
    using: boolean,
    selectedIdx: any,
    sectionTitle: any,
    sectionButton: any,
    showRouteList: boolean,
}> {
    user: any;
    routeNames: any;
    constructor(props: any) {
        super(props)
        this.state = {
            listData: [],
            listRoute: [],
            listRoutesTemp: [],
            showAddComponent: false,
            error: '',
            cargoID: 0,
            routeID: '',
            cargoNm: '',
            using: true,
            sectionTitle: '新規登録',
            sectionButton: '登録',
            showRouteList: false,
            selectedIdx: 0
        }
        
        this.getlistData = this.getlistData.bind(this);
        this.handleSubmitAdd = this.handleSubmitAdd.bind(this);
        this.onEditItem = this.onEditItem.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.onAddItem = this.onAddItem.bind(this);
        this.tickIcon = this.tickIcon.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onCloseBlockRouteList = this.onCloseBlockRouteList.bind(this);
        this.routeNames = '';

        const userData = Cookies.get('user');
        if(userData) {
            this.user = JSON.parse(userData ? userData : '{}');
        }
    };

    componentDidMount() {
        this.getlistData();
    }
  
    public onCancel(path: any) {
        if(this.state.showAddComponent) {
            this.setState({showAddComponent: false});
        }
        else {
            this.props.onNavi('/');
        }
    }

    private getRouteListAll() {
        var api = "/Form/V1/Dat/Route/ListAll?userid=" + this.user.id
        axiosInstance
        .get(HOST() + api)
        .then(response => {
            const routes = response.data.data;
            this.setState({ listRoute: routes });
        })
        .catch(error => {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, toastOptions);
            }
        });
        // const routes = [
        //     {"routeID": 1, "routeNm": "route 1"},
        //     {"routeID": 2, "routeNm": "route 2"},
        //     {"routeID": 3, "routeNm": "route 3"},
        //     {"routeID": 4, "routeNm": "route 4"},
        //     {"routeID": 5, "routeNm": "route 5"},
        //     {"routeID": 6, "routeNm": "route 6"},
        //     {"routeID": 7, "routeNm": "route 7"},
        //     {"routeID": 8, "routeNm": "route 8"},
        //     {"routeID": 9, "routeNm": "route 9"},
        // ];
        // this.setState({ listRoute: routes });
    }

    private getRouteByListID(listID: String) {
        // var api = "/Form/V1/Dat/Route/GetRouteByListID?listID=" + listID
        // axiosInstance
        // .get(HOST() + api)
        // .then(response => {
        //     const routes = response.data.data;
        //     this.setState({ routes: routes });
        // })
        // .catch(error => {
        //     if (error.response && error.response.data) {
        //         toast.error(error.response.data.message, toastOptions);
        //     }
        // });
    }

    private getlistData() {
        this.getRouteListAll();
        // const items = [
        //     {"cargoID": 1, "cargoNm": "cargo 1", "using": 0, "routeID": "1/3/5"},
        //     {"cargoID": 2, "cargoNm": "cargo 2", "using": 1, "routeID": "2/4/6"},
        //     {"cargoID": 3, "cargoNm": "cargo 3", "using": 0, "routeID": "8"},
        //     {"cargoID": 4, "cargoNm": "cargo 4", "using": 1, "routeID": "7/9"},
        //     {"cargoID": 5, "cargoNm": "cargo 5", "using": 0, "routeID": "2/8"},
        //     {"cargoID": 6, "cargoNm": "cargo 6", "using": 0, "routeID": "5/9"},
        //     {"cargoID": 7, "cargoNm": "cargo 7", "using": 1, "routeID": "1/9/4"},
        //     {"cargoID": 8, "cargoNm": "cargo 8", "using": 0, "routeID": "2/5/8"},
        //     {"cargoID": 9, "cargoNm": "cargo 9", "using": 0, "routeID": "3/6/9"},
        //     {"cargoID": 10, "cargoNm": "cargo 10", "using": 1, "routeID": "4/7/1"},
        //     {"cargoID": 11, "cargoNm": "cargo 11", "using": 1, "routeID": "3/8"},
        //     {"cargoID": 12, "cargoNm": "cargo 12", "using": 0, "routeID": "4/9"},
        // ];
        // this.setState({ listData: items });
        
        var api = "/Form/V1/Dat/Cargo/ListAll?userid=" + this.user.id
        axiosInstance
        .get(HOST() + api)
        .then(response => {
            const items = response.data.data;
            this.setState({ listData: items });
           
        })
        .catch(error => {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, toastOptions);
            }
        });
    }

    public onNavi(path: any) {
        
    }

    public close() {
        this.props.onNavi('/');
    }

    public onDeleteItem(object:any) {
        axiosInstance
            .delete(HOST() + "/Form/V1/Dat/Cargo/Delete?carID=" + object.cargoID)
            .then((res: any) => {
                var message = "Delete failed."
                if(res.data) {
                    const result = res.data.result;
                    if (result) {
                        message = '積込み種別を削除しました。';
                        this.getlistData();
                        toast.success(message, toastOptions);
                        return;
                    }
                    else message = res.data.message;
                }
                toast.error(message, toastOptions);
            }).catch(e => {
                toast.error("Delete failed: " + e, toastOptions);
            });
    }

    private updateListRouteTemp(routeID:String) {
        var listRoute = this.state.listRoute;
        
        if(routeID == null || routeID.length == 0) {
            for(var item of listRoute) item.checked = false;
        }
        else {
            var vals = routeID.split("/");

            const listIDs = "/" + routeID + "/"

            if(vals.length == 0) return "";

            for(var item of listRoute) {
                if(listIDs.includes( "/" + item.routeID + "/")){
                    item.checked = true;
                }
                else {
                    item.checked = false;
                }
            }
        
        }
        this.setState({listRoutesTemp: listRoute});
    }

    public onEditItem(object:any, index: number) {
        this.setItemData(object);
        this.setState({selectedIdx: index});

        this.setState({showAddComponent: true});
        this.setState({showRouteList: false});
    }

    public onAddItem() {
        this.setItemData(null);
        
        this.setState({showAddComponent: true});
        this.setState({showRouteList: false});
    }

    setItemData(item: any) {
        this.setState({error: ''});
        if(item && item.cargoID > 0) {
            this.setState({cargoID: item.cargoID});
            this.setState({cargoNm: item.cargoNm});
            this.setState({using: (item.using == 1)});
            this.setState({routeID: item.routeID});
            this.updateListRouteTemp(item.routeID);
            this.routeNames = this.listRouteName(item.routeID);

            this.setState({sectionTitle: '編集'});
            this.setState({sectionButton: '編集'});
        }
        else {
            this.setState({cargoID: 0});
            this.setState({cargoNm: ''});
            this.setState({using: false});
            this.setState({routeID: ""});
            this.updateListRouteTemp("");
            this.routeNames = '';

            this.setState({sectionTitle: '新規登録'});
            this.setState({sectionButton: '登録'});
        }
    }
 
    public handleSubmitAdd() {
        var isValid = this.state.cargoNm && this.state.cargoNm.length > 0
        if(!isValid) {
            this.setState({error: "積込み種別を入力してください。"});
            return
        }
        this.setState({error: ""});

        // var routeIDs=[];
        // for(var item of this.state.listRoutesTemp){
        //     if(item.using) {
        //         routeIDs.push(item); 
        //     }
        // }
        var body = {
            cargoID: this.state.cargoID,
            cargoNm: this.state.cargoNm,
            using: this.state.using? 1: 0,
            routeID: this.state.routeID
        };
        
        axiosInstance
            .put(HOST() + "/Form/V1/Dat/Cargo/Update", body)
            .then(res => {
                if(res.status == 200 && res.data) {
                    if(this.state.cargoID > 0) {
                        this.addItemToListView(body);
                        toast.success("正常に編集されました", toastOptions);
                    }
                    else {
                        this.getlistData();
                        this.setState({showAddComponent: false});
                        toast.success("積込み種別を登録しました。", toastOptions);
                    }
                }
                else {
                    toast.error("保存に失敗しました。", toastOptions);
                }
            })
    }
 
    addItemToListView(body: any) {
        // var index = this.page*this.PAGING + this.state.index;
        var index = this.state.selectedIdx;
        this.setState({
            listData: [
                ...this.state.listData.slice(0, index),
                body,
                ...this.state.listData.slice(index + 1)
            ]
            });
        
        // this.calculatePaging();
        // this.onPageChange(this.page);
        this.setState({showAddComponent: false});
    }

    public onChangeRouteStatus(index: number, value:boolean) {
        var updateList = this.state.listRoutesTemp
        updateList[index].checked = value
        this.setState({listRoutesTemp: updateList});        
    }
    
    private onCloseBlockRouteList() {
        var ids = [];
        var names = [];
        for(var item of this.state.listRoutesTemp) {
            if(item.checked == 1) {
                ids.push(item.routeID);
                names.push(item.routeNm);
            }
        }
        const routeID = ids.join("/");
        this.setState({routeID: routeID});
        this.routeNames = names.join("/");

        this.setState({showRouteList: false});
    }

    private tickIcon(value:any) {
        if(value === 1 || value === true) {
            return <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
        }
        return <span></span>
    }

    private listRouteName(listID: String) {
        var vals = listID.split("/");
        const listIDs = "/" + listID + "/"

        if(vals.length == 0) return "";

        var listNames = []
        for(var item of this.state.listRoute) {
            if(listIDs.includes("/" + item.routeID+ "/")){
                listNames.push(item.routeNm);
            }
            if(listNames.length == vals.length) break;
        }
        return listNames.join("/");
    }

    public render() {
        return (
            <div>
            <div className="box loading-type"  style={{padding: '15px'}}>
                    <div className="box-title d-flex justify-content-between align-items-center">
                <span className="page-header">積込み種別設定</span>
                <div className="buttonEdit">
                    <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.onCancel} >キャンセル</button>
                </div>
            </div>
            
            <header className="th-header">
                        <span style={{position:"absolute", marginTop:"10px",left: "45%", fontSize:"15px"}}>積込み種別一覧</span>
                        <Button onClick={this.onAddItem} className="align-right plus-button button-img">
                        <img src={icPlusGreen} width="27" height="27" className="border rounded-circle"/>
                        </Button>
            </header>    
            <table id='cargo' style={{marginBottom:"10px"}}>
                <tbody>
                    <tr>
                        <th style={{width:'150px'}}>積込み種別</th>
                        <th style={{width:'150px'}}>有効</th>
                        <th style={{width:'300px'}}>ルート</th>
                        <th style={{width:'100px'}}>編集</th>
                    </tr>
                   
                    {this.state.listData.map( (item, index) => (
                            <tr key={index}>
                                <td>{item.cargoNm}</td>
                                <td>{this.tickIcon(item.using)}</td>
                                <td>{this.listRouteName(item.routeID)}</td>
                                <td><Button onClick={() => this.onEditItem(item, index)} className="button-img">
                                    <FontAwesomeIcon icon={faEdit} color="#000000"></FontAwesomeIcon>
                                    </Button>
                                    <Button onClick={() => this.onDeleteItem(item)} className="button-img">
                                        <FontAwesomeIcon icon={faTrash} color="#000000"></FontAwesomeIcon>
                                    </Button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <div id="addComponent" style={{ display: this.state.showAddComponent ? "block" : "none" }}>
            <form>
                <header className="th-header bg-blue">
                    <span style={{position:"absolute", marginTop:"10px",left: "45%", fontSize:"15px"}}>{this.state.sectionTitle}</span>
                </header>                      
                <table>
                    <tbody>
                        <tr>
                            <th style={{width:'150px'}}>積込み種別</th>
                            <th style={{width:'150px'}}>有効</th>
                            <th style={{width:'300px'}}>ルート</th>
                            <th style={{width:'100px'}}>登録</th>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'start' }}>
                                <input name="cargoNm" value={this.state.cargoNm} onChange={e => this.setState({ cargoNm: e.target.value })} style={{ width: "100%", height: "100%" }} maxLength={ 50 } />
                            </td>
                            <td>
                                <input name="using" type="checkbox" checked={this.state.using} onChange={e => this.setState({using: e.target.checked})}/>
                            </td>
                            <td>
                                <input name="routeID" placeholder='ルート一覧' value={this.routeNames} style={{width:"100%", height:"100%"}}
                                        onClick={e => this.setState({showRouteList: true})}/>
                            </td>
                            <td>
                                {/* <input type="submit" value="登録" className="cancel-button register"/> */}
                                <Button onClick={this.handleSubmitAdd} className="btn btn-primary btn-sm register">{this.state.sectionButton}</Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="box-routes-list"  style={{display: this.state.showRouteList? 'block': 'none'}}>
                    <li style={{height: '45px'}}>
                            <span style={{fontWeight:'bold', position:'absolute', top:'10px'}}>ルート一覧</span>
                                <Button onClick={this.onCloseBlockRouteList} style={{position:'absolute', right:'3px', top:'3px'}}>
                                    <FontAwesomeIcon icon={faCheck} color="#000000"></FontAwesomeIcon>
                                </Button>
                    </li>
                    <ul className="dropdown-menu box-routes-chk" style={{paddingTop: '0px', height:'200px', overflow:'hidden', overflowY:'scroll'}}>
                        
                                    {this.state.listRoutesTemp.map((item, index) => (
                                        <li style={{height: 'auto'}}>
                                <input name={item.routeID} type="checkbox" checked={item.checked} onChange={(e) => this.onChangeRouteStatus(index, e.target.checked)}/> 
                                &nbsp;{item.routeNm}
                            </li>
                        ))
                        }
                   </ul>
                </div>
                <br/><span className="errClass">{this.state.error}</span>
                </form>
            </div>
            </div>
        </div>
        )
    };
}