
import React, { useState, forwardRef } from "react";
import "./Setting.css"
import "./DetailSetting.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Button } from "reactstrap";
import { isBlank, toastOptions, getValidNumber } from "../../common/Utils";
import { toast } from "react-toastify";
import eventBus from "../../common/EventBus"

export default class DetailSetting extends React.Component<{
    onNavi: Function
}, {
    data: any,
    error: any,
    updateInterval: any,
    vallidRange: any,
    vallidRangeBk: any,
    recAutoCreate: any,
    sendMailInterval: any,
    useLocateRoute: boolean,
    collectTerminal: boolean,
    timeCollectTerminal: any,
    trafficRefresh: any,
    pitaatoVersion: any,
    smartphoneResultDisplay: any,
    areaLimit: any,
    co2EmissionDisplay: string
}> {
    user: any;
    constructor(props: any) {
        super(props)
        this.state = {
            data: {},
            error: '',
            updateInterval: 0,
            vallidRange: 0,
            vallidRangeBk: 0,
            recAutoCreate: 0,
            sendMailInterval: 0,
            useLocateRoute: false,
            collectTerminal: false,
            timeCollectTerminal: 1,
            trafficRefresh: 60,
            pitaatoVersion: 0,
            smartphoneResultDisplay: 0,
            areaLimit: 50,
            co2EmissionDisplay: 'False'
        }

        this.onFetchData = this.onFetchData.bind(this);
        this.handleSubmitAdd = this.handleSubmitAdd.bind(this);
    };

    componentDidMount() {
        const userData = Cookies.get('user');
        if (userData) {
            this.user = JSON.parse(userData ? userData : '{}');
        }
        this.onFetchData();
    }


    // {SysID: 1, SiteID: 1, RecAutoCreate: 0, UseLocateRoute: false, CollectTerminal: false, TimeCollect: 0, UpdateInterVal: 0, ValidRange: 0, SendMailInterval: 0}
    private onFetchData() {
        // const data = {SysID: 1, SiteID: 1, RecAutoCreate: 0, UseLocateRoute: false, CollectTerminal: true, TimeCollect: 0, UpdateInterVal: 0, ValidRange: 0, SendMailInterval: 0};
        // this.updateStateData(data);

        var api = "/Form/V1/Dat/GPS/GetSetting?siteID=" + this.user.siteId + "&sysID=" + this.user.siteId;
        axiosInstance
            .get(HOST() + api)
            .then(res => {
                const data = res.data.data;
                this.updateStateData(data);
            }).catch(e => {
                this.setState({ error: "Load GPS Settings Failed" });
            });

    }

    updateStateData(data: any) {
        this.setState({
            updateInterval: data.updateInterVal || 0,
            vallidRange: data.validRange || 0,
            vallidRangeBk: data.validRange || 0,
            recAutoCreate: data.recAutoCreate || 0,
            sendMailInterval: data.sendMailInterval || 0,
            useLocateRoute: data.useLocateRoute,
            collectTerminal: data.collectTerminal,
            timeCollectTerminal: data.timeCollect,
            trafficRefresh: data.trafficRefresh || -1,
            pitaatoVersion: data.pitaatoVersion || 0,
            smartphoneResultDisplay: data.smartphoneResultDisplay || 0,
            areaLimit: data.areaLimit || 50,
            co2EmissionDisplay: data.co2EmissionDisplay
        });
    }

    public close(reloadTerminal = false) {
        return reloadTerminal ? this.props.onNavi('/', 'terminalList') : this.props.onNavi('/');
    }


 
    public handleSubmitAdd() {

        var body = this.state.data;
        body.siteID = this.user.siteId;
        body.sysID = this.user.siteId;
        body.updateInterVal = this.state.updateInterval.toString();
        body.validRange = this.state.vallidRange.toString();
        body.recAutoCreate = Number(this.state.recAutoCreate);
        body.sendMailInterval = this.state.sendMailInterval.toString();
        body.useLocateRoute = this.state.useLocateRoute;
        body.collectTerminal = this.state.collectTerminal;
        body.timeCollect = Number(this.state.timeCollectTerminal);
        body.trafficRefresh = Number(this.state.trafficRefresh);
        body.pitaatoVersion = this.state.pitaatoVersion;
        body.smartphoneResultDisplay = this.state.smartphoneResultDisplay;
        body.areaLimit = this.state.areaLimit.toString();
        body.co2EmissionDisplay = this.state.co2EmissionDisplay;

        var api = "/Form/V1/Dat/GPS/SetSetting"

        axiosInstance
            .put(HOST() + api, body)
            .then(res => {
                if (res) {
                    toast.success("更新に成功。", toastOptions);
                    this.close(this.state.vallidRange != this.state.vallidRangeBk);
                    eventBus.dispatch("changeDetailSetting", { pitaVersion: this.state.pitaatoVersion, co2EmissionDisplay: this.state.co2EmissionDisplay });
                    var user: any = JSON.parse(Cookies.get('user') || "{}");

                    //update lại thông tin ở Cookies - user
                    if (user) {
                        user.pitaVersion = this.state.pitaatoVersion;
                        Cookies.set('user', user);
                    }
                   
                }
                else {
                    toast.error("Request Failed", toastOptions);
                }
            }).catch(e => {
                toast.error("Request Failed: " + e.message, toastOptions);
            });
    }

    public render() {
        return (
            <div>
                <div className="box detail-setting">
                    <div className="box-title d-flex justify-content-between align-items-center">
                        <div className="page-header">オプション</div>
                        <div className="buttonEdit">
                            <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.close()}>キャンセル</button>
                            <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.handleSubmitAdd}> 保存</button >
                        </div>
                    </div>
                    <div className="div-header d-flex">
                        <span>GPSモニタリング</span>
                    </div>
                    <table className="tb-border mb-3">
                        <tbody>
                            <tr>
                                <td className="col1">更新間隔</td>
                                <td className="col2">
                                    <input name="updateInterval" type="number" value={this.state.updateInterval} onChange={e => this.setState({ updateInterval: getValidNumber(e, { minValue: 5, maxValue: 1000, roundTo: 0 }) })} className="detail-input" />
                                </td>
                                <td className="col3">秒</td>
                                <td>※マップに表示する端末位置の表示更新間隔</td>
                            </tr>
                            <tr>
                                <td className="col1"><span>有効範囲</span></td>
                                <td>
                                    <input name="vallidRange" type="number" value={this.state.vallidRange} onChange={e => this.setState({ vallidRange: getValidNumber(e, { minValue: 0, maxValue: 1000, roundTo: 0 }) })} className="detail-input" />
                                </td>
                                <td>時間</td>
                                <td>※指定した時間以内の最終GPS位置のみ表示します。</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="div-header"><span>履歴自動補完</span></div>
                    <table className="tb-border mb-3">
                        <tbody>
                            <tr>
                                <td className="col1">指定時間</td>
                                <td className="col2">
                                    <input name="recAutoCreate" type="number" value={this.state.recAutoCreate} onChange={e => this.setState({ recAutoCreate: getValidNumber(e, { minValue: 0, maxValue: 100, roundTo: 0 }) })} className="detail-input" />
                                </td>
                                <td className="col3">分以上</td>
                                <td>※自動積込み／荷降しを認識できなかった場合
                                    <br />&nbsp;指定時間が経過していれば自動的に認識できなかった
                                    <br />&nbsp;自動処理を補完します。
                                    <br />※0分に設定した場合は履歴情報の補完は行いません。</td>
                            </tr>
                        </tbody>
                    </table>
                    {/*<div className="div-header"><span>エリア入退履歴管理設定</span></div>*/}
                    {/*<table className="tb-border mb-3">*/}
                    {/*    <tbody>*/}
                    {/*        <tr>*/}
                    {/*            <td className="col1">インターバル</td>*/}
                    {/*            <td className="col2">*/}
                    {/*                <input name="interval" type="number" value={this.state.sendMailInterval} onChange={e => this.setState({ sendMailInterval: getValidNumber(e, { minValue: 1, maxValue: 999, roundTo: 0 }) })} className="detail-input" />*/}
                    {/*            </td>*/}
                    {/*            <td className="col3">秒</td>*/}
                    {/*            <td>※特定エリアの入退履歴の反応時間を設定します。</td>*/}
                    {/*        </tr>*/}
                    {/*    </tbody>*/}
                    {/*</table>*/}
                    <div className="div-header"><span>ライブ交通情報更新間隔</span></div>
                    <table className="tb-border mb-3">
                        <tbody>
                            <tr>
                                <td className="col1">交通情報更新有無</td>
                                <td className="col2">
                                    <span className="mr-1">有</span>
                                    <input type="radio"
                                        className="inline mr-4"
                                        name="routeCheck"
                                        checked={this.state.trafficRefresh > 0}
                                        onChange={e => window.confirm('更新間隔が早すぎる場合追加請求が発生します。') && this.setState({ trafficRefresh: 60 })} />

                                    <span className="mr-1">無</span>
                                    <input type="radio"
                                        className="inline"
                                        name="routeCheck"
                                        checked={this.state.trafficRefresh < 0}
                                        onChange={e => this.setState({ trafficRefresh: -1 })} />
                                </td>
                                <td className="col3"></td>
                                <td>※ライブ交通情報の定期的更新有無</td>
                            </tr>
                            <tr>
                                <td className="col1">更新間隔</td>
                                <td className="col2">
                                    <input
                                        disabled={this.state.trafficRefresh < 0}
                                        name="trafficRefresh"
                                        type="number"
                                        value={this.state.trafficRefresh < 0 ? 60 : this.state.trafficRefresh}
                                        onChange={e => this.setState({ trafficRefresh: getValidNumber(e, { minValue: 10, maxValue: 1440, roundTo: 0 }) })}
                                        className="detail-input" />
                                </td>
                                <td className="col3">分</td>
                                <td>※更新間隔が早すぎる場合追加請求が発生します。</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="div-header"><span>CO2排出量表示</span></div>
                    <table className="tb-border mb-3">
                        <tbody>
                            <tr>
                                <td className="col1">CO2排出量表示有無</td>
                                <td className="col2">
                                    <span className="mr-1">有</span>
                                    <input type="radio"
                                        className="inline mr-4"
                                        name="co2EmissionDisplay"
                                        checked={this.state.co2EmissionDisplay === 'True'}
                                        onChange={e => this.setState({ co2EmissionDisplay: 'True' })} />

                                    <span className="mr-1">無</span>
                                    <input type="radio"
                                        className="inline"
                                        name="co2EmissionDisplay"
                                        checked={this.state.co2EmissionDisplay === 'False'}
                                        onChange={e => this.setState({ co2EmissionDisplay: 'False' })} />
                                </td>
                                <td className="col3"></td>
                                <td>※CO2排出量を表示するためには、各端末登録で燃費の登録が必要です。</td>
                            </tr>
                        </tbody>
                    </table>
                    {this.user && this.user.roleID === 1 && (
                        <>
                            <div className="div-header"><span>エリア数上限</span></div>
                            <table className="tb-border mb-3">
                                <tbody>
                                    <tr>
                                        <td className="col1">エリア有効上限数</td>
                                        <td className="col2">
                                            <input name="areaLimit" type="number" value={this.state.areaLimit} onChange={e => this.setState({ areaLimit: getValidNumber(e, { minValue: 1, maxValue: 999 }) })} className="detail-input" />
                                        </td>
                                        <td className="col3">個</td>
                                        <td>※エリアの有効化ができるエリア上限数</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    )}
                    {this.user && this.user.roleID === 1 && (
                        <>
                            <div className="div-header"><span>ぴたあっとバージョン</span></div>
                            <table className="tb-border mb-3 w-50">
                                <tbody>
                                    <tr>
                                        <td className="col1">バージョン</td>
                                        <td className="col2">
                                            <select className="form-select w-90 h-28"
                                                name="pitaatoVersion"
                                                value={this.state.pitaatoVersion}
                                                onChange={e => this.setState({ pitaatoVersion: e.target.value })}
                                            >
                                                <option value="0">通常</option>
                                                <option value="1">peacock連携</option>
                                                <option value="2">コンクリート施工</option>
                                            </select>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                        </>
                    )}
                    {this.state.pitaatoVersion === "1" && (
                        <>
                            <div className="div-header"><span>スマートフォンpeacock結果表示</span></div>
                            <table className="tb-border mb-3 w-50">
                                <tbody>

                                    <tr>
                                        <td className="col1">計量結果表示方式</td>
                                        <td className="col2">
                                            <select className="form-select w-90 h-28"
                                                name="smartphoneResultDisplay"
                                                value={this.state.smartphoneResultDisplay}
                                                onChange={e => this.setState({ smartphoneResultDisplay: e.target.value })}
                                            >
                                                <option value="0">積載重量</option>
                                                <option value="1">自主管理値</option>
                                            </select>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>

                        </>
                    )}
                    <div className="temporary_disabled">
                        <div className="div-header"><span>到着予想時刻計算</span></div>
                        <table className="tb-border">
                            <tbody>
                                <tr>
                                    <td style={{ width: "270px", paddingLeft: "20px" }}>ルートで設定した往復路時刻を利用する</td>
                                    <td>
                                        &nbsp;&nbsp;&nbsp;<input type="radio" id="useLocateRouteN" name="useLocateRoute" value="1" checked={this.state.useLocateRoute} onChange={e => this.setState({ useLocateRoute: !this.state.useLocateRoute })} />&nbsp;する
                                        &nbsp;&nbsp;&nbsp;<input type="radio" id="useLocateRouteY" name="useLocateRoute" value="0" checked={!this.state.useLocateRoute} onChange={e => this.setState({ useLocateRoute: !this.state.useLocateRoute })} />&nbsp;しない
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "270px", paddingLeft: "20px" }}>同一ルートを走行した端末の履歴を統計</td>
                                    <td>
                                        &nbsp;&nbsp;&nbsp;<input type="radio" id="collectTerminalN" name="collectTerminal" value="0" checked={this.state.collectTerminal} onChange={e => this.setState({ collectTerminal: !this.state.collectTerminal })} />&nbsp;する
                                        &nbsp;&nbsp;&nbsp;<input type="radio" id="collectTerminalY" name="collectTerminal" value="1" checked={!this.state.collectTerminal} onChange={e => this.setState({ collectTerminal: !this.state.collectTerminal })} />&nbsp;しない
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ paddingLeft: "40px" }}>
                                        <span>過去&nbsp;&nbsp;<input name="gpsValidTime" disabled={!this.state.collectTerminal} type="number" value={this.state.timeCollectTerminal} onChange={e => this.setState({ timeCollectTerminal: getValidNumber(e, { minValue: 0 }) })} className="detail-input1" />&nbsp;時間をデータ利用</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        )
    };
}