import * as React from 'react';
import './Message.css';
import axiosInstance from '../../common/interceptor';
import { HOST } from '../../common/constant';
import SoundList from '../../common/SoundList/SoundList';
import MultiCheckBox from '../../common/MultiCheckBox/MultiCheckBox';
import { clear } from 'console';
import { render } from 'react-dom';
import { toast } from 'react-toastify';
import { reloadAudio, reloadTerminal, toastOptions } from '../../common/Utils';
import Cookies from 'js-cookie';

export default class Message extends React.Component<{
	map: any,
	onNavi: Function
}, {
	SoundId: any,
	Terminal: any[],
	Message: any
}> {
	constructor(props: any) {
		super(props);
		this.state = {
			Terminal: [],
			Message: '',
			SoundId: ''
		};
	}

	componentDidMount() {
		
	}

	sendMessge() {
		// {"ListCliID": [1,2,3],"Message": "test message","SoundID": 1}
		var body = {
            ListCliID: this.state.Terminal,
            Message: this.state.Message,
            SoundID: Number(this.state.SoundId)
        };
		
		axiosInstance
            .post(HOST() + "/Form/V1/Dat/Mes/AddMessage", body)
            .then((res: any) => {
                if(res.status == 200 && res.data) {
					toast.success("送信に成功しました。", toastOptions);
					this.setState({
						Terminal: [],
						Message: '',
						SoundId: ''
					});
				}
				else {
					toast.error("送信に失敗しました。" + res.data.message, toastOptions);
				}
			})
	}

	messageHistory() {
		this.props.onNavi('/messageHistory', { isRouteChange: false });
	}

	componentWillUnmount() {
		this.clear();
    }

	private clear() {
    }
	user: any = JSON.parse(Cookies.get('user') || "{}");

	public render() {
		return (
			<div className="message-container">
				<div className="form-container">
					<div className="w-50">
						<span className="AudioList" onClick={() => reloadAudio(this)}></span>
						<SoundList
							fetchUrl="/Form/V1/Dat/Sound/ListAll"
							className=''
							ref="AudioList"
							value={this.state.SoundId}
							type="mesFlg"
							onChange={(e: any) => this.setState({ SoundId: e.target.value})}
						/>
					</div>
					<div className="form-row row">
						<div className="col-6">
							<label className="col-form-label form-control-label">宛先</label>
							<span className="TerminalList" onClick={() => reloadTerminal(this)}></span>
							<div className="container-up">
								<MultiCheckBox
									ref='TerminalList'
									ckey='cliID'
									cvalue='cliNm'
									cname='Terminal'
									urlData={`/Form/V1/Mst/Terminal/GetList?sysID=${this.user.siteId}`}
									value={this.state.Terminal}
									onChange={(e: any) => this.setState({ Terminal: e.data })}
									isSingleCheck={false}
								/>
							</div>
						</div>
						<div className="col-6">
							<label className="col-form-label form-control-label">内容</label>
							<div className="container-up h-100 w-90">
								<textarea
									className="form-control h-100"
									onChange={(e) => this.setState({ Message: e.target.value })}
									value={this.state.Message}
									maxLength={100}
								></textarea> 
							</div>
						</div>
					</div>
				</div>
				<div className="buttonGroup t-10">
					<button
						type="button"
						className="btn btn-primary btn-sm mar-5"
						disabled={this.state.Terminal.length < 1 || (!this.state.Message && !Number(this.state.SoundId))}
						onClick={() => this.sendMessge()}
					>メッセージ送信</button>
					<button
						type="button"
						className="btn btn-primary btn-sm mar-5"
						onClick={() => this.messageHistory()}
					> 送信履歴</button >
				</div>
			</div>
		);
	}
}
