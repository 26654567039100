
import React from "react";
import "./Setting.css"
import "./ProximityAlarmSetting.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import Cookies from 'js-cookie';
import { getValidNumber, isBlank, toastOptions } from "../../common/Utils";
import { toast } from "react-toastify";
import SoundList from "../../common/SoundList/SoundList";


export default class ProximityAlarmSetting extends React.Component<{
    onNavi: Function
}, {
    listData: any[],
    error: any,
    sound: any,
    gpsValidTime: any,
    useProximityAlarm: boolean,
    selectIdx: any,
    soundName: any,
    gpsAlarmDistance: any
}> {
    constructor(props: any) {
        super(props)
        this.state = {
            listData: [],
            error: '',
            sound: 0,
            gpsValidTime: 30,
            useProximityAlarm: false,
            selectIdx: 0,
            soundName: '',
            gpsAlarmDistance: 0
        }
        
        this.onFetchData = this.onFetchData.bind(this);
        this.handleSubmitAdd = this.handleSubmitAdd.bind(this);
        this.onChangeSelectValue = this.onChangeSelectValue.bind(this);
    };

    componentDidMount() {
        this.onFetchData();
    }

    userData = Cookies.get('user');
    user = JSON.parse(this.userData ? this.userData : '{}');
    isAdmin = this.user.roleID == 1;

    private updateSoundSelection() {
        const sounds = this.state.listData;
        const soundID = this.state.sound;
        if(sounds && sounds.length > 0 && soundID > 0) {
            for (var i = 0; i < sounds.length; i++) {
                if(sounds[i].soundID === soundID){
                    this.setState({selectIdx: i});
                    this.setState({soundName: sounds[i].name});
                    break;
                }
            }
        }
    }

    private onFetchData() {
        axiosInstance
        .get(HOST() + "/Form/V1/Dat/Approach/GetApproach")
        .then(response => {
            var data = response.data.data;
            
            this.setState({ sound: data.apP_SOUNDID });
            this.setState({ useProximityAlarm: data.usE_APPROACH });
            this.setState({ gpsValidTime: data.apP_VALGPSMIN });
            this.setState({ gpsAlarmDistance: data.apP_PROX_DISTANCE });
            this.updateSoundSelection();
        })

        // axiosInstance
        // .get(HOST() + "/Form/V1/Dat/Sound/ListAll")
        // .then(response => {
        //     const first  = [{ soundID:0, name: '0'}];
        //     const data = response.data.data;
        //     this.setState({ listData: [...first, ...data] });
        //     this.updateSoundSelection();
        // })
        // .catch(error => {
        //     if (error.response && error.response.data) {
        //         alert(error.response.data.message);
        //     }
        // });
       
    }

    public onNavi(path: any) {
        
    }

    public close() {
        this.props.onNavi('/');
    }

  
    public handleSubmitAdd() {

        
        this.setState({error: ""});
        var body = {
            "usE_APPROACH": this.state.useProximityAlarm,
            "apP_VALGPSMIN": this.state.gpsValidTime,
            "apP_SOUNDID": this.state.sound,
            "apP_PROX_DISTANCE": this.state.gpsAlarmDistance
        };
        
        var api = "/Form/V1/Dat/Approach/SetApproach"
        axiosInstance
            .put(HOST() + api, body)
            .then(res => {
                if(res.status == 200 && res.data) {
                    toast.success('更新に成功。', toastOptions);
                    this.close();
                }
                else {
                    toast.error('近接警報設定の保存に失敗しました。', toastOptions);
                }
            }).catch(e => {
                toast.error('Request Failed。', toastOptions);
            });
    }

    
    public onChangeSelectValue(value:any) {
        var index = parseInt(value);
        this.setState({selectIdx: index});
        const sound = this.state.listData[value];
        this.setState({sound: sound.soundID})
        this.setState({soundName: sound.name});
    }

    public render() {
        return (
            <div>
                <div className="box prox-alarm">
                    <div className="box-title d-flex justify-content-between align-items-center">
                    <span className="page-header buttonEdit" style={{top: "10px", left: "25px"}}>近接警報設定</span>
                    <div className="buttonEdit">
                        <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.close()} >キャンセル</button>
                            <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.handleSubmitAdd} >保存</button>
                    </div>
                </div>

                <table className="tb-no-border mb-3 mt-3" >
                    <tbody>
                        <tr>
                            <td>近接警報を使用する</td>
                            <td>
                                <div className="col-9">
                                    <input name="useProximityAlarm" type="checkbox" checked={this.state.useProximityAlarm} onChange={e => this.setState({ useProximityAlarm: e.target.checked })} disabled={!this.isAdmin} style={{width:"17px", height:"17px"}}/>
                                </div>
                            </td>    
                        </tr>
                        <tr>
                            <td>GPS有効時間</td>
                            <td>
                                <div className="col-9">
                                        <input name="gpsValidTime" disabled={!this.state.useProximityAlarm} type="number" value={this.state.gpsValidTime} onChange={e => this.setState({ gpsValidTime: getValidNumber(e, { minValue: 0, maxValue: 100, roundTo: 0 })})} className="input-fixedszie"/>
                                &nbsp;&nbsp;分以内
                                </div>
                            </td> 
                        </tr>
                        <tr>
                            <td style={{width:"150px"}}>音声</td>
                            <td>
                            <SoundList
                                type="proximityFlg"
                                fetchUrl="/Form/V1/Dat/Sound/ListAll"
                                className="col-9 dropdown-w-100"
                                value={this.state.sound}
                                onChange={(e: any) => this.setState({ sound: Number(e.target.value)})}
					        />
                            </td> 
                        </tr>
                        <tr>
                            <td>近接警報距離</td>
                            <td>
                                <div className="col-9">
                                    <input name="gpsAlarmDistance"
                                        disabled={!this.state.useProximityAlarm} type="number"
                                        value={this.state.gpsAlarmDistance}
                                            onChange={e => this.setState({ gpsAlarmDistance: getValidNumber(e, { minValue: 0, maxValue: 99, roundTo: 0 }) })}
                                        className="input-fixedszie" />
                                    &nbsp;&nbsp;m
                                </div>
                            </td> 
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
        )
    };
}