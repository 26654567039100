import React from 'react'
import { HOST } from '../../common/constant';
import axiosInstance from '../../common/interceptor';

export default class VersionInfo extends React.Component<{
    onNavi: Function
}, {
    vData: string,
}> {
    constructor(props: any) {
        super(props)
        this.state = {
            vData: "",
        }
    }

    componentDidMount() {
        axiosInstance
            .get(HOST() + '/Form/V1/Mst/Version?cliKey=PC')
            .then(response => {
                this.setState({ vData: response.data.data || "" });
            })
    }
    public close() {
        this.props.onNavi('/');
    }

    render() {
        return (
            <div className="info-app">
                <p>バージョン情報: {this.state.vData}</p>
                <button type="button" className="btn btn-primary float-right" onClick={() => this.close()} >OK</button>
            </div>
        );
    }
}