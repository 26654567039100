import "./ImageAttachMode.css";
import React, {forwardRef, RefObject, ChangeEvent} from "react";
import Draggable, { ControlPosition } from 'react-draggable'; 
import axiosInstance from "../../common/interceptor";
import { HOST } from "../../common/constant";
import { toast } from "react-toastify";
import { toastOptions } from "../../common/Utils";
import { getUSGSOverlay } from "../../common/USGSOverlay";
import Cookies from "js-cookie";

export default class ImageAttachMode extends React.Component<{
    onNavi: Function,
    map: any,
    updateStateFunc: Function,
}, {
    imageFile: any,
    urlObject: any
    btnTitle: String,
    allowDrag: boolean,
    numFiles: any,
    drawedOverlay: any
}> {
    readonly inputOpenFileRef: any;
    constructor(props: any) {
        super(props);
        this.inputOpenFileRef = React.createRef()
        this.state = {
            btnTitle:'ファイル選択',
            allowDrag: false,
            imageFile: null,
            urlObject: null,
            numFiles: 0,
            drawedOverlay: []
        };
        
        this.updateStates = this.updateStates.bind(this);
        this.initStates = this.initStates.bind(this);
        this.onClickFileChange = this.onClickFileChange.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    overlay: any = null;
    rectangle: any = null;
    onzoom: any = null;
    beforeBound: any = null;

    componentDidMount() {
        var hasImage = (this.state.imageFile != null);
        this.updateStates(hasImage);
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.map !== this.props.map) {
            this.drawUploaded(this.props.map);
        }
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.map !== prevState.map) {
            return { map: nextProps.someValue };
        }
        return null;
    }

    getCrossLength(bound: any) {
        const ne = bound.getNorthEast();
        const sw = bound.getSouthWest();

        return Math.sqrt(Math.pow(sw.lat() - ne.lat(), 2) + Math.pow(sw.lng() - ne.lng(), 2));
    }
    accessToken = Cookies.getJSON('user').token;
    drawUploaded(map: any) {
        axiosInstance
            .get(HOST() + '/Form/V1/Dat/images/getall')
            .then((res: any) => {
                let data: any = res.data ? (res.data.data || []) : [];
                const numFiles: any = data.length;
                const drawedOverlay: any = [];
                data.forEach((image: any) => {
                    let startMarker: any = image.xY_START.split("|");
                    let endMarker: any = image.xY_END.split("|");
                    const bounds = new window.google.maps.LatLngBounds(
                        new window.google.maps.LatLng(startMarker[0], startMarker[1]),
                        new window.google.maps.LatLng(endMarker[0], endMarker[1]),
                    );
                    let overlay = getUSGSOverlay(bounds, HOST() + '/Form/V1/File/download?filePath=' + image.filePath + '&access_token=' + this.accessToken);
                    overlay.setMap(map);
                    drawedOverlay.push(overlay);
                });

                this.setState({ numFiles, drawedOverlay });
            });
    }

    private updateStates(hasImage:boolean) {
        if(hasImage) {
            this.setState({ btnTitle: '保存'});
            this.setState({allowDrag: true});
        }
        else {
            this.setState({btnTitle: 'ファイル選択'});
            this.setState({allowDrag: false});
        }
    }

    public onClickFileChange(files: any, event: any) {
        var aFiles = files as FileList;
        if(aFiles && aFiles.length > 0) {
            this.setState({ imageFile: files[0] });
            let urlObject = URL.createObjectURL(files[0]);
            const img = new Image();
            const that = this;
            img.onload = function () {
                that.setMarker(urlObject, img.width, img.height);
            }
            img.src = urlObject;
            this.setState({ urlObject });
            this.updateStates(true);
        }

        event.target.value = null;
    }

    setMarker(urlObject: any, width = 0, height = 0) {
        let ratio = 0;
        if (width && height) {
            ratio = width / height;
        }
        let center: any = this.props.map.getCenter();
        let mapBounds: any = this.props.map.getBounds();
        let deltaLat = Math.abs(center.lat() - mapBounds.getSouthWest().lat());
        let deltaLng = Math.abs(center.lng() - mapBounds.getSouthWest().lng());
        let startMarker = new window.google.maps.Marker({
                position: {
                    lat: center.lat() - deltaLat * 0.5,
                    lng: center.lng() - deltaLng * 0.5
                },
        });
        let endMarker = new window.google.maps.Marker({
            position: {
                lat: center.lat() + deltaLat * 0.5,
                lng: center.lng() + deltaLng * 0.5
            },
        });
        if (ratio < 1) {
            const xheight = (deltaLat * 0.5);
            const xwidth = deltaLat * ratio;
            endMarker = new window.google.maps.Marker({
                position: {
                    lat: center.lat() + xheight,  // height
                    lng: center.lng() + xwidth - deltaLng * 0.5
                },
            });
        } else {
            const xwidth = (deltaLng * 0.5);
            const xheight = deltaLng / ratio;
            endMarker = new window.google.maps.Marker({
                position: {
                    lat: center.lat() + xheight - deltaLat * 0.5,  // height
                    lng: center.lng() + xwidth
                },
            });
        }

        const bounds: any = new window.google.maps.LatLngBounds(
            startMarker.getPosition(),
            endMarker.getPosition()
        );

        if (this.rectangle) {
            this.rectangle.setMap(null);
        }

        this.rectangle = new google.maps.Rectangle({
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.1,
            map: this.props.map,
            draggable: true,
            editable: true,
            bounds,
            
        });
        
        this.overlay = getUSGSOverlay(bounds, urlObject);
        this.overlay.setMap(this.props.map);
        this.markerOnDrag(urlObject);
    }

    markerOnDrag(image: any) {
        let _this = this;
        this.beforeBound = _this.props.map.getBounds();

        window.google.maps.event.addListener(this.rectangle, 'dragend', () => {
            _this.overlay.setMap(null);
            _this.overlay = getUSGSOverlay(this.rectangle.getBounds(), image);
            _this.overlay.setMap(_this.props.map);
        });
        window.google.maps.event.addListener(this.rectangle, 'bounds_changed', () => {
            _this.overlay.setMap(null);
            _this.overlay = getUSGSOverlay(this.rectangle.getBounds(), image);
            _this.overlay.setMap(_this.props.map);
        });

        this.onzoom = window.google.maps.event.addListener(_this.props.map, 'zoom_changed', (e: any) => {
            if (!_this.beforeBound) {
                _this.beforeBound = _this.props.map.getBounds();
                return;
            }
            const b4Length = this.getCrossLength(_this.beforeBound);
            const afterLength = this.getCrossLength(_this.props.map.getBounds());
            _this.beforeBound = _this.props.map.getBounds();

            if (this.rectangle) {
                const ratio = afterLength / b4Length;
                const ne = this.rectangle.getBounds()!.getNorthEast();
                const sw = this.rectangle.getBounds()!.getSouthWest();

                const north = sw.lat() + (ne.lat() - sw.lat()) * ratio;
                const east = sw.lng() + (ne.lng() - sw.lng()) * ratio;

                const bounds = {
                    north,
                    south: sw.lat(),
                    east,
                    west: sw.lng(),
                };
                this.rectangle.setOptions({ bounds });
            }
        });
    }
    
    private fileUpload(file: File) {
        if (this.state.numFiles >= 5) {
            toast.error("登録できるファイル数は5つまでです。", toastOptions);
            return;
        }

        if(this.state.imageFile.size > 7 * 1024 * 1024) {
            toast.error("ファイルサイズが超過しています。\n7MB以下のファイルを指定してください。", toastOptions);
            return;
        }

        const formData = new FormData();
        formData.append('files',file)
        
        axiosInstance.post(HOST() + '/Form/V1/File/Upload/MapImage', formData)
            .then(res => {
                let data: any = res.data;
                if (data) {
                    data.forEach((e: any) => this.invokeImageRegister(e.fileName, e.filePath));
                } else {
                    toast.error("画像のアップロードに失敗しました。", toastOptions);
                }
                this.rectangle && this.rectangle.setMap(null);
                window.google.maps.event.removeListener(this.onzoom);
            })
    }

    private invokeImageRegister(FileName: any, FilePath: any) {
        const ne = this.rectangle.getBounds()!.getNorthEast();
        const sw = this.rectangle.getBounds()!.getSouthWest();
        let body: any = {
            FileName: FileName,
            FilePath: FilePath,
            XYStart: `${sw.lat()}|${sw.lng()}` ,
            XYEnd: `${ne.lat()}|${ne.lng()}`,
            Comments: '',
        }
        axiosInstance
            .post(HOST() + '/Form/V1/Dat/images/add', body)
            .then(res => {
                toast.success("画像を正常にアップロードします。", toastOptions);
        })
        
        
        // clean Data & call calback
        const imageFileData = this.state.imageFile;
        this.setState({
            allowDrag: false,
            imageFile: null,
            urlObject: null});
        this.updateStates(false);
        this.props.updateStateFunc({ data: imageFileData, cancelable: false});
    }

    public onSelectFile(){
        if(this.state.allowDrag) {
            this.fileUpload(this.state.imageFile);
            this.inputOpenFileRef.value = "";
        }
        else {
            if (this.inputOpenFileRef !== undefined) {
                this.inputOpenFileRef.current.click();
            }
        }
    }

    private initStates() {
        this.setState({imageFile: null});
        this.setState({urlObject: null});
        this.updateStates(false);
    }

    public onCancelClick() {
        this.rectangle && this.rectangle.setMap(null);
        this.overlay && this.overlay.setMap(null);
        window.google.maps.event.removeListener(this.onzoom);
        if (this.state.allowDrag) {
            this.initStates();
        }
        else {
            this.props.updateStateFunc({ data: this.state.imageFile, cancelable: true});
        }
    }

    public render() {
        // const { deltaPosition } = this.state;
        return (
            <div style={{ position: 'relative', backgroundColor: '#88000000', height: '100%', textAlign: 'center', verticalAlign: 'middle' }} id="div-image-attach">
                <div className="button-group-image-attach">
                    <input type="file" accept="image/*" id="file" ref={this.inputOpenFileRef} hidden={true}
                        onChange={(e) => this.onClickFileChange(e.target.files, e)} />                                    

                    <button
                        className="btn btn-primary btn-md mar-5 btn-135"
                        style={{ display: 'inline-block' }}
                        onClick={this.onSelectFile}>
                            {this.state.btnTitle}
                    </button>
                    <button
                        className="btn btn-primary btn-md mar-5 btn-135"
                        style={{ display: 'inline-block'}}
                        onClick={this.onCancelClick}>
                        キャンセル
                    </button>
                </div>
            </div >
        );
    }
}
