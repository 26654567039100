import React from 'react'
import { HOST } from '../../common/constant';
import axiosInstance from '../../common/interceptor';
import Cookies from 'js-cookie';
import Moment from 'react-moment';

export default class ContractDetail extends React.Component<{
    onNavi: Function
}, {
    CountTerminal: any,
    UserInfo: any,
}> {
    constructor(props: any) {
        super(props)
        this.state = {
            CountTerminal: {},
            UserInfo: {},
        }
    }
    user: any = JSON.parse(Cookies.get('user') || "{}");

    componentDidMount() {
        axiosInstance
            .get(HOST() + '/Form/V1/Mst/Terminal/CountTerminal?SiteID=' + this.user.siteId)
            .then(response => {
                this.setState({ CountTerminal: JSON.parse(response.data.data || '') });
            })

        axiosInstance
            .get(HOST() + '/Form/V1/Mst/User/Info?userID=' + this.user.id)
            .then(response => {
                this.setState({ UserInfo: response.data.data || {} });
            })
    }

    public close() {
        this.props.onNavi('/');
    }

    render() {
        return (
            <div className="info-app">
                <p>開始日: <Moment format="yyyy/MM/DD" date={this.state.UserInfo.startDT} /></p>
                <p>終了予定日: <Moment format="yyyy/MM/DD" date={this.state.UserInfo.timeLimit} /> </p>
                <p>アクティベーション端末台数: {this.state.CountTerminal.N}台/{this.state.CountTerminal.Z}台</p>
                <p>アクティベーション用QRコード</p>
                <img src={"/Form/V1/File/QRCode?site=" + this.user.siteId} style={{ display: 'block', margin: '0 auto' }} />
                <button type="button" className="btn btn-primary float-right" onClick={() => this.close()} >OK</button>
            </div>
        );
    }
}