import React from "react";
import "./UserManagement.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSignOutAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import icPlusGreen from "../../assets/img/ic_plus_green.png";
import { Button } from "reactstrap";
import { formatDateFrom, getValidNumber, tableResizable, toastOptions } from "../../common/Utils";
import { toast, ToastContainer } from "react-toastify";
import BaseSelectList from "../../common/BaseSelectList/BaseSelectList";
import GlobalLoader from "../../common/interceptorLoader";
import Moment from "react-moment";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Cookies from "js-cookie";
import moment from 'moment';

export default class UserManagement extends React.Component<{
}, {
    listData: any[],
    showAddComponent: boolean,
    sectionButton: any,
    sectionTitle: any,
    siteId: any,
    userID: any,
    loginID: any,
    password: any,
    mailAdd: any,
    roleID: any,
    startDT: any,
    timeLimit: any,
    jobNo: any
}> {
    addFormComponent: any;
    constructor(props: any) {
        super(props)
        this.state = {
            listData: [],
            siteId: '',
            showAddComponent: false,
            sectionTitle: '新規登録',
            sectionButton: '登録',
            userID: '',
            loginID: '',
            password: '',
            mailAdd: '',
            roleID: '',
            startDT: '',
            timeLimit: '',
            jobNo: 0
        }

        let user = Cookies.getJSON('user');
        if (user.roleID !== -1) {
            window.location.href = '/';
        }
    };

    componentDidMount() {
        this.getlistData();
    }

    private getlistData() {
        let siteId = this.state.siteId;
        if (!siteId) return;

        axiosInstance
            .get(HOST() + `/Form/V1/Authenticate/getusers/${siteId}`)
            .then(response => {
                this.setState({ listData: response.data.data });
            })
    }

    public onCancel() {
        if (this.state.showAddComponent) {
            this.setState({ showAddComponent: false });
        }
        else {
            Cookies.remove('jwt');
            Cookies.remove('user');
            Cookies.remove('pv');
            window.location.href = '/login';
        }
    }
    
    public onDeleteItem(id: any) {
        axiosInstance
            .delete(HOST() + `/Form/V1/Authenticate/deluser/${id}`)
            .then(() => {
                this.setState({ showAddComponent: false });
                this.getlistData();
                toast.success('ユーザー情報を削除しました。', toastOptions);
            });
    }

    public onEditItem(object: any) {
        object.showAddComponent = true;
        object.startDT = new Date(object.startDT);
        object.timeLimit = new Date(object.timeLimit);
        object.sectionTitle = 'ユーザー情報編集';
        object.sectionButton = '編集';
        object.password = '';
        this.setState(object);
    }

    public async onAddItem() {
        let loginID: any = await axiosInstance
            .get(HOST() + `/Form/V1/Authenticate/getUULoginID`)
            .then((res: any) => res.data.data);
        this.setState({
            showAddComponent: true,
            userID: '',
            loginID: loginID,
            password: '',
            mailAdd: '',
            roleID: '0',
            startDT: new Date(),
            timeLimit: new Date('2030-12-31 23:59:59'),
            sectionTitle: '新規登録',
            sectionButton: '登録',
            jobNo: 0
        });
    }

    public handleSubmitAdd() {
        var body = {
            UserID: this.state.userID ? this.state.userID : 0,
            LoginID: this.state.loginID,
            Password: this.state.password,
            MailAdd: this.state.mailAdd,
            RoleID: Number(this.state.roleID),
            DefSiteID: Number(this.state.siteId),
            StartDT: this.state.startDT ? moment(this.state.startDT).format("YYYY-MM-DD") + "T00:00:00Z" : new Date(),
            TimeLimit: this.state.timeLimit ? moment(this.state.timeLimit).format("YYYY-MM-DD") + "T00:00:00Z" : '2030-12-31T23:59:59Z',
            jobNo: this.state.jobNo
        };

        if (!body.LoginID && Number(this.state.roleID) == 1) {
            toast.error('ログインIDを入力してください', toastOptions);
            return;
        }

        if (!body.UserID && !body.Password) {
            toast.error('パスワードを入力してください', toastOptions);
            return;
        }

        let axio = this.state.userID
            ? axiosInstance.put(HOST() + "/Form/V1/Authenticate/update", body)
            : axiosInstance.post(HOST() + "/Form/V1/Authenticate/adduser", body)
        axio.then(res => {
            if (res.data.data) {
                this.setState({ showAddComponent: false });
                this.getlistData();
                toast.success('ユーザー情報を保存しました。', toastOptions);
            } else {
                toast.error('ログインIDが存在しました', toastOptions);
            }
        });
        if (this.state.userID && this.state.password) {
            axiosInstance.post(HOST() + `/Form/V1/Authenticate/changepass/${this.state.loginID}`, body).then();
        } 
    }

    roleMap: any = {
        1: '管理者',
        2: 'ウェブユーザー',
        3: 'モバイルユーザー',
        4: 'QRコード'
    }

    public render() {
        return (
            <div>
                <GlobalLoader />
                <ToastContainer />
                <div className="box">
                    <div className="box-title d-flex justify-content-between align-items-center">
                        <span className="page-header">ユーザー登録</span>
                        <span className="page-header">
                            <div className="d-flex">
						        <label className="col-form-label form-control-label text-nowrap mr-2">サイトID</label>
						        <BaseSelectList
							        className=""
							        dkey="siteID"
							        dvalue="siteNm"
							        nullLabel={null}
                                    fetchUrl="/Form/V1/Authenticate/getallsite"
                                    onChange={(e: any) => this.setState({ siteId: e.target.value }, () => this.getlistData())}
							        value={this.state.siteId}
						        />
					        </div>
                        </span>
                        <div className="buttonEdit">
                            {
                                this.state.showAddComponent ? 
                                    (<button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.onCancel()}>キャンセル</button>) :
                                    (
                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => this.onCancel()}>
                                            <span>ログアウト</span>
                                            <FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon>
                                        </button>
                                    )
                            }
                        </div>
                    </div>

                    <header className="th-header">
                        <span style={{ position: "absolute", marginTop: "10px", left: "45%", fontSize: "15px" }}>ユーザー一覧</span>
                        <Button onClick={() => this.onAddItem()} className="align-right plus-button button-img">
                            <img src={icPlusGreen} width="27" height="27" className="border rounded-circle" />
                        </Button>
                    </header>
                    <div className="tableUser">
                        <table id='users' style={{ marginBottom: "10px" }}>
                            <tbody>
                                <tr>
                                    <th>ユーザーID</th>
                                    <th>ロールID</th>
                                    <th>ログインID</th>
                                    <th>メール</th>
                                    <th>開始日</th>
                                    <th>有効期限</th>
                                    <th>ジョブ番号</th>
                                    <th>編集</th>
                                </tr>
                                {this.state.listData.map((item, index) => (
                                    <tr key={`user${item.id}&${index}`}>
                                        <td >{item.userID}</td>
                                        <td >{this.roleMap[item.roleID]}</td>
                                        <td >{item.loginID}</td>
                                        <td >{item.mailAdd}</td>
                                        <td >
                                            <Moment format="YYYY-MM-DD" date={item.startDT} />
                                        </td>
                                        <td >
                                            <Moment format="YYYY-MM-DD" date={item.timeLimit} />
                                        </td>
                                        <td >{item.jobNo}</td>
                                        <td>
                                            <Button onClick={() => this.onEditItem(item)} className="button-img btn-50">
                                                <FontAwesomeIcon icon={faEdit} color="#000000"></FontAwesomeIcon></Button>
                                            {/*<Button onClick={() => this.onDeleteItem(item.userID)} className="button-img">*/}
                                            {/*    <FontAwesomeIcon icon={faTrash} color="#000000"></FontAwesomeIcon>*/}
                                            {/*</Button>*/}
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div id="addComponent" style={{ display: this.state.showAddComponent ? "block" : "none" }}>
                        <form>
                            <header className="th-header bg-blue">
                                <span style={{ position: "absolute", marginTop: "10px", left: "45%", fontSize: "15px" }}>{this.state.sectionTitle}</span>
                            </header>
                            <table onSubmit={this.handleSubmitAdd} >
                                <tbody>
                                    <tr>
                                        <th>ユーザーID</th>
                                        <th>ロールID </th>
                                        <th>ログインID</th>
                                        <th>パスワード</th>
                                        <th>メール</th>
                                        <th>開始日</th>
                                        <th>有効期限</th>
                                        <th>ジョブ番号</th>
                                        <th style={{ width: "10%" }}>{this.state.sectionButton}</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input
                                                className='w-100'
                                                value={this.state.userID}
                                                onChange={e => this.setState({ userID: e.target.value })}
                                                style={{ background: '#8080805e' }}
                                                readOnly />
                                        </td>
                                        <td>
                                            <select className='w-100'
                                                value={this.state.roleID}
                                                onChange={e => this.setState({ roleID: e.target.value })}>
                                                <option value="0"></option>
                                                <option value="1">管理者</option>
                                                <option value="2">ウェブユーザー</option>
                                                {<option value="3">モバイルユーザー</option>}
                                                {/*<option value="4">QRコード</option>*/}
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                className='w-100'
                                                value={this.state.loginID}
                                                maxLength={100}
                                                readOnly={this.state.roleID != 1}
                                                onChange={e => this.setState({ loginID: e.target.value })}/>
                                        </td>
                                        <td>
                                            <input
                                                className='w-100'
                                                value={this.state.password}
                                                readOnly={this.state.roleID == 0}
                                                maxLength={128}
                                                onChange={e => this.setState({ password: e.target.value })}/>
                                        </td>
                                        <td>
                                            <input
                                                className='w-100'
                                                value={this.state.mailAdd}
                                                maxLength={50}
                                                readOnly={this.state.roleID == 0}
                                                onChange={e => this.setState({ mailAdd: e.target.value })} />
                                        </td>
                                        <td>
                                            <DatePicker
                                                className="form-control form-control-sm input-date"
                                                readOnly={this.state.roleID == 0}
                                                selected={this.state.startDT}
                                                dateFormat="yyyy/MM/dd"
                                                onBlur={(e: any) => formatDateFrom(this, e, 'startDT')}
                                                onChange={(e: any) => this.setState({ startDT: e })} />
                                        </td>
                                        <td>
                                            <DatePicker
                                                className="form-control form-control-sm input-date"
                                                readOnly={this.state.roleID == 0}
                                                selected={this.state.timeLimit}
                                                dateFormat="yyyy/MM/dd"
                                                onBlur={(e: any) => formatDateFrom(this, e, 'timeLimit')}
                                                onChange={(e: any) => this.setState({ timeLimit: e })} />
                                        </td>
                                        <td>
                                            <input
                                                className='w-100'
                                                type='number'
                                                readOnly={this.state.roleID == 0}
                                                value={this.state.jobNo}
                                                onChange={e => this.setState({ jobNo: getValidNumber(e, { maxValue: 99999, minValue: 0 }) })} />
                                        </td>
                                        <td>
                                            <Button onClick={() => this.handleSubmitAdd()} className="btn btn-primary btn-sm register">{this.state.sectionButton}</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
        )
    };
}