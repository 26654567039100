import * as React from 'react';
import './AccelerationHistory.css';
import axiosInstance from '../../common/interceptor';
import { HOST, UNSET_OPTION } from '../../common/constant';
import AccelerationHistoryList from './AccelerationHistoryList';
import BaseSelectList from '../../common/BaseSelectList/BaseSelectList';
import DateTimeFromTo from '../../common/DateTimeFromTo/DateTimeFromTo';
import warningIcon from '../../assets/img/warning.png'
import Cookies from 'js-cookie';
import { reloadTerminal } from '../../common/Utils';

export default class AccelerationHistory extends React.Component<{
	map: any,
	onNavi: Function
}, {
	Terminal: any,
	dateFrom: any,
	timeFrom: any,
	dateTo: any,
	timeTo: any,
	AccelerationHistoryList: any[],
	AutoUpdate: boolean,
	intervalId: any,
	intervalTime: any,
}> {
	constructor(props: any) {
		super(props);
		this.state = {
			Terminal: UNSET_OPTION,
			dateFrom: '',
			timeFrom: '',
			dateTo: '',
			timeTo: '',
			AccelerationHistoryList: [],
			AutoUpdate: false,
			intervalId: null,
			intervalTime: 5
		};
	}

	interval: any = null;
	gmarkers: any = {};
    comments: any = [];

	componentDidMount() {
		const DateTimeFromTo: any = this.refs.DateTimeFromTo;
		DateTimeFromTo.initDefaultTime();
	}

	componentWillUnmount() {
		clearInterval(this.state.intervalId);
    }

	createIntervel(intervalTime: any) {
		clearInterval(this.state.intervalId);
		var intervalId = setInterval(() => {
			this.state.AutoUpdate && this.search();
		}, intervalTime * 1000);
		this.setState({ intervalId });
	}

	updateInterval(intervalTime: any) {
		if (this.state.AutoUpdate) {
			this.createIntervel(intervalTime);
		}
		this.setState({ intervalTime });
	}

	toggleAutoUpdate(AutoUpdate: any) {
		if (AutoUpdate) {
			this.createIntervel(this.state.intervalTime);
		} else {
			clearInterval(this.state.intervalId);
		}

		this.setState({ AutoUpdate });
    }

	search() {
		const bottomTab: any = document.getElementById("bottomTab");
		const thisHeight = bottomTab.style.height;
		const heightTabcontent = parseFloat(thisHeight) - 130;
		if (heightTabcontent > 65) {
			const AccelerationHistoryList: any = document.getElementById("AccelerationHistoryList");
			if (AccelerationHistoryList) AccelerationHistoryList.style.height = `${heightTabcontent}px`;
		}
		const DateTimeFromTo : any = this.refs.DateTimeFromTo;
		const { fromDate, toDate } = DateTimeFromTo.getDateTime();
		const CliId = this.state.Terminal ? `&CliID=${this.state.Terminal}` : '';
		this.clearIcon();
		this.comments = [];
        this.gmarkers = {};
		axiosInstance
			.get(HOST() + `/Form/V1/Dat/Report/GetEventList?fromDate=${fromDate}&toDate=${toDate}${CliId}`)
			.then(response => {
				this.setState({ AccelerationHistoryList: response.data.data || [] });
				this.displayEventToMap(response.data.data);
			});
	}

	private focusToEvent(index: any) {
		this.gmarkers[index] && this.props.map.setCenter(this.gmarkers[index].getPosition());
    }

	public displayEventToMap(dataMap: any[]) {
		if (!this.props.map) {
			return;
		}

        dataMap.forEach((point: any, index: any) => {
            var marker = new window.google.maps.Marker({
                position: { lat: point.posLat, lng: point.posLon },
                icon: warningIcon,
                title: point.name,
                map: this.props.map
			});

			const infowindow = new window.google.maps.InfoWindow();
            const infowindowContent: any = document.createElement( 'div' );
            infowindowContent.innerHTML =
                `<div id="infowindow-content">
                    <span id="place-name" className="title"></span><br />
                    <span id="place-event"></span>
                </div>`;
            infowindow.setContent(infowindowContent);

            infowindowContent.children[0].children["place-name"].textContent = point.cliNm;
            infowindowContent.children[0].children["place-event"].textContent = point.eventKind;
			infowindow.open(this.props.map, marker);

            this.comments.push(infowindow);
            this.gmarkers[index] = marker;
        });
	}

	private clearIcon() {
        this.comments.forEach((m: any) => m && m.close());
        Object.keys(this.gmarkers).forEach((m: any) => this.gmarkers[m] && this.gmarkers[m].setMap(null));
	}
	user: any = JSON.parse(Cookies.get('user') || "{}");

	public render() {
		return (
			<div className="acceleration-history-container">
				<div className="mb-2 row">
					<div className="col-xl-3 col-lg-12 d-flex">
						<label className="col-form-label form-control-label text-nowrap mr-2">端末</label>
						<span className="TerminalList" onClick={() => reloadTerminal(this)}></span>
						<div className="">
							<BaseSelectList
								className=""
								ref="TerminalList"
								dkey="cliID"
								dvalue="cliNm"
								nullLabel='すべて端末'
								fetchUrl={`/Form/V1/Mst/Terminal/GetList?sysID=${this.user.siteId}`}
								onChange={(e: any) => this.setState({ Terminal: e.target.value })}
								value={this.state.Terminal}
							/>
						</div>
						<div className="mt-1 ml-2">
							<label className="col-form-label form-control-label inline">自動更新 </label>
							<input
								type="checkbox"
								className="inline"
								checked={this.state.AutoUpdate}
								onChange={e => this.toggleAutoUpdate(e.target.checked)}
							/>
						</div>
					</div>
					<div className="col-xl-7 col-lg-10 d-flex">
						<label className="col-form-label form-control-label mr-2">作業日</label>
						<DateTimeFromTo
							ref="DateTimeFromTo"
							className=""
							dateFrom={this.state.dateFrom}
							timeFrom={this.state.timeFrom}
							dateTo={this.state.dateTo}
							timeTo={this.state.timeTo}
							onChange={(e: any) => this.setState(e)}
						/>
					</div>
					<div className="col-xl-2 col-lg-2">
						<button type="button" className="btn btn-primary btn-sm float-right mar-5" onClick={() => this.search()}>検索</button>
						<button type="button" className="btn btn-primary btn-sm float-right mar-5" onClick={() => this.clearIcon()}>クリア</button>
					</div>
					<div className="col-0">
						<input
							type="number"
							className="form-control form-control-sm inline w-50"
							value={this.state.intervalTime}
							min={5}
							step={1}
							onBlur={e => this.updateInterval(e.target.value)}
							onChange={e => this.setState({ intervalTime: e.target.value })}
						/>
						<label className="col-form-label form-control-label inline">秒間隔で定期的更新</label>
					</div>

				</div>

				<AccelerationHistoryList
					AccelerationHistoryList={this.state.AccelerationHistoryList}
					focusToEvent={ (e: any) => this.focusToEvent(e) }/>
			</div>
		);
	}
}
