import React from 'react'

export const CheckBox = (props: any) => {

    return (
        <li>
            <input
                onChange={props.handleCheckChieldElement}
                type="checkbox"
                checked={props.isChecked || false}
                value={props.data[props.ckey]}
            /> {props.data[props.cvalue]}
        </li>
    )
}

export default CheckBox