
import React, { useState, forwardRef } from "react";
import "../Settings/Setting.css"
import "./EditTerminalItem.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { Button } from "reactstrap";
import { isBlank, toastOptions, getValidNumber } from "../../common/Utils";
import { toast } from "react-toastify";


export default class EditTerminalItem extends React.Component<
    {
        onNavi: Function,
        id: any
    },
    {
        data: any,
        accelSetting: any,
        //
        errorName: any,
        errorSpeed: any,
        errorType: any,
        errorPhone: any,
        errorPIC: any,
        errorBelong: any,
        errorGpsRecord: any,
        errorGpsSend: any,
        errorGpsDensity: any,
        errorMes: any,
        // 
        errorChargeStatus: any,
        errorLoading: any,
        errorUnloading: any,
        errorFuelCons: any,
        errorFuelType: any,
        co2EmissionDisplay: any
    }> {
    constructor(props: any) {
        super(props)
        this.state = {
            data: this.initData(),
            accelSetting: { forward: false, backward: false, handle: false },
            errorName: '',
            errorSpeed: '',
            errorType: '',
            errorPhone: '',
            errorPIC: '',
            errorBelong: '',
            errorGpsRecord: '',
            errorGpsSend: '',
            errorGpsDensity: '',
            errorMes: '',
            //
            errorChargeStatus: '',
            errorLoading: '',
            errorUnloading: '',
            errorFuelCons: '',
            errorFuelType: '',
            co2EmissionDisplay: ''
        }

        this.onFetchData = this.onFetchData.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.handleSubmitAdd = this.handleSubmitAdd.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    };

    initData() {
        return {
            cliID: 0, sysID: 1, cliNm: "", cliType: "1", defSpdLim: 0.00, cliIP: "2.0/2.0/2.0",
            telNo: "", mailAdd: "", driverNm: "", belongTo: "", length: 0.99, width: 0.00, senserX: 0.00, senserY: 0.00, radius: 0,
            gpsDistWrite: 5, gpsDistSend: 60, gpsDistVariant: 100, mesDistSend: 5,
            chargeInterval: 300, loadPlaceNm: "積込み場", unLoadPlaceNm: "荷降し場", loadProcess: true, unLoadProcess: true, gpsUnTrack: false,
            accelAlert: false, sudAccelAlert: 0, breakUrgentAlert: 0, handBreakAlert: false, vicintyAlert: 0, routeAuto: true, dashcam: false, imei: '', fuelType: 2.32, fuelCons: 0
        };
    }
    userData = Cookies.get('user');
    user = JSON.parse(this.userData ? this.userData : '{}');

    validateData(data: any) {
        // var data = this.state.data;
        if (data.gpsDistWrite === null) data.gpsDistWrite = 5;
        if (data.gpsDistSend === null) data.gpsDistSend = 60;
        if (data.gpsDistVariant === null) data.gpsDistVariant = 100;
        if (data.mesDistSend === null) data.mesDistSend = 5;
        if (data.chargeInterval === null) data.chargeInterval = 300;
        if (data.loadPlaceNm === null) data.loadPlaceNm = '積込み場';
        if (data.unLoadPlaceNm === null) data.unLoadPlaceNm = '荷降し場';
        // 
        if (data.loadProcess === null) data.loadProcess = true;
        if (data.unLoadProcess === null) data.unLoadProcess = true;
        if (data.gpsUnTrack === null) data.gpsUnTrack = false;
        if (data.accelAlert === null) data.accelAlert = false;

        if (data.sudAccelAlert === null) data.sudAccelAlert = 0;
        if (data.breakUrgentAlert === null) data.breakUrgentAlert = 0;
        if (data.vicintyAlert === null) data.vicintyAlert = 0;
        if (data.routeAuto === null) data.routeAuto = true;
        if (data.dashcam === null) data.dashcam = false;
        if (!data.fuelType) data.fuelType = 2.32;

        this.setState({ data: data });
    }

    componentDidMount() {
        if (this.props.id) {
            this.onFetchData();
        }
        if (this.user) {
            var api = "/Form/V1/Dat/GPS/GetSetting?siteID=" + this.user.siteId + "&sysID=" + this.user.siteId;
            axiosInstance
                .get(HOST() + api)
                .then(res => {
                    this.setState({ co2EmissionDisplay: res.data.data.co2EmissionDisplay === 'True' })
                }).catch(e => {
                    toast.error("Loading setting failed!");
                });
        }
    }

    private onFetchData() {
        // this.getAccelSetting();
        var CliID = this.props.id;
        if (CliID === 0) return;
        var api = "/Form/V1/Mst/Terminal/GetTerminal?CliID=" + CliID
        axiosInstance
            .get(HOST() + api)
            .then(response => {
                const terminal = response.data.data;
                if (terminal) {
                    this.validateData(terminal);
                    // this.updateAccelSettingInfo();
                }
                else {
                    alert(response.data.message);
                    this.goBack(false);
                }
            })
    }

    private goBack(refresh: boolean) {
        if (refresh) this.props.onNavi('/', 'terminalList');
        else this.props.onNavi('/');
    }

    updateIcon() {
        this.props.onNavi('/EditTerminalIcon', {
            cliID: this.props.id,
            sizeFitContent: true
        });
    }

    public onCancel(path: any) {
        this.goBack(false);
    }

    public validateInput() {
        var isValid = true;
        // 端末編集画面-1
        if (isBlank(this.state.data.cliNm)) {
            this.setState({ errorName: "端末名が空です。有効な端末名を入力してください" });
            isValid = false
        }
        else { this.setState({ errorName: '' }); }

        if (this.state.data.cliNm.indexOf(',') !== -1) {
            this.setState({ errorName: "端末名に「,」が含まれています。「,」は使用不可文字です" });
            isValid = false
        }
        else { this.setState({ errorName: '' }); }

        if (this.state.data.defSpdLim < 0) {
            this.setState({ errorSpeed: "制限速度が無効です。有効な制限速度を入力してください" });
            isValid = false
        }
        else { this.setState({ errorSpeed: '' }); }

        if (this.state.data.driverNm.indexOf(',') !== -1) {
            this.setState({ errorPIC: "所有者に「,」が含まれています。「,」は使用不可文字です" });
            isValid = false
        }
        else { this.setState({ errorPIC: '' }); }

        var gpsDistWrite = this.state.data.gpsDistWrite;
        if (gpsDistWrite < 3 || gpsDistWrite > 10) {
            this.setState({ errorGpsRecord: "GPS記録間隔が無効です。3～10秒まで設定可能" });
            isValid = false
        }
        else { this.setState({ errorGpsRecord: '' }); }

        var gpsDistSend = this.state.data.gpsDistSend;
        if (gpsDistSend < 10 || gpsDistSend > 1800) {
            this.setState({ errorGpsSend: "GPS送信間隔が無効です。10～1800秒まで設定可能 オフライン版では60秒以上を設定" });
            isValid = false
        }
        else { this.setState({ errorGpsSend: '' }); }

        if (this.state.data.gpsDistVariant < 0) {
            this.setState({ errorGpsDensity: "GPS許容誤差が無効です。有効な値を入力してください" });
            isValid = false
        }
        else { this.setState({ errorGpsDensity: '' }); }

        if (this.state.data.mesDistSend < 0) {
            this.setState({ errorMes: "MES受信間隔が無効です。有効な値を入力してください" });
            isValid = false
        }
        else { this.setState({ errorMes: '' }); }

        // 端末編集画面-2
        if ((this.state.data.chargeInterval) < 30 || (this.state.data.chargeInterval) > 9999) {
            this.setState({ errorChargeStatus: "充電確認間隔が無効です。30～9999秒まで設定可能" });
            isValid = false
        }
        else { this.setState({ errorChargeStatus: '' }); }

        if (isBlank(this.state.data.loadPlaceNm)) {
            this.setState({ errorLoading: "積込み場が空です。有効な積込み場を入力してください" });
            isValid = false
        }
        else { this.setState({ errorLoading: '' }); }

        if (this.state.data.loadPlaceNm.indexOf(',') !== -1) {
            this.setState({ errorLoading: "積込み場に「,」が含まれています。「,」は使用不可文字です" });
            isValid = false
        }
        else { this.setState({ errorLoading: '' }); }

        if (isBlank(this.state.data.unLoadPlaceNm)) {
            this.setState({ errorUnloading: "荷降し場が空です。有効な荷降し場を入力してください" });
            isValid = false
        }
        else { this.setState({ errorUnloading: '' }); }

        if (this.state.data.unLoadPlaceNm.indexOf(',') !== -1) {
            this.setState({ errorUnloading: "荷降し場に「,」が含まれています。「,」は使用不可文字です" });
            isValid = false
        }
        else { this.setState({ errorUnloading: '' }); }

        if (isBlank(this.state.data.fuelType)) {
            this.setState({ errorFuelType: "燃料の種類が空です。 有効なタイプを入力してください" });
            isValid = false
        }
        else { this.setState({ errorFuelType: '' }); }

        if (this.state.data.fuelCons < 0 || this.state.data.fuelCons > 999.99) {
            this.setState({ errorFuelCons: "燃料消費量が無効です。 有効な値を入れてください" });
            isValid = false
        }
        else { this.setState({ errorFuelCons: '' }); }

        return isValid;
    }

    public handleSubmitAdd() {
        if (!this.validateInput()) {
            return;
        }

        var body = this.state.data;

        axiosInstance
            .put(HOST() + "/Form/V1/Mst/Terminal/UpdateTerminal", body)
            .then(res => {
                if (res.status == 200 && res.data) {
                    toast.success('更新に成功。', toastOptions);
                    this.goBack(true);
                    document.querySelectorAll('.TerminalList').forEach((e: any) => e.click()); // reload all terminal
                }
                else {
                    // alert("Request Failed。" + res.statusText);
                    toast.error('保存に失敗しました。' + "[" + res.statusText + "]", toastOptions);
                }
            })
    }

    public onInputChange(event: any) {

        var inputName = event.target.name;
        var value = event.target.value;
        var aData = this.state.data;
        // 
        if (inputName === "cliNm") {
            aData.cliNm = value;
        }
        else if (inputName === "defSpdLim") {
            aData.defSpdLim = getValidNumber(event, { minValue: 0, maxValue: 200, roundTo: 0 });
        }
        else if (inputName === "cliType") {
            aData.cliType = value;
        }
        else if (inputName === "telNo") {
            let financialGoal = (event.target.validity.valid) ? event.target.value : aData.telNo;
            aData.telNo = financialGoal;
        }
        else if (inputName === "driverNm") {
            aData.driverNm = value;
        }
        else if (inputName === "belongTo") {
            aData.belongTo = value;
        }
        else if (inputName === "gpsDistWrite") {
            aData.gpsDistWrite = getValidNumber(event, { minValue: 3, maxValue: 7, roundTo: 0 });
        }
        else if (inputName === "gpsDistSend") {
            aData.gpsDistSend = getValidNumber(event, { minValue: 10, maxValue: 1800, roundTo: 0 });
        }
        else if (inputName === "gpsDistVariant") {
            aData.gpsDistVariant = getValidNumber(event, { minValue: 10, maxValue: 300, roundTo: 0 });
        }
        else if (inputName === "mesDistSend") {
            aData.mesDistSend = getValidNumber(event, { minValue: 0, maxValue: 3600, roundTo: 0 });
        }
        //
        else if (inputName === "chargeInterval") {
            aData.chargeInterval = getValidNumber(event, { minValue: 30, maxValue: 9999, roundTo: 0 });;
        }
        else if (inputName === "loadPlaceNm") {
            aData.loadPlaceNm = value;
        }
        else if (inputName === "unLoadPlaceNm") {
            aData.unLoadPlaceNm = value;
        }
        else if (inputName === "loadProcess") {
            aData.loadProcess = event.target.checked;
        }
        else if (inputName === "unLoadProcess") {
            aData.unLoadProcess = event.target.checked;
        }
        else if (inputName === "gpsUnTrack") {
            aData.gpsUnTrack = event.target.checked;
        }
        else if (inputName === "accelAlert") {
            aData.accelAlert = event.target.checked;
        }
        else if (inputName === "sudAccelAlert") {
            aData.sudAccelAlert = getValidNumber(event, { minValue: 0, maxValue: 9.9 });
        }
        else if (inputName === "breakUrgentAlert") {
            aData.breakUrgentAlert = getValidNumber(event, { minValue: 0, maxValue: 9.9 });
        }
        else if (inputName === "vicintyAlert") {
            aData.vicintyAlert = getValidNumber(event, { minValue: 0, maxValue: 9.9 });
        }
        else if (inputName === "radius") {
            aData.radius = getValidNumber(event, { minValue: 0, maxValue: 99, roundTo: 0 });
        }
        else if (inputName === "routeAuto") {
            aData.routeAuto = event.target.checked;
        }
        else if (inputName === "dashcam") {
            aData.dashcam = event.target.checked;
        }
        else if (inputName === "fuelCons") {
            aData.fuelCons = getValidNumber(event, { minValue: 0, maxValue: 999.99 });
        }
        else if (inputName === "fuelType") {
            aData.fuelType = parseFloat(event.target.value);
        }

        this.setState({ data: aData });
    }

    private clearIMEI() {
        var body = {
            SysID: this.user.siteId,
            IMEI: this.state.data.imei
        };
        axiosInstance
            .post(HOST() + "/Form/V1/Mst/deactivate", body)
            .then((res: any) => {
                if (res.data.result) {
                    toast.success('IMEIを削除しました。', toastOptions);
                }
            });
        var aData = this.state.data;
        aData.imei = '';
        this.setState({ data: aData });
    }

    public onValidInputNumber(e: any, min: number, max: number) {
        var inputName = e.target.name;
        var value = e.target.value;
        if (value <= max) {
            var aData = this.state.data;
            if (inputName === "gpsRecordInterval") aData.gpsRecordInterval = value;
            else if (inputName === "gpsSendInterval") aData.gpsSendInterval = value;
        }
        this.setState({ data: aData });
    }

    public render() {
        return (
            <div>
                <div className="box edit-terminal">
                    <div className="box-title d-flex justify-content-between align-items-center mb-4">
                        <span className="page-header">端末登録</span>
                        <div className="buttonEdit">
                            <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.onCancel} >キャンセル</button>
                            <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.handleSubmitAdd} >保存</button>
                        </div>
                    </div>
                    <div className="row pb-3">
                        <div className="col-md-6">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>端末ID</td>
                                        <td>
                                            <span>{(this.state.data.cliID == 0) ? "" : this.state.data.cliID}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>端末名</td>
                                        <td>
                                            <input name="cliNm" type="text" value={this.state.data.cliNm} onChange={this.onInputChange} className="input-edit" maxLength={20} />
                                            &nbsp; <span className="errClass">{this.state.errorName}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>制限速度</td>
                                        <td>
                                            <input name="defSpdLim" type="number" min={0} max={200} value={this.state.data.defSpdLim} onChange={this.onInputChange} className="input-edit" />
                                            &nbsp;&nbsp;(km/h)
                                            <br />※端末の通常(エリア以外)制限速度を設定してください。
                                            <br />※0に設定した場合は、速度を制限しません。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>端末種類</td>
                                        <td>
                                            <input name="cliType" type="text" value={this.state.data.cliType} onChange={this.onInputChange} className="input-edit" maxLength={20} />
                                            &nbsp; <span className="errClass">{this.state.errorType}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>電話番号</td>
                                        <td>
                                            <input name="telNo" pattern="[0-9]*" type="text" value={this.state.data.telNo} onChange={this.onInputChange} className="input-edit" maxLength={20} />
                                            &nbsp; <span className="errClass">{this.state.errorPhone}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>所有者</td>
                                        <td>
                                            <input name="driverNm" type="text" value={this.state.data.driverNm} onChange={this.onInputChange} className="input-edit" maxLength={20} />
                                            &nbsp; <span className="errClass">{this.state.errorPIC}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>所属</td>
                                        <td>
                                            <input name="belongTo" type="text" value={this.state.data.belongTo} onChange={this.onInputChange} className="input-edit" maxLength={20} />
                                            &nbsp; <span className="errClass">{this.state.errorBelong}</span>
                                        </td>
                                    </tr>
                                    {
                                        this.state.co2EmissionDisplay && (
                                            <tr>
                                                <td>燃費</td>
                                                <td>

                                                    <input name="fuelCons" type="number" value={this.state.data.fuelCons} style={{ width: '25%' }} onChange={this.onInputChange} className="input-edit" maxLength={6} />
                                                    &nbsp; <span className="errClass">{this.state.errorFuelCons}</span>

                                                    <select className="" name="fuelType" onChange={this.onInputChange} value={this.state.data.fuelType}>
                                                        <option value="2.32">ガソリン</option>
                                                        <option value="2.58">軽油</option>
                                                    </select>
                                                    &nbsp;&nbsp;(km/L) &nbsp;&nbsp; <span className="errClass">{this.state.errorFuelType}</span>

                                                </td>
                                            </tr>)
                                    }

                                </tbody>
                            </table>
                            {this.user.roleID == 2 ? (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>アイコン変更</td>
                                            <td>
                                                <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.updateIcon()} >アイコン変更</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <table />)}
                            {this.user.roleID == 1 ? (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>GPS記録間隔</td>
                                            <td>
                                                <input name="gpsDistWrite" type="number" onChange={this.onInputChange} value={this.state.data.gpsDistWrite} className="input-edit" />
                                                &nbsp;&nbsp;(s) &nbsp;&nbsp;<span className="errClass">{this.state.errorGpsRecord}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>GPS送信間隔</td>
                                            <td>
                                                <input name="gpsDistSend" type="number" min={10} onChange={this.onInputChange} value={this.state.data.gpsDistSend} className="input-edit" />
                                                &nbsp;&nbsp;(s) &nbsp;&nbsp;<span className="errClass">{this.state.errorGpsSend}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>GPS許容誤差</td>
                                            <td>
                                                <input name="gpsDistVariant" type="number" value={this.state.data.gpsDistVariant} onChange={this.onInputChange} className="input-edit" />
                                                &nbsp;&nbsp;(m) &nbsp;&nbsp;<span className="errClass">{this.state.errorGpsDensity}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>MES受信間隔</td>
                                            <td>
                                                <input name="mesDistSend" type="number" min={0} value={this.state.data.mesDistSend} onChange={this.onInputChange} className="input-edit" />
                                                &nbsp;&nbsp;(s) &nbsp;&nbsp;<span className="errClass">{this.state.errorMes}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <table />)}
                        </div>
                        {this.user.roleID == 1 ? (
                            <div className="col-md-6">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>充電確認間隔</td>
                                            <td>
                                                <input name="chargeInterval" type="number" min={30} value={this.state.data.chargeInterval} onChange={this.onInputChange} className="input-edit" />
                                                &nbsp; <span className="errClass">{this.state.errorChargeStatus}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>積込み場</td>
                                            <td>
                                                <input name="loadPlaceNm" type="text" value={this.state.data.loadPlaceNm} onChange={this.onInputChange} className="input-edit" />
                                                &nbsp; <span className="errClass">{this.state.errorLoading}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>荷降し場</td>
                                            <td>
                                                <input name="unLoadPlaceNm" type="text" value={this.state.data.unLoadPlaceNm} onChange={this.onInputChange} className="input-edit" />
                                                &nbsp; <span className="errClass">{this.state.errorUnloading}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>積込み自動</td>
                                            <td><input name="loadProcess" type="checkbox" checked={this.state.data.loadProcess} onChange={this.onInputChange} /></td>
                                        </tr>
                                        <tr>
                                            <td>荷降し自動</td>
                                            <td><input name="unLoadProcess" type="checkbox" checked={this.state.data.unLoadProcess} onChange={this.onInputChange} /></td>
                                        </tr>
                                        <tr>
                                            <td>GPS記録しない</td>
                                            <td><input name="gpsUnTrack" type="checkbox" checked={this.state.data.gpsUnTrack} onChange={this.onInputChange} /></td>
                                        </tr>
                                        <tr>
                                            <td>加速度警告する</td>
                                            <td><input name="accelAlert" type="checkbox" checked={this.state.data.accelAlert} onChange={this.onInputChange} /></td>
                                        </tr>
                                        <tr>
                                            <td>急加速警告</td>
                                            <td>
                                                <input name="sudAccelAlert" type="number" step={0.1} value={this.state.data.sudAccelAlert} onChange={this.onInputChange} className="input-edit" />
                                                &nbsp;&nbsp;(G以上)&nbsp;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>急ブレーキ警告</td>
                                            <td>
                                                <input name="breakUrgentAlert" type="number" step={0.1} value={this.state.data.breakUrgentAlert} onChange={this.onInputChange} className="input-edit" />
                                                &nbsp;&nbsp;(G以上)&nbsp;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>急ハンドル警告</td>
                                            <td>
                                                <input name="vicintyAlert" type="number" step={0.1} value={this.state.data.vicintyAlert} onChange={this.onInputChange} className="input-edit" />
                                                &nbsp;&nbsp;(G以上) &nbsp;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>近接警報距離</td>
                                            <td>
                                                <input name="radius" type="number" step={0.1} value={this.state.data.radius} onChange={this.onInputChange} className="input-edit" />
                                                &nbsp;&nbsp;(m)&nbsp;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>自動でルートを選択する</td>
                                            <td><input name="routeAuto" type="checkbox" checked={this.state.data.routeAuto} onChange={this.onInputChange} /></td>
                                        </tr>
                                        <tr>
                                            <td>ＩＭＥＩ</td>
                                            <td>
                                                <input name="IMEI" type="text" value={this.state.data.imei} className="input-edit" readOnly={true} disabled={true} />
                                                <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.clearIMEI()} >削除</button><br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>アイコン変更</td>
                                            <td>
                                                <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={() => this.updateIcon()} >アイコン変更</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>) :
                            (<div />)}
                    </div>
                </div>
            </div>
        )
    };
}