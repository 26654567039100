import React, { useState } from "react";
import "./AccountInfoChange.css";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";
import userImage from "../../assets/img/user.jpg";
import { isBlank, toastOptions } from "../../common/Utils";
import { toast } from "react-toastify";

export default function AccountInfoChange(props: any) {
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");

    const [canChangeEmail, setCanChangeEmail] = useState(false);
	const [canChangePassword, setCanChangePassword] = useState(false);

	const [validating, setValidating] = useState(false);

    const history = useHistory();
    var userData = Cookies.get('user');
    var user = JSON.parse(userData ? userData : '{}');

	function validateForm() {
		setValidating(true);
		var valid = true;
		if (isBlank(password)) {
			valid = false;
		}

		if ((!isEmail(newEmail) || isBlank(confirmEmail) || newEmail !== confirmEmail) && canChangeEmail) {
			valid = false;
		}

		if ((isBlank(newPassword) || isBlank(confirmPassword) || newPassword !== confirmPassword) && canChangePassword) {
			valid = false;
		}

		return valid;
	}

	function isEmail(str: string) {
		return !isBlank(str) && /^[A-Za-z0-9_\.]{1,32}@[a-zA-Z0-9\-]{2,}(\.[a-zA-Z0-9]{2,4}){1,2}$/.test(str);
    }

    function handleSubmit(event: { preventDefault: () => void; }) {
		event.preventDefault();
		if (!validateForm()) {
			return;
        }
        var body = {
			loginId: user.username,
			password: password,
            newEmail: newEmail,
			confirmNewEmail: confirmEmail,
			newPassword: newPassword,
			confirmNewPassword: confirmPassword,
        };
        axiosInstance
			.post(HOST() + "/Form/V1/Mst/UpdateUser", body)
			.then((res: any ) => {

				if (res && res.data) {
					if (res.data.code === "1.2.4_001") {
						toast.error("現在のパスワードは正しくありません。", toastOptions);
						return;
					}
				}

				toast.success('更新に成功。', toastOptions);

				let user = JSON.parse(Cookies.get('user') || "{}");
				var body = {
					loginID: user.username,
					password: '**********'
				};
				axiosInstance
					.post(HOST() + "/Form/V1/Mst/logout", body)
					.then((res: any) => {
						if (res.data.result) {
							Cookies.remove('jwt');
							Cookies.remove('user');
							Cookies.remove('pv');
							window.location.href = '/login';
						}
					});
				
			}).catch(error => {
				if (error.response && error.response.data) {
					if (error.response.data.code === "1.2.4_001") {
						toast.error("現在のパスワードは正しくありません。", toastOptions);
					}
				}
			});

    }

	return (
		<div>
			<form>
				<div className="user-details box mb-5">
					<div className="user-image">
						<div className="image">
							<div className="bg-transfer"><img src={userImage} className="image" alt="" /></div>
						</div>
					</div>
					<div className="description clearfix">
						<h3>ログインID:</h3>
						<h2>{user.username}</h2>
						<h3>現在のメールアドレス:</h3>
						<h2>{user.mailAdd}</h2>
					</div>
				</div>
				<div className={(validating && isBlank(password)) ? 'error form-group' : 'form-group'}>
					<label>現在のパスワード：</label>
					<input type="password" className="form-control form-control-sm" onChange={e => setPassword(e.target.value)} />
					{
						validating && isBlank(password)  
							? <div className="text-error">現在のパスワード必須項目です。</div>
							: <span></span>
					}					
				</div>
				<fieldset className="mb-4">
					<legend>
						<div className="custom-control custom-checkbox">
							<input type="checkbox" className="custom-control-input" id="canChangeEmail" onChange={e => setCanChangeEmail(e.target.checked)} />
							<label className="custom-control-label font-weight-bold" htmlFor="canChangeEmail" >メールアドレスの変更</label>
						</div>
					</legend>
					<div className={(!isEmail(newEmail) && canChangeEmail && validating) ? 'error form-group' : 'form-group'}>
						<label >新しいメールアドレス：</label>
						<input
							type="text"
							className="form-control form-control-sm"
							id="newEmail"
							onChange={e => setNewEmail(e.target.value)}
							disabled={!canChangeEmail}
						/>
						{
							validating && isBlank(newEmail) && canChangeEmail
								? <div className="text-error">必須項目です。</div>
								: <span></span>
						}
						{
							validating && !isBlank(newEmail) && !isEmail(newEmail) && canChangeEmail
								? <div className="text-error">メールアドレスが無効です。</div>
								: <span></span>
						}
					</div>
					<div className={(isBlank(confirmEmail) || (newEmail !== confirmEmail && !isBlank(newEmail))) && canChangeEmail && validating ? 'error form-group' : 'form-group'}>
						<label >新しいメールアドレス再入力：</label>
						<input
							type="text"
							className="form-control form-control-sm"
							id="confirmNewEmail"
							onChange={e => setConfirmEmail(e.target.value)}
							disabled={!canChangeEmail}
						/>
						{
							validating && isBlank(confirmEmail) && canChangeEmail
								? <div className="text-error">必須項目です。</div>
								: <span></span>
						}
						{
							validating && !isBlank(newEmail) && !isBlank(confirmEmail) && newEmail !== confirmEmail && canChangeEmail
								? <div className="text-error">メールアドレスをもう一度入力してください。</div>
								: <span></span>
						}
					</div>
				</fieldset>
				<fieldset>
					<legend>
						<div className="custom-control custom-checkbox">
							<input
								type="checkbox"
								className="custom-control-input"
								id="canChangePassword"
								onChange={e => setCanChangePassword(e.target.checked)}
							/>
							<label className="custom-control-label font-weight-bold" htmlFor="canChangePassword" >バスワードの変更</label>
						</div>
					</legend>
					<div className={(isBlank(newPassword) && canChangePassword && validating) ? 'error form-group' : 'form-group'}>
						<label >新しいバスワード：</label>
						<input
							type="password"
							className="form-control form-control-sm"
							id="newPassword"
							onChange={e => setNewPassword(e.target.value)}
							disabled={!canChangePassword}
						/>
						{
							validating && canChangePassword && isBlank(newPassword)
								? <div className="text-error">必須項目です。</div>
								: <span></span>
						}
					</div>
					<div className={(isBlank(confirmPassword) || (newPassword !== confirmPassword && !isBlank(newPassword))) && canChangePassword && validating ? 'error form-group' : 'form-group'}>
						<label >新しいパスワード再入力：</label>
						<input
							type="password"
							className="form-control form-control-sm"
							id="confirmNewPassword"
							onChange={e => setConfirmPassword(e.target.value)}
							disabled={!canChangePassword}
						/>
						{
							validating && canChangePassword && isBlank(confirmPassword)
								? <div className="text-error">必須項目です。</div>
								: <span></span>
						}
						{
							validating && canChangePassword && !isBlank(newPassword) && !isBlank(confirmPassword) && newPassword !== confirmPassword
								? <div className="text-error">パスワードを再入力してください。</div>
								: <span></span>
						}
					</div>
				</fieldset>
			</form>
			<div className="accoutInfo-button d-flex d-flex justify-content-center">
				<button
					type="button"
					className="btn btn-primary m-3"
					disabled={!canChangePassword && !canChangeEmail}
					onClick={handleSubmit}>
					保存
				</button>
				<button
					type="button"
					className="btn btn-secondary m-3"
					onClick={() => props.onNavi('/') }> キャンセル</button >
			</div>
		</div>
    );
}