import React from 'react'
import './MultiCheckBox.css'
import CheckBox from './Checkbox'
import { HOST } from '../constant'
import axiosInstance from '../interceptor'

export default class MultiCheckBox extends React.Component<{
    urlData: string,
    ckey: string,
    cvalue: string,
    cname: string,
    onChange: Function,
    value: any[],
    isSingleCheck: boolean,
    extraLable?: string,
    extraValue?: string,
}, {
    checkData: any[],
    allChecked: boolean
}> {
    constructor(props: any) {
        super(props)
        this.state = {
            checkData: [],
            allChecked: false
        }
    }

    handleCheckChieldElement = (event: any, isAllCheck: boolean = false) => {
        let checkData = this.state.checkData
        let countCheck = 0;
        checkData.forEach(data => {
            if (isAllCheck) {
                data.isChecked = event.target.checked;
            } else if (data[this.props.ckey] == event.target.value && !this.props.isSingleCheck) {
                data.isChecked = event.target.checked;
            } else if (this.props.isSingleCheck) {
                data.isChecked = data[this.props.ckey] == event.target.value;
            }

            countCheck += data.isChecked ? 1 : 0;
        });
        this.setState({ checkData: checkData })
        if (isAllCheck) {
            this.setState({ allChecked: event.target.checked })
        } else {
            this.setState({ allChecked: countCheck === checkData.length });
        }
        this.props.onChange({
            data:
                this.state.checkData
                    .filter(e => e.isChecked)
                    .map(e => e[this.props.ckey])
        });
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        axiosInstance
            .get(HOST() + this.props.urlData)
            .then((response: any) => {
                if (response && response.data) {
                    const checkData = response.data.data || [];
                    if (this.props.extraLable) {
                        let extra: any = {};
                        extra[this.props.cvalue] = this.props.extraLable;
                        extra[this.props.ckey] = this.props.extraValue;
                        checkData.unshift(extra);
					}
                    this.setState({ checkData: checkData });
                }
            })
    }

    componentWillReceiveProps(nextProps: any) {
        const checkData = this.state.checkData;

        let value = nextProps.value || [];
        value = value.map((e: any) => e.toString());
        const key = nextProps.ckey;
        checkData.forEach((data: any) => {
            data.isChecked = value.includes(data[key].toString());
        })

        this.setState({ checkData: checkData });
    }

    render() {
        return (
            <ul className="MultiCheckBox">
                {
                    this.props.isSingleCheck ?
                        <span/> :
                        (<CheckBox
                            handleCheckChieldElement={(e: any) => this.handleCheckChieldElement(e, true)}
                            data={{ cvalue: 'すべて', ckey:  '' }}
                            isChecked={this.state.allChecked || false}
                            ckey='ckey'
                            cvalue='cvalue'
                        />)
                }
                {
                    this.state.checkData.map((data: any, index: any) => {
                        return (<CheckBox
                            key={this.props.cname + index}
                            handleCheckChieldElement={this.handleCheckChieldElement}
                            data={data}
                            isChecked={data.isChecked || false}
                            ckey={this.props.ckey}
                            cvalue={this.props.cvalue}
                        />)
                    })
                }
            </ul>
        );
    }
}