import "./RouteManagement.css";
import React from "react";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import Cookies from "js-cookie";
import { hexToRGB, rgbToHex, toastOptions, getValidNumber, calculatePath } from "../../common/Utils";
import MultiCheckBox from "../../common/MultiCheckBox/MultiCheckBox";
import SoundList from "../../common/SoundList/SoundList";
import { toast } from 'react-toastify';
import { promises } from "fs";

export default class EditRouteManagement extends React.Component<{
    onNavi: Function,
    path: any[],
    routeId: string,
    routeLinkingMode: boolean,
    goTime: number,
    reTime: number,
    distance: number,
}, {
    RouteID: number,
    Name: string,
    Radius: number,
    RouteInterval: number,
    Speed: number,
    GoTime: number,
    ReTime: number,
    Distance: number,
    Sound: number,
    _isUpdateAutomation: boolean, // temporary variable
    //Automation: any,
    Recognition: any,
    Color: string,
    Path: any[],
    RouteLinking: any[]
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            RouteID: props.routeId,
            Name: '',
            Radius: 100,
            RouteInterval: 20,
            Speed: 0,
            GoTime: this.props.goTime || 0,
            ReTime: this.props.reTime || 0,
            Distance: this.props.distance || 0,
            Sound: 0,
            _isUpdateAutomation: false,
            //Automation: false,
            Recognition: 0,
            Color: '',
            Path: props.path,
            RouteLinking: []
        };

        this.goBack = this.goBack.bind(this);
        this.delete = this.delete.bind(this);
    }

    userData = Cookies.get('user');
    user = JSON.parse(this.userData ? this.userData : '{}');
    tempRouteMap: any = {};

    private delete() {
        axiosInstance
            .delete(HOST() + "/Form/V1/Dat/Route/DeleteRoute?routeID=" + this.state.RouteID)
            .then((e: any) => {
                const data = JSON.parse(e.data.data);
				if (this.isDeleteOk(data)) {
                    toast.success('ルートを削除しました。', toastOptions);
                    this.goBack(null);
                    this.syncDataAllUser();
                } else {
					[1, 2, 3, 4, 5].forEach(v => this.showDeleteMessage(data, v));
				}
            });
    }

    private isDeleteOk(data: any) {
		return Object.keys(data).length === 1 && Object.keys(data)[0] === '0';
    }

    private showDeleteMessage(data: any, checkType: any) {
		if ([3].indexOf(checkType) >= 0 && data[checkType] && data[checkType].length > 0) {
            toast.error(`「${data[checkType].join('」「')}」と紐づけされているため削除できません。`, toastOptions);
        }

        if ([5].indexOf(checkType) >= 0 && data[checkType] && data[checkType].length > 0) {
            toast.error(`「${data[checkType].join('」「')}」と紐づけされているため削除できません。`, toastOptions);
        }
     
        if ([1].indexOf(checkType) >= 0 && data[checkType] === null) {
			toast.error(`ルートが見つかりません。`, toastOptions);
        }
        if ([2].indexOf(checkType) >= 0 && data[checkType] === null) {
            toast.error(`積込み種別設定で使用されているため削除できません。`, toastOptions);
        }

        if ([4].indexOf(checkType) >= 0 && data[checkType] === null) {
			toast.error(`ルートの削除に失敗しました。`, toastOptions);
        }
    }

    private async save() {
        let valid = true;
        if (!this.state.Name) {
            toast.error('ルート名を設定してください。', toastOptions);
            valid = false;
        }

        if (!valid) {
            return;
        }

        const countPoly = await axiosInstance.get(HOST() + "/Form/V1/Dat/Route/CountPoly").then(res => res.data ? res.data.data : 9999);
        if (countPoly > 10000 && !this.state.RouteID) {
            toast.error('ルートの作成上限を超過しています。\n ルートを削除。又は、無効化した後お試しください。', toastOptions);
            return;
        }

        var body = {
            RouteID: this.state.RouteID,
            Name: this.state.Name,
            Radius: this.state.Radius,
            RouteInterval: this.state.RouteInterval,
            Speed: this.state.Speed,
            GoTime: this.state.GoTime,
            ReTime: this.state.ReTime,
            Distance: this.state.Distance,
            Sound: this.state.Sound,
            //Automation: this.state.Automation,
            Recognition: this.state.Recognition,
            ColorRGB: hexToRGB(this.state.Color),
            UserId: this.user.id
        };

        const api = this.props.routeId ?
            axiosInstance.put(HOST() + "/Form/V1/Dat/Route/UpdateRoute", body) :
            axiosInstance.post(HOST() + "/Form/V1/Dat/Route/AddRoute", body);
        
        const saveData = await api.then(res => res.data);
        if ((this.state._isUpdateAutomation || !this.props.routeId) && this.state.Path) {
            const path = this.state.Path;
            path.forEach(e => {
                e.RouteID = saveData.data.routeId;
                //e.Automation = this.state.Automation ? 1 : 0;
                e.Recognition = this.state.Recognition;
                delete e["automation"];
                delete e["recognition"];
            });
            const pathData = {
                RouteID: saveData.data.routeId,
                Path: path
            };

            await axiosInstance.put(HOST() + "/Form/V1/Dat/RouteDetail/Update", pathData).then(res => {
                if (res.data && res.data.data) {
                    this.goBack(saveData.data.routeId);
                    toast.success('ルートを保存しました。', toastOptions);
                    this.syncDataAllUser();
                    document.querySelectorAll('.RouteList').forEach((e: any) => e.click()); // reload all route
                } else {
                    toast.error('ルートの保存に失敗しました', toastOptions);
                }
            });
        } else {
            if (saveData && saveData.data) {
                this.goBack(saveData.data.routeId);
                toast.success('ルートを保存しました。', toastOptions);
                this.syncDataAllUser();
                document.querySelectorAll('.RouteList').forEach((e: any) => e.click()); // reload all route

            } else {
                toast.error('ルートの保存に失敗しました', toastOptions);
            }
        }
    }

    componentDidMount() {

        if (!this.props.routeId) {
            return;
        }
        const userid = this.user.id;
        axiosInstance
            .get(HOST() + `/Form/V1/Dat/Route/GetRouteDetail?routeid=${this.props.routeId}&userid=${userid}`)
            .then(response => {
                const data = response.data.data;
                this.setState({
                    Name: data.name,
                    Radius: data.radius,
                    RouteInterval: data.routeInterval,
                    Speed: data.speed,
                    GoTime: data.goTime,
                    ReTime: data.reTime,
                    Distance: data.distance,
                    Color: rgbToHex(data.colorRGB),
                    Sound: Number(data.sound),
                });
            });

        axiosInstance
            .get(HOST() + `/Form/V1/Dat/RouteDetail/GetByID?routeID=${this.props.routeId}`)
            .then(response => {
                if (!response || !response.data || !response.data.data || response.data.data.length == 0) {
                    return;
                }
                const Path = response.data.data;
                this.setState({
                    Path,
                    //Automation: Path[0].automation == 1,
                    Recognition: Path[0].recognition ? Path[0].recognition : 0
                });
            });
    }

    public syncDataAllUser() {
        axiosInstance.get(HOST() + "/Form/V1/Dat/Route/SyncDataAllUser");
    }

    public async exportRoute() {
        const link: any = document.createElement("a");

        var accessToken: any = { 'authorization': Cookies.get('jwt') };
        const result = await fetch('/Form/V1/Dat/RouteDetail/Export?routeID=' + this.props.routeId, {
            headers: new Headers(accessToken)
        });
    
        const blob = await result.blob()
        const href = window.URL.createObjectURL(blob)

        link.download = `Route_Detail_${this.state.Name}_${new Date().getTime()}.csv`;
        link.href = href;
        link.click();
    }

    private goBack(id: any = null) {
        this.props.onNavi('/', {
            target: 'routeList',
            foucsId: id
        });
    }

    private async calcLinkingInfo() {
        const userid = this.user.id;

        for (const id of this.state.RouteLinking) {
            if (!this.tempRouteMap[id]) {
                const path = await axiosInstance
                    .get(HOST() + `/Form/V1/Dat/RouteDetail/GetByID?routeID=${id}`)
                    .then(response => {
                        if (!response || !response.data || !response.data.data || response.data.data.length == 0) {
                            return [];
                        }
                        let path = response.data.data;
                        for (var i = 0; i < path.length; i++) {
					        path[i].lat = path[i].posLat;
					        path[i].lng = path[i].posLon;
				        }
                        return path;
                    });

                const metadata = await axiosInstance
                    .get(HOST() + `/Form/V1/Dat/Route/GetRouteDetail?routeid=${id}&userid=${userid}`)
                    .then(response => response.data.data);

                this.tempRouteMap[id] = { path, metadata };
            }
        };

        let GoTime = 0;
        let ReTime = 0;
        let Distance = 0;
        let Path: any = [];
        let preId = 0;
        for (const id of this.state.RouteLinking) {
            if (preId) {
                const prePath = this.tempRouteMap[preId].path;
                const path = this.tempRouteMap[id].path;
                const res: any = await calculatePath(prePath[prePath.length - 1], path[0]);
                GoTime += Math.round(res.GoTime / 60);
                ReTime += Math.round(res.ReTime / 60);
                Distance += res.Distance;

                const resPath = res.response.routes[0].overview_path
                for (var i = 0; i < resPath.length; i++) {
                    Path.push({
                        PosLat: resPath[i].lat(),
                        PosLon: resPath[i].lng()
                    });
                }
            }
            const meta = this.tempRouteMap[id].metadata;
            GoTime += meta.goTime;
            ReTime += meta.reTime;
            Distance += meta.distance;
            Path = Path.concat(this.tempRouteMap[id].path);
            preId = id;
        }

        Path.forEach((e: any, i: any) => {
            e.PosID = i
        });
        this.setState({
            GoTime,
            ReTime,
            Distance,
            Path
        })
    }

    public render() {
        return (
            <div className="routeEditContainer">
                <div className="clearfix">
                <div className="buttonEdit">
                    <button
                        type="button"
                        className="btn btn-primary btn-sm mar-5"
                        onClick={() => this.exportRoute()}
                        hidden={!this.props.routeId}
                    >
                        ルート出力
                    </button >
                    <button
                        type="button"
                        className="btn btn-primary btn-sm mar-5"
                        onClick={() => this.save()}
                    >
                        保存
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary btn-sm mar-5"
                        onClick={() => this.delete()}
                        hidden={!this.props.routeId}
                    >
                        削除
                    </button >
                    <button
                        type="button"
                        className="btn btn-primary btn-sm mar-5"
                        onClick={() => this.goBack(null)}
                    >
                        キャンセル
                    </button >
                    </div>
                    </div>
                
                    <div className="form-row row" style={{ display: this.props.routeLinkingMode ? 'flex' : 'none' }}>
                        <label className="col-3 col-form-label form-control-label">対象ルート</label>
                        <div className="col-4">
                            <MultiCheckBox
                                ckey='routeID'
                                cvalue='routeNm'
                                cname='routeList'
                                urlData="/Form/V1/Dat/RouteToInterval/ListAll"
                                value={this.state.RouteLinking}
                                onChange={(e: any) => this.setState({ RouteLinking: e.data }, () => this.calcLinkingInfo())}
                                isSingleCheck={false}
                            />
                        </div>
                    </div>
                    <div className="form-row row">
                        <label className="col-3 col-form-label form-control-label">ID</label>
                        <div className="col-9">
                            {this.state.RouteID !== 0 ? this.state.RouteID : '' }
                        </div>
                    </div>
                    <div className="form-row row">
                        <label className="col-3 col-form-label form-control-label">ルート名</label>
                        <div className="col-9">
                            <input
                                type="text"
                                value={this.state.Name}
                                maxLength={30}
                                className="form-control form-control-sm w-50"
                                onChange={e => this.setState({ Name: e.target.value})} />
                        </div>
                    </div>
                    <div className="form-row row">
                        <label className="col-3 col-form-label form-control-label">範囲(m)</label>
                        <div className="col-9">
                            <input
                                type="number"
                                className="form-control form-control-sm w-50"
                                value={this.state.Radius}
                            onChange={e => this.setState({ Radius: getValidNumber(e, { maxDigit: 3, minValue: 0, roundTo: 0 }) })}
                            />
                            <p>※ルートから指定した距離を離れたら、ルート逸脱と判断します。</p>
                        </div>
                    </div>
                    <div className="form-row row">
                        <label className="col-3 col-form-label form-control-label">逸脱時間(秒)</label>
                        <div className="col-9">
                            <input
                                type="number"
                                value={this.state.RouteInterval}
                                className="form-control form-control-sm w-50"
                            onChange={e => this.setState({ RouteInterval: getValidNumber(e, { maxDigit: 4, minValue: 0, roundTo: 0 }) })}
                            />
                            <p>※指定した時間ルートから逸脱した場合に、ルート逸脱と判断します。</p>
                        </div>
                    </div>
                    <div className="form-row row">
                        <label className="col-3 col-form-label form-control-label">制限速度(km/h)</label>
                        <div className="col-9">
                            <input
                                type="number"
                                className="form-control form-control-sm w-50 inline"
                                value={this.state.Speed}
                            onChange={e => this.setState({ Speed: getValidNumber(e, { maxDigit: 3, minValue: 0, roundTo: 0 }) })}
                            />
                        </div>
                    </div>
                    <div className="form-row row">
                        <label className="col-3 col-form-label form-control-label">往路予想時間(分)</label>
                        <div className="col-9">
                            <input
                                type="number"
                                className="form-control form-control-sm w-50"
                                value={this.state.GoTime}
                            onChange={e => this.setState({ GoTime: getValidNumber(e, { maxDigit: 4, minValue: 0, roundTo: 0 }) })}
                            />
                        </div>
                    </div>
                    <div className="form-row row">
                        <label className="col-3 col-form-label form-control-label">復路予想時間(分)</label>
                        <div className="col-9">
                            <input
                                type="number"
                                className="form-control form-control-sm w-50"
                                value={this.state.ReTime}
                            onChange={e => this.setState({ ReTime: getValidNumber(e, { maxDigit: 4, minValue: 0, roundTo: 0 }) })}
                            />
                        </div>
                    </div>
                    <div className="form-row row">
                        <label className="col-3 col-form-label form-control-label">走行距離(m)</label>
                        <div className="col-9">
                            <input
                                type="number"
                                className="form-control form-control-sm w-50"
                                value={this.state.Distance}
                            onChange={e => this.setState({ Distance: getValidNumber(e, { maxDigit: 7, minValue: 0, roundTo: 0 }) })}
                            />
                        </div>
                    </div>
                    
                    {/*<div className="form-row row">
                        <label className="col-3 col-form-label form-control-label">端末が、本ルートの</label>
                        <div className="col-9">
                            <input
                                type="number"
                                className="form-control form-control-sm w-25 inline"
                                value={this.state.Recognition}
                                onChange={e => this.setState({ Recognition: getValidNumber(e, { maxValue: 100, roundTo: 0, minValue: 0 }), _isUpdateAutomation: true })}
                            /> &nbsp;&nbsp;％以上を走行した場合、本ルートを選択したものと認識する。
                            <div>※手動でルートを選択した場合、そちらを有効とする。</div>
                        </div>
                    </div>*/}
                    <div className="form-row row">
                        <label className="col-3 col-form-label form-control-label">ルート変更時音声</label>
                        <SoundList
				    		fetchUrl="/Form/V1/Dat/Sound/ListAll"
                            className='col-9 select-w-50'
                            type="routeFlg"
				    		value={this.state.Sound}
				    		onChange={(e: any) => this.setState({ Sound: Number(e.target.value)})}
				    	/>
                    </div>
                    <div className="form-row row">
                        <label className="col-3 col-form-label form-control-label">色</label>
                        <div className="col-9">
                            <input
                                className="form-control form-control-sm w-25"
                                value={this.state.Color}
                                type="color"
                                onChange={e => this.setState({ Color: e.target.value })}/>
                        </div>
                    </div>
              </div >
        );
    }
}


//<div className="form-row row">
//    <label className="col-3 col-form-label form-control-label">自動ルート認識</label>
//    <div className="col-9">
//        <input
//            type="checkbox"
//            checked={this.state.Automation}
//            onChange={e => this.setState({ Automation: e.target.checked, _isUpdateAutomation: true })}
//        />
//    </div>
//</div>