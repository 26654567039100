import * as React from 'react';
import './Collapse.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { hideContextMenu } from '../Utils';

export default class VCollapse extends React.Component<{
	target: any,
	collapseToRight: boolean
}, {
	isOpen: any,
	isFullWidth: any,
}> {
	constructor(props: any) {
		super(props);
		this.state = {
			isOpen: this.props.target ? !this.props.target.state.isOpen : true,
			isFullWidth: false
		};
	}

	isResizing = false;

	private getArrowDirection() {
		const collapseToRight: any = this.props.collapseToRight;
		return "arrow " + (this.state.isOpen ^ collapseToRight ? "left" : "right");
	}

	componentWillReceiveProps(nextProps: any) {
		this.setState({
			isOpen: nextProps.target ? nextProps.target.state.isOpen : true
		});

		if (nextProps.target) {
			const _this = this;
			const resize: any = document.getElementsByClassName(this.getResizeClassName())[0];
			resize.addEventListener("mousedown", (e: any) => {
				hideContextMenu();
				if (e.button == 0) {
					_this.isResizing = true;
					resize.style.borderLeft = "2px solid #03a9f4";
                }
			}); 
			document.addEventListener("mouseup", (e) => {
				if (_this.isResizing) {
					if (this.props.collapseToRight) {
						resize.style.left = "";
						this.props.target.setWidth(window.innerWidth - e.x);
					} else {
						this.props.target.setWidth(e.x);
						resize.style.left = e.x + "px";
                    }
                }
				_this.isResizing = false;
				resize.style.borderLeft = '';
				resize.style.borderRight = '';
			});
			document.addEventListener("mousemove", (e: any) => {
				if (_this.isResizing) {
					if (this.props.collapseToRight) {
						const navRight: any = document.getElementById('navRight');
						resize.style.left = -(window.innerWidth - e.x - navRight.offsetWidth) + "px";
					} else {
						resize.style.left = e.x + "px";
                    }
					if(e.stopPropagation) e.stopPropagation();
					if(e.preventDefault) e.preventDefault();
					e.cancelBubble=true;
					e.returnValue=false;
					return false;
				}

			});
        }
	}

	getResizeClassName() {
		return this.props.collapseToRight ? "VCollapse-resize-left" : "VCollapse-resize-right";
    }

	private toggle() {
		const isOpen = !this.state.isOpen;
		this.setState({ isOpen });
		this.props.target.setOpen(isOpen);
		const resize: any = document.getElementsByClassName(this.getResizeClassName())[0];
		resize.style.left = '';
		hideContextMenu();
	}

	private toggleFullWidth() {
		const isFullWidth = !this.state.isFullWidth;
		this.setState({ isFullWidth });
		this.props.target.setOpen(this.state.isOpen, isFullWidth);
		const resize: any = document.getElementsByClassName(this.getResizeClassName())[0];
		resize.style.left = '';
		hideContextMenu();
	}

	render() {
		return (
			<div>
				{this.props.collapseToRight
					? (<div className="full-box-icon full-box-icon-right" onClick={() => this.toggleFullWidth()}>
						<FontAwesomeIcon icon={faExpandAlt}></FontAwesomeIcon>
					</div>)
					: (<div className="full-box-icon full-box-icon-left" onClick={() => this.toggleFullWidth()}>
						<FontAwesomeIcon icon={faExpandAlt}></FontAwesomeIcon>
					</div>)
				}
				<div
					className={"arrow-container " + (this.props.collapseToRight ? "right-container" : "left-container")}
					onClick={() => this.toggle()}>
					<div className={this.getArrowDirection()}></div>
				</div>
				<div className={this.getResizeClassName()}/>
			</div>
		);
    }
}