import * as React from 'react';
import { hideContextMenu } from '../Utils';
import './Collapse.css';

export default class VCollapse extends React.Component<{
	target: any,
	collapseToDown: boolean
}, {
	isOpen: any
}> {
	constructor(props: any) {
		super(props);
		this.state = {
			isOpen: this.props.target ? !this.props.target.state.isOpen : true
		};
	}

	private getArrowDirection() {
		const collapseToDown: any = this.props.collapseToDown;
		return "h-arrow " + (this.state.isOpen ^ collapseToDown ? "up" : "down");
	}

	isResizing = false;

	componentWillReceiveProps(nextProps: any) {
		this.setState({
			isOpen: nextProps.target ? nextProps.target.state.isOpen : true
		});

		if (nextProps.target) {
			const _this = this;
			const headerHeight = document.getElementsByTagName('header')[0].offsetHeight;
			const resize: any = document.getElementsByClassName(this.getResizeClassName())[0];
			resize.addEventListener("mousedown", (e: any) => {
				hideContextMenu();
				if (e.button == 0) {
					_this.isResizing = true;
					if (this.props.collapseToDown) {
						resize.style.borderTop = "2px solid #03a9f4";
					} else {
						resize.style.borderBottom = "2px solid #03a9f4";
                    }
                }
			}); 
			document.addEventListener("mouseup", (e) => {
				if (_this.isResizing) {
					if (this.props.collapseToDown) {
						resize.style.top = '';
						this.props.target.setOpen(this.state.isOpen, `${window.innerHeight - e.y}px`);
					} else {
						this.props.target.updateTopBarHeight(0, `${e.y - headerHeight}px`);
						resize.style.top = '';
                    }
                }
				_this.isResizing = false;
				resize.style.borderTop = '';
				resize.style.borderBottom = '';
			});
			document.addEventListener("mousemove", (e: any) => {
				if (_this.isResizing) {
					if (this.props.collapseToDown) {
						const bottomTab: any = document.getElementById('bottomTab');
						resize.style.top = -(window.innerHeight - e.y - bottomTab.offsetHeight) + "px";
					} else {
						resize.style.top = (e.y - headerHeight) + "px";
                    }
					if(e.stopPropagation) e.stopPropagation();
					if(e.preventDefault) e.preventDefault();
					e.cancelBubble=true;
					e.returnValue=false;
					return false;
				}

			});
        }
	}

	private toggle() {
		const isOpen = !this.state.isOpen;
		this.setState({ isOpen });
		this.props.target.setOpen(isOpen);
		hideContextMenu();
	}

	getResizeClassName() {
		return this.props.collapseToDown ? "VCollapse-resize-top" : "VCollapse-resize-bottom";
    }

	render() {
		return (
			<div>
				<div onClick={() => this.toggle()}
					className={"h-arrow-container " + (this.props.collapseToDown ? "down-container" : "up-container")}>
					<div className={this.getArrowDirection()} ></div>
				</div>

				<div className={this.getResizeClassName()} style={{display: this.state.isOpen ? '' : 'none'} }/>
			</div>
		);
    }
}