import { publicDecrypt } from "crypto";
import Cookies from "js-cookie";
import { SETTING, API_KEY, HOST, API_NAME } from "./constant";
import axiosInstance from "./interceptor";

export function isBlank(str: string) {
    return (!str || 0 === str.length || /^\s*$/.test(str));
}

export function rgbToHex(color: string) {
    if (!color) {
        return "#000000";
    }
    var colorArr = color.split("/");
    return (
        "#" +
        componentToHex(parseInt(colorArr[0])) +
        componentToHex(parseInt(colorArr[1])) +
        componentToHex(parseInt(colorArr[2]))
    );
}

export function hexToRGB(color: string) {
    if (color === "" || color === null) {
        return "000/000/000";
    }
    return (
        componentToInt(color.substr(1, 2)) + "/" +
        componentToInt(color.substr(3, 2)) + "/" +
        componentToInt(color.substr(5, 2))
    );
}

function componentToHex(c: number) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

function componentToInt(c: string) {
    return parseInt(c, 16);
}

export function jsonDiff(obj1: any, obj2: any) {
    const result: any = {};
    if (Object.is(obj1, obj2)) {
        return undefined;
    }
    if (!obj2 || typeof obj2 !== 'object') {
        return obj2;
    }
    Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach(key => {
        if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key]) && obj2[key] !== undefined) {
            result[key] = obj2[key];
        }
        if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
            const value = jsonDiff(obj1[key], obj2[key]);
            if (value !== undefined) {
                result[key] = value;
            }
        }
    });
    return result;
}

export function hideMenu(menuID: any) {
    const menu: any = document.querySelector("[id='" + menuID + "']");
    if (menu && menu.getAttribute('aria-expanded') == 'true') {
        menu.click();
    }
}

export function hideContextMenu(force = false) {
    const openContext = Number(Cookies.get('openContext'));
    if (!force && (Date.now() - openContext < 1000)) {
        return;
    }
    const contextMenu: any = document.getElementById('mapContextMenu');
    contextMenu && (contextMenu.style.display = "none");
}

export function showContextMenu(event: any, _this: any) {
    Cookies.set('openContext', `${Date.now()}`);
    const contextMenu: any = document.getElementById('mapContextMenu');
    const topTab: any = document.getElementById('topTab');
    const navLeft: any = document.getElementById('navLeft');

    contextMenu.style.display = "block";
    contextMenu.style.top = (event.pixel.y - 52 + topTab.offsetHeight) + "px";

    const screenWidth = window.innerWidth;
    const contextWidth = contextMenu.offsetWidth;
    const left = (event.pixel.x + navLeft.offsetWidth);
    contextMenu.classList.remove('left-context', 'right-context');

    if (left + contextWidth < screenWidth) {
        contextMenu.style.left = left + "px";
        contextMenu.classList.add('left-context');
    } else {
        contextMenu.style.left = (left - contextWidth) + "px";
        contextMenu.classList.add('right-context');
    }

    const latLonContext: any = document.querySelector("#LatLonContext");
    latLonContext.innerHTML = `${event.latLng.lat().toFixed(7)}, ${event.latLng.lng().toFixed(7)}`;;
    latLonContext.dataset.latlon = `${event.latLng.lat()}, ${event.latLng.lng()}`;;

    Cookies.set('mapPos_t', JSON.stringify({
        zoom: _this.state.map.zoom,
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
    }));
}

export function stringToDate(stringDate: string, stringTime: string) {
    if (stringDate && stringTime) {
        return new Date(`${stringDate} ${stringTime}`);
    }

    return null;
}

export function drawPathToMap(path: any[], map: any, colorRGB: string, fitBounds = false, options: any = {}) {
    var bounds = new window.google.maps.LatLngBounds();
    for (var i = 0; i < path.length; i++) {
        path[i].lat = path[i].posLat;
        path[i].lng = path[i].posLon;
        bounds.extend(path[i]);
    }
    let fromMarker = null;
    let toMarker = null;
    if (options && !options.hideMarker) {
        fromMarker = new window.google.maps.Marker({
            map: map,
            position: path[0],
            draggable: false,
            animation: window.google.maps.Animation.DROP,
            title: 'ここから',
            label: options.hideMarkerText ? undefined : { color: 'white', text: "出" }
        });

        toMarker = new window.google.maps.Marker({
            map: map,
            position: path[path.length - 1],
            draggable: false,
            animation: window.google.maps.Animation.DROP,
            title: 'ここまで',
            label: options.hideMarkerText ? undefined : { color: 'white', text: "到" }
        });
	}
    var color = rgbToHex(colorRGB);
    var polyline = new window.google.maps.Polyline({
        path: path,
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: 0.35,
        map: map
    });
    polyline.setMap(map);
    fitBounds && map.fitBounds(bounds);

    return {
        drawedPath: { polyline, fromMarker, toMarker, pathData: path, bounds },
        pathDrawedPosition: bounds.getCenter()
    }
}

export function timeToString(days: any, hours: any, minutes: any, seconds: any) {
    let time = '';
    if (seconds != null && seconds != undefined) {
        time += seconds + '秒';
    }

    if (days || hours || minutes) {
        time = minutes + '分' + time;
    } else {
        return time;
    }

    if (days || hours) {
        time = hours + '時' + time;
    } else {
        return time;
    }

    if (days) {
        time = days + '日' + time;
    } else {
        return time;
    }

    return time;
}

export const toastOptions: any = {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

export function getValidNumber(
    event: any,
    config: NumberConfig): number {
    let number = Number(event.target.value)

    if (config.maxDigit || config.maxValue) {
        let maxDigit: any = config.maxDigit
        let max = Math.pow(10, maxDigit);
        if (config.maxValue != null) {
            max = max < config.maxValue ? max : (config.maxValue + 0.0001);
        }
        number = number < max ? number : (max - 0.0001);
    }

    if (config.minValue != null) {
        number = number < config.minValue ? config.minValue : number;
    }

    if (config.roundTo != null) {
        let magic = Math.pow(10, config.roundTo);
        number = Math.round(number * magic) / magic;
    }

    return number;
}

class NumberConfig {
    maxDigit?: number = 0;
    maxValue?: any = null;
    minValue?: any = null;
    roundTo?: any = null;
}

export async function calculatePath(start: any, dest: any, waypoints = []) {
    countAPIGoogle(API_NAME.DIRECTION);
    const goPromise: any = await new Promise(resolve => {
        new window.google.maps.DirectionsService().route({
            origin: start,
            destination: dest,
            waypoints: waypoints,
            travelMode: 'DRIVING',
            provideRouteAlternatives: false
        }, (responseGo: any) => resolve(responseGo));
    })

    countAPIGoogle(API_NAME.DIRECTION);
    const rePromise: any = await new Promise(resolve => {
        new window.google.maps.DirectionsService().route({
            origin: dest,
            destination: start,
            waypoints: waypoints,
            travelMode: 'DRIVING',
            provideRouteAlternatives: false
        }, (responseRe: any) => resolve(responseRe));
    })

    const legGo = goPromise.routes[0] ? goPromise.routes[0].legs[0] : 0;
    const legRe = rePromise.routes[0] ? rePromise.routes[0].legs[0] : 0;

    return {
        GoTime: legGo.duration ? legGo.duration.value : 0,
        ReTime: legRe.duration ? legRe.duration.value : 0,
        Distance: legGo.distance ? legGo.distance.value : 0,
        response: goPromise
    };
}

export function haversineDistance(mk1: any, mk2: any) {
    var R = 3958.8; // Radius of the Earth in miles
    var rlat1 = mk1.position.lat() * (Math.PI / 180); // Convert degrees to radians
    var rlat2 = mk2.position.lat() * (Math.PI / 180); // Convert degrees to radians
    var difflat = rlat2 - rlat1; // Radian difference (latitudes)
    var difflon = (mk2.position.lng() - mk1.position.lng()) * (Math.PI / 180); // Radian difference (longitudes)

    var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
    return d * 1609.344; //to metter
}

export function tableResizable(id: string) {
    const table = document.getElementById(id);
    if (!table) {
        return;
    }

    const cells = table.querySelectorAll('th');
    reCalcTableWidth(table);

    cells.forEach((e: any) => {
        e.style.position = 'relative';

        const oldSpan = e.querySelector(`.${id}-table-resize`);
        if (oldSpan) {
            e.removeChild(oldSpan);
        }

        const span = document.createElement('span');
        span.classList.add(`${id}-table-resize`);
        span.style.height = (table.scrollHeight - 1) + 'px';
        e.appendChild(span);

        let isResizing = false;
        span.addEventListener("mousedown", (e: any) => {
            if (e.button == 0) {
                isResizing = true;
                span.style.borderRight = "3px solid #03a9f4";
            }
        });

        document.addEventListener("mouseup", (e) => {
            if (isResizing) {
                isResizing = false;
                span.style.left = "";
                span.style.borderRight = '';
                const parentNode: any = span.parentNode;
                if (parentNode) {
                    parentNode.style.width = (e.x - parentNode.getBoundingClientRect().x) + "px";
                    reCalcTableWidth(table);
                    const resizers = table.querySelectorAll(`.${id}-table-resize`);
                    resizers.forEach((resize: any) => {
                        resize.style.height = (table.scrollHeight - 1) + 'px';
                    });
                }
            }
        });

        document.addEventListener("mousemove", (e: any) => {
            const parentNode: any = span.parentNode;
            if (isResizing && parentNode) {
                span.style.left = (e.x - parentNode.getBoundingClientRect().x - span.offsetWidth / 2) + "px";
                if (e.stopPropagation) e.stopPropagation();
                if (e.preventDefault) e.preventDefault();
                e.cancelBubble = true;
                e.returnValue = false;
                return false;
            }

        });
    });

}

function reCalcTableWidth(table: any) {
    let totalWidth = 0;
    const headers = table.querySelectorAll('th');
    headers.forEach((e: any) => {
        if (!e.style.width && e.offsetWidth) {
            e.style.width = e.offsetWidth + 'px';
        }
        totalWidth += Number(e.style.width.replace('px', ''));
    });

    if (totalWidth) {
        table.style.width = totalWidth + 'px';
    }
}

export function initMapUtils(_this: any, homePostion: any) {
    countAPIGoogle(API_NAME.STATIC_MAP);
    let savedPos: any = {};
	try {
        savedPos = JSON.parse(homePostion || "{}");
	} catch {}
    const mapPos = {
        lat: savedPos.lat || SETTING.MAP_CENTER_Y,
        lng: savedPos.lng || SETTING.MAP_CENTER_X,
        zoom: savedPos.zoom || SETTING.MAP_SCALE
    }
    Cookies.set("mapDefault", JSON.stringify(mapPos));
    const map = new window.google.maps.Map(document.getElementById("map"), {
        center: mapPos,
        disableDoubleClickZoom: true,
        streetViewControl: false,
        zoom: mapPos.zoom,
        mapTypeControl: false,
        draggableCursor: 'default',
        gestureHandling: 'greedy',
        disableDefaultUI: true,
        zoomControl: true,
        minZoom: 3
    });

    let contextMenu = document.getElementById('contextMenu');
    map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(contextMenu);

    hideContextMenu();
    if (!isMobile()) {
        window.google.maps.event.addListener(map, "rightclick", (event: any) => showContextMenu(event, _this));
    }
    window.google.maps.event.addListener(map, "click", () => hideContextMenu());
    window.google.maps.event.addListener(map, "zoom_changed", () => hideContextMenu());
    window.google.maps.event.addListener(map, "center_changed", () => hideContextMenu());
    document.addEventListener('click', () => hideContextMenu());
    _this.setState({ map });
    document.oncontextmenu = () => false;
    return map;
}

export function importGoogleMap() {
    let script: any = document.createElement("script");
    script.async = true;
    script.crossorigin = 'anonymous';
    script.Crossorigin = "anonymous"
    console.log('-----------')
    console.log(API_KEY);
    console.log('-----------')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=geometry&libraries=places&callback=initMap&language=ja-jp`;
    document.head.appendChild(script);
}

export function generalInitHome(_this: any, homePosition: any) {
    var accessToken = Cookies.get('jwt');
    var user: any = JSON.parse(Cookies.get('user') || "{}");
    var skipRemindTime: any = Cookies.get('skipRemindTime');

    if (!accessToken) {
        Cookies.remove('jwt');
        Cookies.remove('user');
        Cookies.remove('pv');
        window.location.href = '/login';
        return;
    }

    window.initMap = () => _this.initMap(homePosition);
    if (typeof window.google === "undefined") {
        importGoogleMap();
    } else {
        _this.initMap(homePosition);
    }

    if (!skipRemindTime && Math.round(user.time) < 30) {
        _this.onNavi('/remindTime', { remainDays: Math.round(user.time), sizeFitContent: true });
    }
}

export function isMobile() {
    var userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipod|ipad/.test(userAgent);
}

export function countAPIGoogle(apiName: any) {
    axiosInstance
        .put(HOST() + "/Form/V1/Dat/apigoogle/add?apiName=" + apiName, { apiName })
        .then((res: any) => { });
}

export function formatDateFrom(that: any, e: any, key: string) {
    const value = e.target.value;
    let parsed = '';
    if (/(\d{4}).?(\d{2}).?(\d{2})/g.test(value)) parsed = value.replace(/(\d{4}).?(\d{2}).?(\d{2})/g, '$1/$2/$3');
    parsed = parsed.substr(0, 10);
    if (parsed && parsed != e.target.value) {
        const state: any = {};
        state[key] = new Date(parsed);

        if (Object.prototype.toString.call(state[key]) === "[object Date]") {
            // it is a date
            if (isNaN(state[key].getTime())) {  // d.valueOf() could also work
                state[key] = new Date();
            } else {
                // date is valid
            }
        } else {
            state[key] = new Date();
        }

        that.updateState(state);
    }
}

async function checkSecret() {
    if (!Cookies.get('k')) {
        const key = await genkey();
        const eptKey = await exportKey(key);
        Cookies.set('k', eptKey, { expires: 365 });
        Cookies.remove('p');
        Cookies.remove('u');
    }
}

export async function encryptData(plainText: any) {
    if (!window.crypto.subtle) {
        return btoa(plainText);
    }
    await checkSecret();
    const key: any = await importKey();
    return await window.crypto.subtle.encrypt(
        {
            name: "AES-CTR",
            counter: new Uint8Array(16),
            length: 128
        },
        key,
        str2ab(plainText)
    ).then(r => {
        const passEcrypted: any = new Uint8Array(r);
        return ab2str(passEcrypted);
    });
}

export async function decryptData(data: any) {
    if (!window.crypto.subtle) {
        return atob(data);
    }
    const key: any = await importKey();
    return await window.crypto.subtle.decrypt(
        {
            name: "AES-CTR",
            counter: new ArrayBuffer(16),
            length: 128,
        },
        key,
        str2ab(data)
    ).then((decrypted) => ab2str(new Uint8Array(decrypted)));
}

function str2ab(str: any) {
    var buf = new ArrayBuffer(str.length); 
    var bufView = new Uint8Array(buf);
    for (var i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

function ab2str(buf: any) {
    return String.fromCharCode.apply(null, buf);
}

async function genkey() {
    return await window.crypto.subtle.generateKey(
        { name: "AES-CTR", length: 256 },
        true,
        ["encrypt", "decrypt"]
    ).then(k => k)
}

async function exportKey(key: any) {
    return await window.crypto.subtle.exportKey("jwk", key).then(r => r);
}

async function importKey() {
    const savedInfo: JsonWebKey = Cookies.getJSON('k');
    const name: AlgorithmIdentifier = 'AES-CTR';

    return await window.crypto.subtle.importKey(
        "jwk",
        savedInfo,
        name,
        true,
        ["encrypt", "decrypt"]
    ).then(r => r);
}

export const reloadTerminal = (_this: any) => _this.refs.TerminalList && _this.refs.TerminalList.getData();
export const reloadAudio = (_this: any) => _this.refs.AudioList && _this.refs.AudioList.getData();
export const reloadRoute = (_this: any) => _this.refs.RouteList && _this.refs.RouteList.getData();