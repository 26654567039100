import React from 'react'
import logo from '../../assets/img/logo.png';
import axiosInstance from '../interceptor';
import { HOST } from '../constant';

export default class Logo extends React.Component<{
    className: any,
    width?: any,
    height?: any,
    style?: any
}, {
    id: any,
}> {
    constructor(props: any) {
        super(props)
        this.state = {
            id: Math.random(),
        }
    }

    componentDidMount() {
       /*axiosInstance
        .get(HOST() + '/Form/V1/Mst/Version?cliKey=PC')
        .then(response => {
            const canvas: any = document.getElementById('textCanvas' + this.state.id);
            const tCtx: any = canvas.getContext('2d');
            const imageElem: any = document.getElementById('textImage' + this.state.id);
            const oriImage: any = document.getElementById('oriImage' + this.state.id);
            tCtx.canvas.width = oriImage.width;
            tCtx.canvas.height = oriImage.height;
            tCtx.font = "bold 53px Tahoma";
            tCtx.fillStyle = "#6090bf";
            tCtx.fillText(response.data.data || "1.0", 350, 403);
            tCtx.drawImage(oriImage, 0, 0);
            imageElem.src = tCtx.canvas.toDataURL();
        });*/
    }

    render() {
        return (
            <div style={this.props.style ? { "display": this.props.style } : {}}>
                <img src={logo} id={"oriImage" + this.state.id} style={{ display: "none" }} />
                <canvas id={"textCanvas" + this.state.id} style={{ display: "none" }} ></canvas>
                <div className={this.props.className}>
                    <img src={logo} alt="Logo" id={"textImage" + this.state.id} height={this.props.height} width={this.props.width}/>
                </div>
            </div>
        );
    }
}