import React from "react";
import { HOST } from '../../common/constant';
import axiosInstance from "../../common/interceptor";
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import icPlusGreen from "../../assets/img/ic_plus_green.png";
import { Button } from "reactstrap";
import { getValidNumber, isBlank, toastOptions } from "../../common/Utils";
import { toast } from "react-toastify";
import BaseSelectList from "../../common/BaseSelectList/BaseSelectList";
import { error } from "console";


export default class AutoSendMessage extends React.Component<{
    onNavi: Function
}, {
    listData: any[],
    showAddComponent: boolean,
    sectionTitle: any,
    sectionButton: any,
    // ----- form ---------
    areaID: any,
    alertCount: any,
    reciveAreaID: any,
    message: any,
    soundID: any,
    updDT: any,
    error: any,
    areaMap: any,
    soundMap: any,
    isBelow: any
}> {
    addFormComponent: any;
    constructor(props: any) {
        super(props)
        this.state = {
            listData: [],
            showAddComponent: false,
            sectionTitle: '新規登録',
            sectionButton: '登録',
            areaID: '',
            alertCount: '',
            reciveAreaID: '',
            message: '',
            soundID: '',
            updDT: null,
            error: {},
            areaMap: {},
            soundMap: {},
            isBelow: 0
        }
    };

    componentDidMount() {
        this.getlistData();
    }
    private getlistData() {
        axiosInstance
            .get(HOST() + "/Form/V1/Dat/Rigou/GetAllRigou")
            .then(response => {
                const messages = response.data.data;
                this.setState({ listData: messages });
            })
        axiosInstance
            .get(HOST() + "/Form/V1/Dat/Area/ListAvailable")
            .then(response => {
                const areaMap: any = {};
                const data = response.data.data;
                data.forEach((e: any) => { areaMap[e.areaID] = e.areaNm });
                this.setState({ areaMap })
            })
        axiosInstance
            .get(HOST() + "/Form/V1/Dat/Sound/ListAll")
            .then(response => {
                const data = response.data.data;
                const soundMap: any = {};
                data.forEach((e: any) => { soundMap[e.soundID] = e.soundNm });
                this.setState({ soundMap })
            })
    }

    onCancel = (path: any) => {
        if (this.state.showAddComponent) {
            this.setState({ showAddComponent: false });
        }
        else {
            this.props.onNavi('/');
        }
    }

    onEditDeleteItem = (object: any) => {
        this.setItemData(object);
        this.setState({ showAddComponent: true });
    }

    onDeleteItem = (item: any) => {
        if (!window.confirm("削除してもよろしいですか。")) return;

        axiosInstance.delete(`${HOST()}/Form/V1/Dat/Rigou/DeleteRigou?areaID=${item.areaID}&&recieveAreaID=${item.reciveAreaID}`)
            .then(() => {
                this.setState({ showAddComponent: false });
                this.getlistData();
                toast.success('自動メッセージ送信設定を削除しました。', toastOptions);
            });
    }

    onAddItem = () => {
        this.setItemData(null);
        this.setState({ showAddComponent: true });
    }

    setItemData(item: any) {
        if (item) {
            this.setState({
                areaID: item.areaID,
                alertCount: item.alertCount,
                reciveAreaID: item.reciveAreaID,
                message: item.message,
                soundID: item.soundID,
                updDT: item.updDT,
                sectionTitle: '編集',
                sectionButton: '編集',
                error: {},
                isBelow: item.isBelow
            });
        }
        else {
            this.setState({
                areaID: '',
                alertCount: '',
                reciveAreaID: '',
                message: '',
                soundID: '',
                updDT: null,
                sectionTitle: '新規登録',
                sectionButton: '登録',
                error: {},
                isBelow: 0
            });
        }
    }

    handleSubmitAdd = () => {
        const error: any = {};
        if (isBlank(this.state.areaID)) error.areaFrom = "メッセージ送信エリアを選択してください。";
        if (isBlank(this.state.reciveAreaID)) error.areaTo = "メッセージ受信エリアを選択してください。";
        if (isBlank(this.state.alertCount)) error.unitCount = "台数を入力してください。";
        if (isBlank(this.state.message) && isBlank(this.state.soundID)) {
            error.message = "メッセージ内容又は音声を設定してください。";
        }
        this.setState({ error });

        if (Object.keys(error).length > 0) {
            return;
		}
        var body: any = {
            areaID: Number(this.state.areaID),
            alertCount: this.state.alertCount,
            reciveAreaID: Number(this.state.reciveAreaID),
            message: this.state.message,
            soundID: Number(this.state.soundID),
            updDT: this.state.updDT,
            isBelow: this.state.isBelow
        };

        const api = axiosInstance.put(HOST() + "/Form/V1/Dat/Rigou/UpdateRigou", body);

        api.then(res => {
            if (res.status == 200 && res.data) {
                this.getlistData();
                toast.success((body.id > 0) ? "自動メッセージ送信設定を編集しました。" : "自動メッセージ送信設定を登録しました。", toastOptions);
                this.setState({ showAddComponent: false });
            }
        })
    }
    getRecieveAreaLink() {
        if (!this.state.areaID) return "";
        let defaultDate = '2021-01-01 00:00:00'
        return `/Form/V1/Dat/Area/ListAvailableRigou?areaID=${this.state.areaID}&updDT=${this.state.updDT || defaultDate}`;
    }
    public render() {
        return (
            <div>
                <div className="box">
                    <div className="box-title d-flex justify-content-between align-items-center">
                        <span className="page-header">自動メッセージ送信</span>
                        <div className="buttonEdit">
                            <button type="button" className="btn btn-primary btn-sm mar-5 btn-135" onClick={this.onCancel} >キャンセル</button>
                        </div>
                    </div>

                    <header className="th-header">
                        <span style={{ position: "absolute", marginTop: "10px", left: "45%", fontSize: "15px" }}>設定一覧</span>
                        <Button onClick={this.onAddItem} className="align-right plus-button button-img">
                            <img src={icPlusGreen} width="27" height="27" className="border rounded-circle" />
                        </Button>
                    </header>
                    <table style={{ marginBottom: "10px" }}>
                        <tbody>
                            <tr>
                                <th>メッセージ送信エリア名称</th>
                                <th>台数</th>
                                <th>メッセージ受信エリア名称</th>
                                <th>メッセージ内容</th>
                                <th>以下／以上</th>
                                <th>音声 </th>
                                <th>編集</th>
                            </tr>
                            {this.state.listData.map((item, index) => (
                                <tr key={`messageSetting${item.id}&${index}`}>
                                    <td style={{ textAlign: 'left', padding: '5px' }}>{this.state.areaMap[item.areaID]}</td>
                                    <td style={{ textAlign: 'left', padding: '5px' }}>{item.alertCount}</td>
                                    <td style={{ textAlign: 'left', padding: '5px' }}>{this.state.areaMap[item.reciveAreaID]}</td>
                                    <td style={{ textAlign: 'left', padding: '5px' }}>{item.message}</td>
                                    <td style={{ textAlign: 'left', padding: '5px' }}>{item.isBelow ? "以下" : "以上"}</td>
                                    <td style={{ textAlign: 'left', padding: '5px' }}>{this.state.soundMap[item.soundID]}</td>
                                    <td>
                                        <Button onClick={() => this.onEditDeleteItem(item)} className="button-img btn-50">
                                            <FontAwesomeIcon icon={faEdit} color="#000000"></FontAwesomeIcon></Button>
                                        <Button onClick={() => this.onDeleteItem(item)} className="button-img">
                                            <FontAwesomeIcon icon={faTrash} color="#000000"></FontAwesomeIcon>
                                        </Button>
                                    </td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </table>
                    <div id="addComponent" style={{ display: this.state.showAddComponent ? "block" : "none" }}>
                        <form>
                            <header className="th-header bg-blue">
                                <span style={{ position: "absolute", marginTop: "10px", left: "45%", fontSize: "15px" }}>{this.state.sectionTitle}</span>
                            </header>
                            <table onSubmit={this.handleSubmitAdd} id="auto-message">
                                <tbody>
                                    <tr>
                                        <th>メッセージ送信エリア名称</th>
                                        <th>台数</th>
                                        <th>メッセージ受信エリア名称</th>
                                        <th>メッセージ内容</th>
                                        <th>以下／以上</th>
                                        <th>音声 </th>
                                        <th style={{ width: "10%" }}>{this.state.sectionButton}</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <BaseSelectList
                                                dkey="areaID"
                                                dvalue="areaNm"
                                                fetchUrl={"/Form/V1/Dat/Area/ListAvailable"}
                                                onChange={(e: any) => this.setState({ areaID: e.target.value })}
                                                value={this.state.areaID}
                                            />
                                            <div className="errClass">{this.state.error['areaFrom']}</div>
                                        </td>
                                        <td>
                                            <input type="number"
                                                className="form-control form-control-sm"
                                                value={this.state.alertCount}
                                                onChange={e => this.setState({ alertCount: getValidNumber(e, { maxValue: 999, minValue: 0, roundTo: 0 }) })} />
                                            <div className="errClass">{this.state.error['unitCount']}</div>
                                        </td>
                                        <td>
                                            <BaseSelectList
                                                dkey="areaID"
                                                dvalue="areaNm"
                                                fetchUrl={this.getRecieveAreaLink()}
                                                onChange={(e: any) => this.setState({ reciveAreaID: e.target.value })}
                                                value={this.state.reciveAreaID}
                                            />
                                            <div className="errClass">{this.state.error['areaTo']}</div>
                                        </td>
                                        <td>
                                            <input type="text" className="form-control form-control-sm" value={this.state.message} onChange={e => this.setState({ message: e.target.value })} maxLength={ 100 }/>
                                            <div className="errClass">{this.state.error['message']}</div>
                                        </td>
                                        <td>
                                        <select 
                                            className="form-control form-control-sm" 
                                            id="highlow"
                                            onChange={e => this.setState({isBelow: Number(e.target.value)})}
                                            value={this.state.isBelow}
                                        >
                                            <option value="1">以下</option>
                                            <option value="0">以上</option>
                                        </select>
                                        </td>
                                        <td>
                                            <BaseSelectList
                                                dkey="soundID"
                                                dvalue="soundNm"
                                                fetchUrl={"/Form/V1/Dat/Sound/ListAll"}
                                                onChange={(e: any) => this.setState({ soundID: e.target.value })}
                                                value={this.state.soundID}
                                            />
                                            <div className="errClass">{this.state.error['message']}</div>
                                        </td>
                                        <td>
                                            <Button onClick={this.handleSubmitAdd} className="btn btn-primary btn-sm register">{this.state.sectionButton}</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
        )
    };
}